.Ots_ppr_test_intructions_view_wrapper {
  padding-top: 50px;
  //   width: 70%;
  margin: 0 auto;
  max-width: 1320px;

  .ots_test_intructions_view_inner_wrapper {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;

    //istruction Screen
    .OtsInstructionScreenBox {
      .backBtn {
        cursor: pointer;
        display: inline-block;
      }

      .ots_instruction_title {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .ots_instruction_detailBox {
        max-width: 750px;
        margin: 0 auto;

        .OtsExamTime {
          border: 1px solid #ffdec2;
          background: #ff933a0d;
          padding: 15px;
          text-align: center;
          border-radius: 10px;
          margin-bottom: 20px;

          .OtsExamTitle {
            color: #f1a769;
            margin-bottom: 8px;
          }

          .OtsExamDurations {
          }
        }

        .OtsInstructions {
          border: 1px solid #ffdec2;
          border-radius: 10px;
          background: #ff933a0d;
          margin-bottom: 15px;

          h3 {
            font-size: 20px;
            border-bottom: 1px solid #ffdec2;
            padding: 10px 15px;
          }

          .instPoint {
            padding: 10px 15px;

            img {
              width: 100%;
            }

            p {
              font-size: 14px;
              color: #000;
            }

            h6 {
            }
          }
        }

        .img {
          text-align: center;
          padding: 15px 0px;
        }

        .OtsStartTestBtn {
          button {
            background: #6d48ef;
            width: 100%;
            color: #fff;
            padding: 7px 10px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

.detailsModal {
  font-family: "Poppins";
  font-style: normal;
  margin-top: 100px;
  h3 {
    text-align: start;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #303030;
  }

  .form-group {
    margin-bottom: 20px;
    label {
      font-weight: 400;
      font-size: 17px;
      line-height: 27px;
      color: #444444;
    }
    span {
      color: #940001;
    }

    input::placeholder {
      color: #a2a2a2;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    input[type="submit"] {
      cursor: pointer;
      border: 1px solid #6d48ef;
      border-radius: 6px;
      height: 40px;
      width: 100%;
      background: linear-gradient(
        90.65deg,
        #6d48ef 0.21%,
        rgba(58, 19, 196, 0.93) 97.55%
      );
      box-shadow: 0px 2px 8px rgba(109, 72, 239, 0.16);
      border-radius: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;
      margin-bottom: -20px;
    }
  }
  .submit_btn {
    cursor: pointer;
    border: 1px solid #6d48ef;
    border-radius: 6px;
    height: 40px;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(109, 72, 239, 0.16);
    border-radius: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
  }
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .Ots_ppr_test_intructions_view_wrapper {
    padding-top: 0;
    .ots_test_intructions_view_inner_wrapper {
      height: 100vh;
      .OtsInstructionScreenBox {
          .ots_instruction_title {
            font-size: 22px !important;
          }
      
          .OtsInstructions {
            .instPoint {
              height: 50vh;
              overflow-y: scroll;
            }
          }
        }
    }
  }
}