.howItWorksContainer {
  .howItWorksTitle {
    font-size: 24px;
    margin-bottom: 25px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;
  }

  .howItWorksContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .howItWorksImages {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      img {
        width: 66px;
        height: auto;
        margin: 0 20px;
      }

      .dottedLine {
        width: 193px;
        margin: 0 -15px;
        margin-top: -60px !important;
      }
    }

    .howItWorksSteps {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .howItWorksStep {
        flex: 1;
        padding: 0 10px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #2d2d2d;
      }
    }
  }
}
@media (max-width: 578.98px) {
  .howItWorksContainer {
    padding: 10px;
    .howItWorksTitle {
      margin-bottom: 10px;
    }
    .howItWorksContent {
      .howItWorksImages {
        .dottedLine {
          display: none;
        }
      }
      .howItWorksSteps {
        .howItWorksStep {
          padding: 0 5px;
        }
      }
    }
  }
}
