.pay_now {
  margin-top: 20px;
  button {
    // background: #059249;
    background: #ff933a;
    letter-spacing: 0.36px;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    width: 100%;
    padding: 10px 0px;
    font-family: "Open Sans";
    font-style: normal;
    font-size: 18px;
    line-height: 27px;
  }
  a {
    // background: #059249;
    letter-spacing: 0.36px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    padding: 8px 10px;
    width: 100%;
    display: inline-block;
    text-decoration: none;
  }
}
