// .signUp_step_two_wrapper {
//   display: flex;
//   min-height: 100vh;
//   .signup_left_part{
//     width: 50%;
//     background-color: #E00116;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .logo{
//       img{}
//     }
//   }
//   .signup_right_form {
//     width: 50%;
//    .signup_form{
//      display: flex;
//      align-items: center;
//      justify-content: center;
//      min-height: 100vh;
//       .content_inner_wrapper {
//         width: 450px;
//         padding: 20px;
//         .back_btn_wrapper {
//           margin-bottom: 30px;
//           button {
//             border: none;
//             outline: none;
//             background: transparent;
//           }
//         }
//         .targets_wrapper {
//           .text_content_wrapper {
//             margin: 10px 0 20px;
//             .text_content {
//               margin: 0;
//               font-size: 18px;
//               font-weight: 600;
//               line-height: 1.33;
//               text-align: left;
//               color: #0f0404;
//               text-align: center;
//             }
//           }
//           .target_progrs_bar{
//             display: flex;
//             justify-content: space-between;
//             position: relative;
//             max-width: 80%;
//             margin: 0 auto 30px;
//             .first_box{
//               display: inline-block;
//               text-align: -webkit-center;
//               vertical-align: top;
//               .circle{
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 width: 35px;
//                 height: 35px;
//                 border: 1px solid #F3F3F3;
//                 background-color: #fff;
//                 color: #ACACAC;
//                 text-align: center;
//                 border-radius: 50%;
//                 position: relative;
//               }
//               span{
//                 font-size: 14px;
//               }
//             }
//             .line_div{
//               top: 15px;
//               position: absolute;
//               width: 95%;
//               line-height: 0px;
//               background-color: #F3F3F3;
//               z-index: -1;
//               left: 2%;
//               height: 3px;
//               .line{
//              width: 150px;
//             height: 3px;
//             display: inline-block;
//             position: relative;
//             line-height: 0px;
//             top: -2px;
//             }
//             }
//             .bg_color{
//               background-color: #FF8826;
//               color: #fff;
//             }
//           }
//           .radio_collection_wrapper {
//             display: flex;
//             justify-content: flex-start;
//             align-items: center;
//             flex-wrap: wrap;
//             .single_select {
//               margin: 5px;
//               input[type="radio"] {
//                 opacity: 0;
//                 position: fixed;
//                 width: 0;
//               }
//               label {
//                 background: #F3F3F3;
//                 padding: 6px 20px;
//                 border-radius: 6px;
//                 font-size: 16px;
//                 font-weight: 500;
//                 letter-spacing: 0.2px;
//                 color: #6b6876;
//                 cursor: pointer;
//                 display: flex;
//                 align-items: center;
//                 &:hover{
//                   background: transparent linear-gradient(326deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//                   border: none;
//                   color: #ffffff;
//                 }
//                 img {
//                   margin-right: 5px;
//                 }
//               }
//               input[type="radio"]:checked + label {
//                 background: transparent linear-gradient(326deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//                 border: none;
//                 color: #ffffff;
//               }
//             }
//           }
//         }
//         .classes_wrapper {
//           .text_content_wrapper {
//             margin: 10px 0;
//             .text_content {
//               margin: 0;
//               font-size: 15px;
//               font-weight: 600;
//               line-height: 1.33;
//               text-align: left;
//               color: #0f0404;
//               text-align: center;
//             }
//           }
//           .target_progrs_bar{
//             display: flex;
//             justify-content: space-between;
//             position: relative;
//             max-width: 80%;
//             margin: 0 auto 30px;
//             .first_box{
//               display: inline-block;
//               text-align: -webkit-center;
//               vertical-align: top;
//               .circle{
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 width: 35px;
//                 height: 35px;
//                 border: 1px solid #F3F3F3;
//                 background-color: #fff;
//                 color: #ACACAC;
//                 text-align: center;
//                 border-radius: 50%;
//                 position: relative;
//               }
//               span{
//                 font-size: 14px;
//               }
//               .bg_color{
//               background-color: #FF8826;
//               color: #fff;
//             }
//             }
//             .line_div{
//               top: 15px;
//               position: absolute;
//               width: 95%;
//               line-height: 0px;
//               background-color: #F3F3F3;
//               z-index: -1;
//               left: 2%;
//               height: 3px;
//               .line{
//             height: 3px;
//             display: inline-block;
//             position: relative;
//             line-height: 0px;
//             top: -2px;
//             }
//             .bg_clr{
//               background: transparent linear-gradient(90deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//               width: 50%;
//             }
//             }

//           }
//           .radio_collection_wrapper {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             flex-wrap: wrap;
//             .single_select {
//               margin: 5px;
//               input[type="radio"] {
//                 opacity: 0;
//                 position: fixed;
//                 width: 0;
//               }
//               label {
//                 background: #F3F3F3;
//                 padding: 6px 20px;
//                 border-radius: 6px;
//                 font-size: 16px;
//                 font-weight: 500;
//                 letter-spacing: 0.2px;
//                 color: #6b6876;
//                 cursor: pointer;
//                 display: flex;
//                 align-items: center;
//                 &:hover{
//                   background: transparent linear-gradient(326deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//                   border: none;
//                   color: #ffffff;
//                 }
//                 img {
//                   margin-right: 5px;
//                 }
//               }
//               input[type="radio"]:checked + label {
//                 background: transparent linear-gradient(326deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//                 border: none;
//                 color: #ffffff;
//               }
//             }
//           }
//         }
//         .courses_wrapper {
//           .text_content_wrapper {
//             margin: 10px 0;
//             .text_content {
//               margin: 0;
//               font-size: 15px;
//               font-weight: 600;
//               line-height: 1.33;
//               text-align: center;
//               color: #0f0404;
//             }
//           }
//           .target_progrs_bar{
//             display: flex;
//             justify-content: space-between;
//             position: relative;
//             max-width: 80%;
//             margin: 0 auto 30px;
//             .first_box{
//               display: inline-block;
//               text-align: -webkit-center;
//               vertical-align: top;
//               .circle{
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 width: 35px;
//                 height: 35px;
//                 border: 1px solid #F3F3F3;
//                 background-color: #fff;
//                 color: #ACACAC;
//                 text-align: center;
//                 border-radius: 50%;
//                 position: relative;
//               }
//               span{
//                 font-size: 14px;
//               }
//               .bg_color{
//               background-color: #FF8826;
//               color: #fff;
//             }
//             }
//             .line_div{
//               top: 15px;
//               position: absolute;
//               width: 95%;
//               line-height: 0px;
//               background-color: #F3F3F3;
//               z-index: -1;
//               left: 2%;
//               height: 3px;
//               .line{
//             height: 3px;
//             display: inline-block;
//             position: relative;
//             line-height: 0px;
//             top: -2px;
//             }
//             .bg_clr{
//               background: transparent linear-gradient(90deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//               width: 100%;
//             }
//             }

//           }
//           .radio_collection_wrapper {
//             display: flex;
//             justify-content: flex-start;
//             align-items: center;
//             flex-wrap: wrap;
//             .single_select {
//               margin: 5px;
//               input[type="radio"] {
//                 opacity: 0;
//                 position: fixed;
//                 width: 0;
//               }
//               label {
//                 background: #F3F3F3;
//                 padding: 6px 20px;
//                 border-radius: 6px;
//                 font-size: 16px;
//                 font-weight: 500;
//                 letter-spacing: 0.2px;
//                 color: #6b6876;
//                 cursor: pointer;
//                 display: flex;
//                 align-items: center;
//                 &:hover{
//                   background: transparent linear-gradient(326deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//                   border: none;
//                   color: #ffffff;
//                 }
//                 img {
//                   margin-right: 5px;
//                 }
//               }
//               input[type="radio"]:checked + label {
//                 background: transparent linear-gradient(326deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//                 border: none;
//                 color: #ffffff;
//               }
//             }
//           }
//           .course_description{
//             margin-top: 15px;
//             h6{}
//             p{
//               font-size: 14px;
//             }
//           }
//         }
//         .batches_wrapper {
//           .text_content_wrapper {
//             margin: 10px 0;
//             .text_content {
//               margin: 0;
//               font-size: 15px;
//               font-weight: 600;
//               line-height: 1.33;
//               text-align: left;
//               color: #0f0404;
//             }
//           }
//           .radio_collection_wrapper {
//             display: flex;
//             justify-content: flex-start;
//             align-items: center;
//             flex-wrap: wrap;
//             .single_select {
//               margin: 5px;
//               input[type="radio"] {
//                 opacity: 0;
//                 position: fixed;
//                 width: 0;
//               }
//               label {
//                 background: #F3F3F3;
//                 padding: 6px 20px;
//                 border-radius: 6px;
//                 font-size: 16px;
//                 font-weight: 500;
//                 letter-spacing: 0.2px;
//                 color: #6b6876;
//                 cursor: pointer;
//                 display: flex;
//                 align-items: center;
//                 &:hover{
//                   background: transparent linear-gradient(326deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//                   border: none;
//                   color: #ffffff;
//                 }
//                 img {
//                   margin-right: 5px;
//                 }
//               }
//               input[type="radio"]:checked + label {
//                 background: transparent linear-gradient(326deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
//                 border: none;
//                 color: #ffffff;
//               }
//             }
//           }
//         }
//         .select_wrapper {
//           margin: 15px 0;
//           select {
//             width: 100%;
//             height: 50px;
//             border-radius: 25px;
//             border: solid 1px #f2f2f3;
//             background-color: #faf9fe;
//             padding: 0 10px;
//           }
//         }
//         .terms_n_condn_wrapper {
//           margin: 5px 0 10px;
//           .terms_n_condn_inner_wrapper {
//             text-align: center;
//             input[type="checkbox"] {
//               margin: 0 10px;
//             }
//             label {
//             }
//           }
//         }
//         .btn_wrapper {
//           margin: 20px 0;
//           text-align: center;
//           button {
//             border: none;
//             outline: none;
//             width: 100%;
//             height: 42px;
//             border-radius: 8px;
//             background-color: #6d48ef;
//             font-size: 18px;
//             font-weight: 600;
//             color: #ffffff;
//           }
//         }
//       }
//     }
//     }
// }

// // Large devices (desktops, less than 1200px)
// @media (max-width: 1199.98px) {
// }

// // Medium devices (tablets, less than 992px)
// @media (max-width: 991.98px) {
// }

// // X-Small devices (portrait phones, less than 576px)
// @media (max-width: 575.98px) {
//   .signUp_step_two_wrapper {
//     .signUp_step_two_inner_wrapper {
//       .content_wrapper {
//         width: 100%;
//         .content_inner_wrapper {
//           width: 100%;
//           padding: 30px 15px;
//         }
//       }
//     }
//   }
// }

.signUp_step_two_wrapper {
  display: flex;
  min-height: 100vh;
  .signup_left_part {
    width: 50%;
    background-color: #e00116;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      img {
      }
    }
  }
  .signup_right_form {
    width: 50%;
    .signup_form {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      .content_inner_wrapper {
        width: 450px;
        padding: 20px;

        .back_btn_wrapper {
          margin-bottom: 30px;
          button {
            border: none;
            outline: none;
            background: transparent;
          }
        }

        .targets_wrapper {
          .text_content_wrapper {
            margin: 10px 0 20px;
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              line-height: 1.33;
              text-align: left;
              color: #0f0404;
              text-align: center;
            }
          }
          .target_progrs_bar {
            display: flex;
            justify-content: space-between;
            position: relative;
            max-width: 80%;
            margin: 0 auto 30px;
            .first_box {
              display: inline-block;
              text-align: -webkit-center;
              vertical-align: top;
              .circle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                border: 1px solid #f3f3f3;
                background-color: #fff;
                color: #acacac;
                text-align: center;
                border-radius: 50%;
                position: relative;
              }
              span {
                font-size: 14px;
              }
            }
            .line_div {
              top: 15px;
              position: absolute;
              width: 95%;
              line-height: 0px;
              background-color: #f3f3f3;
              z-index: -1;
              left: 2%;
              height: 3px;
              .line {
                width: 150px;
                height: 3px;
                display: inline-block;
                position: relative;
                line-height: 0px;
                top: -2px;
              }
            }
            .bg_color {
              background-color: #ff8826;
              color: #fff;
            }
          }
          .radio_collection_wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .single_select {
              margin: 5px;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                background: #f3f3f3;
                padding: 6px 20px;
                border-radius: 6px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                display: flex;
                align-items: center;
                &:hover {
                  background: transparent
                    linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                    no-repeat padding-box;
                  border: none;
                  color: #ffffff;
                }
                img {
                  margin-right: 5px;
                }
              }
              input[type="radio"]:checked + label {
                background: transparent
                  linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                  no-repeat padding-box;
                border: none;
                color: #ffffff;
              }
            }
          }
        }

        .classes_wrapper {
          .text_content_wrapper {
            margin: 10px 0;
            .text_content {
              margin: 0;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.33;
              text-align: left;
              color: #0f0404;
              text-align: center;
            }
          }
          .target_progrs_bar {
            display: flex;
            justify-content: space-between;
            position: relative;
            max-width: 80%;
            margin: 0 auto 30px;
            .first_box {
              display: inline-block;
              text-align: -webkit-center;
              vertical-align: top;
              .circle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                border: 1px solid #f3f3f3;
                background-color: #fff;
                color: #acacac;
                text-align: center;
                border-radius: 50%;
                position: relative;
              }
              span {
                font-size: 14px;
              }
              .bg_color {
                background-color: #ff8826;
                color: #fff;
              }
            }
            .line_div {
              top: 15px;
              position: absolute;
              width: 95%;
              line-height: 0px;
              background-color: #f3f3f3;
              z-index: -1;
              left: 2%;
              height: 3px;
              .line {
                height: 3px;
                display: inline-block;
                position: relative;
                line-height: 0px;
                top: -2px;
              }
              .bg_clr {
                background: transparent
                  linear-gradient(90deg, #ff8826 0%, #ffc697 100%) 0% 0%
                  no-repeat padding-box;
                width: 50%;
              }
            }
          }
          .radio_collection_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .single_select {
              margin: 5px;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                background: #f3f3f3;
                padding: 6px 20px;
                border-radius: 6px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                display: flex;
                align-items: center;
                &:hover {
                  background: transparent
                    linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                    no-repeat padding-box;
                  border: none;
                  color: #ffffff;
                }
                img {
                  margin-right: 5px;
                }
              }
              input[type="radio"]:checked + label {
                background: transparent
                  linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                  no-repeat padding-box;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
        .courses_wrapper {
          .text_content_wrapper {
            margin: 10px 0;
            .text_content {
              margin: 0;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.33;
              text-align: center;
              color: #0f0404;
            }
          }
          .target_progrs_bar {
            display: flex;
            justify-content: space-between;
            position: relative;
            max-width: 80%;
            margin: 0 auto 30px;
            .first_box {
              display: inline-block;
              text-align: -webkit-center;
              vertical-align: top;
              .circle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                border: 1px solid #f3f3f3;
                background-color: #fff;
                color: #acacac;
                text-align: center;
                border-radius: 50%;
                position: relative;
              }
              span {
                font-size: 14px;
              }
              .bg_color {
                background-color: #ff8826;
                color: #fff;
              }
            }
            .line_div {
              top: 15px;
              position: absolute;
              width: 95%;
              line-height: 0px;
              background-color: #f3f3f3;
              z-index: -1;
              left: 2%;
              height: 3px;
              .line {
                height: 3px;
                display: inline-block;
                position: relative;
                line-height: 0px;
                top: -2px;
              }
              .bg_clr {
                background: transparent
                  linear-gradient(90deg, #ff8826 0%, #ffc697 100%) 0% 0%
                  no-repeat padding-box;
                width: 100%;
              }
            }
          }
          .radio_collection_wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .single_select {
              margin: 5px;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                background: #f3f3f3;
                padding: 6px 20px;
                border-radius: 6px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                display: flex;
                align-items: center;
                &:hover {
                  background: transparent
                    linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                    no-repeat padding-box;
                  border: none;
                  color: #ffffff;
                }
                img {
                  margin-right: 5px;
                }
              }
              input[type="radio"]:checked + label {
                background: transparent
                  linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                  no-repeat padding-box;
                border: none;
                color: #ffffff;
              }
            }
          }
          .course_description {
            margin-top: 15px;
            h6 {
            }
            p {
              font-size: 14px;
            }
          }
        }
        .batches_wrapper {
          .text_content_wrapper {
            margin: 10px 0;
            .text_content {
              margin: 0;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.33;
              text-align: left;
              color: #0f0404;
            }
          }
          .radio_collection_wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .single_select {
              margin: 5px;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                background: #f3f3f3;
                padding: 6px 20px;
                border-radius: 6px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                display: flex;
                align-items: center;
                &:hover {
                  background: transparent
                    linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                    no-repeat padding-box;
                  border: none;
                  color: #ffffff;
                }
                img {
                  margin-right: 5px;
                }
              }
              input[type="radio"]:checked + label {
                background: transparent
                  linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                  no-repeat padding-box;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
        .select_wrapper {
          margin: 15px 0;
          select {
            width: 100%;
            height: 50px;
            border-radius: 25px;
            border: solid 1px #f2f2f3;
            background-color: #faf9fe;
            padding: 0 10px;
          }
        }
        .terms_n_condn_wrapper {
          margin: 5px 0 10px;
          .terms_n_condn_inner_wrapper {
            text-align: center;
            input[type="checkbox"] {
              margin: 0 10px;
            }
            label {
            }
          }
        }
      }

      .btn_wrapper {
        button {
          margin: 0 8px;
          padding: 10px 20px;
          background-color: #6d48ef;
          color: #fff;
          border: none;
          cursor: pointer;
          outline: none;
          border-radius: 4px;
          width: 45%;
          &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .signUp_step_two_wrapper {
    .signUp_step_two_inner_wrapper {
      .content_wrapper {
        width: 100%;
        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;
        }
      }
    }
  }
}
