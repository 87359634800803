.parent-header_left_wrapper {
  .logo {
    width: 100px;
    display: inline-block;
    img {
      width: 100%;
    }
  }

  .left_menu {
    display: inline-block;
    margin-left: 60px;

    ul {
      margin-bottom: 0px;
      li {
        list-style: none;
        display: inline-block;
        line-height: normal;
        margin-right: 30px;
        &:last-child {
          margin-right: 0px;
        }

        .menu-link {
          text-decoration: none;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #121212;
        }

        .current {
          color: #6d48ef;
          text-decoration: underline;
          text-underline-offset: 6px;
        }
      }
    }
  }
}
