// .TestReportContentComponentWrapper {
//   margin-top: -30px;
//   .papers_tab_wrapper {
//     background-color: #ffffff;
//     border-radius: 10px;

//     .outer_content_wrapper {
//       .content_wrapper {
//         padding: 20px 0;

//         .left_side_content_wrapper {
//           text-align: -webkit-center;
//           margin-bottom: 25px;
//           text-align: center;

//           .paper_type_collection_wrapper {
//             display: inline-block;
//             background-color: #f5f4f8;
//             border-radius: 25px;
//             overflow: hidden;

//             .single_select_paper_type {
//               width: auto;
//               min-width: 160px;
//               display: inline-block;

//               input[type="radio"] {
//                 opacity: 0;
//                 position: fixed;
//                 width: 0;
//               }

//               label {
//                 background-color: #fafafd;
//                 padding: 0.5rem 1rem;
//                 font-size: 15px;
//                 font-weight: 500;
//                 letter-spacing: 0.2px;
//                 color: #6b6876;
//                 cursor: pointer;
//                 width: 100%;
//                 text-align: center;
//               }

//               input[type="radio"]:checked + label {
//                 background-color: #ff933a;
//                 border: none;
//                 color: #ffffff;
//               }
//             }
//           }
//         }

//         .right_side_content_wrapper {
//           flex: 0 0 65%;
//           padding: 0 20px;

//           .paper1 {
//             margin-top: 40px;

//             section {
//               margin-bottom: 30px;
//             }

//             .cards {
//               display: flex;
//               justify-content: space-between;
//               align-items: center;
//               width: 100%;
//               .card {
//                 width: 23.5%;
//                 border: 0.953191px solid #e8e8e8;
//                 box-shadow: 2.38298px 2.38298px 9.53191px rgba(0, 0, 0, 0.08);
//                 border-radius: 6px;
//                 text-align: center;
//                 padding: 12px 15px;
//                 .top {
//                   font-family: "Poppins";
//                   font-style: normal;
//                   font-weight: 400;
//                   font-size: 16px;
//                   line-height: 24px;
//                   text-align: center;

//                   color: #828282;
//                   margin-bottom: 4px;
//                 }
//                 .btm {
//                   display: flex;
//                   justify-content: center;
//                   align-items: center;

//                   .num {
//                     font-family: "Poppins";
//                     font-style: normal;
//                     font-weight: 600;
//                     font-size: 32px;
//                     line-height: 48px;

//                     letter-spacing: 0.02em;

//                     color: #00afb9;
//                     margin-left: 15px;
//                   }
//                 }
//               }
//             }

//             .testSummary {
//               .section-heading {
//                 font-family: "Poppins";
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 20px;
//                 line-height: 36px;
//                 color: #373737;
//               }

//               table {
//                 border-radius: 10px;
//                 border: 1px solid #c2c2c2;
//               }
//               #customers {
//                 font-family: Arial, Helvetica, sans-serif;
//                 border-collapse: collapse;
//                 border-radius: 10px;
//                 width: 100%;
//                 background: #ffffff;

//                 th {
//                   background: #a98b72;
//                   padding: 12px 0px 12px 35px;

//                   font-family: "Poppins";
//                   font-style: normal;
//                   font-weight: 600;
//                   font-size: 16px;
//                   line-height: 24px;
//                   letter-spacing: 0.01em;
//                   color: #fff2e7;
//                   border-bottom: none !important;
//                 }

//                 tr {
//                   background: #fffcfa;
//                   border-bottom: 1px dashed #dec2ab;
//                 }

//                 td {
//                   padding: 10px 32px;
//                   font-family: "Poppins";
//                   font-style: normal;
//                   font-weight: 400;
//                   font-size: 18px;
//                   line-height: 27px;
//                   color: #373737;
//                 }
//                 .num {
//                   padding: 10px 53px;
//                   font-family: "Poppins";
//                   font-style: normal;
//                   font-weight: 500;
//                   font-size: 18px;
//                   line-height: 27px;
//                   color: #000000;
//                 }
//               }
//             }

//             .improvementsSuggestions {
//               width: 100%;
//               .section-heading {
//                 font-family: "Poppins";
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 20px;
//                 line-height: 36px;
//                 color: #373737;
//                 margin-bottom: 5px;
//               }

//               .content {
//                 background: #ffffff;
//                 border: 1px solid #c2c2c2;
//                 border-radius: 6px;
//                 padding: 25px;
//                 .cards {
//                   gap: 0.58rem;
//                   margin-bottom: 14px;
//                   .card {
//                     width: 16.5%;
//                     padding: 20px;
//                     .text {
//                       font-family: "Poppins";
//                       font-style: normal;
//                       font-weight: 400;
//                       font-size: 13px;
//                       line-height: 24px;
//                       text-align: center;

//                       color: #6d6d6d;
//                     }
//                     .num {
//                       font-family: "Poppins";
//                       font-style: normal;
//                       font-weight: 500;
//                       font-size: 18px;
//                       line-height: 27px;
//                       color: #000000;
//                     }
//                   }
//                 }

//                 .circles {
//                   width: 26%;
//                   display: flex;
//                   justify-content: space-between;
//                   align-items: center;
//                   margin-bottom: 30px;
//                   .circlesContent {
//                     display: flex;
//                     align-items: center;
//                   }
//                   .circle {
//                     border-radius: 50%;
//                     padding: 6px;
//                     margin-right: 5px;
//                   }

//                   .cr1 {
//                     background: #d1ffde;
//                     border: 0.6px solid #16d54b;
//                   }
//                   .cr2 {
//                     background: #ffe1e1;
//                     border: 0.6px solid #ff9b9b;
//                   }
//                   .text {
//                     font-family: "Poppins";
//                     font-style: normal;
//                     font-weight: 400;
//                     font-size: 13px;
//                     line-height: 15px;
//                     color: #000000;
//                   }
//                 }

//                 .topics-to-focus-on {
//                   margin-bottom: -15px;
//                   .section-heading {
//                     font-family: "Poppins";
//                     font-style: normal;
//                     font-weight: 500;
//                     font-size: 17px;
//                     line-height: 24px;
//                     color: #000000;
//                     margin-bottom: 7px;
//                   }

//                   .container {
//                     .row {
//                       .column {
//                         .subject {
//                           font-family: "Poppins";
//                           font-style: normal;
//                           font-weight: 500;
//                           font-size: 14px;
//                           line-height: 21px;
//                           color: #626262;
//                           margin-left: -10px;
//                         }

//                         ul {
//                           padding: 5px 9px 0px 9px;

//                           li {
//                             font-family: "Poppins";
//                             font-style: normal;
//                             font-weight: 300;
//                             font-size: 14px;
//                             line-height: 21px;
//                             color: #414141;
//                           }
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }

//         }
//       }
//     }
//   }
// }

// .TestReportContentComponentWrapper {
//   margin-top: -30px;
//   .papers_tab_wrapper {
//     background-color: #ffffff;
//     border-radius: 10px;

//     .outer_content_wrapper {
//       .content_wrapper {
//         .paper {
//           margin-top: 40px;

//           section {
//             margin-bottom: 30px;
//           }

//           .cards {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             width: 100%;
//             .card {
//               width: 23.5%;
//               border: 0.953191px solid #e8e8e8;
//               box-shadow: 2.38298px 2.38298px 9.53191px rgba(0, 0, 0, 0.08);
//               border-radius: 6px;
//               text-align: center;
//               padding: 12px 15px;
//               .top {
//                 font-family: "Poppins";
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 16px;
//                 line-height: 24px;
//                 text-align: center;

//                 color: #828282;
//                 margin-bottom: 4px;
//               }
//               .btm {
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 .num {
//                   font-family: "Poppins";
//                   font-style: normal;
//                   font-weight: 600;
//                   font-size: 32px;
//                   line-height: 48px;

//                   letter-spacing: 0.02em;

//                   margin-left: 15px;
//                 }

//                 .expectedRank {
//                   color: #00afb9;
//                 }

//                 .all_india_rank {
//                   color: #ec6f64;
//                 }

//                 .yourMarks {
//                   color: #6d48ef;
//                 }

//                 .topperMarks {
//                   color: #6c976b;
//                 }
//               }
//             }
//           }

//           .testSummary {
//             .section-heading {
//               font-family: "Poppins";
//               font-style: normal;
//               font-weight: 400;
//               font-size: 20px;
//               line-height: 36px;
//               color: #373737;
//             }

//             table {
//               border-radius: 10px;
//               border: 1px solid #c2c2c2;
//             }
//             #customers {
//               font-family: Arial, Helvetica, sans-serif;
//               border-collapse: collapse;
//               border-radius: 10px;
//               width: 100%;
//               background: #ffffff;

//               th {
//                 background: #a98b72;
//                 padding: 12px 0px 12px 35px;

//                 font-family: "Poppins";
//                 font-style: normal;
//                 font-weight: 600;
//                 font-size: 16px;
//                 line-height: 24px;
//                 letter-spacing: 0.01em;
//                 color: #fff2e7;
//                 border-bottom: none !important;
//               }

//               tr {
//                 background: #fffcfa;
//                 border-bottom: 1px dashed #dec2ab;
//               }

//               td {
//                 padding: 10px 32px;
//                 font-family: "Poppins";
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 18px;
//                 line-height: 27px;
//                 color: #373737;
//               }
//               .num {
//                 padding: 10px 53px;
//                 font-family: "Poppins";
//                 font-style: normal;
//                 font-weight: 500;
//                 font-size: 18px;
//                 line-height: 27px;
//                 color: #000000;
//               }
//             }
//           }

//           .improvementsSuggestions {
//             width: 100%;
//             .section-heading {
//               font-family: "Poppins";
//               font-style: normal;
//               font-weight: 400;
//               font-size: 20px;
//               line-height: 36px;
//               color: #373737;
//               margin-bottom: 5px;
//             }

//             .content {
//               background: #ffffff;
//               border: 1px solid #c2c2c2;
//               border-radius: 6px;
//               padding: 25px;
//               .cards {
//                 gap: 0.58rem;
//                 margin-bottom: 14px;
//                 .card {
//                   width: 16.5%;
//                   padding: 20px;
//                   .text {
//                     font-family: "Poppins";
//                     font-style: normal;
//                     font-weight: 400;
//                     font-size: 13px;
//                     line-height: 24px;
//                     text-align: center;

//                     color: #6d6d6d;
//                   }
//                   .num {
//                     font-family: "Poppins";
//                     font-style: normal;
//                     font-weight: 500;
//                     font-size: 18px;
//                     line-height: 27px;
//                     color: #000000;
//                   }
//                 }
//               }

//               .circles {
//                 width: 26%;
//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 margin-bottom: 30px;
//                 .circlesContent {
//                   display: flex;
//                   align-items: center;
//                 }
//                 .circle {
//                   border-radius: 50%;
//                   padding: 6px;
//                   margin-right: 5px;
//                 }

//                 .cr1 {
//                   background: #d1ffde;
//                   border: 0.6px solid #16d54b;
//                 }
//                 .cr2 {
//                   background: #ffe1e1;
//                   border: 0.6px solid #ff9b9b;
//                 }
//                 .text {
//                   font-family: "Poppins";
//                   font-style: normal;
//                   font-weight: 400;
//                   font-size: 13px;
//                   line-height: 15px;
//                   color: #000000;
//                 }
//               }

//               .topics-to-focus-on {
//                 margin-bottom: -15px;
//                 .section-heading {
//                   font-family: "Poppins";
//                   font-style: normal;
//                   font-weight: 500;
//                   font-size: 17px;
//                   line-height: 24px;
//                   color: #000000;
//                   margin-bottom: 7px;
//                 }

//                 .container {
//                   .row {
//                     .column {
//                       .subject {
//                         font-family: "Poppins";
//                         font-style: normal;
//                         font-weight: 500;
//                         font-size: 14px;
//                         line-height: 21px;
//                         color: #626262;
//                         margin-left: -10px;
//                       }

//                       ul {
//                         padding: 5px 9px 0px 9px;

//                         li {
//                           font-family: "Poppins";
//                           font-style: normal;
//                           font-weight: 300;
//                           font-size: 14px;
//                           line-height: 21px;
//                           color: #414141;
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.TestReportContentComponentWrapper {
  margin-top: -30px;
  width: 100%;
  .papers_tab_wrapper {
    background-color: #ffffff;
    border-radius: 10px;

    .outer_content_wrapper {
      .content_wrapper {
        .left_side_content_wrapper {
          text-align: -webkit-center;
          margin-bottom: 25px;
          text-align: center;

          .subject_type_collection_wrapper {
            display: inline-block;
            background-color: #f5f4f8;
            border-radius: 25px;
            overflow: hidden;

            .single_select_subject_type {
              width: auto;
              min-width: 160px;
              display: inline-block;

              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                background-color: #fafafd;
                padding: 0.5rem 1rem;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }

              input[type="radio"]:checked + label {
                background-color: #ff933a;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
        .paper {
          margin-top: 40px;

          section {
            margin-bottom: 30px;
          }

          .cards {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .card {
              width: 23.5%;
              border: 0.953191px solid #e8e8e8;
              box-shadow: 2.38298px 2.38298px 9.53191px rgba(0, 0, 0, 0.08);
              border-radius: 6px;
              text-align: center;
              padding: 12px 15px;
              .top {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;

                color: #828282;
                margin-bottom: 4px;
              }
              .btm {
                display: flex;
                justify-content: center;
                align-items: center;
                .num {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  // font-size: 32px;
                  // font-size: 25px;
                  font-size: 25px;
                  line-height: 48px;

                  letter-spacing: 0.02em;

                  margin-left: 15px;
                }
                img {
                  width: 18px;
                  height: 18px;
                  margin-bottom: 4px;
                }

                .expectedRank {
                  color: #00afb9;
                }

                .all_india_rank {
                  color: #ec6f64;
                }

                .yourMarks {
                  color: #6d48ef;
                }

                .topperMarks {
                  color: #6c976b;
                }
              }
            }
          }

          .redirectPredictClg {
            border-radius: 6px;
            border: 1px solid #e8e8e8;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 27px;
            height: 102px;
            background: #ffffff;
            .redirectPredictClgPara {
              width: 80%;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              color: #4d4d4d;
            }

            button {
              /* Your existing styles */
              color: #ffffff;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0em;
              gap: 0.7rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: linear-gradient(0deg, #ff933a, #ff933a),
                linear-gradient(180deg, #ff981e 0%, #f28300 100%);
              border: 1px solid #ff933a;
              /* Add transition for smooth animation */
              transition: transform 0.2s ease, box-shadow 0.2s ease;
            }

            /* Animation on hover */
            button:hover {
              /* Add a slight scale up effect when hovering */
              transform: scale(1.05);
              /* Add a slight shadow when hovering */
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
