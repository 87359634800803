.chat_container_wrapper {
  .chat_container_inner_wrapper {
    .upcoming_chats_wrapper {
      min-height: 200px;
      max-height: 300px;
      overflow-y: scroll;
      .sender_single_chat_wrapper {
        display: flex;
        justify-content: flex-end;
        padding: 5px;
        margin: 5px 0;
        .left_side_content_wrapper {
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .text_content_wrapper {
            background-color: #6d48ef;
            border-radius: 15px 15px 0 15px;
            padding: 10px;
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #ffffff;
            }
          }
          .image_wrapper {
            img {
              width: 200px;
              height: 200px;
              border-radius: 5px;
            }
          }
          .time_content_wrapper {
            margin-top: 5px;
            .time_content {
              margin: 0;
              font-weight: 600;
            }
          }
        }
        .right_side_content_wrapper {
          .initial_wrapper {
            border-radius: 50%;
            background-color: #e2bfc0;
            padding: 5px;
            .text_content_wrapper {
              .text_content {
                margin: 0;
                font-size: 18px;
                font-weight: 600;
                color: #000000;
              }
            }
          }
        }
      }
      .receiver_single_chat_wrapper {
        display: flex;
        justify-content: flex-start;
        padding: 5px;
        margin: 5px 0;
        .left_side_content_wrapper {
          .initial_wrapper {
            border-radius: 50%;
            background-color: #e2bfc0;
            padding: 5px;
            .text_content_wrapper {
              .text_content {
                margin: 0;
                font-size: 18px;
                font-weight: 600;
                color: #000000;
              }
            }
          }
        }
        .right_side_content_wrapper {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .text_content_wrapper {
            background-color: #f0ecfd;
            border-radius: 15px 15px 15px 0;
            padding: 10px;
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #000000;
            }
          }
          .image_wrapper {
            img {
              width: 200px;
              height: 200px;
              border-radius: 5px;
            }
          }
          .time_content_wrapper {
            margin-top: 5px;
            .time_content {
              margin: 0;
              font-weight: 600;
            }
          }
        }
      }
    }
    .upcoming_chats_wrapper::-webkit-scrollbar {
      width: 7px;
    }
    .upcoming_chats_wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    .upcoming_chats_wrapper::-webkit-scrollbar-thumb {
      background: #6d48ef;
      border-radius: 10px;
    }

    /* Handle on hover */
    .upcoming_chats_wrapper::-webkit-scrollbar-thumb:hover {
      background: #6d48ef;
    }
  }
}
