.termsAndConditions {
  height: 100%;
  .terms_container {
    .sectionTitle {
      margin-top: 25px;

      .backBtnContent {
        display: flex;
        align-items: center;
        padding: 10px;

        .backBtn {
          margin-right: 20px;
          cursor: pointer;

          img {
            width: 30px;
            height: auto;
          }
        }

        strong {
          color: #000;
          font-family: Poppins;
          font-size: 1.3125rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.01313rem;
        }
      }
    }

    .contentWrppr {
      border-radius: 0.5rem;
      background: #fff;

      text-align: left;
      padding: 2rem 0 0 2.5rem;

      h3 {
        color: #121212;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.4375rem;
      }

      .termData {
        color: #5c5c5c;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5625rem;
        max-width: 50.5625rem;
      }
    }
  }
}

@media (max-width: 578px) {
  .termsAndConditions {
    margin-top: 35px;
    .container {
      width: 95%;

      .sectionTitle {
        margin-top: 25px;
      }
      .contentWrppr {
        padding: 20px 10px;
        .content {
          ul {
            margin-left: 0;
            padding-left: 1rem;
          }
        }
      }
    }
  }
}
