.modalbody{
    padding:1.5rem 2rem !important;
}
.close-btn{
    height: 15px;
    width: 15px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
@media(max-width:796px){
    .modalbody{
        padding: 1rem !important;
    }
}