.adaptiveCpsWrapper {
  background-color: #f5f4f8;
  min-height: 100vh;

  .adaptiveCpsInrWrapper {
    padding-top: 101px;
    padding-bottom: 100px;
    .adaptiveCpsInr {
      margin: 20px 50px 0px;
      background-color: #fff;
      border-radius: 10px;
      padding: 20px 20px 70px 20px;

      .backBtn {
        cursor: pointer;

        img {
        }
      }

      h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 45px;
        margin-top: -25px;
      }

      .adaptiveCps {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .adaptiveCpsContent {
          margin-bottom: 20px;
          .aCpsFirstText {
            text-align: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 20px;

            color: #000000;
          }

          .aCpsSecondText {
            text-align: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1px;
            text-align: center;
            margin-top: 0px;

            color: #717171;
          }
        }

        .adaptiveCpsImg {
          img {
            width: 100px;
            height: 110px;
          }
        }

        .adaptiveCpsMarksList {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 4rem;
          width: 100%;
          .adaptiveCpsMarkItem {
            background: #fafafa;
            border: 0.6px solid #e1e1e1;
            border-radius: 6px;
            margin-right: 1.5em;
            padding: 0.625em;
            width: 137px;
            height: 80px;
            .adaptiveCpsMarks {
              text-align: center;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              color: #000000;
              padding-bottom: 5px;
            }

            .adaptiveCpsMarkLabel {
              text-align: center;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #585858;
            }
          }
        }

        .adaptiveCpsBtns {
          display: flex;
          justify-content: space-between;
          margin-top: 3em;
          width: 60%;

          .adaptvieCpsBackBtn {
            width: 45%;
            border: 1px solid #6d48ef;
            text-align: center;
            border-radius: 5px;
            button {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #6d48ef;
              height: 46px;
            }
          }

          .adaptiveCpsGetCpsBtn {
            width: 45%;
            background: #6d48ef;
            text-align: center;
            border-radius: 5px;
            button {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              // padding: 10px 100px;
              color: #ffffff;
              background: #6d48ef;

              height: 46px;
            }
          }
        }
      }
    }
  }
}
