.SupportCenterInfoWrpr {
  // margin-top: 55px;
  .img-wrapper {
    text-align: center;
    img {
      width: 169px;
    }
    .text {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      width: 200px;
      margin: 0 auto;
    }
  }

  .form-section {
    margin-top: 35px;
    small {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      color: #4e4e4e;
    }
    p {
      margin-top: 4px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      background: #ececec;
      padding: 10px;
      border-radius: 4px;
      color: #000000;
      height: 42px;

    }
    .rollnum {
      margin-bottom: 20px;
    }
    button {
      background: #6d48ef;
      width: 100%;
      padding: 8px;
      border-radius: 4px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      color: #ffffff;
      margin-top: 35px;
    }
  }
}
