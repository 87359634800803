.slider_container {
  .dashboard_slider_wrapper {
    .single_image_inner_wrapper {
      iframe {

        //padding: 10px;
        .html5-video-player {
          border: 5px solid red !important;

          .ytp-chrome-top-buttons {
            display: none !important;
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .slider_container {
    .container {
      padding: 0px;
    }
  }
}