.freeVideosCollectionView_wrapper {
  padding: 40px 0px;
  .freeVideosCollectionView_inner_wrapper {
    .pause_vd_title{
      text-align: center;
      width: 700px;
    margin: 0 auto;
    padding-bottom: 40px;
      h4{
        letter-spacing: 0.6px;
      color: #000000;
      font-size: 22px;
      margin-bottom: 15px;
      }
      p{
        letter-spacing: 0.36px;
    color: #707070;
    opacity: 0.8;
    font-size: 14px;
      }
    }
    .section_2_wrapper {
      .section_2_inner_wrapper {
        .free_videos_collection {
          display: flex;
          flex-wrap: wrap;
          height: 300px;
          overflow-y: auto;
          .single_video_wrapper {
            width: 32.5%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 8px;
            background-color: #faf9fe;
            padding: 7px;
            margin: 10px 0;
            margin-right: 13px;
            cursor: pointer;
            &:nth-child(3n+3){
              margin-right: 0px;
            }
            .left_side_content_wrapper {
              width: 100px;
              flex: 0 0 100px;
              .image_wrapper {
                text-align: center;
                img {
                  width: 100px;
                }
              }
            }
            .right_side_content_wrapper {
              flex: auto;
              margin-left: 10px;
              width: 100%;
              .text_content_wrapper_1 {
                .text_content_1 {
                  font-size: 14px;
                  font-weight: 500;
                  color: #0f0404;
                  line-height: 18px;
                  margin-bottom: 0px;
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 500;
                  color: #6f6868;
                }
              }
              .sub_content_wrapper {
                text-align: right;
                padding-right: 10px;
                width: 100%;
                  .label_content_wrapper {
                    label {
                      span {
                        vertical-align: middle;
                        img {
                          width: 13px;
                          height: 13px;
                        }
                      }
                      .rating{
                        vertical-align: bottom;
                        font-size: 14px;
                        margin-left: 3px;
                      }
                    }
                  }
                .right_side_content_wrapper {
                  // flex: 0 0 50%;
                  text-align: right;
                  margin: 0;
                  padding: 0;
                  width: auto;
                  .subject_name {
                    padding: 5px;
                    border-radius: 5px;
                    background-image: linear-gradient(
                      120deg,
                      #ff9600 37%,
                      #ff5d00 72%
                    );
                    font-size: 12px;
                    font-weight: 600;
                    color: #ffffff;
                  }
                }
              }
              .resume_btn{
                text-align: right;
                padding-top: 15px;
                span{
                  background: #668DE1;
                  display: inline-block;
                  font-size: 10px;
                  color: #fff;
                  padding: 3px 8px;
                  border-radius: 4px;
                }
              }
            }
            .resume_btn{
                text-align: right;
                padding-top: 15px;
                span{
                  background: #FF933A;
                  display: inline-block;
                  font-size: 10px;
                  color: #fff;
                  padding: 3px 8px;
                  border-radius: 4px;
                }
              }
          }
        }
        .free_videos_collection::-webkit-scrollbar {
          width: 3px;
        }
        .free_videos_collection::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }

        /* Handle */
        .free_videos_collection::-webkit-scrollbar-thumb {
          background: #668DE1;
          border-radius: 10px;
        }

        /* Handle on hover */
        .free_videos_collection::-webkit-scrollbar-thumb:hover {
          background: #6d48ef;
        }
      }
    }
  }
}
