.fade.modal.show {
  display: block;
  padding-left: 12px;
  margin-top: 0px;

  .modal-dialog.email-update-wrapper {
    width: 512px;
    height: 327px;
    border-radius: 8px;
    font-family: Poppins;
    margin-top: 0px;

    .modal-content {
      .email-modal-header.modal-header {
        border: none;
        margin-top: 0px;
      }

      .modal-body {
        .email-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h2 {
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0em;
            color: #121212;
            margin-bottom: 35px;
          }

          .email-input {
            width: 408px;
            height: 52px;
            margin-bottom: 35px;
            border-radius: 8px;
            border: none;
            border: 0.5px solid #f3f3f3;
          }

          input {
            border: none;
            outline: none;
            width: 100%;
            height: 100%;
            background-color: #f3f3f3;
            padding: 0 0 0 16px;
            font-size: 16px;
          }

          input::placeholder {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            color: #8c8c8c;
          }

          .update-button {
            width: 408px;
            height: 56px;
            background-color: #1e9800;

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            margin-bottom: 47px;
            cursor: pointer;

            button {
              color: #ffffff;
              font-size: 17px;
              font-weight: 400;
              line-height: 24px;
            }
          }

          #errorText {
            color: red;
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .fade.modal.show {
    padding-left: 0;
  }
}
