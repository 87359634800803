.subjectWiseMarksBox {
  .subjectMarksSmry {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .marksChart {
      width: calc(50% - 10px);
      background: #f5f4f8;
      border-radius: 8px;
      padding: 10px;
      margin-right: 20px;

      .canvasjs-chart-credit,
      .canvasjs-chart-toolbar {
        display: none;
      }
    }

    .marksSummary {
      border: 1px solid #eeeeee;
      background: #f5f4f8;
      border-radius: 8px;
      width: calc(50% - 10px);
      padding: 10px;

      .section_heading {
        color: #353535;
        text-align: center;
        font: 400 22px "Poppins", sans-serif;
        // margin-top: -47px;
      }

      ul {
        padding: 0px;
        // margin: 0px;
        margin-top: 60px;

        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          margin: 12px 0px;

          span {
            font-size: 12px;

            &:last-child {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .rank {
      //  margin:auto;
      margin-top: 30px;
      margin-left: 200px;

      width: 60%;
      height: 80%;
      background: #f5f4f8;
      border-radius: 8px;
      padding: 10px;
      margin-right: 20px;
    }
  }
}
