.filling_address_container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 10px 20px;
  .left {
    width: 63%;
    .section_heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #2d2d2d;
    }
    .form {
      margin-top: 20px;
      border-radius: 4px;
    }
    .savedAddress {
      margin-bottom: 20px;
      .card {
        margin-top: 20px;
        .card-body {
          display: flex;
          justify-content: space-between;
          align-items: center;

          // .input {
          //   padding: 10px;
          //   background: red;
          //   input[type="checkbox"] {
          //     width: 100%;
          //     height: 30px;
          //   }
          // }
          input {
            background: red;
          }
          input.larger {
            width: 10%;
            height: 20px;
            margin-left: -20px;
          }

          .savedAddress_content {
            width: 100%;

            .top {
              .fullName {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #2d2d2d;
              }
              .phoneNum {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #2d2d2d;
              }
            }
            .bottom {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #797979;
            }
          }
          .edit_del {
            width: 15%;
            flex: end;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }

    .card-body {
      background: #ffffff;
      border: 0.8px solid #cccccc;
      border-radius: 4px;
      padding: 20px;
    }
    .addAddressBtn {
      text-align: center;
      button {
        background: #ffffff;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #2d2d2d;
        border: 1px solid black;
        padding: 7px 10px;
        cursor: pointer;
      }
    }
  }
  .right {
    width: 32%;
    margin-right: 30px;
    margin-top: 50px;
    .checkOutBtn {
      Button {
        background: #ff933a;
        border: none;
        outline: none;
      }
    }
  }
}

.backBtn_content {
  .backBtn {
    cursor: pointer;
  }
}

@media(max-width:578px) {
  .filling_address_container {
    flex-direction: column;
    padding: 0;
    .left{
      width: 100%;
    }
    .right{
      width: 100%;
    }
  }
}
