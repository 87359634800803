.banner_wrapper {
    margin: 50px 0;
    color: #FFFFFF;
    border-radius: 10px;
    background: {
        image: url('../../../../../../utilities/images/homepage/aits_banner_background.png');
        size: 100% 100%;
        repeat: no-repeat;
        position: center center;
    }

    .left_col {
        div {
            padding: 35px 0 35px 100px;

            p {
                margin: 0;
            }

            p:nth-child(1) {
                font-family: 'Poppins';
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0.24px;
                text-align: left;
            }
            p:nth-child(2) {
                font-family: 'Source Sans Pro';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            button {
                color: #121212;
                font-family: 'Poppins';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                padding: 12px 34px 12px 20px;
                border-radius: 4px;
                border: 1px solid #FFF;
                background: #FFF;
                margin-top: 16px;
                position: relative;

                &::after {
                    content: '';
                    border: solid black;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 3px;
                    position: absolute;
                    top: 49%;
                    right: 20px;
                    transform: translate(0, -50%) rotate(-45deg);
                    -webkit-transform: translate(0, -50%) rotate(-45deg);
                }
            }
        }
    }
    .right_col {
        div {
            padding: 40px 0 40px 40px;

            p {
                color: #FFF;
                font-family: 'Poppins';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.16px;
                position: relative;
                padding-left: 20px;
                margin-bottom: 15px;

                &::before {
                    position: absolute;
                    content: '';
                    background: url('../../../../../../utilities/images/homepage/green_checked_aits.png');
                    background-size: contain;
                    width: 16px;
                    height: 16px;
                    top: 3.5px;
                    left: 0px;
                }
            }

            p:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.banner_wrapper_2 {
    margin: 50px 0;
    color: #FFFFFF;

    .left_col {
        border-radius: 10px;
        padding: 40px 75px 40px 50px;
        background: {
            image: url('../../../../../../utilities/images/homepage/aits_banner_background.png');
            size: 100% 100%;
            repeat: no-repeat;
            position: center center;
        }

        button {
            color: #121212;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            border-radius: 4px;
            border: 1px solid #FFF;
            background: #FFF;
            padding: 16px 44px 16px 32px;
            position: relative;

            &::after {
                content: '';
                border: solid black;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                position: absolute;
                top: 49%;
                right: 30px;
                transform: translate(0, -50%) rotate(-45deg);
                -webkit-transform: translate(0, -50%) rotate(-45deg);
            }
        }

        .left_inner {
            p {
                margin-bottom: 0;
            }

            p:nth-child(1) {
                font-family: 'Poppins';
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.24px;
            }
            p:nth-child(2) {
                font-family: 'Source Sans Pro';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

        }
    }

    .right_col {
        div {
            margin-left: 20px;

            a {
                display: flex;
                border-radius: 8px;
                border: 0.8px solid #D3D3D3;
                background: #FFF;
                width: 100%;
                padding: 20px 0 20px 32px;
                position: relative;
                color: #121212;
                text-decoration: none;

                &:first-child {
                    margin-bottom: 20px;
                }

                &::after {
                    position: absolute;
                    content: '';
                    background: url('../../../../../../utilities/images/homepage/aits_red_arrow.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 25px;
                    height: 25px;
                    top: 31%;
                    right: 0px;
                }
            }
        }
        
    }
}