.strong_topic_dropdown_box {
  .strong_topic_dropdown {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .heading-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 33px;
      margin: 0px 0px 10px 20px;
      color: #060606;
    }

    select {
      border: none;
      border: 1px solid #d6d6d6;
      padding: 5px 10px 5px 10px;
      border-radius: 10px;
      margin-right: 13px;
    }
  }
}

.improve_topic_dropdown_box {
  .improve_topic_dropdown {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .heading-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 33px;
      margin: 0px 0px 10px 20px;
      color: #060606;
    }

    select {
      border: none;
      border: 1px solid #d6d6d6;
      padding: 5px 10px 5px 10px;
      border-radius: 10px;
      margin-right: 13px;
    }
  }
}

.improve_topic_lists {
  .improve_topic_list {
    display: flex;
    justify-content: space-between;
    margin: 15px 15px 15px 0px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;

    li {
      margin-left: 20px;
      margin-top: 10px;
      color: #000000;
      font-family: Poppins;
      font-size: 16px;
      line-height: 21px;
    }

    .start_practice {
      color: #ffffff;
      background-color: #6d48ef;
      padding: 8px;
      border-radius: 5px;
    }
  }
}

.strong_topic_lists {
  .strong_topic_list {
    display: flex;
    justify-content: space-between;
    margin: 15px 0px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;

    li {
      margin-left: 20px;
      margin-top: 10px;
      color: #000000;
      font-family: Poppins;
      font-size: 16px;
      line-height: 21px;
    }

    .icon {
      // color: #ffffff;
      // background-color: #6d48ef;
      // padding: 8px;
      // border-radius: 5px;
    }
  }
}
