.topicContentComponent_wrapper {
  padding-top: 70px;
  .topicContentComponent_inner_wrapper {
    margin: 20px 0 0px;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px;
    .sec_1_wrapper {
      margin: 0px 0 15px;
      .sec_1_inner_wrapper {
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          text-align: center;
          .text_content {
            margin-top: -35px;
            font-size: 25px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .sec_2_wrapper {
      .faculty_tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        overflow-x: auto;
        width: 100%;
        margin-bottom: 30px;
        .single_select_facultyName {
          display: -webkit-inline-box;
          margin-left: 20px;
          margin-right: 10px;
          display: flex;

          img {
            width: 32px;
            margin-right: 4px;
            border-radius: 50%;
          }

          input[type="radio"] {
            opacity: 0;
            position: fixed;
            width: 0;
          }
          label {
            border: solid 1px #f0eef8;
            background: #f5f4f8;
            border-radius: 12px;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0.2px;
            color: #6b6876;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 6px 20px 6px 10px;
          }
        }

        input[type="radio"]:checked + label {
          background-color: #6d48ef;
          border: none;
          color: #ffffff;
        }
      }

      .sec_2_inner_wrapper {
        .content_wrapper {
          padding: 20px 10px;
          .left_side_content_wrapper {
            .feature_collection_wrapper {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              background: #f5f4f8;
              width: max-content;
              margin: 0 auto 40px;
              border-radius: 24px;
              overflow: hidden;
              .single_select_feature {
                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }
                label {
                  background-color: #f5f4f8;
                  padding: 10px 20px;
                  font-size: 15px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                  color: #707070;
                  cursor: pointer;
                  text-align: center;
                  min-width: 125px;
                }
                input[type="radio"]:checked + label {
                  background-color: #ff933a;
                  border: none;
                  color: #ffffff;
                }
              }
            }
          }
          .right_side_content_wrapper {
            flex: 0 0 70%;
            padding: 0 10px;
            ////////////////////videos/////////////////
            .videosCollectionView_wrapper {
              .videosCollectionView_inner_wrapper {
                .sec_1_wrapper {
                  text-align: right;
                  margin: 0;
                  .select_wrapper {
                    select {
                      width: auto;
                      height: 45px;
                      border-radius: 25px;
                      border: 2px solid #6d48ef;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #6d48ef;
                      padding: 0 10px;
                    }
                  }
                }
                .sec_2_wrapper {
                  margin: 10px 0;
                  .sec_2_inner_wrapper {
                    // overflow-y: auto;
                    // height: 350px;
                    .videos_collection_wrapper {
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: center;
                      .single_video_wrapper {
                        width: 488px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-radius: 8px;
                        background-color: #f5f4f8;
                        padding: 10px;
                        margin: 0px 15px 15px 0px;
                        cursor: pointer;
                        &:nth-child(2n + 2) {
                          margin-right: 0px;
                        }
                        .left_side_content_wrapper {
                          width: auto;
                          flex: auto;
                          .image_wrapper {
                            text-align: center;
                            width: 100px;
                            img {
                              width: 100px;
                            }
                          }
                        }
                        .right_side_content_wrapper {
                          flex: auto;
                          margin: 0 10px;
                          width: 100%;
                          .right_side_content_wrapper_top {
                            display: flex;
                            justify-content: space-between;
                            margin-right: 3px;
                            .text_content_wrapper_1 {
                              .text_content_1 {
                                margin: 0;
                                font-size: 16px;
                                font-weight: 500;
                                color: #000;
                                margin-bottom: 5px;
                              }
                            }
                          }
                          .text_content_wrapper_2 {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .text_content_2 {
                              margin: 0;
                              font-size: 14px;
                              font-weight: 500;
                              color: #6f6868;
                            }
                          }
                          .sub_content_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            .left_side_content_wrapper {
                              // flex: 0 0 50%;
                              .label_content_wrapper {
                                label {
                                  padding: 5px 10px;
                                  border-radius: 15px;
                                  background-color: #fef4ec;
                                  span {
                                    img {
                                      width: 15px;
                                      height: 15px;
                                    }
                                  }
                                }
                              }
                            }
                            .right_side_content_wrapper {
                              // flex: 0 0 50%;
                              text-align: right;
                              margin: 0;
                              padding: 25px 0px 0px;
                              width: auto;
                              .subject_name {
                                padding: 5px;
                                border-radius: 5px;
                                background-image: linear-gradient(
                                  120deg,
                                  #ff9600 37%,
                                  #ff5d00 72%
                                );
                                font-size: 12px;
                                font-weight: 600;
                                color: #ffffff;
                              }
                              button {
                                background: #ff933a;
                                border-radius: 4px;
                                color: #f5f4f8;
                                font-size: 12px;
                                padding: 5px 15px;
                              }
                            }
                          }
                        }
                      }
                    }
                    .videos_collection_wrapper::-webkit-scrollbar {
                      width: 3px;
                    }
                    .videos_collection_wrapper::-webkit-scrollbar-track {
                      box-shadow: inset 0 0 5px #f5f4f8;
                      border-radius: 10px;
                    }

                    /* Handle */
                    .videos_collection_wrapper::-webkit-scrollbar-thumb {
                      background: grey;
                      border-radius: 10px;
                    }

                    /* Handle on hover */
                    .videos_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      background: #f5f4f8;
                    }
                  }
                }
                .sec_3_wrapper {
                  margin: 10px 0;
                  .sec_3_inner_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn_wrapper {
                      button {
                        border: none;
                        outline: none;
                        width: auto;
                        height: 33px;
                        border-radius: 5px;
                        border: 2px solid #6d48ef;
                        background-color: #6d48ef;
                        font-size: 15px;
                        font-weight: 500;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 10px;
                      }
                    }
                  }
                }
              }
            }
            //////////////live class/////////////////
            .liveClassCollectionView_wrapper {
              .liveClassCollectionView_inner_wrapper {
                .section_wrapper {
                  .section_inner_wrapper {
                    .options_wrapper {
                      .custom_nav_pills {
                        border-radius: 25px;
                        background-color: #f2f2f3;
                        width: max-content;
                        margin: 20px auto;
                        overflow: hidden;
                        .custom_nav_item {
                          min-width: 145px;
                          .custom_nav_link {
                            width: 100%;
                            border-radius: 25px;
                            border: none;
                            outline: none;
                            font-size: 13px;
                            font-weight: 500;
                            color: #000;
                          }
                          .custom_nav_link.active {
                            color: #ffffff;
                            background-color: #6d48ef;
                            border-radius: 0px;
                          }
                        }
                      }
                    }
                    .tab_wrapper {
                      .custom_tab_content {
                        //////upcoming class tab////
                        .custom_tab_pane {
                          .upcoming_class_tab_wrapper {
                            .outer_content_wrapper {
                              .liveClasses_collection_wrapper {
                                display: flex;
                                flex-direction: column;
                                .single_liveClass_wrapper {
                                  width: 100%;
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-start;
                                  border-radius: 8px;
                                  background-color: #faf9fe;
                                  padding: 10px;
                                  margin: 10px 0;

                                  .left_side_content_wrapper {
                                    width: auto;
                                    flex: auto;
                                    .image_wrapper {
                                      text-align: center;
                                      img {
                                      }
                                    }
                                  }
                                  .right_side_content_wrapper {
                                    flex: auto;
                                    margin: 0 10px;
                                    width: 100%;
                                    .text_content_wrapper_1 {
                                      .text_content_1 {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: #0f0404;
                                      }
                                    }
                                    .text_content_wrapper_2 {
                                      .text_content_2 {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #6f6868;
                                      }
                                    }
                                    .sub_content_wrapper {
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                      width: 100%;
                                      .left_side_content_wrapper {
                                        // flex: 0 0 50%;
                                        .label_content_wrapper {
                                          label {
                                            padding: 5px 10px;
                                            border-radius: 15px;
                                            // background-color: #fef4ec;
                                            span {
                                              img {
                                                width: 15px;
                                                height: 15px;
                                              }
                                            }
                                          }
                                        }
                                      }
                                      .right_side_content_wrapper {
                                        text-align: right;
                                        margin: 0;
                                        padding: 0;
                                        width: auto;
                                        .text_content_wrapper {
                                          .text_content {
                                            margin: 0;
                                            font-size: 14px;
                                            font-weight: 600;
                                            text-decoration: underline;
                                            color: #6d48ef;
                                            cursor: pointer;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              .liveClasses_recorded_collection_wrapper {
                                display: flex;
                                justify-content: space-between;

                                .single_liveClass_wrapper {
                                  width: calc(50% - 20px);
                                  padding: 0 20px 20px 0;
                                  background-color: #f5f4f8;
                                  border-radius: 8px;
                                }
                              }
                              .sec_3_wrapper {
                                margin: 10px 0;
                                .sec_3_inner_wrapper {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  .btn_wrapper {
                                    button {
                                      border: none;
                                      outline: none;
                                      width: auto;
                                      height: 45px;
                                      border-radius: 5px;
                                      border: 2px solid #6d48ef;
                                      background-color: #6d48ef;
                                      font-size: 16px;
                                      font-weight: 600;
                                      text-align: center;
                                      color: #ffffff;
                                      padding: 0 10px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        //////previous class tab////
                        .custom_tab_pane {
                          .previous_class_tab_wrapper {
                            .outer_content_wrapper {
                              .liveClasses_collection_wrapper {
                                display: flex;
                                flex-direction: column;
                                .single_liveClass_wrapper {
                                  width: 100%;
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-start;
                                  border-radius: 8px;
                                  background-color: #faf9fe;
                                  padding: 10px;
                                  margin: 10px 0;
                                  .left_side_content_wrapper {
                                    width: auto;
                                    flex: auto;
                                    .image_wrapper {
                                      text-align: center;
                                      img {
                                      }
                                    }
                                  }
                                  .right_side_content_wrapper {
                                    flex: auto;
                                    margin: 0 10px;
                                    width: 100%;
                                    .text_content_wrapper_1 {
                                      .text_content_1 {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: #0f0404;
                                      }
                                    }
                                    .text_content_wrapper_2 {
                                      .text_content_2 {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #6f6868;
                                      }
                                    }
                                    .sub_content_wrapper {
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                      width: 100%;
                                      .left_side_content_wrapper {
                                        // flex: 0 0 50%;
                                        .label_content_wrapper {
                                          label {
                                            padding: 5px 10px;
                                            border-radius: 15px;
                                            // background-color: #fef4ec;
                                            span {
                                              img {
                                                width: 15px;
                                                height: 15px;
                                              }
                                            }
                                          }
                                        }
                                      }
                                      .right_side_content_wrapper {
                                        // flex: 0 0 50%;
                                        text-align: right;
                                        margin: 0;
                                        padding: 0;
                                        width: auto;
                                        .text_content_wrapper {
                                          .text_content {
                                            margin: 0;
                                            font-size: 14px;
                                            font-weight: 600;
                                            text-decoration: underline;
                                            color: #6d48ef;
                                            cursor: pointer;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              .liveClasses_recorded_collection_wrapper {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;

                                .single_liveClass_wrapper {
                                  width: calc(50% - 10px);
                                  margin: 0 20px 20px 0;
                                  background-color: #f5f4f8;
                                  border-radius: 8px;
                                  display: flex;

                                  &:nth-child(2n) {
                                    margin-right: 0;
                                  }

                                  .left_side_content_wrapper_recorded {
                                    display: flex;
                                    align-items: center;
                                    padding: 15px;

                                    .image_wrapper {
                                      background-color: #ffffff;
                                      border-radius: 4px;

                                      img {
                                        border-radius: 4px;
                                        width: 105px;
                                      }
                                    }
                                  }

                                  .right_side_content_wrapper_recorded {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                    padding: 15px 15px 15px 0;

                                    .text_content_wrapper_1 {
                                      .text_content_1 {
                                        color: #121212;
                                        font-size: 16px;
                                        font-weight: 600;
                                        line-height: normal;
                                        margin-bottom: 0;
                                      }
                                    }

                                    span {
                                      img {
                                        padding-right: 5px;
                                        width: 20px;
                                      }

                                      .text_content_2 {
                                        display: inline-block;
                                        color: #484848;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 14px;
                                        margin: 0 10px 0 0;
                                      }
                                    }

                                    .text_content_wrapper_2 {
                                      display: flex;

                                      span {
                                        img {
                                          padding-right: 5px;
                                          width: 20px;
                                        }

                                        .text_content_2 {
                                          display: inline-block;
                                          color: #484848;
                                          font-size: 14px;
                                          font-weight: 400;
                                          line-height: 14px;
                                          margin: 0 10px 0 0;
                                        }
                                      }
                                    }

                                    button {
                                      margin-left: auto;
                                      margin-top: auto;
                                      color: #fff;
                                      font-family: "Poppins";
                                      font-size: 14px;
                                      font-weight: 500;
                                      line-height: 14px;
                                      border-radius: 4px;
                                      background: #ff933a;
                                      padding: 12px 20px;
                                    }
                                  }
                                }
                              }
                              .sec_3_wrapper {
                                margin: 10px 0;
                                .sec_3_inner_wrapper {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  .btn_wrapper {
                                    button {
                                      border: none;
                                      outline: none;
                                      width: auto;
                                      height: 45px;
                                      border-radius: 5px;
                                      border: 2px solid #6d48ef;
                                      background-color: #6d48ef;
                                      font-size: 16px;
                                      font-weight: 600;
                                      text-align: center;
                                      color: #ffffff;
                                      padding: 0 10px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            /////////exercise////////////////
            .exerciseCollectionView_wrapper {
              .exerciseCollectionView_inner_wrapper {
                .sec_1_wrapper {
                  .options_wrapper {
                    .custom_nav_pills {
                      border-radius: 25px;
                      background-color: #f2f2f3;
                      width: max-content;
                      margin: 20px auto;
                      overflow: hidden;
                      .custom_nav_item {
                        min-width: 145px;
                        .custom_nav_link {
                          width: 100%;
                          border-radius: 25px;
                          border: none;
                          outline: none;
                          font-size: 13px;
                          font-weight: 500;
                          color: #000;
                        }
                        .custom_nav_link.active {
                          color: #ffffff;
                          background-color: #6d48ef;
                          border-radius: 0px;
                        }
                      }
                    }
                  }
                  .sec_1_inner_wrapper {
                    overflow-y: auto;
                    height: 350px;
                    .exercise_collection_wrapper {
                      width: 100%;
                      display: flex;
                      flex-wrap: wrap;
                      .single_exercise_wrapper {
                        width: 49%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border: 1px solid #f5f4f8;
                        border-radius: 8px;
                        background-color: #f5f4f8;
                        padding: 10px;
                        margin: 0px 15px 15px 0px;
                        cursor: pointer;
                        &:nth-child(2n + 2) {
                          margin-right: 0px;
                        }
                        .serial_number_wrapper {
                          border-radius: 15px;
                          background-image: linear-gradient(
                            149deg,
                            #fff5e6 32%,
                            #ffefe6 76%
                          );
                          padding: 15px;
                          .text_content_wrapper {
                            .text_content {
                              margin: 0;
                              font-size: 20px;
                              font-weight: 600;
                              text-align: center;
                              color: #b35557;
                            }
                          }
                        }
                        .exercise_description_wrapper {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          width: 100%;
                          padding: 0 10px;
                          .exercise_details_wrapper {
                            .text_content_wrapper_1 {
                              .text_content_1 {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 500;
                                color: #000;
                                margin-bottom: 7px;
                              }
                            }
                            .text_content_wrapper_2 {
                              .text_content_2 {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 500;
                                color: #707070;
                              }
                            }
                          }
                          .caret_wraper {
                            .text_content_wrapper {
                              display: flex;
                              align-items: center;
                              span {
                                color: #707070;
                                font-size: 12px;
                                margin-right: 10px;
                                top: -1px;
                                position: relative;
                              }
                              img {
                                width: 10px;
                              }
                            }
                          }
                        }
                      }
                    }
                    .sec_1_inner_wrapper::-webkit-scrollbar {
                      width: 3px;
                    }
                    .sec_1_inner_wrapper::-webkit-scrollbar-track {
                      box-shadow: inset 0 0 5px #f5f4f8;
                      border-radius: 10px;
                    }

                    /* Handle */
                    .sec_1_inner_wrapper::-webkit-scrollbar-thumb {
                      background: grey;
                      border-radius: 10px;
                    }

                    /* Handle on hover */
                    .exercise_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      background: grey;
                    }
                  }
                }
                .sec_2_wrapper {
                  .sec_2_inner_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn_wrapper {
                      button {
                        border: none;
                        outline: none;
                        width: auto;
                        height: 33px;
                        border-radius: 5px;
                        border: 2px solid #6d48ef;
                        background-color: #6d48ef;
                        font-size: 15px;
                        font-weight: 500;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 10px;
                      }
                    }
                  }
                }
              }
            }
            ////////////notes///////////////
            .notesCollectionView_wrapper {
              .notesCollectionView_inner_wrapper {
                .notes_collection_wrapper {
                  display: flex;
                  flex-wrap: wrap;
                  height: 350px;
                  overflow-y: auto;
                  .single_notes_wrapper {
                    width: 49%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border: 1px solid #f5f4f8;
                    border-radius: 8px;
                    background-color: #f5f4f8;
                    padding: 10px;
                    margin: 0px 15px 15px 0px;
                    cursor: pointer;
                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }
                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-image: linear-gradient(
                        149deg,
                        #fff5e6 32%,
                        #ffefe6 76%
                      );
                      padding: 15px;
                      .image_wrapper {
                        img {
                        }
                      }
                    }
                    .notes_description_wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      padding: 0 10px;
                      .notes_details_wrapper {
                        .text_content_wrapper_1 {
                          .text_content_1 {
                            margin: 10px 0px;
                            font-size: 15px;
                            font-weight: 500;
                            color: #000;
                          }
                        }
                        .text_content_wrapper_2 {
                          .text_content_2 {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }
                      }
                      .arrow {
                        img {
                          width: 10px;
                        }
                      }
                      .caret_wraper {
                        .image_wrapper {
                          img {
                          }
                        }
                      }
                    }
                  }
                }
                .notes_collection_wrapper::-webkit-scrollbar {
                  width: 3px;
                }
                .notes_collection_wrapper::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 5px #f4f5f8;
                  border-radius: 3px;
                }

                /* Handle */
                .notes_collection_wrapper::-webkit-scrollbar-thumb {
                  background: grey;
                  border-radius: 10px;
                }

                /* Handle on hover */
                .notes_collection_wrapper::-webkit-scrollbar-thumb:hover {
                  background: grey;
                }
                .sec_3_wrapper {
                  margin: 10px 0;
                  .sec_3_inner_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn_wrapper {
                      button {
                        border: none;
                        outline: none;
                        width: auto;
                        height: 35px;
                        border-radius: 5px;
                        border: 2px solid #6d48ef;
                        background-color: #6d48ef;
                        font-size: 15px;
                        font-weight: 500;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 10px;
                      }
                    }
                  }
                }
              }
            }

            ///////Dpp/////////
            .dppCollectionView_wrapper {
              .dppCollectionView_inner_wrapper {
                .dpp_collection_wrapper {
                  display: flex;
                  flex-wrap: wrap;
                  height: auto;
                  max-height: 350px;
                  overflow-y: auto;
                  .single_dpp_wrapper {
                    width: 49%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border: 1px solid #f5f4f8;
                    border-radius: 8px;
                    background-color: #f5f4f8;
                    padding: 10px;
                    margin: 0px 15px 15px 0px;
                    cursor: pointer;
                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }

                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-color: #6d48ef;
                      padding: 15px;
                      margin-right: 20px;
                    }
                    .dpp_description_wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      padding: 0 10px;
                      .dpp_details_wrapper {
                        .text_content_wrapper_1 {
                          .text_content_1 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #0f0404;
                          }
                        }
                        .text_content_wrapper_2 {
                          .text_content_2 {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.no_data_found_wrapper {
  text-align: center;
  img {
    width: 150px;
    height: 150px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .topicContentComponent_wrapper {
    padding-top: 60px;
    .topicContentComponent_inner_wrapper {
      padding: 20px 10px;
      margin-top: 0;
      .sec_1_wrapper {
        margin: 10px 0;
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        margin: 10px 0;
        .sec_2_inner_wrapper {
          .content_wrapper {
            flex-wrap: wrap;
            padding: 10px 0;
            .left_side_content_wrapper {
              flex: 0 0 100%;
              margin: 5px 0;
              .feature_collection_wrapper {
                width: 100%;
                flex-direction: row;
                justify-content: stretch;
                margin-bottom: 20px;
                .single_select_feature {
                  // margin: 5px;
                  flex: 0 0 50%;
                  input[type="radio"] {
                  }
                  label {
                    display: block;
                    align-items: unset;
                    width: 100%;
                    text-align: center;
                  }
                  input[type="radio"]:checked + label {
                  }
                }
              }
            }
            .right_side_content_wrapper {
              flex: 0 0 100%;
              margin: 5px 0;
              border-top: 1px solid #000000;
              ////////////////////videos/////////////////
              .videosCollectionView_wrapper {
                .videosCollectionView_inner_wrapper {
                  .sec_1_wrapper {
                    .select_wrapper {
                      select {
                      }
                    }
                  }
                  .sec_2_wrapper {
                    .sec_2_inner_wrapper {
                      .videos_collection_wrapper {
                        .single_video_wrapper {
                          &:nth-child(2n + 2) {
                            margin-right: 15px;
                          }
                          .left_side_content_wrapper {
                            .image_wrapper {
                              img {
                              }
                            }
                          }
                          .right_side_content_wrapper {
                            .text_content_wrapper_1 {
                              .text_content_1 {
                              }
                            }
                            .text_content_wrapper_2 {
                              .text_content_2 {
                              }
                            }
                            .sub_content_wrapper {
                              .left_side_content_wrapper {
                                // flex: 0 0 50%;
                                .label_content_wrapper {
                                  label {
                                    span {
                                      img {
                                      }
                                    }
                                  }
                                }
                              }
                              .right_side_content_wrapper {
                                .subject_name {
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              //////////////live class/////////////////
              .liveClassCollectionView_wrapper {
                .liveClassCollectionView_inner_wrapper {
                  .section_wrapper {
                    .section_inner_wrapper {
                      .options_wrapper {
                        .custom_nav_pills {
                          .custom_nav_item {
                            .custom_nav_link {
                            }
                            .custom_nav_link.active {
                            }
                          }
                        }
                      }
                      .tab_wrapper {
                        .custom_tab_content {
                          //////upcoming class tab////
                          .custom_tab_pane {
                            .upcoming_class_tab_wrapper {
                              .outer_content_wrapper {
                                .liveClasses_collection_wrapper {
                                  .single_liveClass_wrapper {
                                    .left_side_content_wrapper {
                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                    .right_side_content_wrapper {
                                      .text_content_wrapper_1 {
                                        .text_content_1 {
                                        }
                                      }
                                      .text_content_wrapper_2 {
                                        .text_content_2 {
                                        }
                                      }
                                      .sub_content_wrapper {
                                        .left_side_content_wrapper {
                                          .label_content_wrapper {
                                            label {
                                              span {
                                                img {
                                                }
                                              }
                                            }
                                          }
                                        }
                                        .right_side_content_wrapper {
                                          .text_content_wrapper {
                                            .text_content {
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          //////previous class tab////
                          .custom_tab_pane {
                            .previous_class_tab_wrapper {
                              .outer_content_wrapper {
                                .liveClasses_collection_wrapper {
                                  .single_liveClass_wrapper {
                                    .left_side_content_wrapper {
                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                    .right_side_content_wrapper {
                                      .text_content_wrapper_1 {
                                        .text_content_1 {
                                        }
                                      }
                                      .text_content_wrapper_2 {
                                        .text_content_2 {
                                        }
                                      }
                                      .sub_content_wrapper {
                                        .left_side_content_wrapper {
                                          .label_content_wrapper {
                                            label {
                                              span {
                                                img {
                                                }
                                              }
                                            }
                                          }
                                        }
                                        .right_side_content_wrapper {
                                          .text_content_wrapper {
                                            .text_content {
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              /////////exercise////////////////
              .exerciseCollectionView_wrapper {
                .exerciseCollectionView_inner_wrapper {
                  .exercise_collection_wrapper {
                    .single_exercise_wrapper {
                      width: 100% !important;
                      margin: 0px 10px 15px 0px !important;
                      .serial_number_wrapper {
                        .text_content_wrapper {
                          .text_content {
                          }
                        }
                      }
                      .exercise_description_wrapper {
                        .exercise_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                            }
                          }
                        }
                        .caret_wraper {
                          .text_content_wrapper {
                            .text_content {
                              i {
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ////////////notes///////////////
              .notesCollectionView_wrapper {
                .notesCollectionView_inner_wrapper {
                  .notes_collection_wrapper {
                    .single_notes_wrapper {
                      width: 100%;
                      margin: 0 10px 15px 0;
                      &:nth-child(2n + 2) {
                        margin-right: 10px;
                      }
                      .serial_number_wrapper {
                        width: 49%;
                        .image_wrapper {
                          img {
                          }
                        }
                      }
                      .notes_description_wrapper {
                        .notes_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                            }
                          }
                        }
                        .caret_wraper {
                          .image_wrapper {
                            img {
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
