.iframe_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.userEditProfileContentComponent_wrapper_mts {
  padding-top: 65px;
  padding-bottom: 20px;

  .userEditProfileContentComponent_inner_wrapper_mts {
    background: #fff;
    border-radius: 10px;
  }
}
