.modal {
  top: 0;
  .modal-header {
    h3 {
      font-size: 1.313rem;
      font-weight: 600;
      line-height: 2rem;
      letter-spacing: 0.01em;
      color: #000000;
      width: 100%;
      text-align: center;
    }
  }

  .store-terms-and-conditions {
    width: 50% !important;
    max-width: 50% !important;

    .store_terms_condition_wrapper {
      overflow-y: scroll;
      overflow-x: hidden;
      background: #f9f9f9;
      border-radius: 4px;
      padding: 20px;

      .store_terms_condition_inner_wrapper {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.563rem;
        letter-spacing: 0em;
      }
    }
  }
}

@media(max-width:578px){
  .modal{
    .store-terms-and-conditions{
      width: auto !important;
      max-width: none !important;
      .store_terms_condition_wrapper{
        max-height: 60vh;
        padding: 10px;
      }
    }
  }
}
