.userDoubtsContentComponent_wrapper {
  padding-top: 101px;
  .userDoubtsContentComponent_inner_wrapper {
    padding: 20px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userDoubtsContentComponent_wrapper {
    padding-top: 60px;
    .userDoubtsContentComponent_inner_wrapper {
      padding: 10px;
    }
  }
}
