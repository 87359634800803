.verify_details_modal_wrapper {
  .modal-content {
    width: 28.75rem;
    .modal-header {
      border: none;
    }

    .modal-body {
      padding: 1.5rem 1.88rem 2.5rem 1.88rem;
    }
    .verify_details_wrapper {
      h3 {
        color: #000;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      h2 {
        color: #585858;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0.25rem 0 1.25rem 0;
      }
      .verify_details_container {
        .detail {
          padding: 1rem 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // margin: 0 0.75rem;
          padding: 1rem;
        }

        .amnt {
          font-weight: 600;
        }

        .bg {
          background: #f2f2f2;
        }

        .left {
          color: #444;
          text-align: center;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.00438rem;
        }

        .right {
          color: #121212;
          text-align: center;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.005rem;
        }
      }

      .verify_details_button {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        text-align: center;
        margin-top: 1.75rem;

        .btnn {
          padding: 0.75rem 0;
          border-radius: 0.25rem;
          width: 100%;
        }

        .modify {
          border: 0.6px solid #b91311;
          cursor: pointer;

          button {
            color: #b50503;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .redeem {
          cursor: pointer;
          border: 0.6px solid #b91311;
          background: #b50503;

          button {
            color: #fff;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
}
