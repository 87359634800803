.headerLeft_wrapper {
  .headerLeft_inner_wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .hdrTopRight{
      display: flex;
      flex-direction: column;
      align-items: center;
                button{
                    background-color: #000;
                    color: #FFFFFF;
                    padding: 2px 10px 4px;
                    border-radius: 30px;
                    font-size: 12px;
                    p{
                      margin-bottom: 0px;
                    }
                    img{
                        margin-left: 10px;
                    }
                }
            }
  }
}

