.registration-modal {
  font-family: "Poppins";
  font-style: normal;
  margin-top: 50px;
  .modal-content {
    width: 442px;
    margin:0 auto;
    .modal-header {
      border: none;
    }

    h3 {
      margin-left: 12px;
      color: #303030;
      text-align: left;
      font: 600 20px "Poppins", sans-serif;
    }

    .modal-body {
      .details_Popup {
        form {
          .form-group {
            margin-top: -10px;
            margin-bottom: 24px;

            label {
              color: #121212;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 2px;
            }

            span {
              font: 400 18px "Poppins", sans-serif;
              color: #940001;
            }

            input {
              border-radius: 3px;
              border: 0.6px solid #d9d9d9;
              background: rgba(246, 246, 248, 0.6);
              height: 42px;
              // color: #444444;
              color: #777777;

              &::placeholder {
                color: #acacac;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .errMsg {
              color: red;
              margin-top: 4px;
              font-size: 12px;
            }
          }

          .upload-file {
            margin-bottom: 32px;

            label {
              border-radius: 4px;
              border: 1px solid #d9d9d9;
              background: #ececec;
              padding: 8px 12px;
              cursor: pointer;

              input[type="file"] {
                position: absolute;
                top: -1000px;
              }

              img {
                width: 24px;
                height: 24px;
                display: inline-block;
              }

              .upload-file-text {
                color: #323232;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 8px;
              }
            }

            .errMsg {
              color: red;
              margin-top: 4px;
            }
          }

          .submit_btn {
            width: 100%;
            height: 40px;
            padding: 6px;
            gap: 6px;
            flex-shrink: 0;

            &:disabled {
              background-color: #512fbb;
              opacity: 30%;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
