.pay_now {
  // button {
    background: #059249;
    letter-spacing: 0.36px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    padding: 8px 10px;
    width: 100%;
  // }
  a {
    background: #059249;
    letter-spacing: 0.36px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    padding: 8px 10px;
    width: 100%;
    display: inline-block;
    text-decoration: none;
  }
}

.store_pay_now {
  // button {
    color: #ffffff;
    background-image: linear-gradient(to bottom, #7c54f6, #5026d3);
    padding: 10px 28px 10px 28px;
    border-radius: 4px;
    width: 100%;
  // }
}
