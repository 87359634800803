.videoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  .view_videoSolution_btn {
    background: #ff933a;
    width: 30%;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #f5f4f8;
  }

  #iframe {
    height: 420px;
    width: 100%;
  }

  // .shakaPlayer {
  //   height: 420px;
  //   width: 100%;
  //   background-color: #000000;
  // }
}
