// .left_side_content_wrapper {
//   width: 32%;
//   background-color: #ffffff;
//   padding: 20px;
//   position: sticky;
//   top: 0px;

//   .sub_section_1_wrapper {
//     flex: 0 0 100%;

//     .text_content_wrapper {
//       margin-bottom: 20px;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//       .text_content {
//         margin: 0;
//         font-size: 16px;
//         font-weight: 600;
//         color: #0f0404;
//       }

//       button {
//         font-weight: bold;
//         text-decoration: underline;
//       }

//       .countdown_timer_container {
//         min-width: 55px;

//         span {
//           label {
//             color: #6d48ef;
//             font-size: 15px;
//           }
//         }
//       }
//     }

//     .questions_serial_number_collection {
//       overflow-y: scroll;
//       height: calc(100vh - 128px);
//       .single_question_number_wrapper {
//         margin: 10px 5px;

//         .arrow {
//           width: 8px;
//         }

//         .unanswered_lbl {
//           font-size: 14px;
//           font-weight: 500;
//           color: #000;
//           padding: 15px;
//           border-radius: 8px;
//           background-color: #fff;
//           border: 1px solid #e2e2e2;
//           cursor: pointer;
//           display: flex;
//           align-items: center;
//           background: #f5f4f8;
//           justify-content: space-between;
//           width: 100%;
//           max-height: 74px;
//           min-height: 74px;
//           .questionBox {
//             display: flex;
//             align-items: baseline;
//             margin-right: 5px;
//             overflow: hidden;
//             .QBox {
//               overflow: hidden;
//               text-overflow: ellipsis;
//               display: -webkit-box;
//               -webkit-line-clamp: 2;
//               /* number of lines to show */
//               -webkit-box-orient: vertical;
//               p {
//                 margin-left: 2px;
//                 margin-bottom: 0px;

//                 math {
//                   display: inline-block;
//                 }

//                 img {
//                   display: none;
//                 }

//                 table {
//                   width: 100% !important;
//                 }
//               }
//             }
//           }
//         }

//         .answered_lbl {
//           border: 1px solid #e2e2e2;
//           background-color: #48ef4c;
//         }

//         .review_lbl {
//           font-size: 15px;
//           font-weight: 500;
//           color: #000;
//           padding: 10px;
//           border-radius: 8px;
//           background: #fed5d5;
//           border: 1px solid #ffc4c4;
//           cursor: pointer;
//         }

//         .skip_lbl {
//           border: 1px solid #ffc4c4;
//           background-color: #fed5d5;
//         }

//         .current_lbl {
//           border: 1px solid #ff933a;
//           cursor: pointer;
//           background: inherit;
//         }

//         .isGussedAnswer {
//           border: 1px solid #6d48ef;
//           background-color: #6d48ef !important;
//         }
//       }
//     }
//   }
// }

.QuestionListPanelContent {
  .text_content_wrapper {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text_content {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: #0f0404;
    }

    button {
      font-weight: bold;
      text-decoration: underline;
    }

    .countdown_timer_container {
      min-width: 55px;

      span {
        label {
          color: #6d48ef;
          font-size: 15px;
        }
      }
    }
  }

  .question_marking_wrapper {
    flex: 0 0 100%;
    margin-bottom: 20px;
    .question_marking_Innrwrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .single_marking {
        margin-right: 15px;

        &:last-child {
          margin-right: 0px;
        }

        .box_pic {
          width: 14px;
          height: 14px;
          border-radius: 3px;
          margin-right: 5px;
        }

        .box_pick_background_1 {
          background-color: #48ef4c;
          border: 1px solid #ebebeb;
        }

        .box_pick_background_2 {
          background-color: #f99746;
        }

        .box_pick_background_3 {
          background-color: #e2e2e2;
          border: 1px solid #e2e2e2;
        }

        .box_pick_background_4 {
          background: #fed5d5;
          border: 1px solid #ffc4c4;
        }

        .box_pick_background_5 {
          background: #6d48ef;
          border: 1px solid #6d48ef;
        }

        .question_marking_text {
          font-size: 12px;
          font-weight: 500;
          color: #000;
          vertical-align: middle;
          margin-top: -4px;
        }
      }
    }
  }

  .questions_serial_number_collection {
    overflow-y: scroll;
    height: calc(100vh - 128px);
    .single_question_number_wrapper {
      margin: 10px 5px;

      .arrow {
        width: 8px;
      }

      .unanswered_lbl {
        font-size: 14px;
        font-weight: 500;
        color: #000;
        padding: 15px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #e2e2e2;
        cursor: pointer;
        display: flex;
        align-items: center;
        background: #f5f4f8;
        justify-content: space-between;
        width: 100%;
        max-height: 74px;
        min-height: 74px;
        .questionBox {
          display: flex;
          align-items: baseline;
          margin-right: 5px;
          overflow: hidden;
          .QBox {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* number of lines to show */
            -webkit-box-orient: vertical;
            p {
              margin-left: 2px;
              margin-bottom: 0px;

              math {
                display: inline-block;
              }

              img {
                display: none;
              }

              table {
                width: 100% !important;
              }
            }
          }
        }
      }

      .answered_lbl {
        border: 1px solid #e2e2e2;
        background-color: #48ef4c;
      }

      .review_lbl {
        font-size: 15px;
        font-weight: 500;
        color: #000;
        padding: 10px;
        border-radius: 8px;
        background: #fed5d5;
        border: 1px solid #ffc4c4;
        cursor: pointer;
      }

      .skip_lbl {
        border: 1px solid #ffc4c4;
        background-color: #fed5d5;
      }

      .current_lbl {
        border: 1px solid #ff933a;
        cursor: pointer;
        background: inherit;
      }

      .isGussedAnswer {
        border: 1px solid #6d48ef;
        background-color: #6d48ef !important;
      }
    }
  }
}
