.payment_information_component_wrapper {
  .payment_information_component_inner_wrapper {
    .sec_1_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 20px 0;
      .btn_wrapper {
        button {
          border: none;
          outline: none;
          background-color: transparent;
        }
      }
      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      .content_wrapper_1 {
        border-radius: 15px;
        background-color: #d6d0ed45;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        .left_side_content_wrapper {
          .text_content_wrapper_1 {
            .text_content_1 {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #000000;
            }
          }
          .text_content_wrapper_2 {
            .text_content_2 {
              margin: 0;
              font-size: 16px;
              font-weight: 600;
              color: #6b6876;
            }
          }
        }
        .right_side_content_wrapper {
          .btn_wrapper {
            button {
              border: none;
              outline: none;
              background-color: #6d48ef;
              padding: 10px;
              border-radius: 25px;
              font-size: 16px;
              font-weight: 600;
              color: #ffffff;
            }
          }
        }
      }
      .content_wrapper_2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        .left_side_content_wrapper {
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #a02b2d;
            }
          }
        }
        .right_side_content_wrapper {
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #a02b2d;
            }
          }
        }
      }
    }
    .sec_3_wrapper {
      margin: 20px 0;
      .content_wrapper {
        margin: 10px 0;
        .text_content_wrapper {
          .text_content {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            color: #6b6876;
          }
        }
        .form_wrapper {
          form {
            .content_wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              border: 2px solid #f0eef8;
              background-color: #ffffff;
              padding: 10px 0;
              border-radius: 10px;
              .left_side_content_wrapper {
                flex: 0 0 90%;
                .input_wrapper {
                  input[type="text"] {
                    width: 100%;
                    border: none;
                    outline: none;
                    height: 40px;
                    padding-left: 10px;
                  }
                }
              }
              .right_side_content_wrapper {
                flex: 0 0 10%;
                .btn_wrapper {
                  text-align: center;
                  button {
                    border: none;
                    outline: none;
                    background-color: #6d48ef;
                    padding: 10px;
                    border-radius: 15px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #ffffff;
                    width: 90%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sec_4_wrapper {
      margin: 20px 0;
      .content_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn_wrapper {
          button {
            border: none;
            outline: none;
            background-color: #6d48ef;
            padding: 10px;
            border-radius: 25px;
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .payment_information_component_wrapper {
    .payment_information_component_inner_wrapper {
      .sec_1_wrapper {
        .btn_wrapper {
          button {
          }
        }
        .text_content_wrapper {
          .text_content {
          }
        }
      }
      .sec_2_wrapper {
        .content_wrapper_1 {
          .left_side_content_wrapper {
            .text_content_wrapper_1 {
              .text_content_1 {
              }
            }
            .text_content_wrapper_2 {
              .text_content_2 {
              }
            }
          }
          .right_side_content_wrapper {
            .btn_wrapper {
              button {
              }
            }
          }
        }
        .content_wrapper_2 {
          .left_side_content_wrapper {
            .text_content_wrapper {
              .text_content {
              }
            }
          }
          .right_side_content_wrapper {
            .text_content_wrapper {
              .text_content {
              }
            }
          }
        }
      }
      .sec_3_wrapper {
        .content_wrapper {
          .text_content_wrapper {
            .text_content {
            }
          }
          .form_wrapper {
            form {
              .content_wrapper {
                .left_side_content_wrapper {
                  flex: 0 0 70%;
                  margin: 0 5px;
                  .input_wrapper {
                    input[type="text"] {
                    }
                  }
                }
                .right_side_content_wrapper {
                  flex: 0 0 20%;
                  margin: 0 5px;
                  .btn_wrapper {
                    button {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .sec_4_wrapper {
        .content_wrapper {
          .btn_wrapper {
            button {
            }
          }
        }
      }
    }
  }
}
