.subjectCollectionView_wrapper {
  .subjectCollectionView_inner_wrapper {
    .subject_collection {
      .subject_collection_desktop{
        display: block;
      }
      .subject_collection_mobile{
        display: none;
      }
      .slick-arrow {
        box-shadow: 0px 0px 30px #0000001a;
        width: 30px;
        height: 30px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAXCAYAAADQpsWBAAAABHNCSVQICAgIfAhkiAAAAOhJREFUOE+N1L8KwjAQBnC76HuJiGAHQcXV2SkdnFz7AJY+gKvg4KM4C7pJB58hafwOYumf5HJZQsn9SMh3aTISjizLDtba8RkjkRil1Al1uatdR1EPVNhtyqI+MMakZVk+gigE6IhexAEvioEBkoAOkoIGAVzwsXc5VP9bCmWYAFyxuHMFX4AFXSsXOqE7ClZUhOA+mGZFUTxZRIuAN0wbB9+YlxxscgKk3qIeox1Z2Am3DWFfwKlvx0FHSGCojZqj+nbkGjYIY0/DCyWPsAO11vMocjm24VaECOLHcqzreoIIcjFqt9UPHiuwN42MBW0AAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        background-position: center;
        background-color: #fff;
        border-radius: 50%;
        z-index: 1;
        padding: 5px;
        background-size: 9px;
        top: 46%;
        position: absolute;
      }

      .slick-prev:before,
      .slick-next:before {
        content: "";
      }

      .slick-prev {
        left: -14px;
      }

      .slick-next {
        right: -12px;
        transform: rotate(180deg) translateY(50%);
        top: 50%;
      }

      .single_subject_box {
        .single_subject {
          background-color: #F5F4F8;
          margin: 0px 5px;
          cursor: pointer;
          border-radius: 12px;
          padding: 20px 20px 20px 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .image_wrapper {
            display: inline-block;
            width: 40px;

            img {
              width: 100%;
            }
          }

          .text_content_wrapper {
            display: inline-block;

            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 500;
              text-align: center;
              color: #0f0404;
              line-height: 20px;
            }
          }

          .arrow {
            img {}
          }
        }
      }
    }

    .viewAllTrigger_wrapper {
      margin: 10px 0;

      .btn_wrapper {
        text-align: center;

        button {
          border: none;
          outline: none;
          background: transparent;
          font-size: 14px;
          font-weight: 600;
          color: #6d48ef;
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .subjectCollectionView_wrapper {
    .subjectCollectionView_inner_wrapper {
      .subject_collection {
        flex-wrap: wrap;

        .single_subject {
          flex: 0 0 45%;
          margin: 5px;

          .image_wrapper {
            img {}
          }

          .text_content_wrapper {
            .text_content {}
          }
        }
      }

      .viewAllTrigger_wrapper {
        .btn_wrapper {
          button {}
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .subjectCollectionView_wrapper {
    .subjectCollectionView_inner_wrapper {
      .subject_collection {
        
        .single_subject {
          flex: 0 0 100%;

          .image_wrapper {
            img {}
          }

          .text_content_wrapper {
            .text_content {}
          }
        }
      }

      .viewAllTrigger_wrapper {
        .btn_wrapper {
          button {}
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .subjectCollectionView_wrapper {
    .subjectCollectionView_inner_wrapper {
      .subject_collection {
        .subject_collection_desktop{
          display: none;
        }
        .subject_collection_mobile{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .single_subject_box {
            flex: 0 0 45% ;
            .single_subject{
              width: -webkit-fill-available;
              flex-direction: column;
              gap: 0.5rem;
              padding: 10px 5px;
            }
          }

        }
        .single_subject_box{
          margin-bottom: 10px;
        }
        .single_subject {

          .image_wrapper {
            img {}
          }

          .text_content_wrapper {
            .text_content {}
          }
        }
      }

      .viewAllTrigger_wrapper {
        .btn_wrapper {
          button {}
        }
      }
    }
  }
}