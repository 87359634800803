.freewatch {
  .modal-header {
    border-bottom: 0;
  }

  .free-user-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 6.37988rem;
      height: 5.42306rem;
    }

    .free-header {
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.375rem; /* 137.5% */
      letter-spacing: 0.01rem;
      margin-top: 1.58rem;
      margin-bottom: 0.5rem;
    }

    .free-sub-header {
      color: #545454;
      text-align: center;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      letter-spacing: 0.00875rem;
      max-width: 18.6875rem;
      margin-bottom: 2.75rem;
    }

    .watchNowButton {
      width: 100%;
      padding: 0.75rem 0;
      border-radius: 0.25rem;
      background: #6d48ef;
      text-align: center;
      cursor: pointer;

      button {
        color: #fff;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
