.right_side_content_wrapper {
  // width: 68%;
  border-radius: 8px;
  padding: 10px;
  // background-color: #f5f4f8;

  .sub_section_wrapper {
    max-width: 750px;
    margin: 0 auto;
    max-height: 100vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    .sub_sub_section_wrapper_1 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right_side_content_wrapper {
        flex: 0 0 100%;
        display: flex;
        justify-content: flex-end;

        .review_image_wrapper {
          margin: 0 10px;

          img {
            cursor: pointer;
          }
        }

        .bookmark_image_wrapper {
          margin: 0 10px;

          img {
            cursor: pointer;
          }
        }
      }
    }

    .testProgressIndicator {
      background: #fff;
      padding: 15px;
      border-radius: 8px;
      margin-bottom: 40px;
      h4 {
        font-size: 14px;
        color: #000;
        margin-bottom: 16px;
      }

      .ProgressBar_Line {
        position: relative;
      }

      .ProgressBar_Line span {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      .progress {
        height: 8px;
        margin-bottom: 5px;

        .progress-bar {
          background: #ff933a;
          border-radius: 9px;
        }
      }

      .questionPercentage {
        display: flex;
        justify-content: space-between;

        span {
          color: #707070;
          font-size: 14px;
        }
      }
    }

    .sub_sub_section_wrapper_2 {
      h2 {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .text_content_wrapper {
        .typeLanguageWrapper {
          display: flex;
          justify-content: space-between;

          span {
            margin-bottom: 10px;
          }
        }

        .questionBox {
          display: flex;
          align-items: baseline;
          margin-bottom: 20px;

          span {
            margin-right: 5px !important;
          }

          .text_content {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            color: #000;

            p {
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    .sub_sub_section_wrapper_3 {
    }

    .sub_sub_section_wrapper_4 {
      .checkbox_wrapper {
        input[type="checkbox"] {
          margin-right: 10px;
        }

        label {
          font-size: 13px;
          vertical-align: text-top;
          color: #09041a;
        }
      }
    }

    .sub_sub_section_wrapper_5 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left_side_wrapper {
        width: calc(50% - 8px);
        margin-right: 16px;

        .btn_wrapper {
          text-align: left;

          button {
            border: none;
            outline: none;
            border-radius: 8px;
            background-color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: #6d48ef;
            padding: 10px;
            width: 100%;
            border: 1px solid #e2e2e2;
            // text-decoration: underline;
          }
        }
      }

      .right_side_wrapper {
        width: calc(50% - 8px);

        .solution {
          margin-right: 16px;
          background: #ff933a !important;
        }

        .btn_wrapper {
          text-align: right;

          button,
          a {
            border: none;
            outline: none;
            border-radius: 8px;
            background-color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: #fff;
            padding: 10px;
            width: 100%;
            background: #6d48ef;
            display: inline-block;
            text-decoration: none;
          }
        }
      }
    }

    .QuestionSolution {
      padding-top: 20px;

      h5 {
      }

      div {
        p {
          margin-bottom: 2px;

          math {
            width: 100%;
            word-break: break-word;
          }
        }
      }
    }

    .sub_sub_section_wrapper_6 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
}
