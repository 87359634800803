.otsTestSummaryPageInrWrapper {
  .test_summaryBox {
    .rankMarksBox {
      padding: 0px 20px;

      .rankMarksDiv {
        .rnkMrkBox {
          text-align: center;
          display: flex;
          justify-content: center;
          margin-bottom: 40px;

          .rankIcon {
            background: #f5f4f8;
            text-align: center;
            width: 220px;
            height: 220px;
            margin: 0px 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
              margin-bottom: 10px;
              width: 55px;
            }

            h5 {
              margin-bottom: 10px;
              letter-spacing: 0.68px;
              font-size: 28px;
              font-weight: 600;
            }

            span {
              line-height: 18px;
              color: #707070;
              opacity: 0.8;
              font-size: 18px;
            }
          }
        }
      }

      .rankChartBox {
        display: flex;
        justify-content: space-between;
        .AttemptAndAccuracyPercent {
          width: calc(50% - 9px);
          background: #f5f4f8;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 30px;
          border: 1px solid #eeeeee;

          padding: 68px 20px;

          .progress-container {
            // margin-top: 20px;
            // padding: 8px 0px;

            .accurateBar {
              // padding: 20px;

              .text {
                padding: 20px 0px;
              }

              .accuracy {
                .progress-bar {
                  background: #31d680;
                  // border-radius: 5px;
                }
              }

              .attempt {
                .progress-bar {
                  // background: #668de1;
                  // border-radius: 5px;
                }
              }
            }
          }
        }

        .expctd_AIR_Chart {
          width: calc(50% - 9px);
          border-style: solid;
          border-color: #eeeeee;
          border-width: 1px;
          background: #f5f4f8;
          border-radius: 8px;
          padding: 26px;
          height: 345px;

          .rankCard {
            display: flex !important;
            border-radius: 4px;
            border: 0.6px solid #e8e8e8;
            background: #fff;
            // padding: 25px;
            padding: 14px 0px 14px 16px;
            margin: 14px 0px;
            img {
              width: 40px;
              height: 40px;
              flex-shrink: 0;
            }

            .content {
              margin-left: 30px;

              font-family: Poppins;
              .text {
                color: #8b8b8c;

                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              .num {
                color: #000;
                font-size: 19px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.19px;
              }
            }
          }
        }
      }

      .scorePercentageMarksWrpr {
        display: flex;
        margin-bottom: 30px;

        .scorePercentageBox {
          width: calc(50% - 8px);
          margin-right: 16px;
          display: flex;
          align-items: center;
          border: 1px solid #eeeeee;
          border-radius: 8px;
          background: #f5f4f8;
          margin-right: 16px;

          .scoreChart {
            width: 150px;
            margin: 40px 20px;

            .CircularProgressbar .CircularProgressbar-path {
              stroke: #fcb21d;
            }
          }

          .scoreDetail {
            p {
              font-size: 14px;
              margin-bottom: 10px;
            }

            .percentageDiv {
              font-size: 12px;
              margin-bottom: 5px;

              span {
                background-color: #e2e2e2;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 8px;
              }
            }

            .gotPrcntg {
              span {
                background-color: #fcb21d;
              }
            }
          }
        }
      }

      .testTypeOption {
        display: flex;
        flex-wrap: wrap;

        .testResultOption {
          border: 1px solid #eeeeee;
          border-radius: 6px;
          background: #f5f4f8;
          padding: 13px;
          width: calc(50% - 8px);
          margin: 0px 16px 16px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &:nth-child(2n + 2) {
            margin-right: 0px;
          }

          span {
          }

          .arrow {
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .testSummaryPageWrapper {
    .testSummaryPageInrWrapper {
      margin: 0px 0px;
    }
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 575.98px) {
  .testSummaryPageWrapper {
    .testSummaryPageInrWrapper {
      padding: 10px;

      .test_summaryBox {
        .rankMarksBox {
          padding: 0px 0px;

          .testTypeOption {
            .testResultOption {
              width: 100%;
              margin: 0px 0px 15px 0px;
            }
          }

          .rankMarksDiv {
            .rnkMrkBox {
              .rankIcon {
                height: auto;
                padding: 15px 0px;
                border-radius: 10px;
              }
            }
          }

          // .marksChart {
          //   width: 50%;
          // }

          .scorePercentageMarksWrpr {
            flex-direction: column;

            .scorePercentageBox {
              width: 100%;
              margin: 0px 0px 15px 0px;
            }
          }
        }
      }
    }
  }
}
