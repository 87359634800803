
.examCrackerPageWrapper {
  background-color: #f5f4f8;
  min-height: calc(100vh);
  .subjectContentComponent_wrapper {
    padding-top: 70px;
    padding-bottom: 20px;
    .subjectContentComponent_inner_wrapper {
      margin: 30px 0px;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      min-height: 80vh;
      padding: 20px;
      .examCrackerHeading {
        display: flex;
        justify-content: space-between;
        h2 {
          text-align: center;
          width: 100%;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 45px;
          color: #000000;
        }
      }
      .sec_1_wrapper {
        margin: 20px 0;
        .sec_1_inner_wrapper {
          display: flex;
          justify-content: flex-start;
          .btn_wrapper {
            button {
              outline: none;
              border: none;
              background: transparent;
            }
          }
          .text_content_wrapper {
            margin: 0 10px;
            .text_content {
              margin: 0;
              font-size: 20px;
              font-weight: 600;
              color: #0f0404;
            }
          }
        }
      }
      .sec_2_wrapper {
        margin: 20px 0;
        border-radius: 8px;
        background-color: #ffffff;
        .sec_2_inner_wrapper {
          .content_wrapper {
            padding: 0 10px;
            .left_side_content_wrapper {
                margin-left: 30px;
              .subject_collection_wrapper {
                //    display: -webkit-inline-box;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0px 20px;
                overflow-x: auto;
                margin-bottom: 30px;
                   .single_select_subject {
                    border-radius: 10px;
                    cursor: pointer;     
                    .subject_icon {
                            width: 54px;
                            position: absolute;
                            left: 30px;
                            height: 54px;
                            background: #fff;
                            padding: 10px;
                            border-radius: 50%;
                            cursor: pointer;
                            transform: translateY(-50%);
                            top: 50%; 
                          }

                    input[type="radio"] {
                      opacity: 0;
                      position: fixed;
                      width: 0;
                    }
          
                    label {
                      font-family: "Poppins";
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0.2px;
                        color: #6b6876;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        padding: 30px 30px 30px 100px;
                        width: 100%;
                    }
          
                    input[type="radio"]:checked + label {
                      color: #ffffff;
                      background: #31d680;
                      padding: 30px 30px 30px 100px;
                      overflow: hidden;
                      border: 1px solid #31d680;
                      border-radius: 12px;
                      
                      .img_wrp{
                          background: #ffffff;
                          overflow: hidden;
                          border: 1px solid #ffffff;
                          border-radius: 50%;
                          padding: 30px;
                          margin-right: 20px;
                        }
                    }
                    .subjectNameImg{
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding: 10px 8px;
                     margin-right: 40px;
                      .img_wrp {
                        padding: 40px;
                        margin-right: 30px;
                      }
                    }
          
                  }
                
              }
            }

            
            .right_side_content_wrapper {
              min-height: 300px;
              
              .topic_collection_wrapper {
                display: flex;
                flex-wrap: wrap;
                padding: 0 97px;
                overflow-y: scroll;
                margin-left: 90px;
                .single_topic_wrapper {
                  width: 45%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  border-radius: 8px;
                  background: #f5f4f8;
                  padding: 20px 5px;
                  margin: 0px 15px 15px 0px;
                  cursor: pointer;
                  &:nth-child(2n + 2) {
                    margin-right: 0px;
                  }
                  .serial_number_wrapper {
                    border-radius: 15px;
                    background-image: linear-gradient(
                      149deg,
                      #fff5e6 32%,
                      #ffefe6 76%
                    );
                    padding: 15px;
                    .text_content_wrapper {
                      .text_content {
                        margin: 0;
                        font-size: 20px;
                        font-weight: 600;
                        text-align: center;
                        color: #b35557;
                      }
                    }
                  }
                  .topic_description_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 0 10px;
                    .topic_details_wrapper {
                      .text_content_wrapper {
                        .text_content {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #0f0404;
                        }
                      }
                    }
                    .caret_wraper {
                      img {
                        width: 9px;
                      }
                    }
                  }
                }
              }
              .topic_collection_wrapper::-webkit-scrollbar {
                width: 2px;
              }
              .topic_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #f5f4f8;
                border-radius: 10px;
              }

              /* Handle */
              .topic_collection_wrapper::-webkit-scrollbar-thumb {
                background: #6b6876ab;
                border-radius: 10px;
              }

              /* Handle on hover */
              .topic_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #6b6876ab;
              }
            }
          }
        }
      }
    }
  }

  .no_data_found_wrapper {
    text-align: center;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .subject_collection_wrapper::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .subjectContentComponent_wrapper {
      padding-top: 60px;
      .subjectContentComponent_inner_wrapper {
        padding: 20px 10px;
        margin: 0;
        .examCrackerHeading{
          h2{
            line-height: 36px;
            font-size: 1.6rem;
          }
        } 
        .sec_1_wrapper {
          margin: 10px 0;
          .sec_1_inner_wrapper {
            .btn_wrapper {
              button {
              }
            }
            .text_content_wrapper {
              .text_content {
              }
            }
          }
        }
        .sec_2_wrapper {
          margin: 10px 0;
          .sec_2_inner_wrapper {
            .content_wrapper {
              padding: 10px 0;
              flex-wrap: wrap;
              .left_side_content_wrapper {
                flex: 0 0 100%;
                padding: 5px 0;
                margin: 5px 0;
                .subject_collection_wrapper {
                  padding: 0 10px;
                  flex-direction: row;
                  justify-content: flex-start;
                  margin-bottom: 0;
                  .single_select_subject {
                    margin: 5px;
                    .subject_icon{
                      width: 40px;
                      left: 10px;
                      height: 40px;
                    }
                    input[type="radio"] {
                    }
                    label {
                      padding: 15px 20px 15px 60px;
                    }
                    input[type="radio"]:checked + label {
                      padding: 15px 20px 15px 60px;
                    }
                  }
                }
              }
              .right_side_content_wrapper {
                flex: 0 0 100%;
                padding: 5px 0;
                margin: 5px 0;
                .topic_collection_wrapper {
                  border-left: none;
                  border-top: 1px solid #00000017;
                  padding: 10px;
                  margin-left: 0;
                  .single_topic_wrapper {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 10px;
                    .serial_number_wrapper {
                      .text_content_wrapper {
                        .text_content {
                        }
                      }
                    }
                    .topic_description_wrapper {
                      .topic_details_wrapper {
                        .text_content_wrapper {
                          .text_content {
                          }
                        }
                      }
                      .caret_wraper {
                        .text_content_wrapper {
                          .text_content {
                            i {
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
