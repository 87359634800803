.otsAttemtAccuracyWrapper {
  background-color: #f5f4f8;
  min-height: 100vh;

  .attemtAccuracyInrWrapper {
    padding-top: 101px;
    padding-bottom: 20px;

    .attemptAccuracyBox {
      margin: 20px 50px 0px;
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;

      .backBtn {
        cursor: pointer;

        img {
        }
      }

      .subjectsListWrapper {
        .subjectsList {
          display: flex;
          background: #f5f4f8;
          max-width: max-content;
          margin: 0 auto;
          border-radius: 20px;
          overflow: hidden;

          margin-bottom: 60px;
          margin-top: 60px;

          .single_select_subject {
            &:last-child {
              margin-right: 0px;
            }

            input[type="radio"] {
              opacity: 0;
              position: fixed;
              width: 0;
            }

            label {
              padding: 8px 20px;
              font-size: 15px;
              font-weight: 500;
              letter-spacing: 0.2px;
              color: #6b6876;
              cursor: pointer;
              min-width: 150px;
              text-align: center;
            }

            input[type="radio"]:checked + label {
              background-color: #ff933a;
              border: none;
              color: #ffffff;
            }
          }
        }

        .topicAnalysisData {
          display: flex;
          flex-wrap: wrap;

          .testProgressIndicator {
            background: #fff;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 40px;
            width: calc(50% - 10px);
            margin-right: 20px;

            &:nth-child(2n + 2) {
              margin-right: 0px;
            }

            .topicDetail {
              display: flex;
              justify-content: space-between;

              h4 {
                font-size: 13px;
                color: #000;
                margin-bottom: 18px;
                font-weight: 600;
              }

              .Qmark {
                font-weight: 500;
              }
            }

            .ProgressBar_Line {
              position: relative;
            }

            .ProgressBar_Line span {
              position: absolute;
              top: -16px;
              font-size: 14px;
            }

            .progress {
              height: 18px;
              margin-bottom: 5px;

              .progress-bar {
                background: #668de1;
                border-radius: 5px;
              }
            }

            .questionPercentage {
              display: flex;
              justify-content: space-between;
              padding: 0px 3px;

              span {
                color: #acacac;
                font-size: 14px;
              }
            }
          }
        }
      }

      .attemptAccuracyResult {
        h2 {
          text-align: center;
          font-size: 25px;
          font-weight: 600;
        }

        .resultIndicator {
          text-align: right;
          margin-bottom: 30px;

          .indicate {
            display: inline-block;
            margin-left: 20px;
            font-size: 12px;

            span {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              display: inline-block;
              background: #668de1;
              margin-right: 5px;
            }

            .accuracy {
            }

            .attempt {
              background-color: #ff933a;
            }
          }
        }

        .resultProgressBar {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          .resultProgressBox {
            width: calc(50% - 10px);
            margin-bottom: 20px;
            margin-right: 20px;

            &:nth-child(2n + 2) {
              margin-right: 0px;
            }

            h5 {
            }

            .accurateBar {
              padding-right: 14%;
              position: relative;
              margin-bottom: 15px;

              .accuracy {
                .progress-bar {
                  background: #668de1;
                  border-radius: 5px;
                }
              }

              .attempt {
                .progress-bar {
                  background: #ff933a;
                  border-radius: 5px;
                }
              }

              span {
                position: absolute;
                top: 0px;
                right: 50px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .attemtAccuracyWrapper {
    .attemtAccuracyInrWrapper {
      .attemptAccuracyBox {
        margin: 20px 0px 0px;
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .attemtAccuracyWrapper {
    .attemtAccuracyInrWrapper {
      padding-top: 50px;

      .attemptAccuracyBox {
        padding: 10px;

        .attemptAccuracyResult {
          h2 {
            font-size: 22px;
          }

          .resultProgressBar {
            .resultProgressBox {
              width: 100%;
              margin-right: 0px;

              .accurateBar {
                padding-right: 18%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 578.98px) {
  .otsAttemtAccuracyWrapper {
      .attemtAccuracyInrWrapper {
        padding-top: 46px;
        .attemptAccuracyBox{
          margin: 10px 0 0;
          padding: 10px;
          .subjectsListWrapper{
            .subjectsList{
              margin-bottom: 30px;
              margin-top: 30px;
              flex-wrap: wrap;
              .single_select_subject{
                width: 50%;
                label{
                  min-width: -webkit-fill-available;
                }
              }
            }
          }
          .attemptAccuracyResult{
            .resultIndicator{
              text-align: left;
            }
          }
          .attemptAccuracyResult{
            .resultProgressBar{
              display: block;
              .resultProgressBox{
                width: 100%;
                margin-right: 0;
                .accurateBar{
                  padding-right: 10%;
                  span{
                    right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
}
