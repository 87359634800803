button[aria-label="Invite"] {
  display: none !important;
}
// button:contains("Invite") {
//   display: none !important;
// }


// zoom_component.scss
.zoom-meeting-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  #zmmtg-root {
    display: block;
    width: 100%;
    height: 100%;
    
    .zm-btn {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    .join-audio-container {
      position: fixed;
      bottom: 20px;
      left: 20px;
      z-index: 1001;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f4f4f4;
    
    h2 {
      color: #2d8cff;
      font-size: 24px;
      font-weight: 500;
    }
  }
}

// Override some Zoom default styles
.meeting-app {
  width: 100% !important;
  height: 100% !important;
}

.meeting-client {
  width: 100% !important;
  height: 100% !important;
}

// Fix for join button
.join-btn {
  background-color: #2d8cff !important;
  color: #ffffff !important;
  border: none !important;
  padding: 10px 20px !important;
  cursor: pointer !important;
  
  &:hover {
    background-color: #2378db !important;
  }
  
  &:active {
    background-color: #1d68c3 !important;
  }
}


.participants-section-container__participants-footer-bottom {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  background-color: #fff;
  display: none !important;
}