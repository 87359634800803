.ots_premium_test {
  .sps_test_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;

    .sps_test_list_item {
      background: #f5f4f8;

      padding: 20px;

      width: 500px;
      margin-bottom: 20px;

      .sps_test_heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .sps_test_name {
          font-family: "Poppins";
          font-style: "SemiBold";
          font-weight: 600;
          line-height: 21px;
          font-size: 14px;
          color: #000000;
        }

        .sps_test_icons {
          display: flex;
          justify-content: space-around;
          width: 90px;
          img {
            cursor: pointer;
          }
        }
      }

      .sps_test_class_course {
        font-size: 12px;
        font-weight: 400;
        font-family: "Poppins";
        font-style: "Regular";
        line-height: 18px;
        color: #4d4d4d;
        margin-bottom: 10px;
        min-height: 18px;
      }
      .sps_test_description {
        // width: 80%;
        font-family: Open Sans;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #4d4d4d;
        margin-bottom: 15px;
        overflow: hidden;
      }

      .sps_test_que_mins_marks {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        ul {
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            // padding: 4px 12px;
            padding: 3px 10px;

            border: 0.4px solid #ffc391;
            background: #fffdfc;

            font-family: "Poppins";
            font-style: "Regular";
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #ff7a0c;
            border-radius: 4px;
            margin-right: 10px;

            span {
              margin-right: 3px;
            }
          }
        }

        // ul:last-child {
        //   li {
        //     margin-top: 30px;

        //     span {
        //       margin-right: 0px;
        //     }
        //   }
        // }

        .sps_test_date_time {
          // margin-top: 5px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          // line-height: 18px;
          /* identical to box height */

          color: #4d4d4d;
          // float: right;
          border: 0.4px solid #cecece;
          border-radius: 4px;
          padding: 2px 5px;
          display: flex;
          align-items: center;
          // margin-right: 5px;

          .date_time {
          }
        }
      }

      .sps_Premtest_buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .sps_test_practice_button,
        .sps_test_start_button {
          font-size: 15px;
          font-weight: 500;
          cursor: pointer;
          background: #ffffff;
          border-radius: 20px;
          text-align: center;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 47%;
          border: 1px solid #00bc5b;

          button {
            color: #00bc5b;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.01em;

            img {
              height: 17px;
              margin-right: 6px;
              margin-top: -3px;
            }

            span {
              margin-left: 6px;
              color: #fff;
            }
          }
        }

        .sps_test_start_button {
          background: #00bc5b;
          box-shadow: 0px 4px 12px rgba(66, 38, 15, 0.15);
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.01em;
        }
      }
    }
    .ots_no_data_found_wrapper {
      margin: auto;
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .ots_premium_test {
    .sps_test_list {
      .sps_test_list_item {
        width: 100%;
        .sps_test_que_mins_marks{
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
          ul{
            li{
              text-align: center;
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
        .sps_Premtest_buttons{
          flex-direction: column;
          gap: 1rem;
          .sps_test_practice_button,.sps_test_start_button{
            width: 100%;
          }
        }
        .sps_test_description{
          text-align: center;
        }
      }
    }
  }
}
