.login_step_two2_wrapper {
  display: flex;
  min-height: 100vh;

  .login2_left_part {
    width: 50%;
    background-color: #E00116;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      img {}
    }
  }

  .login2_right_part {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .signup_form {
      width: 400px;
      border-radius: 10px;
      border: solid 1px #f2f2f3;
      background-color: #ffffff;
      padding: 20px 25px 25px;
      text-align: center;

      .back_btn_wrapper {
        margin-bottom: 0px;
        text-align: left;

        button {
          border: none;
          outline: none;
          background: transparent;
        }
      }

      .image_container {
        text-align: center;
        width: 80px;
        margin: 0 auto 20px;

        img {
          width: 100%;
        }
      }

      h3 {
        font-size: 24px;
        color: #000;
        font-weight: 600;
        margin: 10px 0px 10px;
      }

      p {
        letter-spacing: 0.28px;
        color: #000000;
        opacity: 0.5;
        font-size: 14px;
      }

      .otp_input_wrapper {
        margin: 25px 0;

        .otp_input_inner_wrapper {
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          .single_input {
            input[type="text"] {
              width: 45px;
              height: 45px;
              border-radius: 6px;
              border: none;
              background: #EBEBEB;
              text-align: center;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }

      .btn_wrapper {
        margin: 20px 0 10px;
        text-align: center;

        button {
          border: none;
          outline: none;
          width: 100%;
          height: 42px;
          border-radius: 6px;
          background: #6D48EF;
          box-shadow: 0px 7px 30px #6d48ef1c;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
        }

        button:focus {
          background-color: #6d48ef;
          box-shadow: none;
          color: #ffffff;
        }
      }

      .resend_btn_wrapper {
        margin: 0px 0;
        text-align: center;

        button {
          border: none;
          outline: none;
          width: auto;
          border-radius: 25px;
          background-color: transparent;
          font-size: 16px;
          font-weight: 600;
          color: #6d48ef;
          text-decoration: underline;
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .login_step_two_wrapper {
    .login_step_two_inner_wrapper {
      height: auto;

      .content_wrapper {
        width: 100%;

        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;

          .back_btn_wrapper {
            margin-bottom: 15px;

            button {}
          }

          .image_container {
            img {}
          }

          .text_content_wrapper {
            .text_content {}
          }

          .otp_input_wrapper {
            margin: 15px 0;

            .otp_input_inner_wrapper {
              .single_input {
                input[type="text"] {}
              }
            }
          }

          .btn_wrapper {
            margin: 10px 0;

            button {}
          }

          .resend_btn_wrapper {
            margin: 10px 0;

            button {}
          }
        }
      }
    }
  }
}