.chatbox-container {
  display: flex;
  justify-content: end;
  height: 600px;
  width: 345px;
  .chatbox-wrapper {
    width: 345px;
    border: 1px solid gray;
    scrollbar-width: thin;
    border-radius: 1rem;
    border: 0.8px solid #e9e9e9;
    background: #f6f6f7;
    header {
      display: flex;
      align-items: center;
      border-bottom: 0.6px solid #e3e3e3;
      background: #f6f6f7;

      .tab {
        display: flex;
        flex: 1;
        padding: 0.7rem 0 0.9rem 0.9rem;
        color: #121212;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.00438rem;
        cursor: pointer;
        margin: 0 0 0 auto;
        justify-content: center;
        align-items: center;

        &.active {
          border-bottom: 2px solid red;
        }
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }
    }

    .message-container {
      height: 475px;
      scrollbar-width: thin;
      overflow-y: auto;
      margin-left: 0.9rem;

      .msg-list {
        margin-bottom: 1.2rem;

        .avatar-and-name {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 0.4rem;

          .avatar {}

          .user-name {
            color: #585858;
            font-family: Poppins;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .my {
          justify-content: flex-end;
          margin-right: 0.5rem;
        }

        .user-msg {
          display: inline-block;
          width: fit-content;
          color: #121212;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 0rem 1rem 1rem 1rem;
          background: #e3e3e3;
          padding: 0.5rem;
          margin-left: 2rem;
        }

        .msg-container2 {
          display: flex;
          justify-content: flex-end;

          .my-msg {
            display: inline-block;
            width: fit-content;
            color: #fff;
            font-family: Poppins;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 1rem 0rem 1rem 1rem;
            background: #b50303;
            padding: 0.5rem;
            text-align: right;
            margin-right: 2.5rem;
          }
        }
      }
    }

    .input-send-container {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .input-emoji-wrapper {
        width: 83%;
        border-radius: 2.5rem;
        border: 0.6px solid #ececec;
        background: #fdfdfd;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.75rem 8.8125rem 0.75rem 0rem;

        .emoji-wrapper {
          width: 100%;
          height: 100%;

          .emoji {
            margin: 0 0.5rem 0 1.38rem;
            cursor: pointer;
            img {
              width: 1.5rem;
              height: 1.5rem;
            }
          }

          .close-btn {
            position: absolute;
            top: 0;
            margin-left: 315px;
            margin-top: 42px;
            cursor: pointer;
          }
        }

        .msg-input {
          input {
            border: none;

            &:focus {
              border: 1px solid #ffffff;
              outline: none;
            }

            &::placeholder {
              color: #949494;
              font-family: Poppins;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .send-icon {
        cursor: pointer;

        img {
          width: 2.5rem;
          height: 2.5rem;
        }
    }
  }

    .all-polls {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
       scrollbar-width: thin;
      max-height: 540px;

      .single-poll {
        width: 90%;
        // margin-bottom: 1rem;
       scrollbar-width: thin;


        iframe {
          // width: 90%;
          height: 500px; 
          border: none;


  }
      }
    }
  }
}
