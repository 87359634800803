.testProgressIndicator {
  background: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  margin-bottom: 20px;

  h4 {
    font-size: 14px;
    color: #000;
    margin-bottom: 16px;
  }

  .ProgressBar_Line {
    position: relative;
  }

  .ProgressBar_Line span {
    position: absolute;
    top: -16px;
    font-size: 14px;
  }

  .progress {
    height: 8px;
    margin-bottom: 5px;

    .progress-bar {
      background: #ff933a;
      border-radius: 9px;
    }
  }

  .questionPercentage {
    display: flex;
    justify-content: space-between;

    span {
      color: #707070;
      font-size: 14px;
    }
  }
}
