.questionInfoText {
  h2 {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
  .text_content_wrapper {
    .typeLanguageWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #707070;
      }
    }

    .questionBox {
      display: flex;
      justify-content: space-between;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;

      letter-spacing: 0em;
      color: #000000;
      margin-top: 20px;
      span {
        margin-right: 10px;
      }
      p {
        flex: 1;
        text-align: start;
      }
    }
  }
}
