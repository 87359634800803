.quesitonInfoSection {
  h2 {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .text_content_wrapper {
    .typeLanguageWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      span {
        color: #707070;
        font-size: 12px;
      }

      .markReview {
        display: flex;

        .bookmark_image_wrapper {
          margin: 0px 10px;

          span {
            margin-right: 5px;
          }

          img {
            cursor: pointer;
          }
        }

        button {
        }
      }
    }

    .questionBox {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;

      span {
        margin-right: 5px !important;
      }

      .text_content {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        color: #000;

        p {
          margin-bottom: 0px;
        }
      }

      .hide {
        display: none;
      }

      .show {
        display: block;
      }
    }
  }
}
