.chat_footer_wrapper {
  border-radius: 0 0 10px 10px;
  .chat_footer_inner_wrapper {
    background-color: darksalmon;
    .media_showing_wrapper {
      padding: 5px 0;
      background-color: #a69e9e;
      .media_wrapper {
        margin: 5px 0;
        text-align: center;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .media_action_btn_wrapper {
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn_wrapper {
          margin: 0 5px;
          button {
            border: none;
            outline: none;
            background: none;
            img {
              width: 35px;
            }
          }
        }
      }
    }
    .chat_sending_actions_wrapper {
      // background-color: #6d48ef;
      border-radius: 0 0 10px 10px;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left_side_content_wrapper {
        flex: 0 0 3%;
        .image_upload_wrapper {
          .custom_file_upload {
            width: 100%;
            text-align: center;
            cursor: pointer;
            #upload_image {
              display: none;
            }
            img {
              width: 40px;
            }
          }
        }
      }
      .right_side_content_wrapper {
        flex: 0 0 92%;
        .form_wrapper {
          form {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .input_wrapper {
              flex: 0 0 95%;
              input[type="text"] {
                width: 100%;
                height: 40px;
                border: none;
                outline: none;
                background: transparent;
                color: #000000;
                font-size: 18px;
                border-bottom: 1px solid #707070;
              }
              ::placeholder {
                color: #000000;
              }
            }
            .btn_wrapper {
              flex: 0 0 5%;
              button {
                width: 100%;
                height: 40px;
                border: none;
                outline: none;
                background: none;
                img {
                  width: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .chat_footer_wrapper {
    .chat_footer_inner_wrapper {
      .media_showing_wrapper {
        .media_wrapper {
          img {
          }
        }
        .media_action_btn_wrapper {
          .btn_wrapper {
            button {
              img {
              }
            }
          }
        }
      }
      .chat_sending_actions_wrapper {
        .left_side_content_wrapper {
          .image_upload_wrapper {
            .custom_file_upload {
              #upload_image {
              }
              img {
              }
            }
          }
        }
        .right_side_content_wrapper {
          .form_wrapper {
            form {
              .input_wrapper {
                flex: 0 0 80%;
                input[type="text"] {
                }
                ::placeholder {
                }
              }
              .btn_wrapper {
                button {
                  img {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
