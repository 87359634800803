// .studentReportContainer {
//   //   width: 70%;
//   display: flex;
//   margin-left: 180px;
//   overflow: none;
//   //   max-width: 1100px
//   padding: 0px 200px 0px 0px;
//   .section {
//     margin-left: 100px;
//   }
// }

.studentReportContainer {
  //   width: 70%;
  display: flex;
  margin-left: 180px;
  overflow: none;
  //   max-width: 1100px
  padding: 0px 200px 0px 0px;
  .section {
    margin-left: 100px;
  }
}
