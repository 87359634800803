.contactUsContentComponent_wrapper {
  padding-top: 40px;
  .contactUsContentComponent_inner_wrapper {
    max-width: 560px;
    margin: 0 auto;
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .image_box{
          text-align: center;
          margin-bottom: 20px;
          padding-top: 15px;
        }
        .heading_wrapper {
          .heading_text_content {
            margin-bottom: 20px;
            font-size: 26px;
            font-weight: 600;
            letter-spacing: 0.33px;
            color: #000;
            text-align: center;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 10px 0;
      background-color: #ffffff;
      border-radius: 10px;
      .sec_2_inner_wrapper {
        form {
          .input_wrapper {
            margin: 10px 0;
            input {
              width: 100%;
              height: 45px;
              border-radius: 6px;
              border: none;
              background: #F5F4F8;
              padding: 0px 10px;
            }
          }
          .description_wrapper {
            margin: 10px 0;
            textarea {
              width: 100%;
              border-radius: 10px;
              border: none;
              background: #F5F4F8;
              padding: 10px 10px;
            }
          }
          .btn_wrapper {
            margin: 20px 0;
            text-align: center;
            button {
              border: none;
              outline: none;
              width: 100%;
              height: 45px;
              border-radius: 8px;
              background-color: #6D48EF;
              font-size: 16px;
              font-weight: 600;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
