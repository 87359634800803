.test_questions_solution_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;

          .left_side_content_wrapper {
            width: 32%;
            background-color: #ffffff;
            padding: 20px;

            .sub_section_1_wrapper {
              flex: 0 0 100%;

              .text_content_wrapper {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text_content {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #0f0404;
                }

                .countdown_timer_container {
                  span {
                    label {
                      color: #6d48ef;
                      font-size: 15px;
                    }
                  }
                }
              }

              .questions_serial_number_collection {
                overflow-y: scroll;
                height: calc(100vh - 128px);
                .single_question_number_wrapper {
                  margin: 10px 5px;

                  .arrow {
                    width: 8px;
                  }

                  .unanswered_lbl {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    padding: 15px;
                    border-radius: 8px;
                    background-color: #fff;
                    border: 1px solid #e2e2e2;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    background: #f5f4f8;
                    justify-content: space-between;
                    width: 100%;

                    .questionBox {
                      display: flex;
                      align-items: baseline;
                      margin-right: 5px;
                      overflow-x: hidden;

                      p {
                        margin-left: 2px;
                        margin-bottom: 0px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* number of lines to show */
                        -webkit-box-orient: vertical;

                        math {
                          display: inline-block;
                        }

                        img {
                          display: none;
                        }

                        table {
                          width: 100% !important;
                        }
                      }
                    }
                  }

                  .answered_lbl {
                    border: 1px solid #e2e2e2;
                    background-color: #fff;
                  }

                  .review_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    padding: 10px;
                    border-radius: 8px;
                    background: #fed5d5;
                    border: 1px solid #ffc4c4;
                    cursor: pointer;
                  }

                  .skip_lbl {
                    border: 1px solid #ffc4c4;
                    background-color: #fed5d5;
                  }

                  .current_lbl {
                    border: 1px solid #ff933a;
                    cursor: pointer;
                    background: inherit;
                  }
                }
              }
            }

            .sub_section_2_wrapper {
              flex: 0 0 100%;
              margin-bottom: 20px;

              .question_marking_wrapper {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;

                .single_marking {
                  margin-right: 15px;

                  &:last-child {
                    margin-right: 0px;
                  }

                  .box_pic {
                    width: 14px;
                    height: 14px;
                    border-radius: 3px;
                    margin-right: 8px;
                  }

                  .box_pick_background_1 {
                    background-color: #48ef4c;
                    border: 1px solid #ebebeb;
                  }

                  .box_pick_background_2 {
                    background-color: #f99746;
                  }

                  .box_pick_background_3 {
                    background-color: #f5f4f8;
                    border: 1px solid #e2e2e2;
                  }

                  .box_pick_background_4 {
                    background: #fed5d5;
                    border: 1px solid #ffc4c4;
                  }

                  .question_marking_text {
                    font-size: 12px;
                    font-weight: 500;
                    color: #000;
                    vertical-align: middle;
                    margin-top: -4px;
                  }
                }
              }
            }
          }

          .right_side_content_wrapper {
            width: 68%;
            border-radius: 8px;
            padding: 10px;
            background-color: #f5f4f8;

            .sub_section_wrapper {
              max-width: 750px;
              margin: 0 auto;
              max-height: 100vh;
              overflow-y: auto;
              scroll-behavior: smooth;

              .sub_sub_section_wrapper_1 {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .right_side_content_wrapper {
                  flex: 0 0 100%;
                  display: flex;
                  justify-content: flex-end;

                  .review_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }

                  .bookmark_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }
                }
              }

              .testProgressIndicator {
                background: #fff;
                padding: 15px;
                border-radius: 8px;
                margin-bottom: 40px;

                h4 {
                  font-size: 14px;
                  color: #000;
                  margin-bottom: 16px;
                }

                .ProgressBar_Line {
                  position: relative;
                }

                .ProgressBar_Line span {
                  position: absolute;
                  top: -16px;
                  font-size: 14px;
                }

                .progress {
                  height: 8px;
                  margin-bottom: 5px;

                  .progress-bar {
                    background: #ff933a;
                    border-radius: 9px;
                  }
                }

                .questionPercentage {
                  display: flex;
                  justify-content: space-between;

                  span {
                    color: #707070;
                    font-size: 14px;
                  }
                }
              }

              .sub_sub_section_wrapper_2 {
                h2 {
                  text-align: center;
                  font-size: 25px;
                  font-weight: bold;
                  margin-bottom: 20px;
                }

                .text_content_wrapper {
                  .typeLanguageWrapper {
                    display: flex;
                    justify-content: space-between;

                    span {
                      margin-bottom: 10px;
                    }
                  }

                  .questionBox {
                    display: flex;
                    align-items: baseline;
                    margin-bottom: 20px;

                    span {
                      margin-right: 5px !important;
                    }

                    .text_content {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 500;
                      color: #000;

                      p {
                        margin-bottom: 0px;
                      }
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_3 {
                //single-select
                .single_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;
                  margin-bottom: 30px;

                  .single_select {
                    width: calc(50% - 8px);
                    margin: 10px 16px 10px 0px;

                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }

                    .classCorrect {
                      border: 1px solid #5ddd9b;
                      background: #e1f1ec !important;
                    }

                    .classWrong {
                      border: 1px solid #f93030;
                      background: #f6e0e4 !important;
                    }

                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }

                    .single_option_wrapper {
                      min-height: 50px;
                      background-color: #fff;
                      border-radius: 8px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;

                      .option_initial {
                        margin-right: 10px;

                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #acacac;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #000;

                          p {
                            margin-bottom: 0px;
                          }
                        }
                      }
                    }

                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;

                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }

                //multi-select
                .multi_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;

                  .single_select {
                    width: calc(50% - 8px);
                    margin: 10px 16px 10px 0px;

                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }

                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }

                    .single_option_wrapper {
                      min-height: 50px;
                      border: solid 1px #f0eef8;
                      background-color: #fafafd;
                      border-radius: 15px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;

                      .option_initial {
                        margin-right: 10px;

                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }
                    }

                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;

                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }

                //integer
                .input_wrapper {
                  .input_inner_wrapper {
                    margin-bottom: 30px;

                    input[type="number"] {
                      border: none;
                      outline: none;
                      width: 100%;
                      border: solid 1px #f0eef8;
                      background-color: #fff;
                      height: 50px;
                      border-radius: 8px;
                      padding: 0 5px;
                      font-size: 16px;
                      font-weight: 500;
                    }

                    input[type="number"]:focus {
                      border: 2px solid #6d48ef;
                    }

                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_4 {
                .checkbox_wrapper {
                  input[type="checkbox"] {
                    margin-right: 10px;
                  }

                  label {
                    font-size: 13px;
                    vertical-align: text-top;
                    color: #09041a;
                  }
                }
              }

              .sub_sub_section_wrapper_5 {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left_side_wrapper {
                  width: calc(50% - 8px);
                  margin-right: 16px;

                  .btn_wrapper {
                    text-align: left;

                    button {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #6d48ef;
                      padding: 10px;
                      width: 100%;
                      border: 1px solid #e2e2e2;
                      // text-decoration: underline;
                    }
                  }
                }

                .right_side_wrapper {
                  width: calc(50% - 8px);

                  .btn_wrapper {
                    text-align: right;

                    button,
                    a {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #fff;
                      padding: 10px;
                      width: 100%;
                      background: #6d48ef;
                      display: inline-block;
                      text-decoration: none;
                    }
                  }
                }
              }

              .QuestionSolution {
                padding-top: 20px;

                h5 {
                }

                div {
                  p {
                    margin-bottom: 2px;

                    math {
                      width: 100%;
                      word-break: break-word;
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_6 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.onSubmitModal {
  text-align: center;

  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    .submit_Popup {
      img {
        width: 50px;
        margin-bottom: 20px;
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #6d48ef;
          border-radius: 6px;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #6d48ef;
        }

        .cancel {
          color: #6d48ef;
          background: none;
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .test_questions_solution_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            .left_side_content_wrapper {
              .sub_section_1_wrapper {
                .questions_serial_number_collection {
                  .single_question_number_wrapper {
                    .unanswered_lbl {
                      font-size: 12px;
                      padding: 10px;
                    }
                  }
                }
              }
            }

            .right_side_content_wrapper {
              .sub_section_wrapper {
                .sub_sub_section_wrapper_2 {
                  h2 {
                    font-size: 22px;
                  }

                  .text_content_wrapper {
                    .questionBox {
                      .text_content {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .test_questions_solution_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            flex-direction: column;

            .left_side_content_wrapper {
              width: 100%;

              .sub_section_1_wrapper {
                .questions_serial_number_collection {
                  overflow-x: scroll;
                  display: flex;
                  height: auto;
                  overflow-y: auto;

                  .single_question_number_wrapper {
                    flex: 0 0 280px;

                    .unanswered_lbl {
                      .questionBox {
                        height: 40px;
                      }
                    }
                  }
                }

                .text_content_wrapper {
                  margin-bottom: 10px;
                }
              }

              .sub_section_2_wrapper {
                margin-bottom: 10px;
              }
            }

            .right_side_content_wrapper {
              width: 100%;

              .sub_section_wrapper {
                .sub_sub_section_wrapper_2 {
                  h2 {
                  }

                  .text_content_wrapper {
                    .questionBox {
                      .text_content {
                        line-height: 18px;
                      }
                    }
                  }
                }

                .sub_sub_section_wrapper_3 {
                  .single_select_option_collection_wrapper {
                    margin-bottom: 15px;

                    .single_select {
                      width: 100%;
                      margin: 0px 0px 10px 0px;
                    }
                  }
                }

                .testProgressIndicator {
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.right_side_content_wrapper .sub_section_wrapper::-webkit-scrollbar {
  width: 0px;
}
