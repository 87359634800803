.main-wrapper {
  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between !important;

    .left-panel {
      width: 32%;
      background-color: #ffffff;
      padding: 10px 7px 100px 20px;
      position: fixed; /* Keep the left panel fixed */
      top: 0;
      bottom: 0;
      left: 0;
      border-right: 1px solid #ebebeb;
    }

    .right-panel {
      width: 68%;
      position: fixed; /* Keep the left panel fixed */
      top: 0;
      bottom: 0;
      right: 0;
      background: #f5f4f8;
    }
  }

  .btn-wrapper {
    button {
      border: none;
      outline: none;
      width: 150px;
      height: 40px;
      border-radius: 25px;
      background-color: #6d48ef;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
    }
  }
}
