.testSummary {
  .section-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #373737;
  }
  .table_section {
    border: 1px solid #c2c2c2;
    border-radius: 6px;
    table {
      .table_heading {
        text-align: center;

        tr {
          background: #a98b72;

          .firstTd {
            text-align: start !important;
            border-top-left-radius: 6px;
            width: 33.5%;
          }
          td {
            padding: 10px 25px 10px 25px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #fff2e7;
          }
        }
        :last-child {
          border-top-right-radius: 6px;
        }
      }

      tbody {
        tr {
          border-bottom: 1px dashed #dec2ab;
          background: #fffcfa;
          .num {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 27px;

            color: #000000;
          }
          .firstTd {
            text-align: start !important;
          }
          td {
            text-align: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            color: #373737;

            padding: 10px 25px 5px 25px;
          }
        }
        :last-child {
          border-bottom: none;
          background: none;
        }
      }
    }
  }
}
