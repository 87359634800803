.form_Wrapper {
  padding-top: 101px;
  background-color: #f5f4f8;

  .form_InrWrapper {
    padding: 30px 0px;

    .form_contentWpr {
      background-color: #fff;
      border-radius: 10px;
      .form_header {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        .form_heading {
          margin-right: 430px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          color: #121212;
        }
      }

      .form {
        margin: auto;
        width: 40%;
        padding: 15px;
        .qb_label {
          margin-bottom: 6px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          // color: #393939;
          color: #121212;
        }
        .qb_test_name input {
          width: 576px;
          height: 46px;
          background: #f5f4f8;
          border: 1px solid #eae3ff;
          border-radius: 4px;
        }
        .qb_subjects {
          margin-top: 20px;
          .qb_subjects_list {
            display: flex;
            justify-content: space-between;
            width: 576px;
            margin-bottom: 15px;
            .single_select_subject {
              margin-right: 5px;
              width: 250px;
              background: #f5f4f8;
              border: 1px solid #eae3ff;
              border-radius: 4px;
              border: none;

              &:last-child {
                margin-left: 30px;
              }
              &:nth-child(2) {
                margin-left: 30px;
              }

              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 6px 20px;
                border-radius: 8px;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }

              input[type="radio"]:checked + label {
                border: none;
                background: #f5f4f8;
                color: #6d48ef;
                background-image: url(../../../../utilities/images//user_activity/user_activity_35.png);
                background-repeat: no-repeat;
                background-position: right;
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
              }
            }
          }
        }

        .qb_topics {
          margin-bottom: 10px;
          margin-top: 20px;
          .topic_para {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #808080;
            margin-top: -5px;
          }
          .topic_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #f5f4f8;
            border: 1px solid #eae3ff;
            border-radius: 4px;
            width: 576px;
            padding: 10px;
            margin-top: -10px;
            cursor: pointer;
            img {
              width: 15px;
              height: 10px;
            }
          }
        }

        .selected_topics {
          width: 576px;

          display: flex;
          .topics {
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;
          }
          .selected_topic {
            margin-right: 10px;
            margin-top: 1px;

            background: #fff8f3;
            // background: green;
            border: 1px solid #f46c00;
            border-radius: 16px;
            padding: 4px 12px;
          }

          .qb_length {
            margin-top: 5px;
            cursor: pointer;
            // background-color: red;
            float: right;

            .topic_content {
              font-weight: 500;
            }
          }

          .modal {
            .modal-header {
              display: flex;
              .modal-header-left {
                margin: auto;

                .modal-title p {
                  font-size: 20px;
                  line-height: 30px;
                  color: #000000;
                  background: red;
                }
              }
              .modal-header-right {
                // margin-top: -25px;
              }
            }

            .modal-body {
              .qb_topic {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 27px;

                padding: 10px;

                color: #757575;
                border-bottom: 1px solid #757575;
              }
            }

            .modal-footer {
              .done_btn {
                background: #6d48ef;
                box-shadow: 0px 4px 8px rgba(109, 72, 239, 0.16);
                border-radius: 10px;

                margin: auto;
                width: 40%;
                color: #ffffff;
                padding: 10px 10px;
              }
            }
          }
        }

        .modal {
          .modal-header {
            display: flex;
            justify-content: space-between;
            .modal-header-left {
              margin-bottom: -30px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;

              .modal-title {
                font-size: 20px;
                line-height: 30px;
                color: #000000;
              }
              .modal-title-sub {
                font-size: 16px;
                line-height: 24px;
                color: #6d48ef;
              }
            }
            .modal-header-right {
              margin-top: -25px;
            }
          }
          .single_btn_wrapper {
            cursor: pointer;
            display: flex;
            justify-content: center;
            margin-top: -20px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;

            input {
              margin-right: 5px;
            }
            input[type="checkbox"] {
              opacity: 0;
              position: fixed;
              width: 0;
            }
            label {
              color: hsla(253, 89%, 59%, 0.825);
              border: 1px solid #6d48ef;
              padding: 6px 15px;
              border-radius: 10px;
            }
            input[type="checkbox"]:checked + label {
              background-color: #6d48ef;
              border: none;
              color: #ffffff;
              border: 1px solid #eae3ff;

              border-radius: 10px;
            }
          }
          .single_topic_wrapper {
            border-bottom: 1px solid #757575;
            input[type="checkbox"] {
              opacity: 0;
              position: fixed;
              width: 0;
            }
            .topic_label {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 27px;
              padding: 10px 370px 10px 10px;
              cursor: pointer;

              color: #757575;
            }

            input[type="checkbox"]:checked + label {
              border-radius: 4px;
              color: #000000;
              background-image: url(../../../../utilities/images//user_activity/user_activity_35.png);
              background-repeat: no-repeat;
              background-position: right;
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 100%;
            }
          }
          .modal-footer {
            .done_btn {
              background: #6d48ef;
              box-shadow: 0px 4px 8px rgba(109, 72, 239, 0.16);
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40%;
              margin-right: 200px;
              color: #ffffff;
              padding: 10px 10px;
            }
          }
        }

        .qb_difficulty_level {
          width: 100%;
          margin-top: 20px;
          .difficulty_level_para {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #808080;
            margin-top: -5px;
          }
          .qb_difficulty_level_list {
            display: flex;
            justify-content: space-between;
            width: 576px;
            margin-bottom: 15px;

            .multi_select_diffculty_level {
              margin-right: 5px;
              width: 250px;
              background: #f5f4f8;
              border: 1px solid #eae3ff;
              border-radius: 4px;
              border: none;

              &:last-child {
                margin-left: 30px;
              }
              &:nth-child(2) {
                margin-left: 30px;
              }

              input[type="checkbox"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 6px 20px;
                border-radius: 8px;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }

              input[type="checkbox"]:checked + label {
                border: none;
                background: #f5f4f8;
                color: #6d48ef;
                background-image: url(../../../../utilities/images//user_activity/user_activity_35.png);
                background-repeat: no-repeat;
                background-position: right;
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
              }
            }
          }
        }

        .qb_no_of_qtsns {
          .select_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 576px;
            .single_select_subject {
              background-color: #fafafd;
              border: solid 1px #f0eef8;
              width: 170px;
              margin-bottom: 15px;
              cursor: pointer;
              border-radius: 4px;
              padding: 0px 10px;
              text-align: center;

              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                padding: 6px 25px;
                border-radius: 8px;
                font-size: 15px;
                font-weight: 500;
                color: #6b6876;
                cursor: pointer;
                width: 90%;
                span {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #121212;
                }
              }

              input[type="radio"]:checked + label {
                border: none;
                color: #6d48ef;
                background-image: url(../../../../utilities/images//user_activity/user_activity_35.png);
                background-repeat: no-repeat;
                background-position: right;
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 100%;

                span {
                  color: #6d48ef;
                }
              }
            }
          }
        }

        .qb_create_new_paper_button {
          text-align: center;
          margin: 10px 0px;
          width: 576px;

          button {
            background: #6d48ef;
            box-shadow: 0px 4px 8px rgba(109, 72, 239, 0.16);
            border-radius: 4px;
            padding: 7px 55px;
            color: #ffffff;
            width: 65%;
            margin: auto;
          }
        }

        .modal-content {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 130%;
          pointer-events: auto;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 0.3rem;
          outline: 0;
        }

        .modal-footer {
          text-align: center;
        }
      }
    }
  }
}

.qb_onSubmitModal {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    .qb_submit_Popup {
      .exit_img {
        width: 100%;

        img {
          width: 35%;
          height: 130px;
          margin-bottom: 20px;
          margin-top: -25px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #6d48ef;
          border-radius: 6px;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #6d48ef;
        }

        .cancel {
          color: #6d48ef;
          background: none;
          margin-right: 10px;
        }
      }
    }
  }
}
