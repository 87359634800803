.modal-dialog {
  width: 100%;
  .modal-content {
    // border-radius: 12px !important;
    .modal-header {
      .modal-title {
        width: 100%;
        // padding: -10px;
        .section_heading {
          text-align: center;
          padding: 0px !important;
        }
      }
    }

    .para1 {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.01em;
      text-align: center;
      color: #000000;
    }

    .para2 {
      padding: 0px 60px !important;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.01em;
      text-align: center;
      color: #727272;
      span {
        color: #2d2d2d;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.01em;
        text-align: center;
      }
    }

    .getNowBtn {
      margin-bottom: 50px;
      button {
        background: #6d48ef;
        border-radius: 8px;
        padding: 10px 125px;
        color: #ffffff;
      }
    }
  }
}

.alert_Modal {
  background: #ffff;
  text-align: center;
  width: 600px !important;
  border-radius: 5px !important;
  margin-left: -40px;
}
