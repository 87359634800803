.questions-panel {
  flex: 0 0 100%;
  .questions-panel-header {
    // margin: 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-text {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0em;
      color: #000000;
    }
    button {
      background-color: #6d48ef;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      color: #ffffff;
      padding: 8px 12px 8px 12px;
      border-radius: 4px;
      margin-right: 12px;
    }
  }

  .questions-panel-markings {
    flex: 0 0 100%;
    margin: 10px 5px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .question-marking {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      .marking-icon {
        width: 14px;
        height: 14px;
        border-radius: 3px;
        margin-right: 8px;
      }

      .marking-icon-completed {
        background-color: #48ef4c;
        border: 1px solid #ebebeb;
      }

      .marking-icon-pending {
        background-color: #f5f4f8;
        border: 1px solid #e2e2e2;
      }

      .marking-icon-skipped {
        background: #fed5d5;
        border: 1px solid #ffc4c4;
      }

      .marking-icon-box_pick_background_4 {
        background-color: #f99746;
      }

      .marking-text {
        font-size: 12px;
        font-weight: 500;
        color: #000;
        vertical-align: middle;
        margin-top: -4px;
      }
    }
  }

  .questions-list {
    overflow-y: scroll;
    // height: calc(100vh - 8px);
    height: calc(84vh - 8px);
  }
}
