// .header_left_wrapper {
//   width: fit-content;
//   .logo {
//     width: 100px;
//     display: inline-block;
//     img {
//       width: 100%;
//       margin-bottom: 10px;
//     }
//   }
//   // .left_menu{
//   //     display: inline-block;
//   //     ul{
//   //         margin-bottom: 0px;
//   //         li{
//   //             list-style: none;
//   //             display: inline-block;
//   //             line-height: normal;
//   //             margin-right: 15px;
//   //             &:last-child{
//   //                 margin-right: 0px;
//   //             }
//   //             .menu_link{
//   //                 color: #000000;
//   //                 font-size: 15px;
//   //                 text-decoration: none;
//   //             span{}
//   //             }
//   //         }
//   //     }
//   // }

//   .left_menu {
//     display: inline-block;
//     // margin-left: 60px;

//     ul {
//       margin-bottom: 0px;
//       li {
//         list-style: none;
//         display: inline-block;
//         line-height: normal;
//         margin-right: 30px;
//         &:last-child {
//           margin-right: 0px;
//         }

//         .menu-link {
//           text-decoration: none;
//           font-family: "Poppins";
//           font-style: normal;
//           font-weight: 500;
//           font-size: 15px;

//           line-height: 18px;
//           color: #121212;
//         }

//         .current {
//           color: #6d48ef;
//           position: relative;

//           &:before {
//             content: "";
//             position: absolute;
//             width: 100%;
//             height: 2px;
//             background-color: #6d48ef;
//             bottom: -4px;
//             left: 0;
//           }
//         }
//       }
//     }
//   }
// }

.header_left_wrapper {
  width: fit-content;

  .logo {
    width: 100px;
    display: inline-block;
    // vertical-align: middle; /* Added vertical alignment */

    img {
      width: 100%;
      margin-bottom: 10px;
      //   padding: 10px 0px;
    }
  }

  .left_menu {
    display: inline-block;
    vertical-align: middle; /* Added vertical alignment */

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      margin-left: 20px !important;
      .dropdown {
        &:hover {
          .dropdown-menu {
            display: block;
            margin-top: 0;
          }
        }
        .menu-link-dropdown {
          text-decoration: none;
          font-family: "Poppins";
          font-weight: 500;
          font-size: 15px;
          color: #121212;
          &:active {
            background-color: transparent;
          }
          &.current-dropdown {
            color: #6d48ef;
          }
        }
      }
      li {
        display: inline-block;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        .menu-link {
          text-decoration: none;
          font-family: "Poppins";
          font-weight: 500;
          font-size: 15px;
          //   line-height: 18px;
          color: #121212;
          vertical-align: middle; /* Added vertical alignment */

          &.current {
            color: #6d48ef;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              background-color: #6d48ef;
              bottom: -4px;
              left: 0;
            }
          }
        }
      }
    }
  }
}

.mtse-button {
  margin-left: 45px;
  padding: 0.5rem 0.625rem;
  border-radius: 0.25rem;
  background: #b91311;
  margin-left: 1rem;

  a {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    list-style: none;
    text-decoration: none;
  }

  a:hover {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    list-style: none;
    text-decoration: none;
  }
}
