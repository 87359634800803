.adaptive-cps-container {
  width: 128%;
  // margin: 0 auto;
  margin-left: -145px;

  .adaptive-cps-subjects {
    width: calc(50% - 8px);
  }
  .adaptive-cps-subjects {
    width: calc(50% - 8px);
  }
  .adaptive-cps-subjects {
    width: calc(50% - 8px);
  }
  .adaptive-cps-banner-topics {
    width: calc(50% - 8px);
  }
}
