.canvasDoughnutChart_wrapper {
  position: relative;

  .my_rank {
    position: absolute;
    left: 45%;
    top: 30%;
    margin-bottom: 20px;

    div {
      color: #b20913;
      margin-left: 10px;
      margin-top: 10px;
      font-family: Poppins;
      font-style: semiBold;
      font-size: 42px;
      line-height: 72px;
      line-height: 100%;
    }
  }

  .marksIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px 10px 0px;

    .mrk_indc {
      margin-right: 20px;
      font-size: 12px;

      span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
      }

      .you {
        background-color: #ff933a;
      }

      .topper {
        background-color: #668de1;
      }
    }
  }
}
