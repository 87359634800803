.bitSatTestheading {
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #121212;
  margin-bottom: 20px;
}

.bitSat_WiseWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 40px;
  cursor: pointer;
  .bitSat_box {
    margin-right: 30px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(50% - 8px);
    margin: 0px 0px -5px 0px;
    cursor: pointer;
    &:nth-child(2n + 2) {
      margin-right: 0px;
    }
    .mock_test_content {
      background: #f5f4f8;
      border: 1px solid #e9e1ff;
      border-radius: 4px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 14px;
      .img_wrpr img {
        width: 55px;
        border-radius: 2px;
      }
      .content {
        margin-right: 100px;
        margin-left: 10px;
        .mock_test_heading {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          margin-top: -5px;
        }
        .total_questions_time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .total_question {
            margin-right: 15px;
            display: flex;
            align-items: center;
          }
          span {
            margin-right: 5px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #737373;
          }
          .total_time {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .bitsat_no_data_found_wrapper {
    margin: auto;
    margin-top: 50px;
    p {
      text-align: center;
    }
  }
}
@media (max-width: 575.98px) {
  .bitSat_WiseWrapper{
    margin-left: 0;
    .bitSat_box{
      width: 100%;
      justify-content: center;
      .mock_test_content {
        .content{
          margin-left: 0;
          margin-right: 0;
        }
      }
      .img_wrpr{
        display: none;
      }
    }
  }
}
