.our_course_wrapper {
  padding: 40px 0px;

  .course_title {
    text-align: center;
    width: 700px;
    margin: 0 auto;
    padding-bottom: 20px;

    h4 {
      letter-spacing: 0.6px;
      color: #000000;
      font-size: 22px;
      margin-bottom: 15px;
    }

    p {
      letter-spacing: 0.36px;
      color: #707070;
      opacity: 0.8;
      font-size: 14px;
    }
  }

  .freePaid_courseTab {
    position: relative;

    .freePaidTab {
      display: flex;
      background: #f5f4f8;
      width: max-content;
      padding: 5px 6px;
      border-radius: 24px;
      overflow: hidden;
      margin: 0 auto;
      margin-bottom: 40px;

      .single_select_subject {
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }

        label {
          padding: 7px 20px;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #6b6876;
          cursor: pointer;
          min-width: 150px;
          text-align: center;
          border-radius: 19px;
        }

        input[type="radio"]:checked + label {
          background-color: #ff933a;
          border: none;
          color: #ffffff;
        }
      }
    }

    .seeAll {
      position: absolute;
      top: 50%;
      transform: translatey(-50%);
      right: 0px;
      letter-spacing: 0.36px;
      color: #ff933a;
      text-decoration: none;
      font-size: 15px;
      cursor: pointer;

      img {
        width: 8px;
        vertical-align: middle;
      }
    }
  }

  .course_box_wrapper {
    .slick-arrow {
      box-shadow: 0px 0px 30px #0000001a;
      width: 30px;
      height: 30px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAXCAYAAADQpsWBAAAABHNCSVQICAgIfAhkiAAAAOhJREFUOE+N1L8KwjAQBnC76HuJiGAHQcXV2SkdnFz7AJY+gKvg4KM4C7pJB58hafwOYumf5HJZQsn9SMh3aTISjizLDtba8RkjkRil1Al1uatdR1EPVNhtyqI+MMakZVk+gigE6IhexAEvioEBkoAOkoIGAVzwsXc5VP9bCmWYAFyxuHMFX4AFXSsXOqE7ClZUhOA+mGZFUTxZRIuAN0wbB9+YlxxscgKk3qIeox1Z2Am3DWFfwKlvx0FHSGCojZqj+nbkGjYIY0/DCyWPsAO11vMocjm24VaECOLHcqzreoIIcjFqt9UPHiuwN42MBW0AAAAASUVORK5CYII=);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #fff;
      border-radius: 50%;
      z-index: 1;
      padding: 5px;
      background-size: 9px;
      top: 46%;
      position: absolute;
    }

    .slick-prev:before,
    .slick-next:before {
      content: "";
    }

    .slick-prev {
      left: -14px;
    }

    .slick-next {
      right: -16px;
      transform: rotate(180deg) translateY(50%);
      top: 46%;
    }

    .course_bx_wrap {
      padding: 6px;
      width: 25%;

      .course_box {
        // height: 475px;
        box-shadow: 0px 0px 20px #0000000d;
        // background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        // background: red;
        display: flex;
        flex-direction: column;
        min-height: 485px;

        .course_img {
          position: relative;
          margin-bottom:auto;

          img {
            width: 100%;
            height: 250px;
            // object-fit: cover;
          }

          // .course_price {
          //   position: absolute;
          //   background: #FF1C1C;
          //   color: #fff;
          //   font-size: 14px;
          //   box-shadow: 0px -3px 10px #00000033;
          //   bottom: 0px;
          //   right: 0px;
          //   padding: 2px 7px;
          //   line-height: 18px;

          //   span {
          //     background: red;
          //     margin-right: 10px;
          //   }
          // }
        }

        .course_detail {
          padding: 10px;

          h4 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 7px;
          }

          h6 {
            letter-spacing: 0.24px;
            color: #000000;
            font-size: 12px;
            font-weight: 500;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 28px;
          }

          .course_price {
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            margin-top: -10px;
            margin-bottom: 10px;
            s{
              margin-right: 10px;
            }
            // span {
            //   margin-right: 10px;
            // }
          }

          p {
            font-size: 13px;
            color: #929292;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            min-height: 39px;
          }
        }

        .enroll_btn {
          padding: 0px 10px 20px;

          .button {
            width: 100%;
            border-radius: 4px;
            background: #17bc66;
            color: #fff;
            padding: 5px 0px;
            display: block;
            text-decoration: none;
            text-align: center;

            &:focus {
              outline: none;
              border: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .our_course_wrapper {
    .course_title {
      width: auto;
    }

    .freePaid_courseTab {
      .freePaidTab {
        margin-bottom: 50px;

        .single_select_subject {
          label {
            padding: 7px 9px;
            font-size: 12px;
            width: max-content;
          }
        }
      }

      .seeAll {
        top: 135%;
        right: 15px;
        font-size: 14px;
      }
    }
  }
}