.onlineTransactionWrapper {
  margin: 75px 0;

  .online_transactions_history_collection_wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    // padding: 0 5px;

    .single_transaction_wrapper {
      background: #ffffff;
      border: 0.6px solid #cecece;
      border-radius: 8px;
      padding: 15px;
      margin: 10px 0;

      .section_wrapper_1 {
        background-color: #ffefe6;
        background: #ffefe6;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left_side_content_wrapper {
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 16px;
              font-weight: 500;
              color: #0f0404;
            }
          }
        }

        .right_side_content_wrapper {
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 16px;
              font-weight: 500;
              color: #0f0404;
            }
          }
        }
      }

      .section_wrapper_2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;
        padding: 10px;

        .text_content_wrapper {
          .text_content_1 {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: #6f6868;
            margin-bottom: 5px !important;
          }

          .text_content_2 {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            color: #0f0404;
          }
        }
      }
    }
  }
}
