.bank_details_wrapper {
  .modal-content {
    width: 28.75rem;
    .modal-header {
      border: none;
    }

    .modal-body {
      padding: 1.5rem 1.88rem 2.5rem 1.88rem;
    }

    h3 {
      color: #000;
      font-family: Poppins;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .inputs_wrapper {
      .input_container {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        label {
          color: #3d3d3d;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0.25rem;

          span {
            color: #b50503;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 0.2rem;
          }
        }
        input {
          outline: none;
          appearance: none;
          border-radius: 0.375rem;
          background: #f2f2f3;
          border: none;
          height: 2.75rem;
          color: #121212;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-left: 1rem;

          ::placeholder {
            color: #9e9e9e;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .error_message {
          font-size: 0.675rem;
          color: red;
        }
      }
    }

    .text {
      margin: 1.25rem 0 2.5rem 0;
      color: #000;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        color: #b50503;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .save_btnn {
      width: 100%;

      border-radius: 0.25rem;
      border: 0.6px solid #b91311;
      background: #b50503;
      padding: 0.75rem 0;
      cursor: pointer;
      text-align: center;

      button {
        color: white;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
