.mobile_right_sideBar_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease;
  z-index: 999;
  width: 0;

  .mobile_right_sideBar_inner_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background: #ffffff;
    transition: all 0.3s ease;
    z-index: 999;
    backface-visibility: hidden;
    margin-right: -450px;
    overflow: scroll;
    padding: 10px;
    width: 250px;

    .profile_option_wrapper {
      margin: 5px;

      .profile_option_inner_wrapper {
        .content_wrapper_1 {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #7070701f;

          .image_container {
            margin: 5px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #ffffff;
            text-align: center;
            line-height: 50px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }

          .text_content_wrapper_1 {
            margin: 5px;

            .text_content_1 {
              margin: 0;
              color: #152257;
              font-size: 18px;
              font-weight: 600;
            }

            .text_content_2 {
              margin: 0;
              color: #8188a5;
              font-size: 14px;
              opacity: 0.7;
            }

            .text_content_3 {
              margin: 0;
              color: #000000;
              font-weight: 600;
              font-size: 14px;

              img {
                width: 15px;
                margin-left: 2.5px;
              }
            }
          }

          .text_content_wrapper_2 {
            margin: 5px;
            border: 1px solid #d8e5eb;
            border-radius: 10px;
            padding: 5px;
            cursor: pointer;

            .text_content_1 {
              margin: 0;
              color: #152257;
              font-size: 14px;
              font-weight: 600;
            }

            &:hover {
              box-shadow: 0px 0px 8px #1046752b;
            }
          }
        }

        .content_wrapper_2 {
          margin: 10px;
          border-bottom: 1px solid #7070701f;

          .text_content_wrapper_1 {
            margin: 10px 0;

            a {
              text-decoration: none;
              display: block;
              padding: 5px 0px;

              .text_content_1 {
                margin: 0;
                color: #152257;
                font-weight: 600;

                img {
                  width: 20px;
                  margin-right: 5px;
                }

                .ntf {
                  width: 25px;
                }
              }
            }

            p {
              display: block;
              padding: 5px 0px;
              color: #152257;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}