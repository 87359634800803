.liveWebChatStreamFeatureContentComponent_wrapper {
  padding-top: 80px;
  .liveWebChatStreamFeatureContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      .sec_2_inner_wrapper {
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .liveWebChatStreamFeatureContentComponent_wrapper {
    padding-top: 60px;
    .liveWebChatStreamFeatureContentComponent_inner_wrapper {
      padding: 5px;
      .sec_1_wrapper {
        margin: 5px 0;
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
        }
      }
    }
  }
}
