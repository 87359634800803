.testSummaryPageWrapper {
  .testSummaryPageInrWrapper {
    margin: 40px 50px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;

    .test_summaryBox {
      .backBtn {
        display: inline-block;
        cursor: pointer;
      }

      .rankMarksBox {
        padding: 0px 20px;

        h3 {
          font-size: 25px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 25px;
        }

        .rankMarksDiv {
          .rnkMrkBox {
            text-align: center;
            display: flex;
            justify-content: center;
            margin-bottom: 40px;

            .rankIcon {
              background: #f5f4f8;
              text-align: center;
              width: 220px;
              height: 220px;
              margin: 0px 10px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              img {
                margin-bottom: 10px;
                width: 55px;
              }

              h5 {
                margin-bottom: 10px;
                letter-spacing: 0.68px;
                font-size: 28px;
                font-weight: 600;
              }

              span {
                line-height: 18px;
                color: #707070;
                opacity: 0.8;
                font-size: 18px;
              }
            }
          }
        }


        .marksChart {
          width: calc(50% - 15px);
          background: #F5F4F8;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 30px;

          .marksTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;

            h6 {
              margin-bottom: 0px;
            }

            .marksIndicator {
              display: flex;

              .mrk_indc {
                margin-right: 10px;
                font-size: 12px;

                span {
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  margin-right: 5px;
                  display: inline-block;
                }

                .cutOff {
                  background-color: #668DE1;
                }

                .you {
                  background-color: #31D680;
                }

                .topper {
                  background-color: #FF933A;
                }
              }
            }
          }

          .canvasjs-chart-credit,
          .canvasjs-chart-toolbar {
            display: none;
          }
        }

        .scorePercentageMarksWrpr {
          display: flex;
          margin-bottom: 30px;

          .scorePercentageBox {
            width: calc(50% - 8px);
            margin-right: 16px;
            display: flex;
            align-items: center;
            border: 1px solid #eeeeee;
            border-radius: 8px;
            background: #f5f4f8;
            margin-right: 16px;

            .scoreChart {
              width: 150px;
              margin: 40px 20px;

              .CircularProgressbar .CircularProgressbar-path {
                stroke: #FCB21D;
              }
            }

            .scoreDetail {
              p {
                font-size: 14px;
                margin-bottom: 10px;
              }

              .percentageDiv {
                font-size: 12px;
                margin-bottom: 5px;

                span {
                  background-color: #E2E2E2;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  display: inline-block;
                  margin-right: 8px;
                }
              }

              .gotPrcntg {
                span {
                  background-color: #FCB21D;
                }
              }
            }
          }

       
        }

        .testTypeOption {
          display: flex;
          flex-wrap: wrap;

          .testResultOption {
            border: 1px solid #EEEEEE;
            border-radius: 6px;
            background: #F5F4F8;
            padding: 13px;
            width: calc(50% - 8px);
            margin: 0px 16px 16px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &:nth-child(2n+2) {
              margin-right: 0px;
            }

            span {}

            .arrow {}
          }
        }
      }

    }
  }
}

@media (max-width: 1199.98px) {
  .testSummaryPageWrapper {
    .testSummaryPageInrWrapper {
      margin: 0px 0px;
    }
  }
}

@media (max-width: 991.98px) {}

@media (max-width: 575.98px) {
  .testSummaryPageWrapper {
    .testSummaryPageInrWrapper {
      padding: 10px;

      .test_summaryBox {
        .rankMarksBox {
          padding: 0px 0px;

          .testTypeOption {
            .testResultOption {
              width: 100%;
              margin: 0px 0px 15px 0px;
            }
          }

          .rankMarksDiv {
            .rnkMrkBox {
              margin-bottom: 10px;
              .rankIcon {
                height: auto;
                padding: 15px 0px;
                border-radius: 10px;
              }
            }
          }

          .marksChart {
            width: 100%;
          }

          .scorePercentageMarksWrpr {
            flex-direction: column;

            .scorePercentageBox {
              width: 100%;
              margin: 0px 0px 15px 0px;
            }

           
          }
        }
      }
    }
  }
}
