.questionClassWise {
  margin-bottom: 30px;
  .section-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #373737;
  }

  .table_section {
    border: 1px solid #c2c2c2;
    border-radius: 6px;

    table {
      width: 100%;

      .table_heading {
        text-align: center;

        tr {
          background: #647297;

          th {
            padding: 10px 25px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #fff2e7;

            .sub_subjects {
              display: flex;
              justify-content: space-around;
              align-items: center;
              span {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
              }
            }
          }

          .chem {
            text-align: center;
            border-top-right-radius: 6px;
          }

          .line {
            border: 1px solid #dddddd;
            // width: 93%;
            letter-spacing: 10px;
            margin-top: -2px;
            margin-left: 7px;
          }

          .firstTd {
            text-align: left !important;
            border-top-left-radius: 6px;
            width: 33.5%;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px dashed #dec2ab;
          background: #fffcfa;

          td {
            text-align: center;
            padding: 10px 25px 5px 25px;
          }

          .num {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 27px;
            color: #000000;
          }

          .firstTd {
            text-align: left !important;
            color: #373737;
          }

          .chemistrySubSubjects {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
          }
        }

        :last-child {
          border-bottom: none;
          background: none;
        }
      }
    }
  }
}
