.modal_wrapper {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal_inner {
    background-color: #ffffff;
    width: 40%;
    margin: auto;
    padding: 60px 20px;
    border-radius: 3px;
    position: relative;

    p {
        font-size: 20px;
        text-align: center;
        margin: 0;
    }
    
    img {
        position: absolute;
        top: 10%;
        right: 3%;
    }
}