.topperComparisonWrapper {
  .topperComparisonContent {
    .text {
      text-align: center;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #121212;
    }
    .banner {
      background: #fffbf9;
      border: 1px solid #ffdece;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 53%;
      margin: 0px auto;
      //   padding: 20px 25px;
      padding: 23px 30px;
    }

    .compareBtn {
      text-align: center;
      width: 50%;
      margin: 20px auto;
      button {
        padding: 10px 50px;
        background: linear-gradient(180deg, #7752f9 0%, #421bce 100%);
        opacity: 0.6;
        border-radius: 6px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }

    .activeCompareBtn {
      text-align: center;
      width: 50%;
      margin: 20px auto;
      button {
        padding: 10px 50px;
        background: linear-gradient(180deg, #7752f9 0%, #421bce 100%);
        border-radius: 6px;
        border-radius: 6px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }

    .card {
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 4px;
      width: 46.5%;
      padding: 20px;
      .top {
        text-align: center;
        margin-bottom: 10px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #121212;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #8e8e8e;
          margin-bottom: 5px;
        }
        .num {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          color: #121212;
        }
      }
    }

    .card-right {
      text-align: center;
      padding: 35px 10px;
      .img {
        margin-bottom: 15px;
      }
      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #121212;
      }
    }

    table {
      border-radius: 10px;
      border: 1px solid #c2c2c2;
    }
    .section-heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      color: #121212;
      margin-bottom: 5px;
    }

    #customers {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      border-radius: 10px;
      width: 100%;
      background: #ffffff;
      margin: auto;

      th {
        background: #a98b72;
        // padding: 12px 0px 12px 35px;
        padding: 10px 0px;
        text-align: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #fff2e7;
        border-bottom: none !important;
      }

      .th-name {
        text-align: start !important;
        padding-left: 40px !important;
      }

      tr {
        background: #fffcfa;
      }

      td {
        // padding: 5px 0px 5px 25px;
        padding: 10px 0px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #828282;
      }

      .td-text {
        text-align: start !important;
        // background: red !important;
        padding-left: 40px !important;
        width: 45% !important;
      }
      .num {
        // padding: 5px 0px 5px 65px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #121212;
        text-align: center;
      }
    }

    #comparison-table {
      width: 100%;

      thead {
        background: #a98b72;

        th {
          padding: 10px 0px 10px 25px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height */

          letter-spacing: 0.01em;

          color: #fff2e7;
        }
      }

      tbody {
        tr {
          td {
            padding: 10px 0px 10px 25px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #373737;
          }

          .num {
            padding: 10px 100px 0px 0px;
            text-align: center;
            width: 28%;
            font-weight: 500;
            color: #000000;
          }
        }
      }
    }
    // SCSS for modal
    .modal {
      // SCSS for modal-dialog
      .modal-dialog {
        margin-top: 100px;

        // SCSS for modal-content
        .modal-content {
          // SCSS for modal-header
          .modal-header {
            border: none;

            // SCSS for modal-header-left
            .modal-header-left {
              font-family: "Poppins";
              font-weight: 400;
              font-size: 17px;
              line-height: 30px;
              color: #4a4a4a;
            }
          }

          // SCSS for headings
          .headings {
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);

            ul {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0;
              list-style: none;
              margin-bottom: -0.1px;

              // SCSS for li elements inside headings
              li {
                font-family: "Poppins";
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #858585;
                padding: 0 20px;
              }

              // SCSS for li-name
              .li-name {
                width: 50%;
              }

              // SCSS for li-marks
              .li-marks {
                text-align: center;
                width: 20%;
              }

              // SCSS for li-rank
              .li-rank {
                width: 20%;
                text-align: center;
              }
            }
          }

          // SCSS for modal-body
          .modal-body {
            padding: 0 !important;
            margin-top: 15px;

            // SCSS for singleStudentDetails
            .singleStudentDetails {
              cursor: pointer;

              ul {
                display: flex;
                justify-content: space-between;
                padding: 0;
                list-style: none;

                // SCSS for li elements inside singleStudentDetails
                li {
                  font-family: "Poppins";
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 27px;
                  color: #000000;
                  padding: 0 20px;
                }

                // SCSS for li-name inside singleStudentDetails
                .li-name {
                  width: 50%;
                }

                // SCSS for li-marks inside singleStudentDetails
                .li-marks {
                  width: 20%;
                  text-align: center;
                }

                // SCSS for li-rank inside singleStudentDetails
                .li-rank {
                  width: 20%;
                  text-align: center;
                }
              }
              &:hover {
                background-color: #f0f0f0;
              }
            }
          }
        }
      }
    }

    .tables {
      padding-bottom: 30px;
    }
  }
}
