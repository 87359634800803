.coinDetailsPopup{
  p{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    color: #000000;
  }
  .coinHeading{
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0;
    }
    ul{
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      margin-bottom: 1.5rem;
    }
    ul li{
      margin-bottom: 0.35rem;
    }
    p:last-child{
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 1.5rem;
      color: #000000;
    }
}
