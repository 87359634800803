.topicWiseMarksBox {
  .subjectsListWrapper {
    .subjectsList {
      display: flex;
      background: #f5f4f8;
      max-width: max-content;
      margin: 0 auto;
      border-radius: 20px;
      overflow: hidden;

      margin-bottom: 60px;
      // margin-top: 60px;

      .single_select_subject {
        &:last-child {
          margin-right: 0px;
        }

        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }

        label {
          padding: 8px 20px;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #6b6876;
          cursor: pointer;
          min-width: 150px;
          text-align: center;
        }

        input[type="radio"]:checked + label {
          background-color: #ff933a;
          border: none;
          color: #ffffff;
        }
      }
    }
  }

  .topicAnalysisData {
    display: flex;
    flex-wrap: wrap;

    .testProgressIndicator {
      background: #fff;
      padding: 15px;
      border-radius: 8px;
      margin-bottom: 40px;
      width: calc(50% - 10px);
      margin-right: 20px;

      &:nth-child(2n + 2) {
        margin-right: 0px;
      }

      .topicDetail {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 13px;
          color: #000;
          margin-bottom: 18px;
          font-weight: 600;
        }

        .Qmark {
          font-weight: 500;
        }
      }

      .ProgressBar_Line {
        position: relative;
      }

      .ProgressBar_Line span {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      .progress {
        height: 18px;
        margin-bottom: 5px;

        .progress-bar {
          background: #668de1;
          border-radius: 5px;
        }
      }

      .questionPercentage {
        display: flex;
        justify-content: space-between;
        padding: 0px 3px;

        span {
          color: #acacac;
          font-size: 14px;
        }
      }
    }
  }
}
