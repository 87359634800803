// .modal {
//   // width: fit-content;
//   // width: 300px;

//   .refund-initiated {
//     .modal-body {
//       margin: 0;
//       padding: 0 !important;
//       width: fit-content;

//       .refund-details {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         // background: red;
//         img {
//           height: 159px;
//           width: 159px;
//           margin: 0 auto;
//         }

//         h2 {
//           font-size: 20px;
//           font-weight: 500;
//           line-height: 30px;
//           letter-spacing: 0.01em;
//           margin-bottom: 4px;
//           color: #2d2d2d;
//         }

//         .refund-info {
//           font-size: 14px;
//           font-weight: 400;
//           line-height: 21px;
//           letter-spacing: 0em;
//           color: #5d5d5d;
//           width: 80%;
//           text-align: center;
//         }

//         .refund-mbl-no {
//           font-size: 12px;
//           font-weight: 400;
//           line-height: 18px;
//           letter-spacing: 0em;
//           color: #5c5c5c;
//         }

//         .bck-to-pkgs {
//           font-size: 14px;
//           font-weight: 500;
//           line-height: 21px;
//           letter-spacing: 0em;
//           color: #6d48ef;
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// }

// .modal {
//   padding: 0;
//   margin: 0;
//   .refund-modal-header {
//     background: red;
//     padding: 0;
//     margin: 0;
//   }

//   .refund-initiated {
//     font-family: Poppins;
//     padding: 0;
//     margin: 0;

//     .refund-details {
//       padding: 0;
//       margin: 0;
//       width: 300px;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       img {
//         height: 159px;
//         width: 159px;
//         margin: 0 auto;
//       }

//       h2 {
//         font-size: 20px;
//         font-weight: 500;
//         line-height: 30px;
//         letter-spacing: 0.01em;
//         margin-bottom: 4px;
//         color: #2d2d2d;
//       }

//       .refund-info {
//         font-size: 14px;
//         font-weight: 400;
//         line-height: 21px;
//         letter-spacing: 0em;
//         color: #5d5d5d;
//         width: 80%;
//         text-align: center;
//       }

//       .refund-mbl-no {
//         font-size: 12px;
//         font-weight: 400;
//         line-height: 18px;
//         letter-spacing: 0em;
//         color: #5c5c5c;
//       }

//       .bck-to-pkgs {
//         font-size: 14px;
//         font-weight: 500;
//         line-height: 21px;
//         letter-spacing: 0em;
//         color: #6d48ef;
//         text-decoration: underline;
//       }
//     }
//   }
// }

// @keyframes fadeInDelayed {
//   0% {
//     opacity: 0;
//     transform: translateY(20px);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

// Shake that SCSS sassiness into your stylesheets!

.fade.modal.show {
  display: block;
  padding-left: 12px;

  .modal-dialog.refund-initiated {
    width: 436px;
    .modal-content {
      .refund-modal-header.modal-header {
        border: none;
      }

      .modal-body {
        .refund-details {
          margin-top: -40px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          img {
            height: 159px;
            width: 159px;
            margin: 0 auto;
            text-align: center;
          }

          h2 {
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0.01em;
            color: #2d2d2d;
          }

          .refund-info {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            color: #5d5d5d;
          }

          .refund-mbl-no {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
            color: #5c5c5c;
          }

          .bck-to-pkgs {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            color: #6d48ef;
            cursor: pointer;
          }
        }
      }
    }
  }
}
