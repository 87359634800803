.headerLeft_wrapper {
  .headerLeft_inner_wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // background-color: red;
    .course_select_wrapper {
      width: 270px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 2px solid #f0eef8;
      background-color: #ffffff;
      border-radius: 25px;
      cursor: pointer;
      padding: 0 10px;
      img {
        // margin-right: 10px;
      }
      .text_content_1 {
        margin: 0;
        font-weight: 600;
        color: #000000;
      }
      .caret_dropdown_icon {
        margin: 0 5px;
        i {
          color: #000000;
        }
      }
    }
    .class_select_wrapper {
      width: 135px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 2px solid #f0eef8;
      background-color: #6d48ef;
      border-radius: 25px;
      cursor: pointer;
      padding: 0 10px;
      img {
        // margin-right: 10px;
      }
      .text_content_1 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
      }
      .caret_dropdown_icon {
        margin: 0 5px;
        i {
          color: #ffffff;
        }
      }
    }
    .batch_select_wrapper {
      width: 135px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 2px solid #6d48ef;
      background-color: #ffffff;
      border-radius: 25px;
      cursor: pointer;
      padding: 0 10px;
      img {
        // margin-right: 10px;/
      }
      .text_content_1 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #6d48ef;
      }
      .caret_dropdown_icon {
        margin: 0 5px;
        i {
          color: #6d48ef;
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .headerLeft_wrapper {
    .headerLeft_inner_wrapper {
      .course_select_wrapper {
        width: 160px;
      }
      .class_select_wrapper {
        width: 100px;
      }
      .batch_select_wrapper {
        width: 100px;
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .headerLeft_wrapper {
    .headerLeft_inner_wrapper {
      .course_select_wrapper {
        width: 90px;
      }
      .class_select_wrapper {
        width: 85px;
      }
      .batch_select_wrapper {
        width: 85px;
      }
    }
  }
}
