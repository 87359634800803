.recordedLiveStreamVideoListView_wrapper {
  .recordedLiveStreamVideoListView_inner_wrapper {
    .recordedVideoCollection_wrapper {
      .single_recorded_video_wrapper {
        margin: 10px 0;
        border-radius: 10px;
        background-color: #ffffff;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left_side_content_wrapper {
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 16px;
              font-weight: 500;
              color: #6f6868;
            }
          }
        }
        .right_side_content_wrapper {
          .btn_wrapper {
            button {
              outline: none;
              border: none;
              background-color: green;
              border-radius: 5px;
              padding: 5px;
              font-size: 16px;
              font-weight: 600;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
