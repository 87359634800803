.faqsWrppr {
  max-width: 51.75rem;

  .faqsContainer {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px #0000000f;
    .faqsTitle {
      color: #2d2d2d;
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0.7rem;
    }

    .custom_accordion_item {
      border: none;
      margin-bottom: 0.75rem;
      border-radius: 0.25rem;

      .accordion-header {
        .accordion-button {
          padding: 0.75rem 1rem !important;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          // border-radius: 0.25rem;
          background: #f6f6f7;
        }
      }

      .accordion-button:not(.collapsed) {
        box-shadow: none;
        color: #333;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
      }

      .accordion-body {
        // padding: 10px 0px 7px 0px !important;
        padding: 0.75rem 1rem !important;

        color: #5c5c5c;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
        background: #f6f6f7;
      }
    }
  }
}
