.referFrndBannerWrppr {
  position: relative;

  img {
    width: 51.75rem;
    object-fit: cover;
  }

  .banner_message {
    position: absolute;
    top: 32%;
    left: 5%;
    color: #f6f6f6;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem; /* 157.143% */
    max-width: 27.875rem;

    .earn-together {
      color: #f7f7f7;
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.01125rem;
      margin-bottom: 0.25rem;
    }
  }
}

@media (max-width: 578.98px) {
  .referFrndBannerWrppr {
  }
}
