.userEditProfileContentComponent_wrapper {
  padding-top: 101px;
  padding-bottom: 20px;

  .userEditProfileContentComponent_inner_wrapper {
    padding: 20px;
    background: #fff;
    margin: 20px 0px;
    border-radius: 10px;

    .content_wrapper {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 20px;
      width: 760px;
      margin: 0px auto;

      .image_content_wrapper {
        display: flex;
        justify-content: center;

        .image_wrapper {
          width: 120px;
          height: 120px;
          border-radius: 50%;

          .image_inner_wrapper {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: #eceff4;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .dummy_image {
              width: 100px;
              height: 100px;
              border-radius: 50%;
            }

            .user_image {
              width: 70px;
              height: 70px;
              border-radius: 50%;
            }

            label {
              position: absolute;
              bottom: 0;
              right: 0;
              cursor: pointer;

              input[type="file"] {
                display: none;
              }

              img {
                width: 100%;
              }
            }
          }
        }
      }

      .tab_button {
        border: 0px;
        justify-content: center;
        margin: 30px auto;
        background-color: #f3f3f3;
        display: flex;
        width: max-content;
        padding: 5px 5px 6px;
        border-radius: 26px;

        li {
          button {
            min-width: 160px;
            border: 0px;
            border-radius: 19px;
            color: #707070;
            font-size: 14px;
          }

          .active {
            color: #fff;
            background-color: #ff933a;
          }
        }
      }

      .user_detail_box {
        label {
          margin: 0 0 10px;
        }

        .title_edit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;

          h3 {
            font-size: 20px;
            margin: 0px;
          }

          button {
            i {
              color: #6d48ef;
            }
          }
        }

        .input_wrp_box {
          display: flex;
          justify-content: space-between;

          .input_wrapper {
            margin: 10px 0;
            width: calc(50% - 10px);

            .error_text {
              display: block!important;
              color: red;
              margin: 0;
            }

            input[type="text"],
            input[type="date"],
            input[type="email"],
            input[type="number"] {
              width: 100%;
              height: 45px;
              border-radius: 6px;
              border: none;
              background: #f3f3f3;
              padding: 0 10px;

              ::placeholder {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5;
              }

              &:focus {
                border: 0px !important;
                outline: none !important;
                box-shadow: none !important;
              }
            }
          }
        }

        .gender_wrapper {
          .text_content_wrapper {
            margin: 10px 0;

            .text_content {
              margin: 0;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.33;
              text-align: left;
              color: #0f0404;
            }
          }

          .radio_collection_wrapper {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .single_select {
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 10px 20px;
                border-radius: 25px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                display: flex;
                align-items: center;

                img {
                  margin-right: 5px;
                }
              }

              input[type="radio"]:checked + label {
                background-color: #6d48ef;
                border: none;
                color: #ffffff;
              }
            }
          }
        }

        .select_wrapper {
          margin: 10px 0;
          width: calc(50% - 10px);

          select {
            width: 100%;
            height: 45px;
            border-radius: 6px;
            border: none;
            background: #f3f3f3;
            padding: 0 10px;

            &:focus {
              border: 0px !important;
              outline: none !important;
              box-shadow: none !important;
            }
          }
        }

        .btn_wrapper {
          margin: 40px 0 20px;
          text-align: center;

          button {
            border: none;
            outline: none;
            width: 350px;
            height: 45px;
            border-radius: 8px;
            background-color: #6d48ef;
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }

      .tab-content {
        .your_package_list {
          .current_package {
            margin-bottom: 50px;

            h3 {
              font-size: 20px;
              margin-bottom: 20px;
            }

            .crnt_pckgeBx_wrpr {
              display: flex;
              flex-wrap: wrap;

              .crnt_pckge_box {
                display: flex;
                width: calc(50% - 10px);
                border: 1px solid #eeeeee;
                border-radius: 8px;
                overflow: hidden;
                margin-right: 20px;

                &:nth-child(2n + 2) {
                  margin-right: 0px;
                }

                &:nth-child(even) {
                  .left_title {
                    background-color: #31d680;
                  }
                }

                .left_title {
                  background-color: #e230a0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 10px;
                  flex: 0 0 120px;

                  span {
                    color: #fff;
                    font-weight: 500;
                    font-size: 14px;
                  }
                }

                .right_detail {
                  padding: 10px;
                  width: calc(100% - 120px);
                  position: relative;

                  .refund-status {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-bottom: 15px;
                    border-radius: 0px 4px 0px 4px;
                    padding: 4px 10px 4px 10px;
                    // color: #ffffff;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 11px;
                    letter-spacing: 0em;
                  }

                  .refund_submitted {
                    border: 1px solid #e82b2b;
                    background: #e82b2b;
                    color: #ffffff;
                  }

                  .refundinitiated {
                    border: 1px solid #f68a26;
                    background: #f68a26;
                    color: #ffffff;
                  }

                  .refundcompleted {
                    border: 1px solid #26b923;
                    background-color: #26b923;
                    color: #ffffff;
                  }

                  h4 {
                    font-size: 15px;
                    font-weight: 600;
                    margin-top: 20px;
                  }

                  .expiry_type_1 {
                    span {
                      color: #707070;
                      font-size: 11px;
                    }

                    .package_type_1 {
                      padding-top: 15px;
                      display: flex;
                      justify-content: flex-end;
                      align-items: flex-end;

                      .ask-refund {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 18px;
                        letter-spacing: 0em;
                        text-decoration: underline;
                        color: #6d48ef;
                        cursor: pointer;
                      }

                      .package-status {
                        background: #6d48ef;
                        border-radius: 5px;
                        font-size: 12px;
                        color: #fff;
                        padding: 2px 7px;
                      }
                    }

                    .refund {
                      justify-content: space-between !important;
                    }
                  }

                  .expiry_type {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 15px;
                    align-items: flex-end;

                    span {
                      color: #707070;
                      font-size: 11px;
                    }

                    .package_type {
                      background: #6d48ef;
                      border-radius: 5px;
                      font-size: 12px;
                      color: #fff;
                      padding: 2px 7px;
                    }
                  }
                }
              }
            }
          }

          .past_package {
            .crnt_pckgeBx_wrpr {
              opacity: 0.5;

              .crnt_pckge_box {
                &:nth-child(even) {
                  .left_title {
                    background-color: #a231d6;
                  }
                }

                .left_title {
                  background-color: #668de1;
                }
              }
            }
          }
        }

        .no_dataFind {
          text-align: center;
          max-width: 500px;
          margin: 0 auto;

          .img_box {
            width: 160px;
            margin: 0 auto 20px;

            img {
              max-width: 100%;
            }
          }

          h5 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 20px;
          }

          p {
            color: #707070;
            font-size: 14px;
            margin-bottom: 20px;
          }

          button {
            background: #6d48ef;
            font-size: 16px;
            color: #fff;
            border-radius: 6px;
            width: 180px;
            padding: 5px 15px;
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .userEditProfileContentComponent_wrapper {
    .userEditProfileContentComponent_inner_wrapper {
      .content_wrapper {
        width: auto;
        padding: 0px;
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userEditProfileContentComponent_wrapper {
    width: 100%;
    padding-top: 60px;

    .userEditProfileContentComponent_inner_wrapper {
      padding: 20px 10px;

      .content_wrapper {
        padding: 10px;
        width: 100%;
        margin: 0;

        .tab-content {
          .your_package_list {
            .current_package {
              margin-bottom: 20px;

              .crnt_pckgeBx_wrpr {
                .crnt_pckge_box {
                  width: 100%;
                  margin-right: 0px;
                  flex-direction: column;

                  .left_title {
                    flex: auto;
                  }

                  .right_detail {
                    width: 100%;

                    .expiry_type {
                      padding-top: 5px;
                    }
                  }
                }
              }
            }
          }
        }

        .tab_button {
          li {
            button {
              min-width: 130px;
            }
          }
        }

        .user_detail_box {
          .btn_wrapper {
            button {
              width: -webkit-fill-available;
            }
          }

          .input_wrp_box {
            flex-wrap: wrap;

            .input_wrapper {
              width: 100%;
              margin: 5px 0px;
            }
          }

          .select_wrapper {
            width: 100%;
            margin: 5px 0px;
          }
        }
      }
    }
  }
}
