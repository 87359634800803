.contact_us_wrapper {
  .contact_us_inner_wrapper {
    .contact_us_right_wrapper {
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .contact_us_wrapper {
    .contact_us_inner_wrapper {
      .contact_us_left_wrapper {
        flex: 0 0 190px;
      }
      .contact_us_right_wrapper {
        flex: 0 0 calc(100% - 190px);
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .contact_us_wrapper {
    .contact_us_inner_wrapper {
      .contact_us_left_wrapper {
        flex: 0 0 160px;
      }
      .contact_us_right_wrapper {
        flex: 0 0 calc(100% - 160px);
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .contact_us_wrapper {
    .contact_us_inner_wrapper {
      display: block;
    }
  }
}
