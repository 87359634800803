.QuestionBankCustomPaperWrppr {
  margin-top: 40px;

  .QuestionBankCustomPaperInrWrppr {
    margin-left: 100px;
    .heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #3c3c3c;
      margin-bottom: 10px;
      margin-left: 450px;
    }
    .QuestionBankCustomPaperContent {
      display: flex;
      flex-wrap: wrap;
      .questionBankCustomPaperBox {
        border: 1px solid #eeeeee;
        border-radius: 8px;
        background: #f5f4f8;
        padding: 15px;
        margin: 30px 16px 16px 0px;
        width: calc(45% - 8px);
        &:nth-child(2n + 2) {
          margin-right: 0px;
        }
        .questionBankCustomPaperName {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .paperName {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.01em;
            color: #121212;
            margin-bottom: 7px;
          }
        }

        .questions_data_count {
          font-family: "Poppins";
          font-style: normal;
          ul {
            padding: 0px;

            list-style: none;
            display: flex;
            margin-left: 0px;
            li {
              margin-right: 7px;
            }
            .qb_unattempt {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;

              color: #888888;
              span {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;

                letter-spacing: 0.01em;

                color: #f5a200;
              }
            }
            .qb_wrong {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;

              color: #888888;
              span {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;

                letter-spacing: 0.01em;
                color: #ff1212;
              }
            }
            .qb_correct {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;

              color: #888888;
              span {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;

                letter-spacing: 0.01em;
                color: #19af00;
              }
            }
          }
        }
        .qb_topics {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          .qb_topics_list {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;

            color: #636363;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 40ch;

            .qb_topic {
              margin-right: 15px;
            }
          }
          .qb_length {
            cursor: pointer;
            width: 28%;

            .topic_content {
              font-weight: 500;
            }
          }
        }
        .modal {
          .modal-header {
            display: flex;
            .modal-header-left {
              margin: auto;

              .modal-title p {
                font-size: 20px;
                line-height: 30px;
                color: #000000;
                background: red;
              }
            }
            .modal-header-right {
              // margin-top: -25px;
            }
          }

          .modal-body {
            .qb_topic {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 27px;
              padding: 10px;

              color: #757575;
              border-bottom: 1px solid #757575;
            }
          }

          .modal-footer {
            .done_btn {
              background: #6d48ef;
              box-shadow: 0px 4px 8px rgba(109, 72, 239, 0.16);
              border-radius: 10px;

              margin: auto;
              width: 40%;
              color: #ffffff;
              padding: 10px 10px;
            }
          }
        }

        .num_of_questions {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 10px;

          color: #4e4e4e;
          .question_tex {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;

            color: #4e4e4e;
          }
        }

        .no_of_quests_startBtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height */

          color: #4e4e4e;

          .no_of_questions {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */

            color: #4e4e4e;
            .question_text {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              /* identical to box height */

              color: #4e4e4e;
            }
          }

          .startBtn {
            // width: 28%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            cursor: pointer;
            color: #6d48ef;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .view_sol_btn {
              // background: red;
              text-decoration-line: underline;
              // cursor: pointer;
            }
            .start_pract_btn {
              text-decoration-line: underline;
              // cursor: pointer;
            }
            span {
              font-size: 25px;
              margin-top: 2px;
              cursor: pointer;
              margin-right: 13px;
            }
          }
        }

        .date_startBtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height */

          color: #4e4e4e;

          .created_date {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */

            color: #4e4e4e;
          }

          .startBtn {
            width: 28%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            cursor: pointer;
            color: #6d48ef;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .view_sol_btn {
              text-decoration-line: underline;
              // cursor: pointer;
            }
            .start_pract_btn {
              text-decoration-line: underline;
              // cursor: pointer;
            }
            span {
              font-size: 25px;
              margin-top: 2px;
              cursor: pointer;
              margin-right: 13px;
            }
          }
        }
      }

      .no_custom_tests_img {
        margin: auto;
        margin-right: 530px;
        img {
          width: 250px;
        }
      }
    }
  }
}
