.userTransactionsContentComponent_wrapper {
  padding-top: 99px;
  .container {
    padding: 0px;
    width: 90%;
    .userTransactionsContentComponent_inner_wrapper {
      width: 100%;
      padding: 0px;

      .transactionTabBox {
        padding: 40px 0px 20px 0px;
        margin-bottom: 15px;
        position: fixed;
        z-index: 1;
        background-color: white;
        display: flex;
        justify-content: center;
        width: 84%;
        text-align: center;
        top: 100; /* Fixed to the top */

        ul {
          border: none;
          margin: 0 auto;
          background-color: #f5f4f8;
          border-radius: 22px;
          overflow: hidden;

          li {
            border: none;

            button {
              border: none;
              color: #000;
              font-size: 16px;
              min-width: 150px;
              border-radius: 0px;
              font-weight: 500;
              font-family: "Poppins";
              line-height: 24px;

              &.active {
                background-color: #ff933a;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
      }

      .transactionTabContent {
        padding-top: 30px;
        min-height: calc(
          100vh - 100px
        ); /* Calculate remaining viewport height */
        overflow-y: auto; /* Enable scrolling for content */
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 991.98px) {
  .userTransactionsContentComponent_wrapper {
    .userTransactionsContentComponent_inner_wrapper {
      padding: 20px 0px;
    }
  }
}

@media (max-width: 575.98px) {
  .userTransactionsContentComponent_wrapper {
    padding-top: 60px;

    .userTransactionsContentComponent_inner_wrapper {
      padding: 0px;
      .transactionTabBox {
        width: 100% !important;
        top: 40px;
        left: 0;
      }

      .heading_wrapper {
        .heading_text_content {
          font-size: 24px;
        }
      }

      .transactions_wrapper {
        margin: 0px;

        .transactions_history_collection_wrapper {
          .single_transaction_wrapper {
            .sub_section_wrapper_1 {
              .left_side_content_wrapper {
                width: 70%;

                .text_content_wrapper {
                  .text_content {
                    font-size: 15px;
                    line-height: 18px;
                  }
                }
              }

              .right_side_content_wrapper {
                width: 30%;
              }
            }

            .sub_section_wrapper_2 {
              .text_content_wrapper {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
