.login_step_one2_wrapper {
  display: flex;
  min-height: 100vh;

  .login2_left_part {
    width: 50%;
    background-color: #e00116;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      img {
      }
    }
  }

  .login2_right_part {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .signup_form {
      width: 400px;
      border-radius: 10px;
      border: solid 1px #f2f2f3;
      background-color: #ffffff;
      padding: 50px 25px 25px;
      text-align: center;

      .image_container {
        text-align: center;
        width: 80px;
        margin: 0 auto 20px;

        img {
          width: 100%;
        }
      }

      h3 {
        font-size: 24px;
        color: #000;
        font-weight: 600;
        margin: 10px 0px 10px;
      }

      p {
        letter-spacing: 0.28px;
        color: #000000;
        opacity: 0.5;
        font-size: 14px;
      }

      .input_wrapper {
        margin: 10px 0;

        input[type="number"] {
          width: 100%;
          height: 42px;
          border: 1px solid #ebebeb;
          border-radius: 6px;
          background: #f5f4f8;
          padding: 0 10px;
          font-size: 18px;
          font-weight: 600;

          ::placeholder {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.6;
          }
        }
      }

      .btn_wrapper {
        margin: 20px 0;
        text-align: center;

        button {
          border: none;
          outline: none;
          width: 100%;
          height: 42px;
          border-radius: 6px;
          background: #6d48ef;
          box-shadow: 0px 7px 30px #6d48ef1c;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          &:disabled {
            opacity: 60%;
          }
        }

        button:focus {
          background-color: #6d48ef;
          box-shadow: none;
          color: #ffffff;
        }
      }

      .not_member {
        span {
          letter-spacing: 0.26px;
          color: #000000;
          font-size: 13px;
        }

        .singup {
          letter-spacing: 0.26px;
          color: #ff933a;
          font-size: 13px;
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .login_step_one2_wrapper {
    flex-direction: column;

    .login2_left_part {
      padding: 30px 0px;
      width: 100%;
    }

    .login2_right_part {
      width: 100%;

      .signup_form {
        width: auto;
      }
    }
  }
}
