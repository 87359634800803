.doubtsView_wrapper {
  .doubtsView_inner_wrapper {
    .sec_2_wrapper {
      margin: 20px 0;
      border-radius: 8px;
      background-color: #ffffff;

      .sec_2_inner_wrapper {
        .content_wrapper {
          padding: 20px 0;

          .left_side_content_wrapper {
            .subject_collection_wrapper {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              margin-bottom: 35px;

              .single_select_subject {
                margin: 10px 0;
                margin-right: 5px;
                margin-left: 30px;

                &:last-child {
                  margin-right: 0px;
                }

                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }

                label {
                  border: solid 1px #f0eef8;
                  background-color: #fafafd;
                  padding: 6px 20px;
                  border-radius: 8px;
                  font-size: 15px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                  color: #6b6876;
                  cursor: pointer;
                  min-width: 150px;
                  text-align: center;
                }

                input[type="radio"]:checked + label {
                  background-color: #6d48ef;
                  border: none;
                  color: #ffffff;
                }
              }
            }
          }

          .right_side_content_wrapper {
            position: relative;

            .doubtsCollectionView_wrapper {
              .doubtsCollectionView_inner_wrapper {
                .section_wrapper {
                  .section_inner_wrapper {
                    padding: 0 5px;

                    .options_wrapper {
                      margin-bottom: 45px;

                      .custom_nav_pills {
                        border-radius: 25px;
                        background-color: #f5f4f8;
                        overflow: hidden;
                        max-width: fit-content;
                        margin: 0 auto;
                        display: flex;
                        gap:13px;

                        .custom_nav_item {

                          .custom_nav_link {
                            border-radius: 0px;
                            border: none;
                            outline: none;
                            font-size: 15px;
                            font-weight: 500;
                            color: #6b6876;
                          }

                          .custom_nav_link.active {
                            color: #ffffff;
                            background-color: #ff933a;
                          }
                        }
                      }
                    }

                    .tab_wrapper {
                      .custom_tab_content {
                        //////answered doubts tab////
                        .custom_tab_pane {
                          .answered_doubts_tab_wrapper {
                            .outer_content_wrapper {
                              .doubts_collection_wrapper {
                                display: flex;
                                flex-wrap: wrap;

                                .single_doubt_wrapper {
                                  background-color: #f5f4f8;
                                  border: 1px solid #eeeeee;
                                  border-radius: 8px;
                                  margin: 7px 0;
                                  padding: 15px;
                                  width: 32.5%;
                                  margin-right: 13px;
                                  cursor: pointer;

                                  &:nth-child(3n + 3) {
                                    margin-right: 0px;
                                  }

                                  .content_wrapper_1 {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    .left_side_content_wrapper {
                                      .text_content_wrapper {
                                        .text_content {
                                          margin: 0;
                                          font-size: 13px;
                                          font-weight: 400;
                                          color: #929292;
                                        }
                                      }
                                    }

                                    .right_side_content_wrapper {
                                      text-align: right;

                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                  }

                                  .content_wrapper_2 {
                                    margin: 10px 0;

                                    .text_content_wrapper {
                                      .text_content {
                                        margin: 0;
                                        font-size: 15px;
                                        font-weight: 500;
                                        color: #000;
                                      }
                                    }
                                  }

                                  .content_wrapper_3 {
                                    margin: 10px 0;

                                    .text_content_wrapper {
                                      .text_content {
                                        margin: 0;
                                        font-size: 13px;
                                        font-weight: 600;
                                        color: #6d48ef;

                                        label {
                                          cursor: pointer;
                                          text-decoration: underline;

                                          span {
                                            font-size: 13px;
                                            font-weight: 600;
                                            color: #6d48ef;
                                            vertical-align: middle;
                                            margin-left: 5px;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }

                                .content_inner_wrapper {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  margin: auto;
                                  margin-top: 20px;
                                  border-radius: 30px;
                                  box-shadow: 0 4px 16px 0
                                    rgba(109, 72, 239, 0.08);
                                  background-color: #6d48ef;
                                  padding: 7px 12px;
                                  cursor: pointer;
                                  color: #fff;

                                  .add_btn {
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    color: #6d48ef;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 22px;
                                  }

                                  .text_content_wrapper {
                                    margin-left: 5px;

                                    .text_content {
                                      margin: 0;
                                      font-size: 14px;
                                      color: #fff;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        //////unanswered doubts tab////
                        .custom_tab_pane {
                          .unanswered_doubts_tab_wrapper {
                            .outer_content_wrapper {
                              .doubts_collection_wrapper {
                                display: flex;
                                flex-wrap: wrap;

                                .single_doubt_wrapper {
                                  background-color: #f5f4f8;
                                  border: 1px solid #eeeeee;
                                  border-radius: 8px;
                                  margin: 7px 0;
                                  padding: 15px;
                                  width: 32.5%;
                                  margin-right: 13px;
                                  cursor: pointer;

                                  &:nth-child(3n + 3) {
                                    margin-right: 0px;
                                  }

                                  .content_wrapper_1 {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    .left_side_content_wrapper {
                                      .text_content_wrapper {
                                        .text_content {
                                          margin: 0;
                                          font-size: 14px;
                                          font-weight: 500;
                                          color: #6b6876;
                                        }
                                      }
                                    }

                                    .right_side_content_wrapper {
                                      text-align: right;

                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                  }

                                  .content_wrapper_2 {
                                    margin: 10px 0;

                                    .text_content_wrapper {
                                      .text_content {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 500;
                                        color: #09041a;
                                      }
                                    }
                                  }

                                  .content_wrapper_3 {
                                    margin: 10px 0;

                                    .text_content_wrapper {
                                      .text_content {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 600;
                                        color: #3aa02b;

                                        label {
                                          cursor: pointer;

                                          i {
                                            font-size: 14px;
                                            font-weight: 600;
                                            color: #3aa02b;
                                            vertical-align: middle;
                                            margin-left: 5px;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                .content_inner_wrapper {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  margin: auto;
                                  margin-top: 20px;
                                  border-radius: 30px;
                                  box-shadow: 0 4px 16px 0
                                    rgba(109, 72, 239, 0.08);
                                  background-color: #6d48ef;
                                  padding: 7px 12px;
                                  cursor: pointer;
                                  color: #fff;

                                  .add_btn {
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    color: #6d48ef;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 22px;
                                  }

                                  .text_content_wrapper {
                                    margin-left: 5px;

                                    .text_content {
                                      margin: 0;
                                      font-size: 14px;
                                      color: #fff;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .sec_1_wrapper {
              position: absolute;
              top: 0px;
              right: 0px;

              .content_inner_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 30px;
                box-shadow: 0 4px 16px 0 rgba(109, 72, 239, 0.08);
                background-color: #6d48ef;
                padding: 7px 12px;
                cursor: pointer;
                color: #fff;

                .add_btn {
                  width: 25px;
                  height: 25px;
                  border-radius: 50%;
                  background-color: #fff;
                  color: #6d48ef;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 22px;
                }

                .text_content_wrapper {
                  margin-left: 5px;

                  .text_content {
                    margin: 0;
                    font-size: 14px;
                    color: #fff;
                  }
                }
              }

              .create_douts_modalWrap {
                position: relative;
              }
            }
          }
        }
      }
    }
  }
}

.no_data_found_wrapper {
  text-align: center;
  width: 100%;

  img {
    width: 150px;
    height: 150px;
  }

  p {
    font-size: 14px;
    width: 40%;
    margin: 0 auto;
  }
}

.create_douts_modal {
  position: relative;

  .modal-header {
    padding: 10px 15px;
    border: none;
  }

  .modal-body {
    padding: 0px 1rem !important;
  }
}

.view_douts_modal {
  position: relative;

  .modal-header {
    padding: 5px 10px;
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
  }

  .modal-body {
    padding: 0px 0rem !important;
  }
}

@media (max-width: 991.98px) {
  .doubtsView_wrapper {
    .doubtsView_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .content_wrapper {
            .right_side_content_wrapper {
              .doubtsCollectionView_wrapper {
                .doubtsCollectionView_inner_wrapper {
                  .section_wrapper {
                    .section_inner_wrapper {
                      .options_wrapper {
                        .custom_nav_pills {
                          max-width: 300px;
                        }
                      }
                    }
                  }
                }
              }

              .sec_1_wrapper {
                .content_inner_wrapper {
                  .text_content_wrapper {
                    .text_content {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .doubtsView_wrapper {
    .doubtsView_inner_wrapper {
      .sec_2_wrapper {
        margin: 0px;

        .sec_2_inner_wrapper {
          .content_wrapper {
            .left_side_content_wrapper {
              .subject_collection_wrapper {
                margin-bottom: 20px;

                .single_select_subject {
                  margin: 0px 10px 10px 0px;

                  label {
                    padding: 6px 10px;
                    min-width: max-content;
                  }
                }
              }
            }

            .right_side_content_wrapper {
              .doubtsCollectionView_wrapper {
                .doubtsCollectionView_inner_wrapper {
                  .section_wrapper {
                    .section_inner_wrapper {
                      .options_wrapper {
                        margin-bottom: 75px;

                        .custom_nav_pills {
                          max-width: 300px;
                        }
                      }
                    }
                  }
                }
              }

              .sec_1_wrapper {
                top: 50px;
                right: 50%;
                transform: translateX(50%);

                .content_inner_wrapper {
                  .text_content_wrapper {
                    .text_content {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .no_data_found_wrapper p {
    width: 100%;
  }
}
