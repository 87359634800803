.containers {
  background: #e5e5e5;
  //   height: 100vh;
  //   margin-top: -40px;

  .container {
    .topicWiseWrapper {
      width: 88%;
      margin: 30px auto;
      .section-heading {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        color: #373737;
      }
      .topicWiseCard {
        width: 100%;
        margin-top: 5px;
        border-radius: 12px;
        margin-bottom: 20px;
        background: #ffffff;
        .card-body {
          padding: 20px;
          .card-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: "Poppins";
            font-style: normal;
            .text {
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              color: #121212;
            }
            .date {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #696969;
            }
          }
          .card-contain {
            display: flex;

            .lft {
              flex: 8;
              gap: 1;
              .lft-top {
                width: 100%;
                ul {
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: nowrap;
                  padding: 0px;

                  li {
                    list-style: none;
                    border: 0.953191px solid #e8e8e8;
                    box-shadow: 2.38298px 2.38298px 9.53191px
                      rgba(0, 0, 0, 0.08);
                    border-radius: 6px;
                    display: inline-block;
                    padding: 10px 10px;
                    gap: 0.5rem;
                    width: 135px;

                    .TestReportCard {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .text {
                        .text-top {
                          font-family: "Poppins";
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 21px;
                          text-align: center;
                          color: #535353;
                        }
                        .text-btm {
                          font-family: "Poppins";
                          font-style: normal;
                          font-weight: 300;
                          font-size: 10px;
                          line-height: 15px;
                          color: #221133;
                        }
                      }

                      .marks {
                        color: #aa964d !important;
                      }
                      .rank {
                        color: #134f87 !important;
                      }

                      .num {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;

                        letter-spacing: 0.02em;

                        color: #134f12;
                      }
                    }
                  }
                }
              }

              .lft-btm {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1.1rem;
                margin-top: -12px;

                .percentageBox {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  // background: radial-gradient(
                  //   50% 50% at 50% 50%,
                  //   #e72828 0%,
                  //   #c32b2b 82.68%
                  // );
                  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
                  border-radius: 6px;
                  padding: 9px;
                  margin-left: 2px;
                  width: 39.5%;

                  .text {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;

                    text-align: center;

                    color: #ffffff;
                  }

                  .num {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    letter-spacing: 0.02em;
                    color: #ffffff;
                  }
                  img {
                    width: 35px;
                    height: 35px;
                  }
                }

                .subjs {
                  padding: 0px;
                  margin-top: 13px;
                  width: 61.5%;

                  ul {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0px;
                    border-radius: 6px;
                    background: #ffffff;
                    box-shadow: -2px -2px 6px rgba(148, 148, 148, 0.06),
                      2px 2px 8px rgba(148, 148, 148, 0.12);
                    border: 0.8px solid #e1e1e1;
                    border-radius: 6px;
                    li {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      list-style: none;
                      width: 100%;
                      padding: 18.5px 15px;
                      .text {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #565656;
                      }
                      .num {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        color: #000000;
                      }
                    }
                    .li-1 {
                      border-right: 0.6px solid #c8c8c8;
                    }
                    .li-2 {
                      border-right: 0.6px solid #c8c8c8;
                    }
                  }
                }
              }
            }

            .rght {
              flex: 4;
              text-align: right;

              .type {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #696969;
                margin-bottom: 20px;
              }

              .viewReport {
                margin-left: 180px;
                margin-bottom: 20px;
                button {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #6d48ef;
                  border-radius: 6px;
                  padding: 7px 10px;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #ffffff;
                  .text {
                    margin-right: 7px;
                  }
                  img {
                    width: 20px;
                    height: 20px;
                    margin-left: -5px;
                  }
                }
              }

              .downloadReport {
                margin-left: 180px;
                button {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 6px;
                  padding: 7px 10px;
                  border: 1px solid #6d48ef;
                  .text {
                    margin-right: 7px;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #6d48ef;
                  }

                  img {
                    width: 15px;
                    height: 15px;
                    margin-bottom: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .viewAllTestBtn {
      text-align: center;
      margin-bottom: 30px;
      padding-bottom: 40px;
      button {
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-shadow: 2px 2px 4px rgba(51, 51, 51, 0.12);
        border-radius: 8px;
        padding: 7px 35px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #6d48ef;
      }
    }
  }
}
