.cancel_wrapper {
  .modal-content {
    width: 28.75rem;
    .modal-header {
      border: none;
    }

    .modal-body {
      padding: 1.5rem 1.88rem 2.5rem 1.88rem;
    }

    .cancel_container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h4 {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem; /* 150% */
        letter-spacing: 0.01rem;
      }

      h5 {
        color: #535353;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: 0.00875rem;
        max-width: 19.25rem;
        margin-top: 0.25rem;
        margin-bottom: 2.5rem;
      }

      .verify_details_button {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        text-align: center;
        margin-top: 1.75rem;
        width: 100%;

        .btnn {
          padding: 0.75rem 0;
          border-radius: 0.25rem;
          width: 50%;
        }

        .modify {
          cursor: pointer;
          border: 0.6px solid #b91311;
          background: #b50503;
          button {
            color: #fff;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .cancel {
          border: 0.6px solid #b91311;
          cursor: pointer;

          button {
            color: #b50503;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
}
