.test_intructions_view_wrapper {
  padding-top: 50px;
  .test_intructions_view_inner_wrapper {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    //istruction Screen
    .instructionScreenBox {
      .backBtn {
        cursor: pointer;
        display: inline-block;
      }
      .instruction_title {
        text-align: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 45px;
        color: #000000;
        margin-bottom: 50px;
        margin-top: -25px;
      }
      .instruction_topicName {
        text-align: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 20px;
      }
      .instruction_detailBox {
        max-width: 750px;
        margin: 0 auto;
        .examTime {
          border: 1px solid #ffdec2;
          background: #ff933a0d;
          padding: 15px;
          text-align: center;
          border-radius: 10px;
          margin-bottom: 20px;
          .examTitle {
            color: #f1a769;
            margin-bottom: 8px;
          }
          .examDurations {
          }
        }
        .instructions {
          border: 1px solid #ffdec2;
          border-radius: 10px;
          background: #ff933a0d;
          margin-bottom: 15px;
          h3 {
            font-size: 20px;
            border-bottom: 1px solid #ffdec2;
            padding: 10px 15px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            color: #000000;
          }
          .instPoint {
            padding: 10px 15px;
            img {
              width: 100%;
            }
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #000000;
            }
            h6 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
            }
          }
        }
        .img {
          text-align: center;
          padding: 15px 0px;
        }
        .startTestBtn {
          button {
            background: #6d48ef;
            width: 100%;
            color: #fff;
            padding: 7px 10px;
            border-radius: 8px;
          }:hover{
            background: #6d18ef;

          }
        }
      }
    }
  }
}
