.header_top_wrapper{
    background-color: #EBEBEB;
    .container{
        .hdrTpInr{
            padding: 5px 0px 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .hdrTopLeft{
                ul{
                    margin: 0px;
                    padding: 0px;
                    line-height: normal;
                    li{
                        list-style: none;
                        display: inline-block;
                        margin-right: 20px;
                        .hdr_link{
                            text-decoration: none;
                            color: #000;
                            span{
                                line-height: normal;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            .hdrTopRight{
                button{
                    background-color: #000;
                    color: #FFFFFF;
                    padding: 2px 10px 4px;
                    border-radius: 30px;
                    font-size: 12px;
                    img{
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}