.all_page_content_wrapper {
    .all_page_button {
        background-color: #F5F4F8;

        .back_btn {
            padding: 20px;
        }

        .container_inner {
            max-width: 890px;
            margin: 0 auto;

            h2 {
                font-size: 40px;
                text-align: center;
                padding: 60px 0px 80px;
            }

            .all_page_tabs {
                border: none;
                justify-content: center;

                li {

                    button {
                        color: #000;
                        border: none;
                        height: 37px;
                        padding: 5px 0px;
                        margin-right: 40px;
                        border-bottom: 2px solid #f5f4f8;

                        &:focus,
                        :hover {
                            background-color: none !important;
                            outline: none !important;
                        }

                        :not(:last-child) {
                            margin-right: 15px;
                        }

                    }

                    .nav-link.active {
                        border: 0px;
                        background: none;
                        padding: 5px 0px;
                        height: 37px;
                        border-bottom: 2px solid #6D48EF;
                        color: #6D48EF;
                    }
                }

                .nav-tabs .nav-link {
                    border: none !important;
                }

                .nav-tabs .nav-link:hover {
                    border: none !important;
                }
            }
        }
    }

    .tab-content {
        max-width: 890px;
        margin: 0 auto;
    }
}

@media (max-width: 575.98px) {
    .all_page_content_wrapper {
        .all_page_button {
            .container_inner {
                .all_page_tabs {
                    margin-bottom: 15px;
                }

                h2 {
                    font-size: 28px;
                    padding: 0px 0px 20px;
                }
            }
        }
    }
}