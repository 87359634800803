.Pyq_ppr_test_intructions_view_wrapper {
  padding-top: 50px;

  .test_intructions_view_inner_wrapper {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;

    //istruction Screen
    .instructionScreenBox {
      .backBtn {
        cursor: pointer;
        display: inline-block;
      }

      .instruction_title {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .instruction_detailBox {
        max-width: 750px;
        margin: 0 auto;

        .examTime {
          border: 1px solid #FFDEC2;
          background: #FF933A0D;
          padding: 15px;
          text-align: center;
          border-radius: 10px;
          margin-bottom: 20px;

          .examTitle {
            color: #F1A769;
            margin-bottom: 8px;
          }

          .examDurations {}
        }

        .instructions {
          border: 1px solid #FFDEC2;
          border-radius: 10px;
          background: #FF933A0D;
          margin-bottom: 15px;

          h3 {
            font-size: 20px;
            border-bottom: 1px solid #FFDEC2;
            padding: 10px 15px;
          }

          .instPoint {
            padding: 10px 15px;

            img {
              width: 100%;
            }

            p {
              font-size: 14px;
              color: #000;
            }

            h6 {}
          }
        }

        .img {
          text-align: center;
          padding: 15px 0px;
        }

        .startTestBtn {
          button {
            background: #6D48EF;
            width: 100%;
            color: #fff;
            padding: 7px 10px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .Pyq_ppr_test_intructions_view_wrapper {
    .test_intructions_view_inner_wrapper {
      .instructionScreenBox {
        .instruction_title {
          font-size: 22px;
        }
      }
    }
  }
}