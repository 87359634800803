.ACpsBannerWrapper {

  background: #750000;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;

  .ACpsBannerInnrLeftWrppr {
    width: calc(70% - 8px);
    padding: 10px;

    .ACpsAttemptsLeft p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;

      color: #ffffff;
    }

    .AcpsDescription {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 275;
      font-size: 14px;
      line-height: 136%;

      color: #ffefef;
    }
  }

  .ACpsBannerInnrRigthWrppr {
    width: calc(30% - 8px);

    display: grid;
    place-items: center;
    margin-right: 30px;

    .ACpsGetNowBtn {

      background: #fdae2b;
      border-radius: 24px;
      button {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 136%;

        color: #ffffff;
        padding: 10px 25px 10px 25px;
      }
    }
  }
}
