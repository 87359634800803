.payment_modal {
  .mdl_hdr {
    border-bottom: 0px;
    padding-bottom: 0px;
  }
  .mbd {
    padding: 30px 50px !important;

    .coupon_wrapper {
      .already_purchased {
        font-size: 20px;
        text-align: center;
      }
      .coupon_wraper_inr {
        h2 {
          color: #c00714;
          font-size: 35px;
          text-align: center;
          margin-bottom: 20px;
        }
        p {
          text-align: center;
          letter-spacing: 0.32px;
          color: #000000;
          opacity: 0.7;
          padding: 0px 10%;
        }
        input {
          padding: 0.5rem;
        }
        input:checked {
          background-color: #6d48ef;
        }
        input:focus {
          box-shadow: 0 0 0 0.25rem rgba(109, 72, 239, 0.25);
        }
        label {
          font-weight: 600;
          color: #000;
        }
        .applyCoinError {
          border: 1px solid #bd373f;
          padding: 1rem;
          border-radius: 8px;
          svg {
            fill: #c00714;
          }
          .coinErrorMsg {
            color: #c00714;
            text-align: start;
            padding: 0;
            margin-bottom: 0;
          }
        }
      }
      .coupon_input {
        position: relative;
        margin-bottom: 15px;

        input {
          height: 37px;
          background: #f5f4f8;
          border: none;
          width: 100%;
          padding: 5px 10px;
          border-radius: 6px;
          font-size: 18px;

          &:focus {
            outline: none;
            border: none;
          }
        }
        button {
          position: absolute;
          right: 0px;
          background: #6d48ef;
          border-radius: 6px;
          color: #fff;
          height: 100%;
          padding: 5px 12px;
        }
      }
      .updated_amount {
        text-align: center;
        letter-spacing: 0.32px;
        color: #000000;
        margin-bottom: 15px;

        span {
          letter-spacing: 0.4px;
          color: #c00714;
          margin-left: 7px;
          display: inline-block;
          font-weight: 600;
        }
      }
      .pay_now {
        button {
          background: #059249;
          letter-spacing: 0.36px;
          color: #ffffff;
          font-weight: 600;
          text-align: center;
          border-radius: 6px;
          padding: 8px 10px;
          width: 100%;
        }
        a {
          background: #059249;
          letter-spacing: 0.36px;
          color: #ffffff;
          font-weight: 600;
          text-align: center;
          border-radius: 6px;
          padding: 8px 10px;
          width: 100%;
          display: inline-block;
          text-decoration: none;
        }
      }
    }
  }
}
