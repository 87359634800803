// .pyqCpsExam_wrapper {
//   padding-top: 101px;
//   background-color: #f5f4f8;

//   .pyqCpsExam_InrWrapper {
//     padding: 30px 0px;

//     .pyqCPsExam_contentWpr {
//       background-color: #fff;
//       border-radius: 10px;

//       .pyqCpsExamTabBox {
//         .pyqCpsExamTab {
//           margin-bottom: 20px;

//           .pyqCpsExamUl {
//             padding: 15px 15px;
//             border-bottom: 1px solid #ebebeb;

//             li {
//               border: none;
//               margin-right: 15px;

//               button {
//                 border: none;
//                 background-color: #f5f4f8;
//                 border-radius: 10px;
//                 color: #707070;
//                 font-size: 16px;
//               }

//               .nav-link.active {
//                 color: #fff;
//                 background-color: #6d48ef;
//                 border: none;
//               }
//             }
//           }
//         }
//       }

//       .pyqCpsExamTabContent {
//         min-height: 65vh;
//         padding: 15px 15px;
//         max-width: 1030px;
//         margin: 0 auto;

//         .tab-content {
//           .tab-pane {
//           }
//         }
//       }
//     }
//   }
// }

// @media (max-width: 575.98px) {
//   .pyqCpsExam_wrapper {
//     padding-top: 50px;

//     .pyqCpsExam_InrWrapper {
//       padding: 10px 0px;

//       .pyqCPsExam_contentWpr {
//         .pyqCpsExamTabBox {
//           .pyqCpsExamTab {
//             margin-bottom: 10px;

//             .pyqCpsExamUl {
//               padding: 10px 10px;

//               li {
//                 margin: 0px 10px 10px 0px;

//                 button {
//                   font-size: 13px;
//                 }
//               }
//             }
//           }
//         }

//         .pyqCpsExamTabContent {
//           padding: 10px;
//         }
//       }
//     }
//   }
// }

.pyqCpsExam_wrapper {
  padding-top: 70px;
  background-color: #f5f4f8;

  .pyqCpsExam_InrWrapper {
    padding: 20px 0px;
    .container {
      width: 95%;
      padding: 0px;
      .pyqCPsExam_contentWpr {
        background-color: #fff;
        border-radius: 10px;

        .pyqCpsExamTabBox {
          button {
            outline: none;
            border: none;
          }
          .heading {
            text-align: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: #121212;
            // padding-top: 20px;
            margin-bottom: 20px;
            text-decoration: underline;
          }
          .pyqCpsExamTab {
            margin-bottom: 25px;

            .pyqCpsExamUl {
              display: flex;
              padding: 15px 15px;
              border-bottom: 1px solid #ebebeb;
              flex-wrap: wrap;
              gap: 0.7rem;

              li {
                border: none;
                //   margin-right: 15px;
                // margin-bottom: 15px;

                button {
                  border: none;
                  background-color: #f5f4f8;
                  border-radius: 10px;
                  color: #707070;
                  font-size: 16px;
                }

                .nav-link.active {
                  color: #fff;
                  background-color: #6d48ef;
                  border: none;
                }
              }
            }
          }
        }

        .pyqCpsExamTabContent {
          min-height: 65vh;
          padding: 0 0px;
          max-width: 1030px;
          margin: 0 auto;

          .tab-content {
            .tab-pane {
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .pyqCpsExam_wrapper {
    padding-top: 50px;

    .pyqCpsExam_InrWrapper {
      padding: 10px 0px;

      .pyqCPsExam_contentWpr {
        .pyqCpsExamTabBox {
          .heading {
            margin-bottom: 1rem !important;
          }
          .pyqCpsExamTab {
            margin-bottom: 10px;

            .pyqCpsExamUl {
              padding: 10px 10px;

              li {
                margin: 0px 10px 10px 0px;

                button {
                  font-size: 13px;
                }
              }
            }
          }
        }

        .pyqCpsExamTabContent {
          padding: 10px;
        }
      }
    }
  }
}
