.card {
  width: 100%;
  min-height: 4.75rem; // Ensure minimum height for the card
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;
  border: 0.6px solid #ffdecc;
  background: linear-gradient(97deg, #ffdecc 20.09%, #fcf2fd 101.87%);
  margin-bottom: 1rem;

  .card_content {
    position: absolute;
    bottom: -100%;
    animation: scroll-up 2s infinite;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    white-space: nowrap; // Prevent line breaks

    img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 2.5rem;
      object-fit: contain;
      margin-left: 0.88rem;
      margin-right: 0.5rem;
    }

    .name {
      color: #3d3d3d;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap; // Prevent line breaks
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        color: #121212;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .time_ago {
        color: #3c3c3c;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap; // Prevent line breaks
      }
    }
  }
}

@keyframes scroll-up {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  40% {
    bottom: calc(50% - 30px);
    opacity: 1;
  }
  60% {
    bottom: calc(50% - 30px);
    opacity: 1;
  }
  100% {
    bottom: 150%;
  }
}
