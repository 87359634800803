.dateModalRange .modal-dialog {
  width: 508px !important;
  border-radius: 8px;
}

// // Modal styles
.onSubmitModal {
  .modal-content {
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    width: 95%;
  }

  .head {
    padding-bottom: 0px !important;
    padding-left: 30px !important;
  }

  .modal-header {
    border: none;
    border-radius: 8px 8px 0 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;

    width: 100%;
    color: #000000;
  }

  .dateRangeContainer {
    padding: 0px !important;
    position: relative;
    width: 100%;
    margin-top: -30px;
  }

  .modal-body {
    .to {
      position: absolute;
      top: 17px;
      left: 182.5px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      padding: 8.5px 15.5px;
      color: #676767;
    }
  }

  .calendarElement {
    .rdrDateDisplayWrapper {
      background: #ffff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 0px !important;
      width: 111.7%;
      margin-left: -23px;

      .rdrDateDisplayItem {
        font-size: 16px;
        border: 1px dotted #fe841c;
        &:last-child {
          margin-left: 50px;
        }
        input {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          color: #fe841c;
        }
      }
    }

    .rdrMonthAndYearWrapper {
      width: 111.7%;
      display: flex;
      justify-content: space-between;
      margin-left: -23px;

      .rdrNextPrevButton {
        display: block;
        background: red;
        width: 10%;
        border-style: solid;
        margin: 13px !important;
        transform: translate(-3px, 0px);
      }

      .rdrPprevButton {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 16px;
        margin-right: -20px;

        i {
          margin-right: 28px;
        }
      }

      .rdrNextButton {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 16px;
        margin-right: -20px;
        i {
          margin-left: 35px;
        }
      }

      .rdrMonthAndYearPickers {
        display: flex;
        align-items: center;

        .rdrMonthPicker {
          margin-right: 10px;

          select {
            border: 1px solid #ccc;
            padding: 5px 10px;
            font-size: 14px;
            cursor: pointer;
            width: 120px;
          }
        }

        .rdrYearPicker {
          width: max-content;

          select {
            width: 120px;
            border: 1px solid #ccc;
            padding: 5px 10px;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }

    .rdrMonths {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 111.7%;
      margin-left: -23px;
      .rdrMonthName {
        display: none;
      }

      .rdrWeekDays {
        margin-top: 10px;
        width: 105.7%;
        margin-left: -11px !important;
        .rdrWeekDay {
          font-family: Roboto !important;
          font-weight: 400 !important;
          letter-spacing: 0em;
          text-align: center !important;
          text-transform: uppercase;
          color: #6e7781;
        }
      }

      .rdrDays {
        width: 105.7%;
        margin-left: -11px !important;
        .rdrDay {
          .rdrDayNumber {
            font-family: Roboto !important;
            font-weight: 400 !important;
            line-height: 0px !important;
            letter-spacing: 0em !important;
            text-align: center !important;
          }
        }
      }
    }

    .rdrMonth {
      padding: 0px !important;
      font-size: 15.2px;
      line-height: 0px !important;
    }
  }
}

// Button styles
.subBtn {
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 6px;
  height: 40px;
  width: 80%;
  padding-top: 0px !important;
  color: #fff;
  background: #6d48ef;
}

@media (max-width: 578.98px) {
  .dateModalRange {
    .modal-dialog{
      width: auto !important;
      .modal-body{
        // padding: 10px !important;
        padding:10px 20px 20px 20px !important
      }
    }
  }
  .onSubmitModal {
    .dateRangeContainer{
      margin-top: auto;
    }
    .calendarElement{
      .rdrMonthAndYearWrapper{
        width: 100%;
        margin-left: 0;
        .rdrNextPrevButton{
          margin: 0 !important;
          transform: translate(0px, 0px);
        }
      }
      .rdrMonths{
        width: 100%;
        margin-left: 0;
        .rdrMonth{
          width: 100%;
          .rdrDays{
            width: 100%;
            margin-left: 0 !important;
          }
        }
        .rdrWeekDays{
          width: 100%;
          margin-left: 0 !important;
          .rdrWeekDay{
            font-size: 14px;
          }
        }
      } 
      .rdrDateDisplayWrapper{
        width: 100%;
        margin-left: 0;
      }
    }
  .modal-content {
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    width: 100%;
  }}
}
