$maths-color: #e230a0;
$physics-color: #31d680;
$chemistry-color: #4dd7d8;

.AdaptiveCps_subject_collection_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  gap: 1.5rem;

  .AdaptiveCps_single_select_subject {
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    background: #f5f4f8;

    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    label {
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: #000000;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 25px 20px 25px 100px;
      width: 100%;
      transition: background-color 0.3s ease;

      // Customized styles for the unchecked state
      transition: background-color 0.3s ease;
    }

    input[type="radio"]:checked + label {
      color: #ffffff;
      background: #31d680;
      padding: 25px 20px 25px 100px;
      overflow: hidden;
      border: 1px solid #31d680;
      border-radius: 12px;

      .subject_icon {
        background-color: #fff; // White background for subject_icon when label is active

        img {
          filter: none; // Restore the default image color (black)
        }
      }
    }

    .subject_icon {
      width: 54px;
      height: 54px;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px;
      border-radius: 50%;
      cursor: pointer;
      background-color: #fff; // White background for subject_icon

      img {
        width: 35px;
        filter: brightness(0) invert(1); // Default white image
      }

      // Conditional background color based on the subject type
      &.maths {
        background-color: $maths-color;
      }

      &.physics {
        background-color: $physics-color;
      }

      &.chemistry {
        background-color: $chemistry-color;
      }
    }
  }
}
