.wishList_collection {
  padding-top: 141px;
  background: #e5e5e5;
  padding-bottom: 50px;
  .container {
    background: #ffffff;
    margin-left: 125px;
    width: 83.5%;
    padding-bottom: 100px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 15px;
      width: 100%;
      //   box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
      border-bottom: 2px solid rgba(0, 0, 0, 0.08);
      .right {
        .addCart {
          cursor: pointer;
          display: flex;
          align-items: center;
          .badge {
            background: orange;
            color: #f5f4f8;
            font-weight: bold;
            border-radius: 5px;
            position: relative;
            top: -11px;
            right: 12px;
          }
          .cart {
            margin-left: -5px;
          }
        }
      }
    }

    .backBtn_content {
      display: flex;
      gap: 1rem;
      padding: 20px 25px;
      .backBtn img {
        color: #6d48ef;
      }
      .backBtn_text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #6d48ef;
      }
    }

    .cart_container {
      padding: 10px 20px 10px 20px;

      width: 65%;
      .card {
        max-width: 100%;
        padding: 20px;
        .card_left {
          img {
            width: 70%;
            height: 18vh;
            object-fit: cover;
          }
        }
        .col-right {
          margin-left: -20px;
          .card-body {
            padding: 0px;
            .card-title {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #2d2d2d;
            }
            .card-text {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #686868;
            }
            .card_text_actions {
              border-bottom: 0.5px solid #bdbdbd;
              .actions {
                display: flex;
                justify-content: space-between;
                width: 35%;
                align-items: center;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 8px;
                cursor: pointer;
                .action {
                  color: #6d48ef;
                }
                .qnty {
                  display: flex;
                  justify-content: space-between;
                  width: 30%;
                  .btns {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 60%;
                    padding: 1px 4px;
                    background: #f7f7f7;
                    border: 0.5px solid #d0d0d0;
                    border-radius: 4px;
                    img {
                      width: 17px;
                    }
                    .qntyNo {
                      font-family: "Open Sans";
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 14px;
                      color: #6d48ef;
                    }
                  }
                }
              }
            }
            .price {
              text-align: end;
              font-family: "Poppins";
              font-style: normal;
              font-size: 18px;
              line-height: 27px;
              margin-top: 10px;
              margin-bottom: -10px;
              .price_text {
                font-weight: 400;
                color: #626262;
                margin-right: 10px;
              }
              .cost {
                font-weight: 500;
                color: #000000;
              }
            }
          }
        }
      }
    }
    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50vh;
      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 36px;
        color: #9d9d9d;
        margin-bottom: 10px;
      }

      button {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background: #ff933a;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        color: #ffffff;
        padding: 7px 25px;
      }
    }
  }
}

@media (max-width: 578px){
  .wishList_collection {
    padding-top: 65px;
    .container {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
    }
  }
}
