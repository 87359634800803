.ReferandEarn_wrapper {
  height: 100%;
  width: 100%;

  padding-top: 75px;
  padding-bottom: 75px;
  background: #f6f6f7;
  height: 100%;
  width: 100%;

  .refer_and_earn_inner_wrapper {
    width: 84%;
    margin: 0 auto;
  }
}

@media (max-width: 578.98px) {
  .ReferandEarn_wrapper {
    padding-top: 18px;

    .ReferandEarn_inner_wrapper {
      .container {
        width: 95%;
        .backBtnContent {
          .backBtn {
            img {
            }
          }

          strong {
          }
        }

        .referandEarn_Content {
          flex-direction: column;

          .contentLeft {
            width: 100%;
            section {
            }
          }

          .contentRight {
            width: 100%;
            padding: 10px;
            .sectionTitle {
            }

            .termsAndconditions {
              img {
              }

              .text {
                span {
                }
              }
            }
          }
        }
      }
    }
  }
}
