// .signUp_step_one_wrapper {
//   display: flex;
//   min-height: 100vh;
//    .signup_left_part{
//     width: 50%;
//     background-color: #E00116;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .logo{
//       img{}
//     }
//   }
//   .signup_right_form{
//     width: 50%;
//     .signup_form{
//       width: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       min-height: 100vh;
//       .content_inner_wrapper {
//         width: 450px;
//         border-radius: 8px;
//         padding: 20px;
//         margin: 0 auto;
//         .image_content_wrapper {
//           display: flex;
//           justify-content: center;
//           .image_wrapper {
//             width: 90px;
//             height: 90px;
//             border-radius: 50%;
//             .image_inner_wrapper {
//               width: 70px;
//               height: 70px;
//               border-radius: 50%;
//               background: #eceff4;
//               margin: 10px;
//               display: flex;
//               align-items: center;
//               justify-content: center;
//               position: relative;
//               .dummy_image {
//                 width: 70px;
//                 height: 70px;
//                 border-radius: 50%;
//               }
//               .user_image {
//                 width: 70px;
//                 height: 70px;
//                 border-radius: 50%;
//               }
//               label {
//                 position: absolute;
//                 bottom: 0;
//                 right: 0;
//                 cursor: pointer;
//                 input[type="file"] {
//                   display: none;
//                 }
//                 img {
//                   width: 100%;
//                 }
//               }
//             }
//           }
//         }
//         h3{
//           font-size: 24px;
//           font-weight: 600;
//           text-align: center;
//         }
//         .input_wrapper {
//           margin: 15px 0;
//           input[type="text"],
//           input[type="date"],
//           input[type="email"] {
//             width: 100%;
//             height: 40px;
//             border: 1px solid #EBEBEB;
//             border-radius: 6px;
//             background: #F5F4F8;
//             padding: 0 10px;
//             ::placeholder {
//               font-size: 16px;
//               font-weight: 500;
//               line-height: 1.5;
//             }
//             &:focus{
//               outline: none;
//               border: none;
//             }
//           }
//         }
//         .select_wrapper {
//           margin: 15px 0;
//           display: flex;
//           select {
//             width: 100%;
//             height: 40px;
//             border: 1px solid #EBEBEB;
//             border-radius: 6px;
//             background-color: #F5F4F8;
//             padding: 0 10px;
//             margin-right: 10px;
//             &:last-child{
//               margin-right:0px
//             }
//             &:focus{
//               outline: none;
//             }
//           }
//         }
//         .btn_wrapper {
//           margin: 20px 0;
//           text-align: center;
//           button {
//             border: none;
//             outline: none;
//             width: 100%;
//             height: 50px;
//             border-radius: 25px;
//             background-color: #6d48ef;
//             font-size: 18px;
//             font-weight: 600;
//             color: #ffffff;
//           }
//         }
//       }
//     }
//     }
// }

// // Large devices (desktops, less than 1200px)
// @media (max-width: 1199.98px) {
// }

// // Medium devices (tablets, less than 992px)
// @media (max-width: 991.98px) {
// }

// // X-Small devices (portrait phones, less than 576px)
// @media (max-width: 575.98px) {
//   .signUp_step_one_wrapper {
//     .signUp_step_one_inner_wrapper {
//       .content_wrapper {
//         width: 100%;
//         .content_inner_wrapper {
//           width: 100%;
//           padding: 30px 15px;
//         }
//       }
//     }
//   }
// }

.signUp_step_one_wrapper {
  display: flex;
  min-height: 100vh;
  .signup_left_part {
    width: 50%;
    background-color: #e00116;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      img {
      }
    }
  }
  .signup_right_form {
    width: 50%;
    .signup_form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      .content_inner_wrapper {
        width: 450px;
        border-radius: 8px;
        padding: 20px;
        margin: 0 auto;
        .image_content_wrapper {
          display: flex;
          justify-content: center;
          .image_wrapper {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            .image_inner_wrapper {
              width: 70px;
              height: 70px;
              border-radius: 50%;
              background: #eceff4;
              margin: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              .dummy_image {
                width: 70px;
                height: 70px;
                border-radius: 50%;
              }
              .user_image {
                width: 70px;
                height: 70px;
                border-radius: 50%;
              }
              label {
                position: absolute;
                bottom: 0;
                right: 0;
                cursor: pointer;
                input[type="file"] {
                  display: none;
                }
                img {
                  width: 100%;
                }
              }
            }
          }
        }
        h3 {
          font-size: 24px;
          font-weight: 600;
          text-align: center;
        }
        .input_wrapper {
          margin: 15px 0;
          input[type="text"],
          input[type="date"],
          input[type="email"] {
            width: 100%;
            height: 40px;
            border: 1px solid #ebebeb;
            border-radius: 6px;
            background: #f5f4f8;
            padding: 0 10px;
            ::placeholder {
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
            }
            &:focus {
              outline: none;
              border: none;
            }
          }
        }
        .select_wrapper {
          margin: 15px 0;
          display: flex;
          select {
            width: 100%;
            height: 40px;
            border: 1px solid #ebebeb;
            border-radius: 6px;
            background-color: #f5f4f8;
            padding: 0 10px;
            margin-right: 10px;
            &:last-child {
              margin-right: 0px;
            }
            &:focus {
              outline: none;
            }
          }
        }
        .btn_wrapper {
          margin: 20px 0;
          text-align: center;
          button {
            border: none;
            outline: none;
            width: 100%;
            height: 50px;
            border-radius: 25px;
            background-color: #6d48ef;
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;

            &:disabled {
              opacity: 60%;
              cursor: not-allowed;
            }
          }
        }

        .targets_wrapper {
          .text_content_wrapper {
            margin: 10px 0 20px;

            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              line-height: 1.33;
              text-align: left;
              color: #0f0404;
              text-align: start;
            }
          }

          .radio_collection_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .single_select {
              margin: 5px;

              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                background: #f3f3f3;
                padding: 6px 20px;
                border-radius: 6px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                display: flex;
                align-items: center;

                &.selected {
                  background: transparent
                    linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                    no-repeat padding-box;
                  border: none;
                  color: #ffffff;
                }

                &:hover {
                  background: transparent
                    linear-gradient(326deg, #ff8826 0%, #ffc697 100%) 0% 0%
                    no-repeat padding-box;
                  border: none;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .signUp_step_one_wrapper {
    .signUp_step_one_inner_wrapper {
      .content_wrapper {
        width: 100%;
        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;
        }
      }
    }
  }
}
