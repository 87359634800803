.orderReview_container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 10px 20px;
  .left {
    width: 63%;
    .section_heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #2d2d2d;
    }
    .card {
      //   border: none;
      margin-top: 20px;
      .card-body {
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name_num {
            margin-top: -15px;
            .name {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #2d2d2d;
            }

            .num {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #2d2d2d;
            }
          }
          .changeAddressBtn {
            button {
              background: #6d48ef;
              border-radius: 4px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
              padding: 8px 14px;
            }
          }
        }

        .bottom {
          margin-top: -10px;
          .savedAddress {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #797979;
          }
        }
      }
    }
  }
  .right {
    width: 32%;
    margin-right: 30px;
    margin-top: 20px;
    .coupon_code {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .coupon_input {
        input {
          padding: 5px 60px 5px 20px;
        }
        button {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: #121212;
        }
      }
    }
  }
}


@media(max-width:578px) {
  .orderReview_container {
    padding: 0;
    flex-direction: column;
    .left{
      width: 100%;
    }
    .right{
      width: 100%;
      margin-right: 0;
      .coupon_code{
        // padding: 5px 20px 5px 20px;
        .coupon_input{
          // width: 60%;
          padding-left:5px;
          input{
            padding:5px 20px 5px 20px;
          }
        }
      }
    }
  }
}