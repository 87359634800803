.copyCodeSection {
  .copyCodeInnerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .inputWithButton {
      position: relative;
      width: 73%;

      input {
        width: 100%;
        border: 1px dashed #a4a4a4;
        border-radius: 0.25rem;
        height: 2.75rem;
        padding: 0 0.75rem;

        color: #000;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .copyButton {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        // color: #6d48ef;
        color: #b50503;
        font-family: Poppins;
        font-size: 15px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.01em;
        text-align: left;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.38rem;
      }
    }

    .shareOptions {
      display: flex;
      gap: 0.5rem;
      button {
        img {
          width: 2.75rem;
          height: 2.75rem;
        }
      }
    }
  }

  .shareButtonContainer {
    position: relative;


    .iconsdropdown {
      position: absolute;
      top: 100%;
      left: 0;
      width: 55%;
      background-color: white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      padding: 10px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      z-index: 10000;

      .platformButtons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        button {
          display: flex;
          align-items: center;
          cursor: pointer;
          border: none;
          background: none;
          margin-bottom: 10px;
          padding: 5px 0px 10px 0px;
          img {
            width: 24px;
            height: 24px;
          }

          span {
            margin-left: 15px;
            font-size: 12px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: #000000;
          }
        }
        button:not(:last-child) {
          border-bottom: 1px solid #d4d4d4;
          width: 100%;
        }
        button:last-child {
          padding: 6px 0px 0px 0px !important;
        }
      }
    }
  }
}

.Toastify__toast--success {
  background-color: #1e9800;
}

@media (max-width: 578px) {
  .copyCodeSection {
    .inputWithButton {
      margin-bottom: 15px;
    }
  }
}
