.subjectContentComponent_wrapper {
  padding-top: 70px;
  .subjectContentComponent_inner_wrapper {
    padding:0 20px 20px;
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      border-radius: 8px;
      background-color: #ffffff;
      .sec_2_inner_wrapper {
        .content_wrapper {
          // padding: 0 10px;
          padding: 10px 0;
          .left_side_content_wrapper {
            .subject_collection_wrapper {
             display: -webkit-inline-box;
              align-items: center;
              padding: 0px 20px;
              overflow-x: auto;
              width: 100%;
              margin-bottom: 30px;
              .single_select_subject {
                margin: 10px 20px 10px 0px;
                display: flex;
                position: relative;
                .subject_icon{
                  width: 43px;
                  position: absolute;
                  left: 15px;
                  height: 43px;
                  background: #fff;
                  padding: 7px;
                  border-radius: 50%;
                  transform: translateY(-50%);
                  top: 50%;
                  img{
                    width: 100%;
                  }
                }
                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }
                label {
                  border: solid 1px #f0eef8;
                  background: #F5F4F8;
                  padding: 18px 15px;
                  border-radius: 12px;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                  color: #6b6876;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  padding-left: 70px;
                }
                input[type="radio"]:checked + label {
                  background-color: #31D680;
                  border: none;
                  color: #ffffff;
                }
              }
            }
          }
          .right_side_content_wrapper {
            min-height: 300px;
            .topic_collection_wrapper {
              display: flex;
              flex-wrap: wrap;
              // justify-content: center;
              padding: 0 97px;
              //height: 450px;
              overflow-y: scroll;
              .single_topic_wrapper {
                width: 49%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: 8px;
                background: #F5F4F8;
                padding: 20px 5px;
                margin: 0px 15px 15px 0px;
                cursor: pointer;
                &:nth-child(2n+2){
                  margin-right: 0px;
                }
                .serial_number_wrapper {
                  border-radius: 15px;
                  background-image: linear-gradient(
                    149deg,
                    #fff5e6 32%,
                    #ffefe6 76%
                  );
                  padding: 15px;
                  .text_content_wrapper {
                    .text_content {
                      margin: 0;
                      font-size: 20px;
                      font-weight: 600;
                      text-align: center;
                      color: #b35557;
                    }
                  }
                }
                .topic_description_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  padding: 0 10px;
                  .topic_details_wrapper {
                    .text_content_wrapper {
                      .text_content {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 500;
                        color: #0f0404;
                      }
                    }
                  }
                  .caret_wraper {
                    img{
                      width: 9px;
                    }
                  }
                }
              }
            }
            .topic_collection_wrapper::-webkit-scrollbar {
              width: 2px;
            }
            .topic_collection_wrapper::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px #F5F4F8;
              border-radius: 10px;
            }

            /* Handle */
            .topic_collection_wrapper::-webkit-scrollbar-thumb {
              background: #6b6876ab;
              border-radius: 10px;
            }

            /* Handle on hover */
            .topic_collection_wrapper::-webkit-scrollbar-thumb:hover {
              background: #6b6876ab;
            }
          }
        }
      }
    }
  }
}

.no_data_found_wrapper {
  text-align: center;
  img {
    width: 150px;
    height: 150px;
  }
}
.subject_collection_wrapper::-webkit-scrollbar {
                width: 0px !important;
                height: 0px !important;
               }
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .subjectContentComponent_wrapper {
    padding-top: 60px;
    .subjectContentComponent_inner_wrapper {
      padding: 20px 10px;
      .sec_1_wrapper {
        margin: 10px 0;
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        margin: 10px 0;
        .sec_2_inner_wrapper {
          .content_wrapper {
            padding: 10px 0;
            flex-wrap: wrap;
            .left_side_content_wrapper {
              flex: 0 0 100%;
              padding: 5px 0;
              margin: 5px 0;
              .subject_collection_wrapper {
                padding: 0 10px;
                flex-direction: row;
                .single_select_subject {
                  margin: 5px;
                  input[type="radio"] {
                  }
                  label {
                  }
                  input[type="radio"]:checked + label {
                  }
                }
              }
            }
            .right_side_content_wrapper {
              flex: 0 0 100%;
              padding: 5px 0;
              margin: 5px 0;
              .topic_collection_wrapper {
                border-left: none;
                border-top: 1px solid #00000017;
                padding: 0 10px;
                margin-bottom: 0;
                .single_topic_wrapper {
                  width:100%;
                  margin: 7px 0 7px 0;
                  .serial_number_wrapper {
                    .text_content_wrapper {
                      .text_content {
                      }
                    }
                  }
                  .topic_description_wrapper {
                    .topic_details_wrapper {
                      .text_content_wrapper {
                        .text_content {
                        }
                      }
                    }
                    .caret_wraper {
                      .text_content_wrapper {
                        .text_content {
                          i {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
