.student_pdf {
  .pdf_btn {
    width: 100%;
    text-align: right;
    position: sticky;
  }
  button {
    padding: 10px 20px;
    margin-top: 10px;
    margin-right: 100px;
    background: #ff933a;
    border-radius: 6px;
    color: #ffff;
    // position: absolute;
    // top: 20%;
    // right: 10%;
    cursor: pointer;
  }
}
