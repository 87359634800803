.mobile_left_sideBar_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease;
  z-index: 999;
  width: 0;

  .mobile_left_sideBar_inner_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background: #ffffff;
    transition: all 0.3s ease;
    z-index: 999;
    backface-visibility: hidden;
    margin-left: -450px;
    overflow: scroll;
    padding: 10px;
    width: calc(100vw - 100px);

    .sec_1_wrppaer {
      padding: 15px 15px;

      .sec_1_inner_wrapper {
        .image_wrapper {
          text-align: center;

          a {
            img {}
          }
        }
      }
    }

    .sec_2_wrapper {
      padding: 15px 0;

      .sec_2_inner_wrapper {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            a {
              text-decoration: none;

              .wrapping_container {
                padding: 15px;
                @-webkit-keyframes fadeInDown {
                  0% {
                     opacity: 0;
                     -webkit-transform: translateY(-20px);
                  }
                  100% {
                     opacity: 1;
                     -webkit-transform: translateY(0);
                  }
               }
               
               @keyframes fadeInDown {
                  0% {
                     opacity: 0.5;
                     transform: translateY(-20px);
                  }
                  100% {
                     opacity: 1;
                     transform: translateY(0);
                  }
               }
                ul{
                  margin-left: 20px;
                    -webkit-animation-duration: 500ms;
                    animation-duration: 500ms;
                    // -webkit-animation-fill-mode: both;
                    // animation-fill-mode: both;
                    -webkit-animation-name: fadeInDown;
                    animation-name: fadeInDown;
                  li{
                    &:last-child{
                      border-bottom: 1px solid rgba(0, 0, 0, 0.0588235294);
                    }
                    a{
                      display: block;
                      padding: 5px 0;
                      font-size: 14px;
                      font-weight: 500;
                      color: #6f6868;
                      img{
                        transform: rotateY(180deg);
                        height: 10px;
                        margin-right: 10px;
                      }
                    }
                    .active_link{
                      font-weight: 600;
                      color: #6d48ef;
                    }
                    
                    }
                  }
                  .heading_tag{
                    img{
                      width: 8px;
                      margin-left: 10px;
                      transition: all 150ms ease-in-out;
                    }
                }
                img {}

                .active_icon {
                  display: none;
                }

                span {
                  margin-left: 10px;
                  font-size: 18px;
                  font-weight: 500;
                  color: #6f6868;
                  vertical-align: middle;
                }
              }
            }

            .active {
              .wrapping_container {
                background-color: #f5f2fe;

                .active_icon {
                  display: inline-block;
                }

                .inactive_icon {
                  display: none;
                }

                span {
                  font-weight: 600;
                  color: #6d48ef;
                }
              }
            }

            &:not(:last-child) {
              border-bottom: 1px solid #0000000f;
            }
          }
        }
      }
    }

    .sec_3_wrapper {
      padding: 5px 0;
      border-top: 2px solid #0000000f;

      .sec_3_inner_wrapper {
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            margin: 5px 0;
            padding: 0 15px;

            a {
              text-decoration: none;

              .wrapping_container {
                .heading_tag {
                  font-size: 18px;
                  color: #a6a8af;
                  
                }
              }
            }
          }
        }
      }
    }
  }
}