.exam_test_questions_view_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;

          .section_content_wrapper_backdrop {
            display: none;
          }

          .left_side_content_wrapper {
            width: 25%;
            background-color: #ffffff;
            background-color: #ffffff;
            padding: 0.5rem;
            position: sticky;
            top: 0;
            height: 100vh;
            overflow: hidden;

            .sub_section_1_wrapper {
              // flex: 0 0 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .submitBtn {
                background: #6d48ef;
                border-radius: 4px;
                padding: 0.3rem 0.6rem;
                text-decoration: none;
                font-weight: 500;
                font-size: 1rem;
                width: 100%;
                color: #ffffff;
              }

              .closebtn {
                display: none;
              }

              .testProgressIndicator {
                background: #fff;
                padding: 0.2rem 0.5rem;
                border-radius: 8px;
                margin-bottom: 0.5rem;
                border: 0.8px solid #c9c9c9;

                h4 {
                  font-size: 14px;
                  color: #000;
                  margin-bottom: 16px;
                }

                .ProgressBar_Line {
                  position: relative;
                }

                .ProgressBar_Line span {
                  position: absolute;
                  top: -16px;
                  font-size: 14px;
                }

                .progress {
                  height: 8px;
                  margin-bottom: 5px;

                  .progress-bar {
                    background: #ff933a;
                    border-radius: 9px;
                  }
                }

                .questionPercentage {
                  display: flex;
                  justify-content: space-between;

                  span {
                    color: #707070;
                    font-size: 14px;
                  }
                }
              }

              .text_content_wrapper {
                margin-bottom: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text_content {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #0f0404;
                }

                button {
                  font-weight: bold;
                  text-decoration: underline;
                }

                .countdown_timer_container {
                  min-width: 55px;
                  background: #f5f4f8;
                  text-align: end;
                  border: 0.8px solid #e9e3ff;
                  border-radius: 4px;
                  padding: 3px 6px;

                  span {
                    label {
                      color: #6d48ef;
                      font-size: 15px;
                    }
                  }
                }
              }

              .questions_serial_number_collection {
                font-family: "Poppins";
                overflow-y: scroll;
                overflow-y: auto;
                max-height: 50vh;
                margin-bottom: 0.5rem;
                ;

                .single_question_number_wrapper {
                  margin: 5px;
                  display: inline-block;

                  .arrow {
                    width: 8px;
                  }

                  .unanswered_lbl {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    padding: 8px;
                    border-radius: 8px;
                    border: 1px solid #e2e2e2;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    background: #e2e2e2;
                    justify-content: center;
                    min-width: 40px;

                    .questionBox {
                      display: flex;
                      align-items: baseline;
                      // margin-right: 5px;
                      overflow-x: hidden;

                      .show {
                        display: block;
                      }

                      .hide {
                        display: none;
                      }

                      .QBox {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* number of lines to show */
                        -webkit-box-orient: vertical;

                        p {
                          margin-left: 2px;
                          margin-bottom: 0px;

                          math {
                            display: inline-block;
                          }

                          img {
                            display: none;
                          }

                          table {
                            width: 100% !important;
                          }
                        }
                      }
                    }
                  }

                  .answered_lbl {
                    border: 1px solid #e2e2e2;
                    background-color: #48ef4c;
                  }

                  .review_lbl {
                    // font-size: 15px;
                    // font-weight: 500;
                    // color: #000;
                    // padding: 10px;
                    // border-radius: 8px;
                    background: #FFC4C4;
                    border: 1px solid #FFC4C4;
                    // cursor: pointer;
                  }

                  .skip_lbl {
                    border: 1px solid #ffc4c4;
                    background-color: #fed5d5;
                  }

                  .current_lbl {
                    border: 1px solid #ff933a;
                    cursor: pointer;
                    background: inherit;
                  }

                  .isGussedAnswer {
                    border: 1px solid #CDDC39;
                    background-color: #CDDC39 !important;
                  }
                }
              }
            }

            .sub_section_2_wrapper {
              margin-bottom: 0.5rem;
              background: #f5f4f8;
              border: 0.6px solid #c9c9c9;
              border-radius: 4px;

              .question_marking_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                padding: 5px;

                .single_marking {
                  display: flex;
                  align-items: center;
                  flex: 1 1 50%;
                  margin-bottom: 5px;

                  &:last-child {
                    margin-right: 0px;
                  }

                  .box_pic {
                    width: 14px;
                    height: 14px;
                    border-radius: 3px;
                    margin-right: 5px;
                  }

                  .box_pick_background_1 {
                    background-color: #48ef4c;
                    border: 1px solid #ebebeb;
                  }

                  .box_pick_background_2 {
                    background-color: #f99746;
                  }

                  .box_pick_background_3 {
                    background-color: #E2E2E2;
                    border: 1px solid #E2E2E2;
                  }

                  .box_pick_background_4 {
                    background: #FFC4C4;
                    border: 1px solid #FFC4C4;
                  }

                  .box_pick_background_5 {
                    background: #CDDC39;
                    border: 1px solid #CDDC39;
                  }

                  .question_marking_text {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    color: #000000;

                  }
                }
              }
            }
          }

          .right_side_content_wrapper {
            width: 75%;
            border-radius: 8px;
            padding: 10px;
            // background-color: #f5f4f8;
            background: #ffffff;
            border: 0.6px solid #c9c9c9;
            box-shadow: 4px 4px 12px rgba(169, 169, 169, 0.12), -4px -4px 12px rgba(169, 169, 169, 0.12);

            .right_btn_wrapper {
              display: none;
            }

            .sub_section_wrapper {
              // max-width: 750px;
              margin: 0 auto;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .sub_sub_section_wrapper_1 {

                // display: flex;
                // align-items: center;
                // justify-content: space-between;
                h2 {
                  text-align: center;
                  font-size: 1.5rem;
                  font-weight: bold;
                  margin-bottom: 0.6rem;
                }

                .right_side_content_wrapper {
                  flex: 0 0 100%;
                  display: flex;
                  justify-content: flex-end;

                  .review_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }

                  .bookmark_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }
                }
              }



              .sectionWiseTestWrapper {
                .subjectWiseTest {
                  background: #f5f4f8;
                  border: 0.8px solid #c9c9c9;
                  border-radius: 24px;
                  max-width: -moz-fit-content;
                  max-width: fit-content;
                  display: flex;
                  padding: 2px;
                  border-radius: 36px;
                  justify-content: space-between;
                  align-items: center;
                  margin: 0px auto;

                  .single_select_subject {
                    input[type="radio"] {
                      opacity: 0;
                      position: fixed;
                      width: 0;

                      &:checked+label {
                        background-color: #ff933a;
                        border: none;
                        color: #ffffff;
                        border-radius: 19px;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 23px;
                      }
                    }

                    label {
                      padding: 3px;
                      font-size: 15px;
                      letter-spacing: 0.2px;
                      color: #6b6876;
                      cursor: pointer;
                      min-width: 150px;
                      text-align: center;
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                    }
                  }
                }

                .sectionWiseTest {
                  display: flex;
                  width: max-content;
                  margin: 0 auto;
                  margin-bottom: 0.8rem;
                  margin-top: 0.4rem;

                }
              }

              .questionWrapper {
                background: #f5f4f8;
                border: 1px solid #e6deff;
                border-radius: 4px;
                padding: 10px;
                width: 100%;
                height: 65vh;
                overflow-y: auto;
                overflow-x: hidden;

                .sub_sub_section_wrapper_2 {


                  .text_content_wrapper {
                    .typeLanguageWrapper {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 5px;

                      span {
                        color: #707070;
                        font-size: 12px;
                      }

                      .markReview {
                        display: flex;

                        .bookmark_image_wrapper {
                          margin: 0px 10px;

                          span {
                            margin-right: 5px;
                          }

                          img {
                            cursor: pointer;
                          }
                        }

                        button {}
                      }
                    }

                    .questionBox {
                      display: flex;
                      align-items: baseline;
                      margin-bottom: 20px;
                      overflow-x: hidden;

                      span {
                        margin-right: 5px !important;
                      }

                      .text_content {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 500;
                        color: #000;

                        p {
                          margin-bottom: 0px;
                        }
                      }

                      .qst {
                        position: relative;
                        width: 100%;

                        p {
                          display: block;
                          top: 0px;
                          width: 100%;
                        }

                        .hide {
                          display: none;
                        }

                        .show {
                          display: block;
                        }
                      }
                    }
                  }
                }

                .sub_sub_section_wrapper_3 {

                  //single-select
                  .single_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 30px;

                    .single_select {
                      width: calc(50% - 8px);
                      margin: 10px 16px 10px 0px;

                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }

                      .classCorrect {
                        border: 1px solid #5DDD9B;
                        background: #E1F1EC !important;
                      }

                      .classWrong {
                        border: 1px solid #F93030;
                        background: #F6E0E4 !important;
                      }

                      input[type="checkbox"] {
                        opacity: 0;
                        position: fixed;
                        width: 0;
                      }

                      .single_option_wrapper {
                        min-height: 50px;
                        background-color: #fff;
                        border-radius: 8px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;

                        .option_initial {
                          margin-right: 10px;

                          .text_content_2 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #acacac;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #000;

                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      input[type="checkbox"]:checked+.single_option_wrapper {
                        border: 2px solid #6d48ef;

                        .option_initial {
                          .text_content_2 {
                            font-weight: 600;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            font-weight: 600;
                          }
                        }
                      }
                    }
                  }

                  .hide {
                    display: none !important;
                  }

                  .show {
                    display: flex !important;
                  }

                  .show2 {
                    display: block !important;
                  }

                  //multi-select
                  .multi_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    .single_select {
                      width: calc(50% - 8px);
                      margin: 10px 16px 10px 0px;

                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }

                      input[type="checkbox"] {
                        opacity: 1;
                        position: fixed;
                        width: 0;
                      }

                      .single_option_wrapper {
                        min-height: 50px;
                        border: solid 1px #f0eef8;
                        background-color: #fafafd;
                        border-radius: 15px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;

                        .option_initial {
                          margin-right: 10px;

                          .text_content_2 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }
                      }

                      input[type="checkbox"]:checked+.single_option_wrapper {
                        border: 2px solid #6d48ef;

                        .option_initial {
                          .text_content_2 {
                            font-weight: 600;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            font-weight: 600;
                          }
                        }
                      }
                    }
                  }

                  //integer
                  .input_wrapper {
                    .input_inner_wrapper {
                      margin-bottom: 30px;

                      input {
                        border: none;
                        outline: none;
                        width: 100%;
                        border: solid 1px #f0eef8;
                        background-color: #fff;
                        height: 50px;
                        border-radius: 8px;
                        padding: 0 12px !important;
                        font-size: 16px;
                        font-weight: 500;
                      }

                      input[type="number"]:focus {
                        border: 2px solid #6d48ef;
                      }

                      input[type="number"]::-webkit-outer-spin-button,
                      input[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_4 {
                margin-bottom: 15px;

                .checkbox_wrapper {
                  input[type="checkbox"] {
                    margin-right: 10px;
                  }

                  label {
                    font-size: 13px;
                    vertical-align: text-top;
                    color: #09041a;
                  }
                }
              }

              .sub_sub_section_wrapper_5 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0.5rem auto 0;
                width: 100%;
                padding-top: 0.5rem;
                border-top: 1px solid #ddd;

                .left_side_wrapper {
                  width: calc(50% - 8px);
                  margin-right: 16px;

                  .btn_wrapper {
                    text-align: left;

                    button {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #6d48ef;
                      padding: 10px;
                      width: 100%;
                      border: 1px solid #e2e2e2;
                      // text-decoration: underline;
                    }
                  }
                }

                .right_side_wrapper {
                  width: calc(50% - 8px);

                  .btn_wrapper {
                    text-align: right;

                    button {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #fff;
                      padding: 10px;
                      width: 100%;
                      background: #218838;
                      // text-decoration: underline;
                    }
                  }
                }
              }

              .QuestionSolution {
                padding-top: 20px;

                h5 {}

                div {
                  p {
                    margin-bottom: 2px;
                  }
                }
              }

              .sub_sub_section_wrapper_6 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.onSubmitModal {
  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    margin-top: 0px !important;

    .submit_Popup {
      .exit_img {
        width: 100%;

        img {
          width: 35%;
          height: 130px;
          margin-bottom: 20px;
          margin-top: -25px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #6d48ef;
          border-radius: 6px;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #6d48ef;
        }

        .cancel {
          color: #6d48ef;
          background: none;
          margin-right: 10px;
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

@media (max-width: 991.98px) {
  .exam_test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {

            // flex-direction: column;
            

            .left_side_content_wrapper {
              width: 70%;
              

              .sub_section_1_wrapper {
                .questions_serial_number_collection {
                  // height: 17vh;
                }
              }
            }

            .right_side_content_wrapper {
              width: 100%;

              .sub_section_wrapper {
                .sectionWiseTestWrapper {
                  .sectionWiseTest {
                    width: auto;
                    flex-wrap: wrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .exam_test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            flex-direction: row;

            .section_content_wrapper_backdrop {
              height: 100vh;
              width: 100vw;
              z-index: 1995;
              background-color: #000;
              opacity: 0.5;
              position: absolute;
              display: block;
            }
            @keyframes fadeIn {
              from {
                opacity: 0;
              }

              to {
                opacity: 1;
              }
            }

            
            .left_side_content_wrapper {
              position: absolute;
              display: none;
              z-index: 1999;
              padding: 10px;
              width: 80%;

              .sub_section_1_wrapper {
                position: relative;
                .testProgressIndicator{
                  margin-top: 2rem;
                }

                .text_content_wrapper {
                  button {
                    display: none;
                  }

                  .countdown_timer_container {
                    display: none;
                  }
                }

                .closebtn {
                  display: inline-block;
                  position: absolute;
                  top: 0;
                  right: 0;
                }

                .questions_serial_number_collection {
                  height: 45vh;
                }
              }
            }
            .left_side_content_wrapperToggle {
              display: block !important;
              animation: fadeIn 200ms ease-in;
            }

            .right_side_content_wrapper {
              padding: 0 !important;

              .right_btn_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: sticky;
                top: 0;
                z-index: 1000;
                background: inherit;
                padding: 7px 10px;

                .togglebtn {
                  border: none;
                  outline: none;
                  border-radius: 8px;
                  background-color: #ffffff;
                  font-size: 14px;
                  font-weight: 600;
                  text-align: center;
                  color: #fff;
                  padding: 10px;
                  background: #6d48ef;

                  &.submit {
                    background: coral;
                  }
                }

                .countdown_timer_container {
                  min-width: 55px;

                  span {
                    label {
                      color: #6d48ef;
                      font-size: 15px;
                    }
                  }
                }
              }

              .sub_section_wrapper {
                padding: 0 10px 10px !important;
                justify-content: flex-start;
                .sub_sub_section_wrapper_1 {
                  h2{
                    font-size: 1rem;
                    margin-bottom: 0.4rem;
                  }
                }

                .sectionWiseTestWrapper {
                  .subjectWiseTest {
                    padding: 0;
                    margin-bottom: 0.5rem;
                    justify-content: space-between;
                    border-radius: 36px;
                    overflow: hidden;

                    .single_select_subject {

                      &:nth-child(4),
                      &:nth-child(5) {
                        flex: 0 0 50%;

                        label {
                          width: 100%;
                        }
                      }

                      input[type="radio"] {
                        &:checked+label {
                          border-radius: 36px;
                          line-height: 1;
                        }
                      }

                      label {
                        padding: 6px 18px;
                        font-size: 14px;
                        min-width: auto;
                        border-radius: 0;
                      }
                    }
                  }
                }

                .questionWrapper {
                  height: 55vh;
                  .sub_sub_section_wrapper_2 {
                    h2 {
                      font-size: 22px;
                      margin-bottom: 15px;
                      display: none;
                    }

                    .text_content_wrapper {
                      .typeLanguageWrapper {
                        flex-direction: column;

                        span {
                          margin-bottom: 10px;
                        }

                        .markReview {
                          width: 100%;
                          justify-content: space-between;
                          margin-bottom: 10px;
                        }
                      }

                      .questionBox {
                        .qst {
                          p {
                            line-height: 21px;
                          }
                        }
                      }
                    }
                  }

                  .sub_sub_section_wrapper_3 {
                    .single_select_option_collection_wrapper {
                      .single_select {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                      }
                    }

                    .multi_select_option_collection_wrapper {
                      .single_select {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                      }
                    }
                  }
                }

                .sub_sub_section_wrapper_5 {
                  .left_side_wrapper{
                    .btn_wrapper {
                      button {
                        font-size: 15px;
                        padding: 8px;
                      }
                    }
                  }
                  .right_side_wrapper {
                    .btn_wrapper {
                      button {
                        font-size: 15px;
                        padding: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}