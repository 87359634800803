.wallet_wrapper {
  padding-top: 101px;
  background-color: #ffffff;

  .wallet_InrWrapper {
    padding: 30px 0px;

    .wallet_contentWpr {
      border-radius: 16px;
      background: #f2f2f3;
      width: 95%;
      margin: auto;

      .walletTabBox {
        .walletTab {
          margin-bottom: 20px;
          padding: 20px 55px 55px;
          position: relative;

          .coinsBanner {
            margin-bottom: 20px;

            .banner-top {
              background: linear-gradient(97deg,
                  #ffdecc -0.94%,
                  rgba(245, 204, 255, 0.15) 101.87%);
              padding: 45px 0;
              border-radius: 8px 8px 0 0;

              div {
                margin-bottom: 5px;

                img {
                  width: 56px;
                  margin-right: 15px;
                }

                .total-coins {
                  color: #2d2d2d;
                  font-family: "Poppins";
                  font-size: 42px;
                  font-weight: 700;
                  line-height: normal;
                  letter-spacing: 0.84px;
                }
              }

              p {
                color: #4d4d4d;
                font-family: "Poppins";
                font-size: 18px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
              }
            }

            .banner-bottom {
              background-color: #ffffff;
              padding: 25px;
              border-radius: 0 0 8px 8px;

              div {
                p {
                  color: #505050;
                  font-family: "Poppins";
                  font-size: 18px;
                  font-weight: 400;
                  line-height: normal;
                  letter-spacing: 0.18px;
                  margin: 0;

                  span {
                    color: #3c9d00;
                    font-weight: 600;
                  }
                }
              }
            }
          }

          .coins-conditions {
            border-radius: 8px;
            background: #fff;
            padding: 25px;
            margin-bottom: 20px;

            h3 {
              color: #2d2d2d;
              font-family: "Poppins";
              font-size: 20px;
              font-weight: 500;
              line-height: normal;
              margin: 0 0 15px;
            }

            p {
              color: #2d2d2d;
              font-family: "Poppins";
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
            }

            ul {
              margin: 0;

              li {
                p {
                  color: #121212;
                  font-family: "Poppins";
                  font-size: 16px !important;
                  font-weight: 400;
                  line-height: normal;
                  margin-bottom: 8px !important;
                }
              }
            }
          }

          .walletBackBtn {
            position: absolute;
            top: 25px;
            left: 25px;
          }

          h3 {
            color: #2d2d2d;
            font-family: "Poppins";
            font-size: 22px;
            font-weight: 500;
            line-height: normal;
            margin: 20px 0 25px;
          }

          .walletTransaction_container {
            border-radius: 8px;
            background: #fff;
            margin-bottom: 20px;

            .wallet-heading {
              padding: 25px;
            }

            h3 {
              color: #2d2d2d;
              font-family: "Poppins";
              font-size: 20px;
              font-weight: 500;
              line-height: normal;
              margin: 0;
            }

            .walletTransaction_wrapper {
              padding: 0 40px;

              .transaction {
                border-bottom: 0.6px solid #dbdbdb;
                padding: 12px 0;

                p {
                  color: #2d2d2d;
                  font-family: "Poppins";
                  font-size: 16px;
                  font-weight: 400;
                  line-height: normal;
                }

                .transaction-top {
                  position: relative;

                  .date-center {
                    position: absolute;
                    left: 50%;
                  }
                }

                .coin_desc {
                  color: #2d2d2d;
                  font-family: "Poppins";
                  font-size: 18px;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.18px;
                }

                .coin_earn {
                  font-family: "Poppins";
                  font-size: 24px;
                  font-weight: 600;
                  line-height: normal;
                }

                .transaction_detail {
                  flex-direction: row;
                  gap: 1rem;
                }
              }

              .transaction_btn {
                border-radius: 4px;
                border: 1px solid #b50503;
                padding: 16px 46px;
                background: #fff;
                color: #b50503;
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                margin: 40px 0 45px;
              }

              .transaction_btn:hover {
                color: #ffffff;
                background: #b50503;
              }
            }

            .sec_seprator {
              padding: 0.3rem;
              background-color: #fafafa;
            }

            .useCoins_wrapper {
              padding: 2rem 0;

              ul {
                li {
                  margin: 0.5rem 0;
                  list-style: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .wallet_wrapper {
    .wallet_InrWrapper {
      .wallet_contentWpr {
        .walletTabBox {
          .walletTab {
            .walletTransaction_container {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 996px) {
  .wallet_wrapper {
    .wallet_InrWrapper {
      .wallet_contentWpr {
        .walletTabBox {
          .walletTab {
            .walletTransaction_container {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .wallet_wrapper {
    padding-top: 40px;

    .wallet_InrWrapper {
      .wallet_contentWpr {
        .walletTabBox {
          .walletTab {
            .walletTransaction_container {
              max-width: 100%;

              h3 {
                font-size: 1.2rem;
              }

              .walletTransaction_wrapper {
                .transaction {
                  border-bottom: 0.82335px solid #a3a3a3;
                  padding: 1rem 0;

                  .coin_desc {
                    font-size: 1rem;
                  }

                  .coin_earn {
                    font-size: 1rem;
                  }

                  .transaction_detail {
                    p {
                      font-size: 0.7rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.redeem-coins {
  color: #fff;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.01rem;
  padding: 0.875rem 1.5rem;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background: #b50303;
  transition: filter 0.3s, opacity 0.3s;
}

.redeem-coins:disabled {
  filter: blur(0.5px);
  opacity: 0.6;
  cursor: not-allowed;
}

.upi-details-modal,
.bankDetailsModal {
  .modal-content {
    border-radius: 8px;
    border: none;
    padding: 10px;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;

    .modal-title {
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }
  }

  .modal-body {
    padding-top: 0;

    form {
      .form-group {
        margin-bottom: 20px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: 500;
          color: #333;
          color: #3d3d3d;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: block;
          text-align: left;
        }

        .required {
          color: #b50503;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        input {
          width: 100%;
          padding: 10px;
          border-radius: 0.375rem;
          background: #f2f2f3;
          font-size: 16px;
          border: none;
        }
      }

      .text-left {
        text-align: left;
      }

      .add-bank-details {
        color: #b50503;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
      }

      p {
        margin-bottom: 20px;
      }

      .button-group {
        margin-top: 30px;

        button {
          width: 100%;
          padding: 12px;
          font-size: 16px;
          font-weight: bold;
          border-radius: 5px;
          border-radius: 4px;
          border: 0.6px solid #b91311;
          background: #b50503;
          color: #fff;
        }
      }
    }
  }
}

.redeem-confirm-modal {
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;

    .modal-title {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .modal-body {
    padding-top: 0;

    .confirm-text {
      color: #666;
      margin-bottom: 20px;
    }

    .detail-row {
      display: flex;
      justify-content: space-between;
      padding: 15px 0;

      &.gray {
        background-color: #f8f8f8;
      }

      span {
        font-size: 16px;

        &.value {
          font-weight: bold;
        }
      }
    }

    .button-group {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      button {
        width: 48%;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;

        &.btn-outline-primary {
          border-color: #b50503;
          color: #b50503;

          &:hover,
          &:focus {
            background-color: transparent;
            color: #b50503;
            border-color: #b50503;
          }
        }

        &.btn-primary {
          background-color: #b50503;
          border-color: #b50503;

          &:hover,
          &:focus {
            background-color: #b50503;
            border-color: #b50503;
          }
        }
      }
    }
  }
}

.redemption-success-modal {
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;

    .modal-title {
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }
  }

  .modal-body {
    padding-top: 0;

    .confirm-text {
      color: #666;
      margin-bottom: 10px;
    }

    .detail-row {
      display: flex;
      justify-content: space-between;
      padding: 15px 0;

      &.gray {
        background-color: #f8f8f8;
      }

      span {
        font-size: 16px;

        &.value {
          font-weight: bold;
        }
      }
    }

    .button-group {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      button {
        width: 48%;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;

        &.btn-outline-primary {
          border-color: #b50503;
          color: #b50503;

          &:hover,
          &:focus {
            background-color: transparent;
            color: #b50503;
            border-color: #b50503;
          }
        }

        &.btn-primary {
          background-color: #b50503;
          border-color: #b50503;

          &:hover,
          &:focus {
            background-color: #b50503;
            border-color: #b50503;
          }
        }
      }
    }
  }
}

.final-success-modal {
  .modal-content {
    border-radius: 8px;
    border: none;
    padding: 10px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .success-icon {
    width: 80px;
    height: 80px;
    background-color: #4caf50;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
    text-align: center;
  }

  button {
    width: 48%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;

    &.btn-outline-primary {
      border-color: #b50503;
      color: #b50503;

      &:hover,
      &:focus {
        background-color: transparent;
        color: #b50503;
        border-color: #b50503;
      }
    }

    &.btn-primary {
      background-color: #b50503;
      border-color: #b50503;

      &:hover,
      &:focus {
        background-color: #b50503;
        border-color: #b50503;
      }
    }
  }
}

.walletTransaction_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.walletTransaction_wrapper {
  flex: 1;
  max-height: 100vh;
  overflow-y: auto;
}

.pill {
  display: inline-flex;
  align-items: center;
  padding: 0.2em 0.6em;
  border-radius: 50px;
  color: #fff;
  font-size: 0.9em;
}

.pill-requested {
  border: 1px solid #fea832;
  color: #fff;
}

.pill-redeem {
  border: 1px solid rgb(60, 157, 0);
}

.pill-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

.pill-dot-requested {
  background-color: #fea832;
}

.pill-dot-redeem {
  background-color: rgb(60, 157, 0);
}

.error-input {
  border-color: red;
}

.error-text {
  color: red;
  font-size: 0.875rem;
}
