.components {
  background: #e5e5e5;

  .studentHeader {
    padding-bottom: 20px;
  }
  .testsComponent {
    // padding-top: 135px;
    // padding-top: 98px;
    padding: 116px;
    margin: auto;
    padding-bottom: 50px;
  }
}
