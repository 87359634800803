.questionSwitcherWrrpr {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_side_wrapper {
    // width: calc(50% - 8px);
    width: calc(30%);
    margin-right: 16px;

    .btn_wrapper {
      text-align: left;

      button {
        border: none;
        outline: none;
        border-radius: 8px;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #6d48ef;
        padding: 10px;
        width: 100%;
        border: 1px solid #e2e2e2;
        // text-decoration: underline;
      }
    }
  }

  .right_side_wrapper {
    // width: calc(50% - 8px);
    width: calc(70%);

    .solution {
      margin-right: 16px;
      background: #ff933a !important;
    }

    .btn_wrapper {
      text-align: right;

      button {
        border: none;
        outline: none;
        border-radius: 8px;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        padding: 10px;
        width: 100%;
        background: #6d48ef;
        // text-decoration: underline;
      }
    }
  }
}
