.pyq_subjectTopicWrapper {
    padding-top: 50px;
    padding-bottom: 20px;

    .pyq_subjectTopicInner {
        padding: 20px;
        margin: 40px 0px;
        background-color: #fff;
        border-radius: 10px;
        min-height: 70vh;

        .backBtn {
            display: inline-block;
            cursor: pointer;

            img {}
        }

        .pyqSbjTpcListBox {
            .subject_name {
                text-align: center;
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 30px;
            }

            .subject_topic_list {
                max-width: 950px;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;

                .subject_topics {
                    background: #F5F4F8;
                    border-radius: 8px;
                    padding: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: calc(50% - 8px);
                    margin: 0px 16px 16px 0px;
                    cursor: pointer;

                    &:nth-child(2n+2) {
                        margin-right: 0px;
                    }

                    .sbjct_tpc_name {}

                    .arrow {
                        img {
                            width: 8px;
                        }
                    }
                }
            }
        }

        //istruction Screen
        .instructionScreenBox {
            .instruction_title {
                text-align: center;
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .instruction_detailBox {
                max-width: 750px;
                margin: 0 auto;

                .examTime {
                    border: 1px solid #FFDEC2;
                    background: #FF933A0D;
                    padding: 15px;
                    text-align: center;
                    border-radius: 10px;
                    margin-bottom: 20px;

                    .examTitle {
                        color: #F1A769;
                        margin-bottom: 8px;
                    }

                    .examDurations {}
                }

                .instructions {
                    border: 1px solid #FFDEC2;
                    border-radius: 10px;
                    background: #FF933A0D;
                    margin-bottom: 15px;

                    h3 {
                        font-size: 20px;
                        border-bottom: 1px solid #FFDEC2;
                        padding: 10px 15px;
                    }

                    .instPoint {
                        padding: 10px 15px;

                        p {
                            font-size: 14px;
                            color: #000;
                        }

                        h6 {}
                    }
                }

                .startTestBtn {
                    button {
                        background: #6D48EF;
                        width: 100%;
                        color: #fff;
                        padding: 7px 10px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}

@media (max-width:575.98px) {
    .pyq_subjectTopicWrapper {
        padding-top: 50px;

        .pyq_subjectTopicInner {
            margin: 20px 0px;
            padding: 10px;

            .pyqSbjTpcListBox {
                .subject_topic_list {
                    .subject_topics {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                    }
                }

                .subject_name {
                    font-size: 22px;
                }
            }
        }
    }
}