// .notesFeatureContentComponent_wrapper {
//   padding-top: 80px;
//   .notesFeatureContentComponent_inner_wrapper {
//     // padding: 20px;
//       // margin: 20px 0;
//       .sec_1_inner_wrapper {
//         display: flex;
//         justify-content: flex-start;
//         .btn_wrapper {
//           button {
//             outline: none;
//             border: none;
//             background: transparent;
//           }
//         }
//         .text_content_wrapper {
//           margin: 0 10px;
//           .text_content {
//             margin: 0;
//             font-size: 20px;
//             font-weight: 600;
//             color: #0f0404;
//           }
//         }
//       }
//     .notes_wrapper {
//       .notes_inner_wrapper {
//         // border: 1px solid blue;
//         // #end {
//         //   display: none !important;
//         // }

//         // position: relative;
//         //  height: 72vh;
//         // height: 100%;
//         // overflow-x: auto;
//         overflow-y: auto;
//        scrollbar-width: thin;

//         display: flex; /* Enable flexbox */
//         justify-content: center; /* Center horizontally */
//         align-items: center; /* Center vertically */
//         // .frame_box {
//         //   width: calc(100% - 15px);
//         //   height: 100%;
//         //   position: absolute;
//         //   top: 0px;
//         //   left: 0px;
//         // }

//         // width: 100%;
//         // border: 1px solid blue;
//         // background: lightcoral;


//         text-align: center;
//         // iframe {
//         //   width: 100%;
//         //   height: 600px;
//         // }
//         // .react-pdf__Document {
//         //   .react-pdf__Page {
//         //     canvas {
//         //       margin: 0 auto;
//         //     }
//         //   }
//         // }


        
//       }
//     }
//   }
// }

// // X-Small devices (portrait phones, less than 576px)
// @media (max-width: 575.98px) {
//   .notesFeatureContentComponent_wrapper {
//     padding-top: 60px;
//     .notesFeatureContentComponent_inner_wrapper {
//       padding: 20px 5px;
//       .notes_wrapper {
//         .notes_inner_wrapper {
//           iframe {
//             width: 100%;
//             height: calc(100vh - 60px - 40px);
//           }
//         }
//       }
//     }
//   }
// }

// #end {
//   display: none !important;
// }


.notesFeatureContentComponent_wrapper {
  padding: 20px;
  background-color: #f4f4f4;
}

.pdf-container {
   max-width: 100%;
  padding: 0 20px;
}

.notesFeatureContentComponent_inner_wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 100px;
  min-height: 90vh;
}
.sec_1_inner_wrapper {
  display: flex;
  justify-content: flex-start;
  .btn_wrapper {
    button {
      outline: none;
      border: none;
      background: transparent;
    }
  }
  .text_content_wrapper {
    margin: 0 10px;
    .text_content {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      color: #0f0404;
    }
  }
}



.pdf-header{
  display: flex;
  align-items: center;
  .btn_wrapper{
    width: fit-content;
    margin-right: 20px;
  }

  .headingContentWrapper{
    margin-top: -10 !important;

    .titleText{
      // background-color: yellow;
          font-size: 1.2rem;
    font-weight: bold;
    }
  }
}



// .btn_wrapper button img {
//   width: 24px;
//   height: 24px;
// }

// .text_content_wrapper{
//   display: inline-block;


// .text_content {

//   font-size: 1.5rem;
//   font-weight: bold;
// }
// }

.notes_wrapper {
  margin-top: 20px;
}

.notes_inner_wrapper {
  width: 100%;
  overflow-x: hidden; 
}

.zoom_controls {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.zoom_controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.zoom_controls button:hover {
  background-color: #0056b3;
}

.pdf-container {
  display: flex;
  justify-content: center;
}
