.test_result_component_wrapper {
  .test_result_component_inner_wrapper {
    background-color: #F5F4F8;
    min-height: 100vh;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .test_result_component_wrapper {
    .test_result_component_inner_wrapper {
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .test_result_component_wrapper {
    .test_result_component_inner_wrapper {
    }
  }
}
