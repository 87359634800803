.package_offering_component_wrapper {
  min-height: 100vh;
  margin: 10px 0;
  .package_offering_component_inner_wrapper {
    padding: 20px 15px;
    // padding: 20px 0;
    .sec_2_wrapper {
      margin: 0px 0px 30px 0px;
      .text_content_wrapper {
        padding: 10px 0;
        .text_content {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }
      }
      .package_collection_wrapper {
        .package_collection {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .single_package_wrapper {
            // flex: 0 0 32.5%;
            width: 32%;

            margin-right: 13px;
            margin-bottom: 13px;
            cursor: pointer;

            &:nth-child(3n + 3) {
              margin-right: 13px;
            }
            .single_package_inner_wrapper {
              border: 1px solid #ebebeb;
              border-radius: 10px;
              background-color: #f5f4f8;
              padding: 10px;
              display: flex;
              justify-content: space-between;
              .sec_1_wrapper {
                width: calc(100% - 148px);
                margin: 0px;
                .image_wrapper {
                  text-align: right;
                  img {
                    width: 20px;
                  }
                }
                .text_content_wrapper_1 {
                  .text_content_1 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: #000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // white-space: nowrap;
                    height: 50px;
                    margin-bottom: 5px;
                  }
                }

                .text_content_wrapper_3 {
                  margin-bottom: 18px;

                  .text_content_3 {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #707070;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 250px;
                  }
                }

                .btn_wrapper_1 {
                  span {
                    display: inline-block;
                    font-size: 20px;
                    font-weight: 600;
                  }
                }
              }

              .sec_2_wrapper {
                margin: 0;
                flex-direction: column;
                justify-content: space-between;
                display: flex;

                .content_wrapper_1 {
                  .text_content_wrapper {
                    .text_content {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 600;
                      color: #000000;
                      text-align: center;
                    }
                  }
                }

                .content_wrapper_2 {
                  .btn_wrapper_1 {
                    text-align: center;

                    button {
                      border: none;
                      outline: none;
                      background-color: #6d48ef;
                      font-size: 18px;
                      font-weight: 600;
                      color: #ffffff;
                      border-radius: 25px;
                      padding: 10px 15px;
                      width: 80%;
                    }
                  }

                  .text_content_wrapper_1 {
                    margin: 10px 0;

                    .text_content_1 {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #000000;
                    }
                  }

                  .text_content_wrapper_2 {
                    margin: 10px 0;
                    .text_content_2 {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #a02b2d;
                    }
                  }

                  .btn_wrapper_2 {
                    text-align: right;

                    button {
                      border: none;
                      outline: none;
                      background-color: transparent;
                      font-size: 15px;
                      font-weight: 500;
                      text-decoration: underline;
                      color: #000;
                    }

                    .course_type {
                      background: #ff933a;
                      padding: 2px 10px;
                      border-radius: 4px;
                      color: #fff;
                      font-size: 14px;
                    }
                  }
                }

                .purchase_btn {
                  width: 100%;
                  button {
                    background-color: #31d680;
                    padding: 5px 10px;
                    font-size: 12px;
                    color: #fff;
                    line-height: 14px;
                    border-radius: 4px;
                  }

                  .expiry_date {
                    color: #707070;
                    font-size: 10px;
                    padding: 7px 0px;
                    display: inline-block;
                    width: 110%;
                    width: 145px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .free_course_package {
      .text_content_wrapper {
        margin: 10px 0px;
        // margin: 10px 0px;

        .text_content {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }
      }

      .package_collection {
        display: flex;
        flex-wrap: wrap;

        .single_package_wrapper {
          flex: 0 0 32.5%;
          margin-right: 13px;
          margin-bottom: 13px;
          width: 32.5%;

          &:nth-child(3n + 3) {
            margin-right: 0px;
          }

          .single_package_inner_wrapper {
            border: 1px solid #ebebeb;
            border-radius: 10px;
            background-color: #f5f4f8;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            min-height: 120px;

            .sec_1_wrapper {
              width: calc(100% - 108px);
              margin: 0px;

              .image_wrapper {
                text-align: right;

                img {
                  width: 20px;
                }
              }

              .text_content_wrapper_1 {
                .text_content_1 {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 500;
                  color: #000;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  // white-space: nowrap;
                  height: 50px;
                  margin-bottom: 5px;
                }
              }
              .text_content_wrapper_3 {
                margin-bottom: 18px;
                .text_content_3 {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 500;
                  color: #707070;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .btn_wrapper_1 {
                span {
                  display: inline-block;
                  font-size: 20px;
                  font-weight: 600;
                }
              }
            }

            .sec_2_wrapper {
              margin: 0;
              flex-direction: column;
              justify-content: space-between;
              display: flex;
              text-align: right;
              .content_wrapper_1 {
                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 600;
                    color: #000000;
                    text-align: center;
                  }
                }
              }

              .content_wrapper_2 {
                .btn_wrapper_1 {
                  text-align: center;

                  button {
                    border: none;
                    outline: none;
                    background-color: #6d48ef;
                    font-size: 18px;
                    font-weight: 600;
                    color: #ffffff;
                    border-radius: 25px;
                    padding: 10px 15px;
                    width: 80%;
                  }
                }

                .text_content_wrapper_1 {
                  margin: 10px 0;

                  .text_content_1 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                    color: #000000;
                  }
                }

                .text_content_wrapper_2 {
                  margin: 10px 0;

                  .text_content_2 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                    color: #a02b2d;
                  }
                }

                .btn_wrapper_2 {
                  text-align: center;

                  button {
                    border: none;
                    outline: none;
                    background-color: transparent;
                    font-size: 15px;
                    font-weight: 500;
                    text-decoration: underline;
                    color: #000;
                  }
                }
              }

              .purchase_btn {
                a {
                  background-color: #31d680;
                  padding: 5px 10px;
                  font-size: 12px;
                  color: #fff;
                  line-height: 14px;
                  border-radius: 4px;
                  text-decoration: none;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .package_offering_component_wrapper {
    min-height: auto;
    margin: 5px 0;
    .package_offering_component_inner_wrapper {
      padding: 10px 0;
      .sec_1_wrapper {
        margin: 5px 0;
        .text_content_wrapper {
          .text_content {
          }
        }
      }
      .sec_2_wrapper {
        margin: 5px 0;
        .package_collection_wrapper {
          .package_collection {
            display: block;
            .single_package_wrapper {
              flex: 0 0 95%;
              margin-right: 0;
              &:nth-child(3n + 3) {
                margin-right: 0;
              }
              .single_package_inner_wrapper {
                .sec_1_wrapper {
                  .text_content_wrapper_1 {
                    .text_content_1 {
                    }
                  }
                  .text_content_wrapper_2 {
                    .text_content_2 {
                    }
                  }
                }
                .sec_2_wrapper {
                  .purchase_btn {
                  .expiry_date{
                    width: auto;
                  }
                  }
                  .content_wrapper_1 {
                    .text_content_wrapper {
                      .text_content {
                      }
                    }
                    .module_collection_wrapper {
                      .module_collection {
                        .text_content_wrapper {
                          .text_content {
                          }
                        }
                      }
                    }
                  }
                  .content_wrapper_2 {
                    .btn_wrapper_1 {
                      button {
                      }
                    }
                    .text_content_wrapper {
                      .text_content {
                      }
                    }
                    .btn_wrapper_2 {
                      button {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .free_course_package{
        .package_collection{
          display: block;
          .single_package_wrapper{
            width: 100%;
            margin-right: 0;
            min-height:70px;
           
            .single_package_inner_wrapper{
              .sec_1_wrapper{
                .text_content_wrapper_1 {
                  .text_content_1{
                    white-space: unset;
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
}
