.testsContentWrapper {
  margin-top: -35px;
  // background: red;
  .testsContentInrWrapper {
    padding: 0px 0px;

    .testsHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      border: 0.6px solid #aeaeae;
      border-radius: 12px;
      padding: 9px 14px;
      width: 90.3%;
      margin: 25px auto;
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // width: 14%;
        cursor: pointer;
        .leftSide {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .section-heading {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          color: #121212;

          display: flex;
          width: 100%;

          .paperNaame {
            margin-left: 10px;
            font-weight: 600;

            span {
              margin-right: 5px;
              color: #373737;
              font-weight: 400;
            }
          }

          .paperIdd {
            font-weight: 600;

            span {
              margin-right: 5px;
              color: #373737;
              font-weight: 400;
            }
            margin-left: 10px;
          }
        }
      }

      .right {
        width: 37%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .downloadReportBtn {
          button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 15px;
            background: #ffffff;
            border: 1px solid #6d48ef;
            border-radius: 6px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            color: #6d48ef;
            .text {
              margin-right: 10px;
            }
            img {
              width: 15px;
              height: 15px;
              margin-bottom: 7px;
            }
          }
        }
        .date {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #696969;
        }
        .obj-type {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #696969;
        }
      }

      .rightSide {
        text-align: right;
        width: 37%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .downloadReportBtn {
          button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 15px;
            background: #ffffff;
            border: 1px solid #6d48ef;
            border-radius: 6px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            color: #6d48ef;
            .text {
              margin-right: 10px;
            }
            img {
              width: 15px;
              height: 15px;
              margin-bottom: 7px;
            }
          }
        }
        .date {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #696969;
        }
        .obj-type {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #696969;
        }
      }
    }

    .tests_contentWpr {
      width: 90.3%;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 10px;

      .testsContentTabBox {
        .testsContentTab {
          margin-bottom: 30px;

          .testsContentUl {
            padding: 15px 15px;
            border-bottom: 1px solid #ebebeb;
            border-bottom: none !important;

            li {
              border: none;
              margin-right: 15px;

              button {
                border: none;
                background-color: #f5f4f8;
                border-radius: 10px;
                color: #707070;
                font-size: 16px;
              }

              .nav-link.active {
                color: #fff;
                background-color: #6d48ef;
                border: none;
              }
            }
          }
        }
        .testsTabContent {
          min-height: 65vh;
          padding: 15px 15px;
          max-width: 1030px;
          margin: 0 auto;
          .tab-content {
          }
        }
      }
    }
  }
}
