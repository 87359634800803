.chatbox {
  margin: 0 auto;
  width: 500px;
  border-radius: 10px;
  background-color: #f5f4f8;

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .message {
      .content {
        padding: 0px 30px;
        small {
          font-family: Poppins;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          color: #878787;
        }
      }

      img {
        margin-top: -80px;
        width: 24px;
      }
    }

    .buttons {
      width: 300px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
      // text-align: center;
      padding: 50px 0px;
      align-items: center;
      justify-content: center;

      button {
        margin-top: 10px;
        padding: 10px 20px;
        border: none;
        border-radius: 38px;
        background-color: #ffffff;
        color: #ff933a;
        cursor: pointer;
        border: 1px solid #ff933a;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.01em;
        &:hover {
          background: #ff933a;
          color: #ffffff;
        }
      }
    }

    .para1 {
      border-radius: 16px 16px 16px 0px;
      margin-top: 5px;
    }

    .para2 {
      margin-top: -5px;
      border-radius: 0px 16px 16px 16px;
    }

    p {
      padding: 8px 12px 8px 12px;
      box-shadow: 0px 2px 8px 0px #a3a3a314;
      background: #ffffff;
      width: 90%;
    }
  }

  .assistant {
    border-radius: 5px;
    margin-top: 20px;
    text-align: left;

    .message {
      padding: 20px 20px;
      .content {
        padding: 0px 30px;
        small {
          font-family: Poppins;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          color: #878787;
        }

        .para1 {
          border-radius: 16px 16px 16px 0px;
          margin-top: 5px;
        }

        .para2 {
          margin-top: -5px;
          border-radius: 0px 16px 16px 16px;
        }

        p {
          padding: 8px 12px 8px 12px;
          box-shadow: 0px 2px 8px 0px #a3a3a314;
          background: #ffffff;
          width: 90%;
        }
      }

      img {
        margin-top: -80px;
        width: 24px;
      }
    }

    .selectedOption {
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 0px 20px;
      .option {
        background: #ff933a;
        width: fit-content;
        padding: 8px 20px 8px 20px;
        color: #ffffff;
        border-radius: 16px 16px 0px 16px;
      }
    }

    .selectedOption2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 40px;
      padding: 0px 20px;
      min-width: 10%;
      .option2 {
        background: #ff933a;
        margin-top: 10px;
        width: fit-content;
        padding: 8px 20px 8px 20px;
        color: #ffffff;
        border-radius: 16px 16px 0px 16px;
        overflow-wrap: break-word;
        max-width: 300px;
      }
    }

    .kidlyState {
      text-align: center;
      margin-bottom: 25px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      color: #6b6b6b;
    }

    .confirmationMessage {
      font-family: Poppins;
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: center;
      color: #6b6b6b;
      margin-bottom: 20px !important;
      width: 70%;
      margin: 0px auto;
    }

    .custom-message-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      padding: 20px;
      background-color: #ececec;
      textarea {
        flex-grow: 1;
        border: none;
        outline: none;
        border-radius: 5px;
        background: none;
        min-height: 20px;
        overflow: hidden;
        resize: none;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #121212;
        &::placeholder {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          color: #adadad;
        }
      }

      img {
        display: inline;
        margin-left: 10px;
        cursor: pointer;
        width: 35px;
        height: 35px;
      }
    }

    .buttons {
      margin-top: 20px !important;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      width: 370px;
      margin: 0px auto;
    }

    button {
      margin-top: 5px;
      padding: 10px 20px;
      border: none;
      border-radius: 38px;
      background-color: #ffffff;
      color: #ff933a;
      cursor: pointer;
      border: 1px solid #ff933a;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.01em;
      &:hover {
        background: #ff933a;
        color: #ffffff;
      }
    }

    .other-button {
      .other-btn {
        color: #ff933a;
        text-decoration: underline;
        text-align: center;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    .backbtn {
      text-align: center;
      margin-top: 20px;
      padding-bottom: 30px;
      button {
        border: 1px solid #7e7e7e;
        color: #7e7e7e;
        background: #ffffff;
        &:hover {
          background: none;
        }
      }
    }

    input[type="text"] {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-top: 10px;
    }

    button[type="button"] {
      display: block;
      margin-top: 10px;
    }
  }
}
