.studentProfile {
  padding-top: 115px;
  width: 90%;
  margin: auto;
  .Card {
    background: #ffffff;
    // background: red;
    border: 1px solid #d2d2d2;
    border-radius: 12px;
    width: 100%;
    .card-body {
      // padding: 3%;
      padding: 2% 2%;
      .left {
        flex: 8;
        .profile-img {
          margin-bottom: -144px;

          img {
            // height: 63%;
            height: 205px;
            width: 205px;
            border-radius: 50%;
          }
        }

        .profile-content {
          margin-left: 25px;
          width: 100%;
          h2 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.02em;
            color: #6d48ef;
          }

          ul {
            width: 100%;

            padding: 0px;
            margin-left: 29px;
            li {
              list-style: none;
              margin-left: -28px;
              display: flex;

              .lft-li {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #717171;
                width: 70px;
              }
              .rght-li {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #333333;
              }
            }
          }
        }
      }
      .right {
        flex: 6.5;
        gap: 1.2rem;
        justify-content: center;
        align-items: center;

        .reportCard {
          width: 35%;
          border-radius: 6px;
          height: 169.5px;
          .top {
            background: rgba(255, 255, 255, 0.8);
            text-align: center;
            padding: 17px 10px;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            color: #2b0e0e;
          }
          .btm {
            margin-bottom: -17px;
            border-bottom-left-radius: 5.5px;
            border-bottom-right-radius: 5.5px;
            text-align: center;
            // padding: 22px 10px;
            padding: 13px 10px;

            .num {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              // font-size: 23px;
              font-size: 20px;
              line-height: 38px;
              letter-spacing: 0.1em;
              margin-bottom: 4px;

              color: #000000;
            }
            .text {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              color: #3e3e3e;
            }
          }
        }

        .totalTests {
          background: #9dcad0;
          border: 1px solid #63bfcc;
          box-shadow: 2px 2px 8px 2px rgba(157, 202, 208, 0.24);
        }

        .appeared {
          background: #afce95;
          border: 1px solid #73bb37;
          box-shadow: 2px 2px 8px 2px rgba(175, 206, 149, 0.24);
        }

        .attendance {
          background: #e1d198;
          border: 1px solid #e1d198;
          box-shadow: 2px 2px 8px 2px rgba(225, 209, 152, 0.24);
          .btm {
            text-align: start !important;
            .classes {
              .text {
                padding-right: 20px;
              }
              .num {
                width: 50px;
              }
            }

            .atndce {
              .text {
                padding-right: 7px;
              }
              .num {
                margin-left: 5.5px;
              }
            }
          }
        }
      }
    }
  }
}
