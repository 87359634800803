.userProfileContentComponent_wrapper {
  padding-top: 80px;
  .userProfileContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        background-color: #ffffff;
        padding: 20px;
        border-radius: 10px;
        .content_wrapper {
          display: flex;
          justify-content: space-between;
          .left_side_content_wrapper {
            flex: 0 0 30%;
            .image_background {
              background: url("../../../../utilities/images/user_profile/user_profile_2.png");
              background-repeat: no-repeat;
              background-size: cover;
              border-radius: 10px;
              .image_wrapper {
                height: 300px;
                position: relative;
                background: transparent;

                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 190px;
                  height: 190px;
                  border-radius: 50%;
                }
              }
            }
          }
          .right_side_content_wrapper {
            flex: 0 0 70%;
            padding: 0 15px;
            .content_wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px 0;
              .text_content_wrapper_1 {
                .text_content_1 {
                  margin: 0;
                  font-size: 20px;
                  font-weight: bold;
                  color: #0f0404;
                }
              }
              .btn_wrapper {
                button {
                  border: none;
                  outline: none;
                  background: none;
                  img {
                  }
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 500;
                  color: #6b6876;
                }
              }
              .text_content_wrapper_3 {
                .text_content_3 {
                  margin: 0;
                  font-size: 16px;
                  text-align: right;
                  color: #3a3648;
                }
              }
            }
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      .sec_2_inner_wrapper {
        background-color: #ffffff;
        padding: 20px;
        border-radius: 10px;
        .text_content_wrapper {
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: bold;
            color: #0f0404;
          }
        }
        .package_collection_wrapper {
          margin-top: 20px;
          .single_package_wrapper {
            display: flex;
            justify-content: flex-start;
            background-color: #faf9fe;
            padding: 10px;
            border-radius: 10px;
            .left_side_content_wrapper {
              .image_wrapper {
                img {
                  border-radius: 5px;
                  width: 100px;
                }
              }
            }
            .right_side_content_wrapper {
              padding: 0 10px;
              .text_content_wrapper_1 {
                .text_content_1 {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 500;
                  color: #0f0404;
                }
              }
              .text_content_wrapper_2 {
                margin: 5px 0;
                .text_content_2 {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 500;
                  color: #ff2121;
                }
              }
              .text_content_wrapper_3 {
                margin: 5px 0;
                .text_content_3 {
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: #ffffff;
                  label {
                    padding: 5px 10px;
                    border-radius: 15px;
                    background-color: #f99746;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .userProfileContentComponent_wrapper {
    .userProfileContentComponent_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .content_wrapper {
            .left_side_content_wrapper {
              flex: 0 0 40%;
              .image_background {
                .image_wrapper {
                  img {
                  }
                }
              }
            }
            .right_side_content_wrapper {
              flex: 0 0 60%;
              .content_wrapper {
                .text_content_wrapper_1 {
                  .text_content_1 {
                  }
                }
                .btn_wrapper {
                  button {
                    img {
                    }
                  }
                }
                .text_content_wrapper_2 {
                  .text_content_2 {
                  }
                }
                .text_content_wrapper_3 {
                  .text_content_3 {
                  }
                }
              }
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .text_content_wrapper {
            .text_content {
            }
          }
          .package_collection_wrapper {
            .single_package_wrapper {
              .left_side_content_wrapper {
                .image_wrapper {
                  img {
                  }
                }
              }
              .right_side_content_wrapper {
                .text_content_wrapper_1 {
                  .text_content_1 {
                  }
                }
                .text_content_wrapper_2 {
                  .text_content_2 {
                  }
                }
                .text_content_wrapper_3 {
                  .text_content_3 {
                    label {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .userProfileContentComponent_wrapper {
    .userProfileContentComponent_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .content_wrapper {
            .left_side_content_wrapper {
              .image_background {
                .image_wrapper {
                  img {
                  }
                }
              }
            }
            .right_side_content_wrapper {
              .content_wrapper {
                .text_content_wrapper_1 {
                  .text_content_1 {
                  }
                }
                .btn_wrapper {
                  button {
                    img {
                    }
                  }
                }
                .text_content_wrapper_2 {
                  .text_content_2 {
                  }
                }
                .text_content_wrapper_3 {
                  .text_content_3 {
                  }
                }
              }
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .text_content_wrapper {
            .text_content {
            }
          }
          .package_collection_wrapper {
            .single_package_wrapper {
              .left_side_content_wrapper {
                .image_wrapper {
                  img {
                  }
                }
              }
              .right_side_content_wrapper {
                .text_content_wrapper_1 {
                  .text_content_1 {
                  }
                }
                .text_content_wrapper_2 {
                  .text_content_2 {
                  }
                }
                .text_content_wrapper_3 {
                  .text_content_3 {
                    label {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userProfileContentComponent_wrapper {
    padding-top: 60px;
    .userProfileContentComponent_inner_wrapper {
      padding: 20px 10px;
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          padding: 10px;
          .content_wrapper {
            flex-wrap: wrap;
            .left_side_content_wrapper {
              flex: 0 0 100%;
              margin: 5px 0;
              .image_background {
                .image_wrapper {
                  height: 220px;
                  img {
                  }
                }
              }
            }
            .right_side_content_wrapper {
              flex: 0 0 100%;
              margin: 5px 0;
              padding: 0 5px;
              .content_wrapper {
                .text_content_wrapper_1 {
                  .text_content_1 {
                  }
                }
                .btn_wrapper {
                  button {
                    img {
                    }
                  }
                }
                .text_content_wrapper_2 {
                  .text_content_2 {
                  }
                }
                .text_content_wrapper_3 {
                  .text_content_3 {
                  }
                }
              }
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          padding: 10px;
          .text_content_wrapper {
            .text_content {
            }
          }
          .package_collection_wrapper {
            .single_package_wrapper {
              padding: 5px;
              .left_side_content_wrapper {
                .image_wrapper {
                  img {
                  }
                }
              }
              .right_side_content_wrapper {
                .text_content_wrapper_1 {
                  .text_content_1 {
                  }
                }
                .text_content_wrapper_2 {
                  .text_content_2 {
                  }
                }
                .text_content_wrapper_3 {
                  .text_content_3 {
                    label {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
