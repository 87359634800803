.ots_wrapper {
  .otsTabBox {
    display: flex;

    ul {
      border: none;
      margin: 0 auto;
      width: max-content;
      background-color: #f5f4f8;
      border-radius: 22px;
      overflow: hidden;

      li {
        border: none;

        button {
          border: none;
          color: #000;
          font-size: 15px;
          min-width: 120px;
          border-radius: 0px;
          min-width: 200px;
        }

        .nav-link.active {
          color: #fff;
          background-color: #ff933a;
          border: none;
        }
      }
    }
  }

  .otsTabContent {
    min-height: 65vh;
    padding: 15px 15px;

    .ots_test_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1020px;

      // width: 90%;
      margin-top: 10px;

      .ots_test_list_item {
        background: #f5f4f8;
        border-radius: 4px;
        margin-bottom: 40px;
        // padding-right: 15px;
        width: calc(50% - 8px);
        .ots_test_list_content {
          display: flex;

          .ots_test_list_img {
            padding: 20px 10px 10px 10px;
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
          }

          .ots_test_list_text {
            width: 390px;
            height: 120px;
            display: flex;
            padding: 20px 10px 10px 10px;

            .ots_test_list_left {
              width: 70%;
              pointer-events: none;

              .ots_test_list_title {
                font-family: "Poppins";
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #5125ee;
              }

              .ots_test_list_exam {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                color: #000000;
                margin-top: 5px;
              }

              .ots_test_list_paragraph {
                padding-right: 10px;
                margin-top: 15px;

                p {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;

                  color: rgba(33, 33, 33, 0.6);

                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: -webkit-box !important;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  white-space: normal;
                }
              }
            }
            .ots_test_active {
              pointer-events: auto !important;
              cursor: pointer;
            }

            .ots_test_list_right {
              // background: red;
              width: 30%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .ots_test_list_planner_icon {
                cursor: pointer;
                // margin-right: 10px;
              }

              .ots_test_list_planner_price {
                // background: gray;
                .ots_test_list_price {
                  margin-top: 15px;
                  // margin-left: 10px;
                  margin-bottom: 5px;

                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 22px;

                  // color: #880000;
                  width: 100%;

                  .ots_price_strike {
                    text-decoration: line-through;
                    color: #880000;
                  }

                  .ots_discount_price {
                    // color: #00bc5b;
                    color: #095a30;
                  }

                  .ots_paid_price {
                    // color: #095a30;
                    color: #880000;
                  }
                }

                .ots_test_list_paynow {
                  // margin-left: 5px;
                  float: right;
                  span {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #ffffff;
                    padding: 4px 8px;
                    background: #4f67ff;
                    border-radius: 4px;
                  }
                }

                .free {
                  margin-top: 35px;

                  span {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;

                    letter-spacing: 0.01em;

                    color: #ffffff;
                    padding: 4px 10px;

                    //  color:#00bc5b;
                    background: linear-gradient(
                      90deg,
                      #00bc5b 2.81%,
                      #007e3d 78.21%
                    );
                    border-radius: 2px;
                  }
                }
                .paid {
                  margin-top: 35px;

                  span {
                    // font-family: "Poppins";
                    // font-style: normal;
                    // font-weight: 500;
                    // font-size: 14px;
                    // line-height: 21px;

                    // color: #ffffff;

                    // background: #ff7a0c;
                    // border-radius: 4px;
                    // padding: 4px 10px;
                    // float: right;
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;

                    letter-spacing: 0.01em;

                    color: #ffffff;
                    padding: 4px 10px;

                    //  color:#00bc5b;
                    background: linear-gradient(
                      90deg,
                      #00bc5b 2.81%,
                      #007e3d 78.21%
                    );
                    border-radius: 2px;
                  }
                }
              }
            }
          }
        }
      }

      .ots_no_data_found_wrapper {
        margin: auto;
        img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}

.ots_view_planner_wrapper {
  margin-bottom: 20px;
  .ots_view_planner {
    background: #ffffff;
    border: 0.6px solid #a8a8a8;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 15px;

    .ots_view_planner_header {
      display: flex;
      justify-content: space-around;
      align-items: center;
      // background: red;

      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        padding: 10px;
        margin-bottom: -20px;
      }

      .test_free_paid {
        margin-top: 15px;
        .test_free {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;

          color: #ffffff;

          background: #00bc5b;
          box-shadow: 2px 2px 6px rgba(0, 188, 91, 0.1);
          border-radius: 2px;
          padding: 3px 7px;
        }
        .test_paid {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          background: #b92100;
          box-shadow: 2px 2px 6px rgba(0, 188, 91, 0.1);
          border-radius: 2px;
          padding: 3px 7px;
        }
      }
    }

    ul {
      margin: 0px;
      padding: 0px;
      margin-top: 10px;

      li {
        list-style: none;
        margin-bottom: 10px;
        text-align: start !important;
        margin-left: 50px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #646464;
        width: 100%;
      }
      span {
        color: #000000;
        font-weight: 600;
      }
    }
  }

  table {
    width: 70%;
    text-align: center;
    border-collapse: collapse;
    background: #ffffff;
    border: 0.6px solid #a8a8a8;
    border-radius: 4px;
    margin-bottom: 55px;

    th {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      border: 0.6px solid #a8a8a8;
      color: #ffffff;
      background: linear-gradient(90.62deg, #6d48ef 0%, #5025ee 99.99%);
      padding: 7px;
    }
    td {
      width: 135px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      border: 0.6px solid #a8a8a8;
      color: #5c5c5c;
      padding: 10px;
    }
  }
}

.ots_no_data_text {
  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    padding: 10px;
    margin-bottom: -20px;
    text-align: center;
  }
}


@media (max-width: 575.98px) {
  .ots_wrapper {
    .otsTabBox {
      display: flex;
      ul {
        li {
          button {
            min-width: 10px;
            width: auto;
          }
          .nav-link.active {
          }
        }
      }
    }
    .otsTabContent {
      .ots_test_list {
        .ots_test_list_item{
          width: 100%;
        }
      }
    }
  }
}