

.live-streaming-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
  // padding:5rem 8rem 0 8rem;

  

}
