.modal {
  .my-modal {
    .ots-syllabus-modal-wrapper {
      border: 0.6px solid #a8a8a8;
      border-radius: 4px;
      text-align: center;
      margin: 0px 64px 53px 65px;

      .ots-syllabus-modal_header {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
        padding: 10px 0px;
        border-bottom: 0.5px solid #e3e3e3;
      }

      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        padding: 10px;
        margin-bottom: -20px;
      }
      ul {
        margin: 0px;
        padding: 0px;
        margin-top: 15px;

        li {
          list-style: none;
          margin-bottom: 10px;
          text-align: start !important;
          margin-left: 50px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #646464;
          width: 100%;
        }
        span {
          color: #000000;
          font-weight: 600;
        }
      }
    }

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      background: #ffffff;
      border: 0.6px solid #a8a8a8;
      border-radius: 4px;

      th {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        border: 0.6px solid #a8a8a8;
        color: #ffffff;
        background: linear-gradient(90.62deg, #6d48ef 0%, #5025ee 99.99%);
        padding: 7px;
      }
      td {
        width: 135px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        border: 0.6px solid #a8a8a8;
        color: #5c5c5c;
        padding: 10px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .modal {
    .my-modal {
      .ots-syllabus-modal-wrapper {
        margin: 0px 0 20px 0;
        ul{
          li{
            margin-left: 0;
            padding-left: 5px;
          }
        }
      }}}
}
