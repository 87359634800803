.OtsAnalytics_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1020px;

  margin-top: 10px;

  .OtsAnalytics_test_item {
    background: #f5f4f8;

    padding: 20px;

    // width: 500px;
    width: calc(50% - 8px);
    margin-bottom: 40px;
    .OtsAnalytics_test_heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      .OtsAnalytics_test_name {
        font-family: "Poppins";
        font-style: "SemiBold";
        font-weight: 600;
        line-height: 21px;
        font-size: 14px;
        color: #000000;
      }

      .OtsAnalytics_test_icons {
        display: flex;
        justify-content: space-around;
        width: 90px;
        img {
          cursor: pointer;
        }
      }
    }

    .OtsAnalytics_test_course {
      font-size: 12px;
      font-weight: 400;
      font-family: "Poppins";
      font-style: "Regular";
      line-height: 18px;
      color: #4d4d4d;
      margin-bottom: 12px;
    }

    .OtsAnalytics_test_que_mins_marks ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #232323;
        font-size: 12px;
        margin-bottom: 7px;
      }

      :nth-child(2) {
        margin-left: 5px;
      }
      :nth-child(3) {
        margin-left: 5px;
      }
    }

    .OtsAnalytics_test_attemptedDate {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #5d5d5d;
      margin-bottom: 20px;
    }

    .OtsAnalytics_test_buttons {
      .OtsAnalytics_test_ViewAnalytics_button {
        background: #00bc5b;
        box-shadow: 0px 4px 12px rgba(66, 38, 15, 0.15);
        border-radius: 20px;

        padding: 6px 10px 6px 10px;
        margin-right: 15px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.01em;
      }

      .OtsAnalytics_test_ViewAnalytics_button_fade {
        box-shadow: 0px 4px 12px rgba(66, 38, 15, 0.15);
        border-radius: 20px;
        padding: 6px 10px 6px 10px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.01em;
      }
    }
  }
  .ots_no_data_found_wrapper {
    margin: auto;
    img {
      width: 150px;
      height: 150px;
    }
  }
}

@media (max-width: 575.98px) {
  .OtsAnalytics_container {
    .OtsAnalytics_test_item {
      width: 100%;
      margin-bottom: 20px;
    }
  }  
}
