.acpsBackBtn {
  cursor: pointer;
  display: inline-block;
  margin-left: -120px !important;
  margin-top: 10px;
}
.containerr {
  margin-top: 10px;
  .Acps_Wrapper {
    .Acps_contentWpr {
      .Acps_header {
        text-align: center;
        font-family: "Poppins";
        font-style: normal;
        margin-bottom: 20px;
        margin-top: -30px;
        .Acps_heading {
          font-weight: 500;
          font-size: 30px;
          line-height: 45px;
          color: #000000;
          margin-bottom: 10px;
        }
        p {
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #707070;
          width: 53%;
          margin: 0 auto 30px auto;
        }
      }

      .adaptive-cps-containers {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 2rem;
        .adaptive-cps-subjects {
          // width: calc(50% - 0px);
          width: 100%;
          padding: 30px;
          background: #f5f4f8;
          border-radius: 8px;
        }
        .adaptive-cps-banner {
          // width: calc(50% - 0px);
          width: 50%;
        }
      }
    }
  }
}
