.faqsContentComponent_wrapper {
  padding-top: 40px;

  .faqsContentComponent_inner_wrapper {
    .heading_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .heading_text_content {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.33px;
        color: #000;
        display: inline-block;
      }

      .contact_btn {
        span {
          font-size: 12px;
        }

        .cnt {
          border: 1px solid #FF933A;
          border-radius: 6px;
          padding: 5px 10px;
          font-size: 14px;
          margin-left: 10px;
          text-decoration: none;
          color: #000;
        }
      }
    }

    .tab_accordion_section {
      margin: 30px 0;

      .tab_accordion_wrapper {
        .customAccordion {
          .custom_accordion_item {
            border: 0;
            margin-bottom: 15px;
            background: #f5f4f8;
            border-radius: 6px;

            h2 {
              button {
                background: #f5f4f8;
                border-radius: 6px;
                box-shadow: none;

                &:focus {
                  box-shadow: none;
                }
              }

              .accordion-button:not(.collapsed) {
                color: #000;
              }

              .accordion-button:not(.collapsed)::after {
                background-image: url(../../../../../utilities/images/homepage/right_Ar.png);
                width: 8px;
                background-size: contain;
                transform: rotate(90deg);
              }

              .accordion-button::after {
                background-image: url(../../../../../utilities/images/homepage/right_Ar.png);
                width: 8px;
                background-size: contain;
                transform: rotate(-90deg);
              }
            }

            .custom_accordion_header {}

            .custom_accordion_collapse {
              .custom_accordion_body {
                letter-spacing: 0.28px;
                color: #707070;
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .faqsContentComponent_wrapper {
    padding-top: 70px;

    .faqsContentComponent_inner_wrapper {
      .heading_wrapper {
        flex-direction: column;

        .heading_text_content {
          margin-bottom: 20px;
        }
      }
    }
  }
}