/* Utility classes for flex properties */

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-hori-center {
  display: flex;
  justify-content: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.package-price-details {
  // position: fixed;
  background: #fff;
  padding: 1rem 1rem 0.813rem 1rem;
  border-radius: 0.5rem;

  .referal {
    margin-bottom: 1rem;
    img {
      height: 24.63963px;
      width: 24.63963px;
      margin-right: 0.577rem;
    }

    .referal-descript {
      .hve-referal-code {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        letter-spacing: 0.01em;
        color: #121212;
        margin: 0;
      }

      .about-coins {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        color: #464646;

        .coins-count {
          font-weight: 600;
          color: #ff7300;
        }
      }
    }
  }

  .referal-input {
    position: relative;
    width: 100%;
    border-radius: 2px;
    border: 0.05rem solid #9b9b9b;
    height: 47px;

    input {
      outline: none !important;
      height: 98%;
      padding: 13px 16px 13px 16px;
      border: none;
    }

    input::placeholder {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.313rem;
      letter-spacing: 0em;
      color: #949494;
    }

    input:focus {
      outline: none !important;
    }

    .referal-status {
      position: absolute;
      right: 10%;
      top: 25%;

      span, button {
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.313rem;
        letter-spacing: 0em;
        color: #ff7300;
        right: 10%;
        top: 25%;
      }

      img {
        width: 23px;
        height: 23px;
        right: 10%;
        top: 25%;
      }

      .referal-custom-spinner {
        position: relative;
        top: -4px;
        color: #ff7300;
      }
    }
  }

  .price-details {
    margin-top: 1.25rem;
    .price-details-text {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.188rem;
      letter-spacing: 0em;
      margin-bottom: 0.5rem;

      p {
        margin: 0;
      }
    }

    .package-price {
      margin: 0;
      font-size: 0.875rem;
      line-height: 1.313rem;
      justify-content: space-between;
      margin-bottom: 0.438rem;

      p {
        color: #414141;
        font-size: 12px;
        font-weight: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: 0em;
        margin: 0;
      }
    }

    .package-discount {
      color: #2a7f42;
      justify-content: space-between;
      margin-bottom: 0.625rem;
      border-bottom: 0.038rem solid #ececec;

      p {
        color: #414141;
        font-size: 12px;
        font-weight: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: 0em;
        margin: 0;
      }
    }

    .package-final-price {
      font-size: 0.875rem;
      line-height: 1.313rem;
      justify-content: space-between;
      color: #121212;
      margin-bottom: 1.75rem;
      font-weight: bold;

      p {
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.125rem;
        letter-spacing: 0em;
        margin: 0;
      }
    }
  }

  .package-terms-conditions {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0em;
    padding-bottom: 1.75rem;
    border-bottom: 0.05rem solid #f0f0f0;

    img {
      height: 20px;
      width: 20px;
      margin-right: 0.5rem;
    }

    .terms-condition {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.125rem;
      letter-spacing: 0em;
      text-decoration: underline;
      color: #2e68be;
      margin-left: 0.2rem;
      cursor: pointer;
    }
  }

  .package-valid-date {
    color: #494949;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.188rem;
    letter-spacing: 0em;
    margin: 0.5rem 0 0.625rem 0;
    text-align: center;

    span {
      color: #121212;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.125rem;
      letter-spacing: 0em;
    }
  }

  .pkg-buy-now-btn {
    color: #ffffff;
    background-image: linear-gradient(to bottom, #7c54f6, #5026d3);
    padding: 10px 28px 10px 28px;
    border-radius: 4px;
    width: 100%;
  }
}
