.referalListwrppr {
  margin-top: 1rem;
  background: #ffffff;
  padding: 0.875rem 1rem 0.875rem 1rem;
  max-height: 51.5rem;
  min-height: 20rem;

  overflow-y: auto;
  scrollbar-width: thin;

  .referalListInnerWrapper {
    .redeem_cash_btn {
      width: 100%;
      margin-bottom: 0.5rem;

      button {
        color: #fff;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 0.25663rem;
        background: #b50303;
        box-shadow: 0px 2.053px 6.16px 0px rgba(0, 0, 0, 0.12);
        padding: 0.75rem 0rem;
        text-align: center;
        cursor: pointer;
        width: 100%;
      }
    }
    .referalListTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        .text {
          color: #121212;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .right {
        color: #b50503;
        font-family: Poppins;
        font-size: 0.6875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
      }
    }
    .lists {
      margin-top: 20px;

      .noreferal_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 20rem;

        img {
          width: 2.5rem;
          height: 2.5rem;
        }

        .noreferal_text {
          color: #323232;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

.referal_list_large {
  height: 78vh;
  position: relative;

  .referalListInnerWrapper {
    height: 85%;
    overflow-y: auto;
    scrollbar-width: thin;

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      .backBtnContent {
        display: flex;
        align-items: center;
        padding: 10px;

        .backBtn {
          margin-right: 20px;
          cursor: pointer;

          img {
            width: 30px;
            height: auto;
          }
        }
      }
      .text {
        color: #000;
        font-family: Poppins;
        font-size: 1.3125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.01313rem;
      }
    }

    .right {
      img {
        width: 1.7rem;
        height: 1.7rem;
        margin-right: 1.5rem;
      }
    }
    .redeem_cash_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      button {
        color: #fff;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 0.25663rem;
        background: #b50303;
        box-shadow: 0px 2.053px 6.16px 0px rgba(0, 0, 0, 0.12);
        width: 20.5rem;
        padding: 0.75rem 0rem;
        text-align: center;
        cursor: pointer;
        bottom: 1.5rem;
        position: absolute;
      }
    }

    .lists {
      .noreferal_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          width: 3.25rem;
          height: 3.25rem;
        }

        .noreferal_text {
          color: #323232;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
