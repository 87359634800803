.rewardModal{
    border-radius: 0.3rem;
  outline: 10px solid #6D48EF;
  .coinText{
    font-weight: 600;
    font-size: 13px;
    line-height: 40px;
    color: #000000;
  }
  .coinText1{
    font-weight: 500;
    font-size: 18px;
    // line-height: 27px;
    text-align: center;
    color: #000000;
  }
  .coinNum{
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    color: #2D2D2D;
  }
  .coinBtn{
    background: #6D48EF;
  border-radius: 8px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem ;
  color: #ffffff;
  text-decoration: none;
  }
  .coinText3{
    font-family: 'Poppins';
    font-weight: 400;
    span{
        text-decoration: underline;
        color: #0F3DE1;
        cursor: pointer;
    }
  }
}