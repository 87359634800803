.user_dashboard_wrapper {
  .user_dashboard_inner_wrapper {
    .user_dashboard_left_wrapper {
      // flex: 0 0 220px;
    }
    .user_dashboard_right_wrapper {
      
    }
  }
}
.coinModal{
  // background: #6D48EF;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.3rem;
  outline: 10px solid #6D48EF;
}
.coinModal .coinText{
  font-weight: 600;
  font-size: 13px;
  line-height: 40px;
  color: #000000;
}
.coinModal .coinText1{
  font-weight: 500;
  font-size: 18px;
  // line-height: 27px;
  text-align: center;
  color: #000000;
}
.coinModal .coinNum{
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #2D2D2D;
}
.coinClsClass{
  top: -22px !important;
  right: -19px !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 24px !important;
  background-size: 0.8em !important;
  opacity: 1 !important;
}
.coinModal .coinWelcome{
  position: relative;
    top: -5rem;
    margin-bottom: -3rem;
}
.coinModal .coinBtn{
  background: #6D48EF;
border-radius: 8px;
padding: 0.5rem 1.5rem;
font-size: 1rem ;
color: #ffffff;
}
.coinDetailsPopup p{
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}
.coinDetailsPopup .coinHeading{
font-weight: 600;
font-size: 1.2rem;
margin-bottom: 0;
}
.coinDetailsPopup ul{
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin-bottom: 1.5rem;
}
.coinDetailsPopup ul li{
  margin-bottom: 0.35rem;
}
.coinDetailsPopup p:last-child{
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.5rem;
  color: #000000;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .user_dashboard_wrapper {
    .user_dashboard_inner_wrapper {
      .user_dashboard_left_wrapper {
        flex: 0 0 190px;
      }
      .user_dashboard_right_wrapper {
        flex: 0 0 calc(100% - 190px);
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .user_dashboard_wrapper {
    .user_dashboard_inner_wrapper {
      .user_dashboard_left_wrapper {
        flex: 0 0 160px;
      }
      .user_dashboard_right_wrapper {
        flex: 0 0 calc(100% - 160px);
      }
    }
  }
}
