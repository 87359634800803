.ACpsTopicWiseWrapper {
  display: flex;
  flex-wrap: wrap;
  .section-heading {
    margin: 0 auto 10px auto;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .ACpsTopicBox {
    background: #ffffff;
    box-shadow: 2px 2px 4px 0px #5959591f;
    box-shadow: 2px 2px 4px 0px #5959591f;
    border-radius: 4px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(50% - 60px);
    cursor: pointer;
    box-sizing: content-box;

    &:nth-child(2n + 2) {
      margin-right: 0px;
    }

    .ACpsTopicNameImg {
      .ACpsImg_wrp {
        display: inline-block;
        margin-right: 15px;
        width: 32px;

        img {
          width: 100%;
        }
      }

      .name {
        display: inline-block;
        margin: 0px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }

    .arrow {
      img {
        width: 9px;
      }
    }
  }

  /* Remove margin-bottom from all .ACpsTopicBox elements */
  .ACpsTopicBox {
    margin-top: 16px;
  }
}

@media (max-width: 991.98px) {
  .topicWiseWrapper {
    .topicBox {
      padding: 10px;
    }
  }
}

@media (max-width: 575.98px) {
  .topicWiseWrapper {
    .topicBox {
      margin: 0px 0px 10px 0px;
      width: 100%;

      .topicNameImg {
        h5 {
          font-size: 19px;
        }
      }
    }
  }
}
