.user_bookmarks_wrapper {
  .user_bookmarks_inner_wrapper {
    .user_bookmarks_left_wrapper {
     
    }
    .user_bookmarks_right_wrapper {
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .user_bookmarks_wrapper {
    .user_bookmarks_inner_wrapper {
      .user_bookmarks_left_wrapper {
        flex: 0 0 190px;
      }
      .user_bookmarks_right_wrapper {
        flex: 0 0 calc(100% - 190px);
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .user_bookmarks_wrapper {
    .user_bookmarks_inner_wrapper {
      .user_bookmarks_left_wrapper {
        flex: 0 0 160px;
      }
      .user_bookmarks_right_wrapper {
        flex: 0 0 calc(100% - 160px);
      }
    }
  }
}
