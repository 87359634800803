.player {
  display: flex;
  /* height: calc(45vw * 0.567); */
  overflow-x: hidden;
  /* background-color: #000; */
  /* height: 300px !important;
  width: 700px !important; */
}

.vjs-playback-rate .vjs-playback-rate-value {
  pointer-events: auto !important;
  font-size: 1.3em !important;
  line-height: 2.5 !important;
}

.noLiveVideo .vjs-current-time,
.noLiveVideo .vjs-current-time {
  display: block !important;
}

.noLiveVideo .vjs-time-divider {
  display: block !important;
}

.vjs-time-divider div span {
  margin-top: 0px;
  position: absolute;
}
.noLiveVideo .vjs-duration,
.noLiveVideo .vjs-duration {
  display: block !important;
}

.player {
  position: relative;
}
/* 
.currentTimeDisplay {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
} */

.vjs-remaining-time span {
  display: none;
}

.vjs-button > .vjs-icon-placeholder {
  margin-top: 12px;
}

.video-js .vjs-control-bar {
  padding: 0 20px;
  font-size: 1.2em;
}
.rewindIcon .vjs-icon-placeholder:before {
  content: url(../icon/icons8-skip-to-start-24.png);
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}

.fast-forward-icon .vjs-icon-placeholder:before {
  content: url(../icon/icons8-forward-24.png);
  font-weight: bold;
  font-size: 20px;
  margin-top: 5px;
}

.fast-forward-icon:hover {
  color: #5f3aa5;
}

.rewindIcon:hover {
  color: #5f3aa5;
}

.fast-forward-icon:hover {
  cursor: pointer;
}

.rewindIcon:hover {
  cursor: pointer;
}

.video-js {
  height: 100% !important;
  width: 100%;
  transition: width 0.3s;
  backface-visibility: hidden;
}
/* Change all text and icon colors in the player. */
/* .vjs-matrix.video-js {
  color: #ff7300;
} */

/* Change the border of the big play button. */
.vjs-matrix .vjs-big-play-button {
  border-color: #5f3aa5;
  transition: ease;
  border-radius: 20px;
}

.vjs-matrix .vjs-play-control {
  /* color: rgb(6, 12, 6); */
}

/* Change the color of various "bars". */
.vjs-matrix .vjs-volume-level,
.vjs-matrix .vjs-play-progress,
.vjs-matrix .vjs-slider-bar {
  background-color: #5f3aa5 !important;
}

div.vjs-progress-control.vjs-control {
  padding: 1em 1.7em 1em 1.7em;
  display: border-box;
  position: absolute;
  width: 100%;
  height: 1em;
  top: 0.5em;
  left: 0;
  right: 0;
}

div.vjs-remaining-time.vjs-time-control.vjs-control {
  margin-left: auto;
}

div.vjs-progress-holder.v js-slider.vjs-slider-horizontal {
  margin: 0;
}

div.vjs-control-bar {
  height: 3.1rem;
  padding: 0 20px;
}

.skip-teaser-button {
  position: absolute !important;
  height: 0 !important;
  bottom: 11vh;
  left: 10px;
}

.skip-teaser-button:hover {
  cursor: pointer;
  color: #5f3aa5;
}

.skip-teaser-button::before {
  content: "Skip-Teaser";
  white-space: nowrap;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #5f3aa5;
}

.next-button-on-Control .vjs-icon-placeholder::before {
  content: "❯";
  white-space: nowrap;
  /* font-size: 1.5em; */
  /* background-color: rgba(0, 0, 0, 0.2); */
}

.next-button-side {
  position: absolute !important;
  height: 0 !important;
  bottom: 50%;
  right: 40px;
}

.next-button-side::before {
  content: "Next-Video";
  white-space: nowrap;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #5f3aa5;
}

.next-button-side:hover {
  cursor: pointer;
  color: #5f3aa5;
}

.vjs-big-play-button {
  border-radius: 100px !important;
}

.video-js .vjs-big-play-button {
  font-size: 3.5em !important;
  line-height: 1.9em !important;
  width: 2em !important;
  height: 2em !important;
}

.vjs-menu-button-popup .vjs-menu {
  /* display: none; */
  position: absolute;
  bottom: -35px !important;
  width: 10em;
  left: -3em;
  height: 0;
  margin-bottom: 0;
  border-top-color: rgba(43, 51, 63, 0.7);
  padding-bottom: 24px;
}

.vjs-has-started .vjs-control-bar {
  height: 5em;
  background: none;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.88), transparent);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2em 1em 5em;
  border-radius: 15px;
  /* visibility: visible; */
}

.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}

.vjs-progress-holder:hover {
  height: 0.5em !important;
}

.vjs-control-bar {
  background-color: rgba(0, 0, 0, 0) !important;
}

.vjs-control {
  height: 80%;
}
/* Ensure the menu is hidden by default */
.vjs-playback-rate .vjs-menu {
  display: none;
  position: absolute;
  bottom: -35px;
  width: 10em;
  left: -3em;
  height: auto; /* Adjust this based on your requirements */
  margin-bottom: 0;
  border-top-color: rgba(43, 51, 63, 0.7);
  padding-bottom: 24px;
  z-index: 10; /* Ensure the menu appears above other elements */
}

/* Show the menu on hover */
.vjs-playback-rate:hover .vjs-menu,
.vjs-playback-rate:focus-within .vjs-menu {
  display: block !important;
  bottom: 40px; /* Adjust this based on your requirements */
}

/* Ensure pointer events are enabled */
.vjs-playback-rate,
.vjs-playback-rate .vjs-menu {
  pointer-events: auto !important;
}

/* Additional styling for the playback rate button if needed */
.vjs-playback-rate {
  cursor: pointer !important;
}

/* General styles for the playback rate button */
.vjs-playback-rate .vjs-icon-placeholder {
  margin-top: 12px;
}

.vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-button {
  padding: initial !important;
  border: initial !important;
  background: initial !important;
  outline: initial !important;
}

/* button {
  padding: initial !important;
  border: initial !important;
  background: initial !important;
  outline: initial !important;
} */
