.single_question_number_wrapper {
  margin: 10px 5px;

  .arrow {
    width: 8px;
  }

  .lblBox {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;

    justify-content: space-between;
    width: 100%;

    .questionBox {
      display: flex;
      align-items: baseline;
      margin-right: 5px;
      overflow-x: hidden;

      p {
        margin-left: 2px;
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        math {
          display: inline-block;
        }

        img {
          display: none;
        }

        table {
          width: 100% !important;
        }
      }
    }
  }

  .current_lbl {
    border: 1px solid #ff933a;
    background: inherit;
  }

  .unanswered_lbl {
    background: #f5f4f8;
    border: 1px solid #e2e2e2;
  }

  .answered_lbl {
    border: 1px solid #e2e2e2;
    background-color: #48ef4c !important;
  }
}
