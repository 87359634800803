.your_order {
  border: 0.5px solid #9d9d9d;
  border-radius: 4px;
  .your_order_Content {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid #d9d9d9;
      padding: 10px 15px;
      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 30px;
        color: #4b4b4b;
      }
      .total_items {
        // color: #6d48ef;
        span {
          // color: #6d48ef;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .itemsBox {
      border-bottom: 0.5px solid #d9d9d9;
      .item {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item_left {
          display: flex;
          justify-content: space-between;
          width: 60%;
          padding: 0px;
          margin: 0px;

          img {
            width: 25%;
            height: 6vh;
            object-fit: cover;
          }
          .item_content {
            .subName {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
              color: #2d2d2d;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 150px;
            }

            small {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #787878;
            }
          }
        }

        .item_right {
          width: 27%;
          text-align: right;
          margin-right: -5px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #121212;
        }
      }
    }

    .delivery_discount {
      border-bottom: 0.5px solid #d9d9d9;
      padding: 5px 0px;
      .deliveryBox {
        padding: 3px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .deliveryName {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #6d6d6d;
        }
        .deliveryCost {
          width: 23%;
          text-align: right;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #666666;
          margin-right: -5px;
        }
      }
      .discountBox {
        padding: 3px 14px 3px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dicountName {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #6d6d6d;
        }
        .discountPrice {
          width: 25%;
          text-align: start;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #666666;
          text-align: right;
          margin-right: 2px;
        }
      }
    }

    .totalPriceContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ece7ff;
      padding: 15px 20px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .totalPriceText {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #121212;
      }
      .totalPrice {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #121212;
      }
    }
  }
}

.checkOutBtn {
  margin-top: 20px;
  text-align: center;
  background: #ff933a;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  button {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    padding: 10px 0px;
    width: 100%;
  }
}
