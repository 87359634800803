.QuestionBankCreatePaperWrppr {
  padding: 30px;
  text-align: center;
  background: rgba(255, 248, 242, 0.98);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  width: 100%;
  .heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #121212;
  }
  .QuestionBankCreatePaperContentPara {
    width: 33%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #555555;
    margin-left: 430px;
    .span-1 {
      color: #252525;
      font-weight: bold;
    }
    .span-2 {
      color: #169b00;
      font-weight: bold;
    }
  }
  .create_new_button {
    text-decoration: none;
    .text_content_wrapper {
      width: 28%;
      margin-left: 460px;
      margin-top: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(91.51deg, #815efb 0%, #6d48ef 97.4%);
      box-shadow: 0px 2px 4px rgba(110, 73, 240, 0.16);
      border-radius: 4px;
      p {
        padding: 10px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        margin-bottom: 0px;
        .add_btn {
          margin-right: 10px;
        }
      }
    }
  }
}
