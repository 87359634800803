.userCreateDoubtContentComponent_wrapper {
  .userCreateDoubtContentComponent_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
       text-align: center;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            color: #000000;
          }
        }
      }
    }
    .sec_2_wrapper {
      padding: 0px 10px;
      .sec_2_inner_wrapper {
        .content_wrapper {
          display: flex;
          justify-content: flex-start;
          .content_inner_wrapper {
            flex: 0 0 100%;
            .select_subject{
              border: 1px solid #EEEEEE;
                  border-radius: 6px;
                  background-color: #F5F4F8;
                  width: 100%;
                  height: 45px;
                  padding: 0 10px;
                  font-size: 15px;
                  color: #707070;
                  display: flex;
                  align-items: center;
              span{}
            }
            .sub_content_wrapper_1 {
              margin: 10px 0;
              .select_wrapper {
                select {
                  border: 1px solid #EEEEEE;
                  border-radius: 6px;
                  background-color: #F5F4F8;
                  width: 100%;
                  height: 45px;
                  padding: 0 7px;
                  font-size: 15px;
                  color: #707070;
                  &:focus{
                    outline: none;
                  }
                }
              }
            }
            .sub_content_wrapper_2 {
              margin: 10px 0;
              .textarea_wrapper {
                textarea {
                  border-radius: 10px;
                  border: solid 1px #f0eef8;
                  background-color: #fafafd;
                  width: 100%;
                  padding: 5px 5px;
                  font-size: 15px;
                  color: #707070;
                  min-height: 190px;
                  &:focus{
                    outline: none;
                  }
                }
              }
            }
            .sub_content_wrapper_3 {
              margin: 10px 0;
              .show_uploading_img_wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 15px;
                .image_wrapper {
                  margin: 10px;
                  position: relative;
                  img {
                    width: 70px;
                    height: 70px;
                    border-radius: 4px;
                  }
                  span{
                    width: 15px;
                    height: 15px;
                    background-color: #F33D3D;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    position: absolute;
                    right: -6px;
                    top: -6px;
                    cursor: pointer;
                  }
                }
              }
              .input_wrapper {
                position: relative;
                height: 40px;
                text-align: center;
                .custom_file_upload {
                  
                  cursor: pointer;
                  input[type="file"] {
                    display: none;
                  }
                  .upload_content_action_wrapper {
                    border-radius: 20px;
                    padding: 8px 25px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: #FF933A;
                    color: #fff;
                  }
                }
              }
            }
            .sub_content_wrapper_4 {
              margin: 20px 0;
              .btn_wrapper {
                button {
                  border: none;
                  outline: none;
                  width: 100%;
                  height: 43px;
                  border-radius: 8px;
                  background-color: #6D48EF;
                  font-size: 16px;
                  font-weight: 600;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
}
