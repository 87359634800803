
.examCrackerPaymentPageWrapper {
  background-color: #f5f4f8;
  min-height: calc(100vh);
  .examCrackerPaymentWrapper {
    padding-top: 101px;
    padding-bottom: 20px;
    // margin-left: 200px;
    .examCrackerPaymentInner {
      padding: 20px;
      margin: 40px 0px;
      background-color: #fff;
      // background-color: red;
      // width: 92%;
      border-radius: 10px;
      min-height: 80vh;

      .examCrackerHeading {
        text-align: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 45px;
        color: #000000;
        margin-top: -30px;
      }
      .examCrackerPaymentContent {
        display: flex;
        margin-top: 30px;
        gap: 50px;
        .examCrackerLeft {
          width: 48%;
          margin-left: 100px;
          //   ul{
          //   padding: 0px;
          //   margin: 0px;
          //   }

          // ul li {
          //   // list-style: none;
          //   margin-top: 5px;
          // }
          // ul li h3 {
          //   font-family: "Poppins";
          //   font-style: normal;
          //   font-weight: 500;
          //   font-size: 24px;
          //   line-height: 36px;
          //   color: #000000;

          // }

          // ul li  ul li{
          //   font-family: "Poppins";
          //   font-style: normal;
          //   font-weight: 400;
          //   font-size: 14px;
          //   line-height: 28px;
          //   color: #595959;
          // }
          .examCrackerDesc {
            margin-top: 30px;
            h3 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              color: #000000;
            }
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 28px;
              color: #595959;
            }
          }
        }
        .examCrackerRight {
          width: 32%;
          margin-top: 35px;
          margin-right: 20px;
          .priceDetails {
            border: 0.5px solid #9d9d9d;
            border-radius: 4px;
            .priceDetailsContent {
              // padding: 15px;
              h4 {
                padding: 10px 20px;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
                color: #282828;
                // border-bottom: 1px solid black;
                border-bottom: 0.5px solid #d9d9d9;
              }

              .durationBox {
                padding: 10px 20px;

                border-bottom: 0.5px solid #d9d9d9;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .durationName {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  color: #6d6d6d;
                }
                .durationMonths {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 22px;
                  color: #000000;
                }
              }

              .priceBox {
                padding: 10px 20px;

                border-bottom: 0.5px solid #d9d9d9;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .priceName {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  color: #6d6d6d;
                }
                .price {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 22px;
                  color: #000000;
                }
              }

              .discountBox {
                padding: 10px 20px;
                border-bottom: 0.5px solid #d9d9d9;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .dicountName {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  color: #6d6d6d;
                }
                .dicountPrice{
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 22px;
                  color: #000000;
                }
              }

              .totalPriceContent {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #ece7ff;
                padding: 15px 20px;
                .totalPriceText {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 27px;
                  color: #121212;
                }
                .totalPrice {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 27px;
                  letter-spacing: 0.02em;
                  color: #121212;
                }
              }
            }
          }

          .buyNowBtn {
            margin-top: 20px;
            text-align: center;
            background: #6d48ef;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            cursor: pointer;
            button {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: #ffffff;
              padding: 10px 0px;
              width: 100%;
            }:hover{
              background: #6d18ef;
              border-radius: 4px;
            }
            
          }
        }
      }

      .mdl_hdr{
        .coupon_wrapper{
          .coupon_wraper_inr{
            h2{
              margin-left: 20px;
              background: red;
            }
            p{
              background: green;
            }
          }
        }
      }
    }
  }
}
