.restriction-modal {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Poppins;

  .modal-content {
    width: 24.75rem !important;
    margin: 0 auto;

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .restriction-image {
        width: 80px;
        height: 80px;
        margin-top: 1.8rem;
      }

      .modal-text {
        margin-top: 1.438rem;
        color: #000;
        text-align: center;
        // font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
      }

      .restrict-remark {
        margin-top: 0.75rem;
        color: #000;
        text-align: center;
        // font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .restrict-more-info {
        margin-top: 1rem;
        color: #5c5c5c;
        text-align: center;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 1rem;
        width: 80%;
      }
    }
  }
}
