
.Ots_ppr_test_questions_view_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;
          

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;
          max-height: 100vh;
          height: 100vh;
          .section_content_wrapper_backdrop{
            display: none;
          }
          // align-items: center;
          .left_side_content_wrapper {
              width: 25%;
              background-color: #ffffff;
              padding: 0.5rem;
              position: sticky;
              top: 0;
              height: 100vh;
              overflow: hidden;

            
            .sub_section_1_wrapper {
              // flex: 0 0 100%;
              // margin-bottom: 50px;
              // position: fixed;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .closebtn{
                display: none;
              }

              // display: contents;
              .testProgressIndicator {
                padding: 0.2rem 0.5rem;
                border-radius: 8px;
                margin-bottom: 0.5rem;
                background: #ffffff;
                border: 0.8px solid #c9c9c9;
                h4 {
                  font-size: 14px;
                  color: #000;
                  margin-bottom: 16px;
              }
              .progress {
                height: 10px;
                margin-bottom: 5px;
              .progress-bar {
                  background: #ff933a;
                  border-radius: 50px;
              }
            }
             .ProgressBar_Line span {
              position: absolute;
              top: -16px;
              font-size: 12px;
          }
          .questionPercentage {
            display: flex;
            justify-content: space-between;
            span {
              color: #707070;
              font-size: 12px;
          }
        }
                .ProgressBar_Line {
                  position: relative;
                }
              }
              .text_content_wrapper {
                margin-bottom: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: "Poppins";
                font-style: normal;
                // position: fixed;
                // width: 29%;
                .left {
                  .text_content {
                    margin: 0;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #0f0404;
                  }
                }

                .right {
                  width: 53%;

                  display: flex;
                  justify-content: flex-end;
    align-items: center;
    flex: 1 1 50%;
                  .submitBtn {
                    background: #6d48ef;
                    border-radius: 4px;
                    padding: 6px 12px;
                    text-decoration: none;

                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;
                  }

                  .countdown_timer_container {
                    // min-width: 55px;
                    background: #f5f4f8;
                    text-align: end;
                    border: 0.8px solid #e9e3ff;
                    border-radius: 4px;
                    padding: 6px 12px;
                    // width: 40%;
                    span {
                      label {
                        color: #6d48ef;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                      }
                    }
                  }
                }
              }

              .sub_section_2_wrapper {
                // flex: 0 0 100%;
                margin-bottom: 0.5rem;
                // margin-top: 20px;
                background: #f5f4f8;
                border: 0.6px solid #c9c9c9;
                border-radius: 4px;
                // position: fixed;
                // width: 29%;
                
                .question_marking_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  // justify-content: flex-start;
                  flex-wrap: wrap;
                  // width: 100%;
                  padding: 5px;
                  // background: green;
                  .single_marking {
                    // margin-right: 15px;
                    display: flex;
                    // justify-content: space-between;
                    align-items: center;
                    flex: 1 1 50%;
                    margin-bottom: 5px;

                    &:last-child {
                      margin-right: 0px;
                    }

                    .box_pic {
                      width: 20px;
                      height: 20px;
                      border-radius: 3px;
                      margin-right: 5px;
                    }

                    .box_pick_background_1 {
                      background-color: #48ef4c;
                      border: 1px solid #ebebeb;
                    }

                    .box_pick_background_2 {
                      background-color: #f99746;
                    }

                    .box_pick_background_3 {
                      background-color: #e2e2e2;
                      border: 1px solid #e2e2e2;
                    }

                    .box_pick_background_4 {
                      background: #ffc4c4;
                      border: 1px solid #ffc4c4;
                    }

                    .box_pick_background_5 {
                      background: #cddc39;
                      border: 1px solid #cddc39;
                    }

                    .question_marking_text {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 18px;
                      color: #000000;
                    }
                  }
                }
              }

       

              .questions_serial_number_collection {
                font-family: "Poppins";
                overflow-y: scroll;
                // height: calc(100vh - 128px);
                // position: fixed;
                overflow-y: auto;
                max-height: 50vh;
                margin-bottom: 0.5rem;
                // width: 30%;
                // margin-top: 125px;
                .single_question_number_wrapper {
                  margin: 5px;
                  display: inline-block;

                  .arrow {
                    width: 8px;
                  }

                  .unanswered_lbl {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    padding: 8px;
                    border-radius: 8px;
                    border: 1px solid #e2e2e2;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    background: #e2e2e2;
                    justify-content: center;
                    min-width: 40px;
                    // width: 100%;
                    // min-height: 74px;
                    &.review_lbl {
                      padding: 10px;
                      border-radius: 8px;
                      background: #ffc4c4;
                      border: 1px solid #ffc4c4;
                      cursor: pointer;
                    }

                    &.skip_lbl {
                      border: 1px solid #ffc4c4;
                      background-color: #fed5d5;
                    }

                    &.current_lbl {
                      border: 1px solid #ff933a;
                      cursor: pointer;
                      background: inherit;
                    }

                    &.isGussedAnswer {
                      border: 1px solid #cddc39;
                      background-color: #cddc39 !important;
                    }

                    &.answered_lbl {
                      border: 1px solid #e2e2e2;
                      background-color: #48ef4c;
                    }

                    .questionBox {
                      display: flex;
                      align-items: baseline;
                      // margin-right: 5px;
                      overflow-x: hidden;

                      .QBox {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 5px;

                        .wrs_chemistry {
                          display: block;
                          margin: 20px auto;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          // height: 100px;
                          overflow-x: auto;

                          mtable {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #ccc;

                            mtr {
                              &:nth-child(even) {
                                background-color: #f5f5f5;
                              }

                              mtd {
                                padding: 5px;
                                border: 1px solid #ccc;
                                vertical-align: middle;
                                text-align: center;

                                mtext {
                                  display: inline-block;
                                }

                                mtable {
                                  margin-top: 5px;
                                  width: 100%;

                                  mtr {
                                    mtd {
                                      padding: 5px;
                                      border: 1px solid #ccc;
                                      vertical-align: middle;
                                      text-align: center;
                                      display: inline-block;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        .wrs_chemistry::-webkit-scrollbar {
                          width: 4px;
                        }

                        .wrs_chemistry::-webkit-scrollbar-track {
                          background-color: #f1f1f1;
                        }

                        .wrs_chemistry::-webkit-scrollbar-thumb {
                          background-color: #888;
                          border-radius: 2px;
                        }

                        .wrs_chemistry math,
                        .wrs_chemistry mo,
                        .wrs_chemistry mi,
                        .wrs_chemistry mn,
                        .wrs_chemistry msub,
                        .wrs_chemistry mtext,
                        .wrs_chemistry mfenced,
                        .wrs_chemistry msup,
                        .wrs_chemistry mrow {
                          display: inline;
                          height: 20px;
                        }

                        .tableContainer {
                          max-height: 200px;
                          max-height: 200px; /* Adjust the max-height as per your requirements */
                          overflow-y: auto;
                          overflow-x: auto;

                          ::-webkit-scrollbar {
                            width: 4px !important;
                            height: 2px !important;
                          }

                          ::-webkit-scrollbar-track {
                            background-color: #f1f1f1;
                          }

                          ::-webkit-scrollbar-thumb {
                            background-color: #888;
                            border-radius: 4px;
                          }

                          ::-webkit-scrollbar-thumb:hover {
                            background-color: #555;
                          }
                        }

                        .tableContainer {
                          table {
                            width: 50%;
                            table-layout: fixed;

                            td {
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            }
                          }
                        }

                        .tableContainer::-webkit-scrollbar {
                          width: 4px !important;
                          height: 4px !important;
                        }

                        img {
                          width: 100%;
                          max-height: 100px;
                          object-fit: cover;
                        }
                        &.twoLines {
                          p {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                            margin-left: 5px;
                          }
                        }

                        p {
                          margin-left: 2px;
                          margin-bottom: 0px;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                        }

                        p > p {
                          /* Add  desired styles for nested <p> elements here */
                          display: inline;
                          margin: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .right_side_content_wrapper {
            width: 75%;
            overflow-x: hidden;
            position: relative;
            height: 100%;

            .sub_section_wrapper {
              // max-width: 750px;
              // max-width: 850px;
              // max-width: 900px;
              margin: 0 auto;
              height: 100%;

              .sub_sub_section_wrapper_1 {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .right_side_content_wrapper {
                  flex: 0 0 100%;
                  display: flex;
                  justify-content: flex-end;
                  // width: 100%;
                  .review_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }

                  .bookmark_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }
                }
              }

              .testProgressIndicator {
                padding: 5px 10px;
                border-radius: 8px;
                margin-bottom: 20px;
                background: #ffffff;
                border: 0.8px solid #c9c9c9;
                h4 {
                  font-size: 14px;
                  color: #000;
                  margin-bottom: 16px;
                }

                .ProgressBar_Line {
                  position: relative;
                }

                .ProgressBar_Line span {
                  position: absolute;
                  top: -16px;
                  // font-size: 14px;
                  font-size: 12px;
                }

                .progress {
                  // height: 8px;
                  height: 10px;
                  margin-bottom: 5px;

                  .progress-bar {
                    background: #ff933a;
                    // border-radius: 9px;
                    border-radius: 50%px;
                  }
                }

                .questionPercentage {
                  display: flex;
                  justify-content: space-between;

                  span {
                    color: #707070;
                    font-size: 12px;
                  }
                }
              }

              .btm_container {
                background: #ffffff;
                border: 0.6px solid #c9c9c9;
                box-shadow: 4px 4px 12px rgba(169, 169, 169, 0.12),
                  -4px -4px 12px rgba(169, 169, 169, 0.12);
                padding: 10px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .right_btn_wrapper{
                  display:none;
                }
                .report_error {
                    margin-top: 0.5rem;
                    // margin-bottom: 1rem;
                    color: rgb(255, 135, 36);
                    font-size: 1rem;
                    cursor: pointer;
                    display: inline-block;
                  }
                .sectionWiseTestWrapper {
                  margin-bottom: 0.5rem;
                  .subjectWiseTest {
                    // background: #fff;

                    background: #f5f4f8;
                    border: 0.8px solid #c9c9c9;
                    border-radius: 24px;
                    // max-width: 60%;
                    max-width: fit-content;
                    display: flex;
                    padding: 2px;
                    border-radius: 36px;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0px auto;

                    .single_select_subject {
                      input[type="radio"] {
                        opacity: 0;
                        position: fixed;
                        width: 0;

                        &:checked + label {
                          background-color: #ff933a;
                          border: none;
                          color: #ffffff;
                          border-radius: 19px;
                          font-family: "Poppins";
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 23px;
                        }
                      }

                      label {
                        padding: 3px;
                        font-size: 15px;
                        letter-spacing: 0.2px;
                        color: #6b6876;
                        cursor: pointer;
                        min-width: 150px;
                        text-align: center;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;

                        // color: #707070;
                      }
                    }
                  }

                  .sectionWiseTest {
                    display: flex;
                    width: max-content;
                    margin: 0 auto;
                    margin-bottom: 0.8rem;
                    margin-top: 0.4rem;
                  }
                }

                .FormateText {
                  margin-bottom: 0.5rem;
                  h2 {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1;
                    color: #000000;
                    text-align: center;
                    font-size: 1.4rem;
                    // margin-bottom: 20px;
                  }
                }

                .questionWrapper {
                  // background: red;
                  background: #f5f4f8;
                  border: 1px solid #e6deff;
                  border-radius: 4px;
                  padding: 10px;
                  width: 100%;
                  height: 65vh;
                  overflow-y: auto;
                  overflow-x: hidden;
                  // margin: auto;
                  .sub_sub_section_wrapper_2 {
                    .text_content_wrapper {
                      .typeLanguageWrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 5px;

                        span {
                          font-family: "Poppins";
                          font-style: normal;
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          color: #707070;
                        }

                        .markReview {
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          width: 11%;
                          .bookmark_image_wrapper {
                            margin: 0px 10px;

                            span {
                              margin-right: 5px;
                            }

                            img {
                              cursor: pointer;
                            }
                          }

                          button {
                          }
                        }
                      }

                      .questionBox {
                        display: flex;
                        align-items: baseline;
                        margin-bottom: 20px;
                        overflow-x: hidden;

                        span {
                          margin-right: 5px !important;
                        }

                        .text_content {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #000;
                          p {
                            margin-bottom: 0px;
                          }
                        }

                        .qst {
                          position: relative;
                          width: 100%;

                          p {
                            display: block;
                            top: 0px;
                            width: 100%;
                            font-family: "Poppins";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #000000;
                          }

                          .hide {
                            display: none;
                          }

                          .show {
                            display: block;
                          }
                        }
                      }

                      .question {
                        display: flex;
                        align-items: baseline;
                        margin-bottom: 20px;

                        .wrs_chemistry math,
                        .wrs_chemistry mo,
                        .wrs_chemistry mi,
                        .wrs_chemistry mn,
                        .wrs_chemistry msub,
                        .wrs_chemistry mtext,
                        .wrs_chemistry mfenced,
                        .wrs_chemistry msup,
                        .wrs_chemistry mrow {
                          display: inline;
                        }

                        .mtable-style {
                          // max-height: 200px;
                          // max-height: 300px;
                          max-height: 100vh;
                          max-width: 100%;

                          display: block;
                          margin: 20px auto;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          // height: 100px;
                          // overflow-x: auto;

                          mtable {
                            width: 80%;
                            border-collapse: collapse;
                            border: 1px solid #ccc;

                            mtr {
                              &:nth-child(even) {
                                background-color: #f5f5f5;
                              }

                              mtd {
                                padding: 5px;
                                border: 1px solid #ccc;
                                vertical-align: middle;
                                text-align: center;

                                mtext {
                                  display: inline-block;
                                }

                                mtable {
                                  margin-top: 5px;
                                  width: 100%;

                                  mtr {
                                    mtd {
                                      padding: 5px;
                                      border: 1px solid #ccc;
                                      vertical-align: middle;
                                      text-align: center;
                                      display: inline-block;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        .image-style {
                          img {
                            width: 100%;
                            height: auto;
                            object-fit: contain;

                            width: 500px;
                            height: 100px;
                            object-fit: contain;
                          }
                        }

                        .table-style {
                          width: 100%;
                          border-collapse: collapse;

                          td,
                          th {
                            border: 1px solid black;
                            padding: 8px;
                          }
                        }

                        p {
                          line-height: 1.5;
                          font-family: Poppins;
                          font-size: 16px;
                          font-weight: 500;
                          line-height: 24px;
                          letter-spacing: 0em;
                          text-align: left;

                          .math-variant-normal {
                            font-style: italic;

                            sup {
                              vertical-align: super;
                              font-size: smaller;
                            }

                            sub {
                              vertical-align: sub;
                              font-size: smaller;
                            }

                            mo {
                              margin: 0 2px;
                            }

                            mrow {
                              display: inline;
                            }

                            mfenced {
                              display: inline;
                            }

                            mfrac {
                              display: inline;
                            }

                            mi {
                              font-style: italic;
                            }

                            mn {
                              font-style: normal;
                            }

                            mo[stretchy="true"] {
                              width: 1.2em;
                              text-align: center;
                            }

                            mo[stretchy="true"]:before {
                              content: "";
                              display: inline-block;
                              height: 0.85em;
                              vertical-align: middle;
                            }

                            mo[stretchy="true"][fence="true"] {
                              height: auto;
                              vertical-align: middle;
                            }

                            mo[stretchy="true"][fence="true"]:before {
                              content: "";
                              display: inline-block;
                              height: 0.4em;
                              vertical-align: middle;
                            }

                            mo[largeop="true"] {
                              vertical-align: middle;
                            }

                            mo[largeop="true"]:before {
                              content: "";
                              display: inline-block;
                              height: 0.5em;
                              vertical-align: middle;
                            }
                          }

                          .math-expression {
                            display: inline-block;
                            font-size: 18px;
                            // Add more custom styles as needed
                          }
                        }

                        span {
                          margin-right: 5px !important;
                        }

                        .text_content {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #000;

                          p {
                            margin-bottom: 0px;
                          }
                        }

                        .hide {
                          display: none;
                        }

                        .show {
                          display: block;
                        }
                      }
                    }
                  }

                  .sub_sub_section_wrapper_3 {
                    //single-select
                    .single_select_option_collection_wrapper {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      flex-wrap: wrap;
                      // margin-bottom: 30px;

                      .single_select {
                        width: calc(50% - 8px);
                        margin: 10px 16px 10px 0px;

                        &:nth-child(2n + 2) {
                          margin-right: 0px;
                        }

                        .classCorrect {
                          border: 1px solid #5ddd9b;
                          background: #e1f1ec !important;
                        }

                        .classWrong {
                          border: 1px solid #f93030;
                          background: #f6e0e4 !important;
                        }

                        input[type="checkbox"] {
                          opacity: 0;
                          position: fixed;
                          width: 0;
                        }

                        .single_option_wrapper {
                          min-height: 50px;
                          background: #ffffff;
                          border: 0.8px solid #c9c9c9;
                          border-radius: 8px;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          padding: 10px;
                          cursor: pointer;
                          overflow: hidden;

                          .MJX-TEX {
                            text-wrap: wrap;
                          }

                          .option_initial {
                            margin-right: 10px;

                            .text_content_2 {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #acacac;
                            }
                          }

                          .option_final {
                            .text_content_3 {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #000;

                              p {
                                margin-bottom: 0px;

                                img {
                                  height: 100px;
                                  width: 300px;
                                }
                              }
                            }

                            .rightarrow {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #000;

                              p {
                                margin-bottom: 0px;
                              }
                            }
                          }
                        }

                        input[type="checkbox"]:checked
                          + .single_option_wrapper {
                          border: 2px solid #6d48ef;

                          .option_initial {
                            .text_content_2 {
                              font-weight: 600;
                            }
                          }

                          .option_final {
                            .text_content_3 {
                              font-weight: 600;

                              .image-syle {
                                height: 100px;
                                width: 300px;
                              }
                            }
                          }
                        }
                      }
                    }

                    .hide {
                      display: none !important;
                    }

                    .show {
                      display: flex !important;
                    }

                    .show2 {
                      display: block !important;
                    }

                    //multi-select
                    .multi_select_option_collection_wrapper {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      flex-wrap: wrap;

                      .single_select {
                        width: calc(50% - 8px);
                        margin: 10px 16px 10px 0px;

                        &:nth-child(2n + 2) {
                          margin-right: 0px;
                        }

                        input[type="checkbox"] {
                          opacity: 1;
                          position: fixed;
                          width: 0;
                        }

                        .single_option_wrapper {
                          min-height: 50px;
                          border: solid 1px #f0eef8;
                          background-color: #fafafd;
                          border-radius: 15px;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          padding: 10px;
                          cursor: pointer;
                          overflow: hidden;

                          .option_initial {
                            margin-right: 10px;

                            .text_content_2 {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #6b6876;
                            }
                          }

                          .option_final {
                            .text_content_3 {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #6b6876;

                              .image-syle {
                                height: 100px;
                                width: 300px;
                              }
                            }

                            .rightarrow {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #6b6876;

                              p {
                                margin-bottom: 0px;
                              }
                            }
                          }
                        }

                        input[type="checkbox"]:checked
                          + .single_option_wrapper {
                          border: 2px solid #6d48ef;

                          .option_initial {
                            .text_content_2 {
                              font-weight: 600;
                            }
                          }

                          .option_final {
                            .text_content_3 {
                              font-weight: 600;
                            }
                          }
                        }
                      }
                    }
                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;

                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }

                //integer
                .input_wrapper {
                  .input_inner_wrapper {
                    margin-bottom: 30px;

                    input {
                      border: none;
                      outline: none;
                      width: 100%;
                      border: solid 1px #f0eef8;
                      background-color: #fff;
                      height: 50px;
                      border-radius: 8px;
                      padding: 0 12px !important;
                      font-size: 16px;
                      font-weight: 500;
                    }

                    input[type="number"]:focus {
                      border: 2px solid #6d48ef;
                    }

                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                }
              }
            }

            .sub_sub_section_wrapper_4 {
              margin-bottom: 15px;
              .checkbox_wrapper {
                input[type="checkbox"] {
                  margin-right: 10px;
                }

                label {
                  font-size: 13px;
                  vertical-align: text-top;
                  color: #09041a;
                }
              }
            }

            .sub_sub_section_wrapper_5 {
              // display: flex;
              // align-items: center;
              // justify-content: space-between;
              // width: 80%;
              margin: 0.5rem auto 0;
              width: 100%;
              padding-top: 0.5rem;
              border-top: 1px solid #ddd;

              .left_side_wrapper {
                // width: 100%;
                // margin-right: 16px;

                .btn_wrapper {
                  // text-align: left;
                  text-align: center;

                  button {
                    border: none;
                    outline: none;
                    border-radius: 8px;
                    background-color: #ffffff;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                    color: #6d48ef;
                    padding: 10px;
                    width: 80%;
                    // margin: auto;
                    border: 1px solid #6d48ef;
                    // text-decoration: underline;
                  }
                }
              }

              .right_side_wrapper {
                // width: 100%;

                .btn_wrapper {
                  text-align: center;

                  button {
                    border: none;
                    outline: none;
                    border-radius: 8px;
                    background-color: #ffffff;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                    color: #fff;
                    padding: 10px;
                    width: 80%;
                    // margin: auto;
                    background: #218838;
                    // text-decoration: underline;
                  }
                }
              }
            }

            .QuestionSolution {
              padding-top: 20px;

              h5 {
              }

              div {
                p {
                  margin-bottom: 2px;
                }
              }
            }

            .sub_sub_section_wrapper_6 {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .text_content_wrapper {
                .text_content {
                  margin: 0;
                  font-size: 18px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}
.submitBtn {
  background: #6d48ef;
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
  text-decoration: none;

  font-weight: 500;
  font-size: 1rem;
  // line-height: 24px;
  width: 100%;
  color: #ffffff;
  // margin-top: 30px;
}

.onSubmitModal {
  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    margin-top: 0px !important;
    .submit_Popup {
      .exit_img {
        width: 100%;

        img {
          width: 35%;
          height: 130px;
          margin-bottom: 20px;
          margin-top: -25px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #6d48ef;
          border-radius: 6px;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #6d48ef;
        }

        .cancel {
          color: #6d48ef;
          background: none;
          margin-right: 10px;
        }
      }
    }
  }
}

///////////////////////////   FOR ERROR MODAL ///////////////////////////

.errorModal {
  margin-top: 70px;

  .modal-content {
    border-radius: 16px;
  }
  text-align: center;

  .modal-header {
    margin-top: 20px;
    font-weight: 600;
    font-family: "Poppins";
    font-style: normal;

    border-bottom: 0 none;
    padding: 0;

    .btn-close {
      width: 0;
      height: 0;
      margin-right: 20px;
    }

    h3 {
      color: #000000;
      font-weight: 600;
      margin-left: 150px;
      font-size: 20px;
    }
  }

  .modal-body {
    padding: 0;

    .error_Popup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      height: 85%;
      width: 85%;
      margin: auto;
    }

    .error_Popup .text_content {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;

      letter-spacing: 0.02em;
      color: #606060;
    }

    .errorList {
      text-align: start;
      padding: 0;
      margin: 0;
    }

    .errorList .errorListItem {
      list-style: none;
      text-align: start;
      margin-bottom: 10px;

      font-size: 16px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: #000000;
    }

    .errorList .errorListItem input {
      font-size: 25px;
    }

    .errorList .errorListItem input[type="radio"] {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 8px;
    }

    textarea::-webkit-input-placeholder {
      padding: 0;
      font-size: 14px;

      color: #b8b8b8;
    }

    button {
      padding: 10px 40px;
      font-weight: 500;
      background: #6d48ef;
      border-radius: 4px;
      color: #fff;
    }

    textarea {
      width: 100%;
      height: 85px;
      padding: 10px;

      border-radius: 4px;
      resize: none;
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
  .Pyq_ppr_test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            flex-direction: column;

            .left_side_content_wrapper {
              width: 100%;
              position: relative;
              height: auto;

              .sub_section_1_wrapper {
                .questions_serial_number_collection {
                  height: 17vh;
                }
              }
            }

            .right_side_content_wrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {

  .Ots_ppr_test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {


      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            display: flex;
            justify-content: space-between;
            max-height: none;
            height: 100%;
            .section_content_wrapper_backdrop{
              height: 100vh;
              width: 100vw;
              z-index: 1995;
              background-color: #000;
              opacity: 0.5;
              position: absolute;
              display: block;
            }

            // align-items: center;
            @keyframes fadeIn {  
              from {  
                  opacity:0;  
              }  
           
              to {  
                  opacity:1;  
              }  
           }
            .left_side_content_wrapper {
              width: 75%;
              position: absolute;
              display: none;
              z-index: 1999;
              &.left_side_content_wrapperToggle{
                display: block;
                background-color: #fbfbfb;
                animation: fadeIn 200ms ease-in;
              }
              .sub_section_1_wrapper{
                .closebtn{
                  display: inline-block;
                  margin-bottom: 20px;
                  margin-right: 10px;
                }
              }
              .questions_serial_number_collection{
                background-color: #fff;
                max-height: 45vh !important;
              }
              .countdown_timer_container{
                display: none;
              }
              .sub_section_2_wrapper{
                .question_marking_wrapper{
                  flex-direction: column;
                  justify-content: center !important;
                  align-items: flex-start !important;
                }
              }
              .submitBtn{
                display: none;
              }
            }

            .right_side_content_wrapper {
              width: 100%;
              .sub_section_wrapper{
                .btm_container{
                  .FormateText{
                    h2{
                      font-size: 1.2rem;
                    }
                  }
                  .sectionWiseTestWrapper {
                    .subjectWiseTest{
                      max-width: none;
                      margin-bottom: 0;
                      .single_select_subject{
                        label{
                          min-width: 5px;
                          line-height:1 ;
                          padding: 5px 10px ;
                        }
                        input[type="radio"] {
                          &:checked + label {
                            line-height:1;
                            padding: 5px 10px;
                          }
                        }
                      }
                    }
                  }
                }
                .right_btn_wrapper {
                  display: flex !important;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 0.5rem;
                  .togglebtn {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      background-color: #ffffff;
                      font-size: 14px;
                      font-weight: 600;
                      text-align: center;
                      color: #fff;
                      padding:5px 10px;
                      background: #6d48ef;
                      &.submit{
                        background: coral;
                      }
                    }
                  }
                  .questionWrapper{
                    height: 55vh !important;
                    .single_select_option_collection_wrapper{
                      flex-direction: column;
                      .single_select{
                        width: 100% !important;
                        &:nth-child(2n+2){
                          margin-right: 16px!important;
                        }
                      }
                    }
                  }
                  }
              .sub_sub_section_wrapper_5 {
                .left_side_wrapper{
                  .btn_wrapper{
                    button{
                      font-size: 14px !important;
                      padding: 5px !important;
                      width: 100% !important;
                    }
                  }
                }
                .right_side_wrapper {
                  .btn_wrapper{
                    button{
                      font-size: 14px !important;
                      padding: 6px !important;
                      width: 100% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
