.pyq_detail_wrapper {
  .jee-mains-adv {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    input {
      margin-right: 10px;
      cursor: pointer;
    }

    input[type="radio"]:checked + label {
      color: #6d48ef;
    }

    label {
      margin-right: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .pyqTabBox {
    margin-bottom: 15px;
    // margin-left: 300px;
    display: flex;
    justify-content: space-between;

    ul {
      border: none;
      // margin: 0 auto;
      width: max-content;
      background-color: #f5f4f8;
      border-radius: 22px;
      overflow: hidden;

      li {
        border: none;

        button {
          border: none;
          color: #000;
          font-size: 15px;
          min-width: 120px;
          border-radius: 0px;
          min-width: 190px;
        }

        .nav-link.active {
          color: #fff;
          background-color: #ff933a;
          border: none;
        }
      }
    }

    .pyq_filter_dropdown {
      display: flex;
      justify-content: center;
      align-items: center;

      width:100px ;

       
      select{
        float: right;
        margin-right: 20px;
        border: 0px;
        outline: 0px;
        padding: 5px 20px 5px 20px;
        border: 1px solid #6d48ef;
        border-radius: 15px;
        color: #6d48ef;

        option {
          margin-right: 30px;
        }
      }
    }
  }

  .pyqTabContent {
    min-height: 65vh;
    padding: 15px 15px;

    .upcomingExamWrapper {
      display: flex;
      flex-wrap: wrap;

      .upcomingExamBox {
        border: 1px solid #eeeeee;
        border-radius: 8px;
        background: #f5f4f8;
        padding: 15px;
        margin: 0px 16px 16px 0px;
        width: calc(50% - 8px);

        &:nth-child(2n + 2) {
          margin-right: 0px;
        }

        .pyqNameType {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;

          .pyqName {
            width: 68%;
            display: flex;
            align-items: center;

            img {
              border-radius: 50%;
              width: 25px;
              height: 25px;
              margin-right: 8px;
            }

            span {
              font-size: 14px;
              font-weight: 600;
              display: inline-block;
              line-height: 18px;
            }
          }

          .examDate {
            color: #929292;
            font-size: 12px;
            width: 32%;
            text-align: right;
          }
        }

        .pyqNameDateDuration {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 15px;

          .pyqNameDate {
            p {
              color: #000;
              font-size: 14px;
            }
          }
        }

        .attemBtn {
          display: flex;

          a {
            text-align: center;
            text-decoration: none;
            margin-right: 15px;
          }

          button {
            margin-right: 15px;

            &:last-child {
              margin-right: 0px;
            }
          }

          .attempt {
            background: #31d680;
            border-radius: 19px;
            color: #fff;
            font-size: 16px;
            width: 100%;
            padding: 5px 10px;
            width: calc(65% - 15px);
            margin-right: 15px;
          }

          .practice {
            font-size: 15px;
            font-weight: 500;
            color: #000;
            border: 1px solid #e2e2e2;
            border-radius: 19px;
            background-color: #fff;
            padding: 5px 10px;
            width: 35%;
          }
        }
      }
    }

    .topicWiseWrapper {
      display: flex;
      flex-wrap: wrap;

      .topicBox, .daysBox {
        background: #f5f4f8;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(50% - 8px);
        margin: 0px 16px 16px 0px;
        cursor: pointer;

        &:nth-child(2n + 2) {
          margin-right: 0px;
        }

        .topicNameImg {
          .img_wrp {
            display: inline-block;
            width: 32px;
            margin-right: 10px;

            img {
              width: 100%;
              font-size: 12px;
            }
          }

          h5 {
            display: inline-block;
            margin: 0px;
          }
        }

        .arrow {
          .arrow_image {
            width: 10px;
          }
          .lock_image {
            width: 14px;
          }
        }
      }

      .topicBox {
        padding: 15px;
      }

      .daysBox {
        padding: 24px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .pyq_detail_wrapper {
    .pyqTabContent {
      .upcomingExamWrapper {
        .upcomingExamBox {
          padding: 10px;
          margin: 0px 12px 12px 0px;
          width: calc(50% - 6px);

          .pyqNameType {
            flex-direction: column;
            margin-bottom: 10px;

            .pyqName {
              width: 100%;

              span {
                font-size: 13px;
                line-height: 17px;
              }
            }

            .examDate {
              width: 100%;
              font-size: 11px;
            }
          }

          .attemBtn {
            .practice {
              font-size: 13px;
              padding: 5px 6px;
            }

            .attempt {
              font-size: 13px;
            }
          }

          .pyqNameDateDuration {
            margin-bottom: 5px;

            .pyqNameDate {
              p {
                font-size: 12px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .pyqTabBox {
      ul {
        li {
          button {
            min-width: 170px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .pyq_detail_wrapper {
    .pyqTabContent {
      min-height: auto;
      padding: 10px 0px;

      .upcomingExamWrapper {
        .upcomingExamBox {
          margin: 0px 0px 12px 0px;
          width: 100%;

          .pyqNameType {
            .pyqName {
              span {
              }
            }

            .examDate {
            }
          }

          .attemBtn {
            .practice {
            }

            .attempt {
            }
          }

          .pyqNameDateDuration {
            .pyqNameDate {
              p {
              }
            }
          }
        }
      }

      .topicWiseWrapper {
        .topicBox {
          padding: 10px;
          width: 100%;
          margin: 0px 0px 10px 0px;
        }
      }
    }

    .pyqTabBox {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      ul {
        margin-left: 0;
        li {
          button {
            min-width: 130px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
