.subjectTopicWiseMarksWrapper {
  background-color: #f5f4f8;
  min-height: 100vh;

  .subjectTopicWiseMarksInrWrapper {
    padding-top: 101px;
    padding-bottom: 20px;

    .sbjtTopicWiseMarksInr {
      margin: 20px 50px 0px;
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;

      .backBtn {
        cursor: pointer;

        img {
        }
      }

      .subjectTopicDetailWrapper {
        .subjectTopicTabBox {
          margin-bottom: 15px;
          // margin-left: 300px;
          display: flex;
          justify-content: space-between;

          ul {
            border: none;
            margin: 0 auto;
            margin-bottom:20px;
            // margin-left: 300px;
            width: max-content;
            background-color: #f5f4f8;
            border-radius: 22px;
            overflow: hidden;

            li {
              border: none;

              button {
                border: none;
                color: #000;
                font-size: 15px;
                min-width: 120px;
                border-radius: 0px;
                min-width: 200px;
              }

              .nav-link.active {
                color: #fff;
                background-color: #6D48EF;
                border: none;
              }
            }
          }
        }

        .subjectTopicTabContent {
          min-height: 65vh;
          padding: 15px 15px;







            
          

          
        }

        // .topicWiseMarksBox {
        //   h3 {
        //     text-align: center;
        //     font-size: 25px;
        //     font-weight: 600;
        //     margin-bottom: 25px;
        //   }
        //   .subjectsListWrapper {
        //     .subjectsList {
        //       display: flex;
        //       background: #f5f4f8;
        //       max-width: max-content;
        //       margin: 0 auto;
        //       border-radius: 20px;
        //       overflow: hidden;

        //       margin-bottom: 60px;
        //       margin-top: 60px;

        //       .single_select_subject {
        //         &:last-child {
        //           margin-right: 0px;
        //         }

        //         input[type="radio"] {
        //           opacity: 0;
        //           position: fixed;
        //           width: 0;
        //         }

        //         label {
        //           padding: 8px 20px;
        //           font-size: 15px;
        //           font-weight: 500;
        //           letter-spacing: 0.2px;
        //           color: #6b6876;
        //           cursor: pointer;
        //           min-width: 150px;
        //           text-align: center;
        //         }

        //         input[type="radio"]:checked + label {
        //           background-color: #ff933a;
        //           border: none;
        //           color: #ffffff;
        //         }
        //       }
        //     }
        //   }

        //   .tabListWrapper {
        //     .tabsList {
        //       display: flex;
        //       background: #f5f4f8;
        //       max-width: max-content;
        //       margin: 0 auto;
        //       border-radius: 20px;
        //       overflow: hidden;

        //       margin-bottom: 60px;
        //       margin-top: 60px;

        //       .single_select_tab {
        //         &:last-child {
        //           margin-right: 0px;
        //         }

        //         input[type="radio"] {
        //           opacity: 0;
        //           position: fixed;
        //           width: 0;
        //         }

        //         label {
        //           padding: 8px 20px;
        //           font-size: 15px;
        //           font-weight: 500;
        //           letter-spacing: 0.2px;
        //           color: #6b6876;
        //           cursor: pointer;
        //           min-width: 150px;
        //           text-align: center;
        //         }

        //         input[type="radio"]:checked + label {
        //           background-color: #6d48ef;
        //           border: none;
        //           color: #ffffff;
        //         }
        //       }
        //     }
        //   }

        //   .topicAnalysisData {
        //     display: flex;
        //     flex-wrap: wrap;

        //     .testProgressIndicator {
        //       background: #fff;
        //       padding: 15px;
        //       border-radius: 8px;
        //       margin-bottom: 40px;
        //       width: calc(50% - 10px);
        //       margin-right: 20px;

        //       &:nth-child(2n + 2) {
        //         margin-right: 0px;
        //       }

        //       .topicDetail {
        //         display: flex;
        //         justify-content: space-between;

        //         h4 {
        //           font-size: 13px;
        //           color: #000;
        //           margin-bottom: 18px;
        //           font-weight: 600;
        //         }

        //         .Qmark {
        //           font-weight: 500;
        //         }
        //       }

        //       .ProgressBar_Line {
        //         position: relative;
        //       }

        //       .ProgressBar_Line span {
        //         position: absolute;
        //         top: -16px;
        //         font-size: 14px;
        //       }

        //       .progress {
        //         height: 18px;
        //         margin-bottom: 5px;

        //         .progress-bar {
        //           background: #668de1;
        //           border-radius: 5px;
        //         }
        //       }

        //       .questionPercentage {
        //         display: flex;
        //         justify-content: space-between;
        //         padding: 0px 3px;

        //         span {
        //           color: #acacac;
        //           font-size: 14px;
        //         }
        //       }
        //     }
        //   }

        //   .subjectMarksSmry {
        //     display: flex;
        //     flex-wrap: wrap;
        //     margin-bottom: 50px;

        //     .marksChart {
        //       width: calc(50% - 10px);
        //       background: #f5f4f8;
        //       border-radius: 8px;
        //       padding: 10px;
        //       margin-right: 20px;

        //       .marksTitle {
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         margin-bottom: 5px;

        //         h6 {
        //           margin-bottom: 0px;
        //         }

        //         .marksIndicator {
        //           display: flex;

        //           .mrk_indc {
        //             margin-right: 10px;
        //             font-size: 12px;

        //             span {
        //               width: 8px;
        //               height: 8px;
        //               border-radius: 50%;
        //               margin-right: 5px;
        //               display: inline-block;
        //             }

        //             .cutOff {
        //               background-color: #668de1;
        //             }

        //             .you {
        //               background-color: #31d680;
        //             }

        //             .topper {
        //               background-color: #ff933a;
        //             }
        //           }
        //         }
        //       }

        //       .canvasjs-chart-credit,
        //       .canvasjs-chart-toolbar {
        //         display: none;
        //       }
        //     }

        //     .marksSummary {
        //       border: 1px solid #eeeeee;
        //       background: #f5f4f8;
        //       border-radius: 8px;
        //       width: calc(50% - 10px);
        //       padding: 10px;

        //       ul {
        //         padding: 0px;
        //         margin: 0px;

        //         li {
        //           list-style: none;
        //           display: flex;
        //           justify-content: space-between;
        //           margin: 12px 0px;

        //           span {
        //             font-size: 12px;

        //             &:last-child {
        //               font-size: 14px;
        //               font-weight: 500;
        //             }
        //           }
        //         }
        //       }
        //     }

        //     .rank {
        //       //  margin:auto;
        //       margin-top: 30px;
        //       margin-left: 200px;

        //       width: 60%;
        //       height: 80%;
        //       background: #f5f4f8;
        //       border-radius: 8px;
        //       padding: 10px;
        //       margin-right: 20px;
        //     }
        //   }
        // }

        // .topicWiseAnalysis {
        //   h3 {
        //     text-align: center;
        //     font-size: 25px;
        //     font-weight: 600;
        //     margin-bottom: 25px;
        //   }
        // }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .subjectWiseMarksWrapper {
    .subjectWiseMarksInrWrapper {
      .sbjtWiseMarksInr {
        margin: 20px 0px 0px !important;

        .topicWiseAnalysis {
          h3 {
          }

          .topicAnalysisTab {
            .topicAnalysisData {
              .testProgressIndicator {
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .subjectWiseMarksWrapper {
    .subjectWiseMarksInrWrapper {
      .sbjtWiseMarksInr {
        margin: 20px 0px 0px;

        .topicWiseAnalysis {
          h3 {
            font-size: 22px;
          }

          .topicAnalysisTab {
            .topicAnalysisData {
              .testProgressIndicator {
                margin-bottom: 0px;
                width: 100%;
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .subjectTopicWiseMarksWrapper {

    .subjectTopicWiseMarksInrWrapper {
      padding-top: 50px;

      .sbjtTopicWiseMarksInr {
        margin: 20px 0px 0px;

        .subjectTopicDetailWrapper {
          .subjectTopicTabBox {
            ul {
              width: auto;
              margin-bottom: 0;
              li {
                button {
                  min-width: 10px;
                }
              }
            }
          }
          .subjectTopicTabContent{
            padding: 0;
            .subjectWiseMarksBox{
              .subjectMarksSmry{
                display: block;
                .marksChart{
                  width: 100%;
                  margin-bottom: 20px;
                }
                .marksSummary{
                  width: 100%;
                }
              }
            }
            .topicWiseMarksBox{
              .topicWiseAnalysis{
                .subjectsListWrapper {
                  .subjectsList{
                    margin-bottom: 25px;
                    .single_select_subject {
                      label{
                        min-width: 10px;
                      }
                    }
                  }
                }
              }
              .topicAnalysisData{
                display: block;
                .testProgressIndicator{
                  width: 100%;
                  margin-right: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

