.chat_header_wrapper {
  background-color: #6d48ef;
  border-radius: 10px 10px 0 0;
  .chat_header_inner_wrapper {
    .sec_1_wrapper {
      margin: 0;
      padding: 10px 0;
      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}
