.referal_card_container {
  // display: flex;
  // justify-content: space-between;
  padding: 0.5rem 0rem;
  border-bottom: 0.6px solid #e3e3e3;
  .referal_card_content {
    display: flex;
    justify-content: space-between;
    .referal_card {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;

      .referal_input {
        //   border-color: #000000;
        //   width: 1.25rem;
        //   height: 1.25rem;
        //   appearance: none;
        //   background-color: white;
        //   cursor: pointer;
        //   border: 1px solid #000;
        //   position: relative;
        //   margin-right: 0.3rem;
        //   border-radius: 0.17rem;

        &:checked {
          // background-color: #02262b;
          // border: 2px solid #02262b;
        }

        &:focus {
          outline: none;
        }

        &:checked::after {
          // content: "";
          // position: absolute;
          // // top: 2px;
          // left: 6px;
          // bottom: 2px;
          // right: 6px;
          // width: 6px;
          // height: 12px;
          // border: 1px solid #ffffff;
          // border-width: 0 2px 2px 0;
          // transform: rotate(45deg);
        }
      }

      .referal_details {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .referal_name {
          color: #121212;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .course_name {
          color: #2d2d2d;
          font-family: Poppins;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0.13rem 0;
          width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .referal_amnt_and_date {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1.87rem;

          .amnt {
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            span {
              color: #5a5a5a;
              font-family: Poppins;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration: line-through;
              margin-left: 0.4rem;
            }
          }

          //   .referal_date {
          //     color: #2d2d2d;
          //     font-family: Poppins;
          //     font-size: 0.5625rem;
          //     font-style: normal;
          //     font-weight: 400;
          //     line-height: normal;
          //   }
        }
      }
    }

    .referal_date {
      color: #2d2d2d;
      font-family: Poppins;
      font-size: 0.5625rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: flex-end;
    }

    .status_container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .statuss {
        color: #2d2d2d;
        font-family: Poppins;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .amnt_received {
        color: #3c9d00;
        text-align: right;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0.13rem 0;
      }
      .progresss {
        color: #ff9c19;
      }

      .redeem {
        color: #ff4141;
      }

      .final_status {
        color: #ff8400;
        font-family: Poppins;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        img {
          width: 0.4375rem;
          height: 0.4375rem;
        }
      }

      .cancel {
        color: #b50303;
        font-family: Poppins;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .redeem_date {
    font-size: 0.6rem;
    color: red;
    margin-left: 20px;
    margin-top: 7px;
  }
}

.disable {
  pointer-events: none;
  opacity: 0.5;
}

.referal_list_large {
  .referal_card_container {
    // display: flex;
    // justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 0.6px solid #e3e3e3;

    .referal_card_content {
      display: flex;
      justify-content: space-between;
      .referal_card {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.75rem;

        .referal_input {
          // border-color: #000000;
          // width: 1.75rem;
          // height: 1.75rem;
          // appearance: none;
          // background-color: white;
          // cursor: pointer;
          // border: 1px solid #000;
          // position: relative;
          // margin-right: 1rem;
          // border-radius: 0.15rem;

          &:checked {
            //   background-color: #02262b;
            //   border: 2px solid #02262b;
          }

          &:focus {
            outline: none;
          }

          &:checked::after {
            //   content: "";
            //   position: absolute;
            //   // top: 2px;
            //   left: 9px;
            //   bottom: 2px;
            //   right: 6px;
            //   width: 9px;
            //   height: 15px;
            //   border: 1px solid #ffffff;
            //   border-width: 0 3px 3px 0;
            //   transform: rotate(45deg);
          }
        }

        .referal_details {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          .referal_name {
            color: #121212;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .course_name {
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0.25rem 0;

            width: 300px;
            white-space: normal;
            overflow: visible;
            text-overflow: clip;
          }

          .referal_amnt_and_date {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.87rem;

            .amnt {
              color: #2d2d2d;
              font-family: Poppins;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;

              span {
                margin-left: 0.4rem;
                color: #5a5a5a;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration: line-through;
              }
            }
          }
        }
      }

      .referal_date {
        display: flex;
        align-items: flex-end;
        color: #2d2d2d;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .status_container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .statuss {
          color: #2d2d2d;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .amnt_received {
          margin: 0.25rem 0;
          color: #3c9d00;
          text-align: right;
          font-family: Poppins;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .progresss {
          color: #ff9c19;
        }

        .redeem {
          color: #ff4141;
        }

        .final_status {
          color: #ff8400;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          img {
            width: 0.75rem;
            height: 0.75rem;
          }
        }

        .cancel {
          color: #b50303;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .redeem_date {
      font-size: 0.8rem;
      color: red;
      margin-left: 20px;
      margin-top: 7px;
    }
  }

  .disable {
    pointer-events: none;
    opacity: 0.5;
  }
}
