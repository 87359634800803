.btn_wrapper1 {
  text-align: center;
  button {
    border: none;
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background: #6d48ef;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }
}
