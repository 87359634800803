.userCreateCustomQuestionBankContentComponent_wrapper {
  padding-top: 80px;
  .userCreateCustomQuestionBankContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      border-radius: 8px;
      background-color: #ffffff;
      .sec_2_inner_wrapper {
        padding: 20px;
        .sub_content_wrapper_1 {
          margin: 15px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #0f0404;
            }
          }
          .input_wrapper {
            margin: 10px 0;
            input[type="text"] {
              width: 100%;
              height: 45px;
              outline: none;
              border-radius: 25px;
              border: 2px solid #f0eef8;
              padding: 0 10px;
            }
          }
        }
        .sub_content_wrapper_2 {
          margin: 15px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #0f0404;
            }
          }
          .format_collection_wrapper {
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .single_format_wrapper {
              flex: 0 0 30%;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                width: 100%;
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 10px 20px;
                border-radius: 25px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                text-align: center;
              }
              input[type="radio"]:checked + label {
                background-color: #ff5d00;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
        .sub_content_wrapper_3 {
          margin: 15px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #0f0404;
            }
          }
          // .questions_count_collection_wrapper {
          //   margin: 10px 0;
          //   display: flex;
          //   align-items: center;
          //   justify-content: space-between;
          //   .single_count_wrapper {
          //     flex: 0 0 23%;
          //     input[type="radio"] {
          //       opacity: 0;
          //       position: fixed;
          //       width: 0;
          //     }
          //     label {
          //       width: 100%;
          //       border: solid 1px #f0eef8;
          //       background-color: #fafafd;
          //       padding: 10px 20px;
          //       border-radius: 25px;
          //       font-size: 16px;
          //       font-weight: 500;
          //       letter-spacing: 0.2px;
          //       color: #6b6876;
          //       cursor: pointer;
          //       text-align: center;
          //     }
          //     input[type="radio"]:checked + label {
          //       background-color: #ff5d00;
          //       border: none;
          //       color: #ffffff;
          //     }
          //   }
          // }
          .questions_count__wrapper {
            .questions_count_content_wrapper_1 {
              display: flex;
              justify-content: center;
              .content_wrapper {
                label {
                  font-size: 18px;
                  font-weight: 600;
                  color: #6b6876;
                }
              }
            }
            .questions_count_content_wrapper_2 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 10px;
              .content_wrapper {
                flex: 0 0 3%;
                text-align: center;
                label {
                  font-size: 16px;
                  font-weight: 600;
                  color: #000000;
                }
              }
              .range_wrapper {
                flex: 0 0 94%;
                input[type="range"] {
                  height: 20px;
                  -webkit-appearance: none;
                  margin: 10px 0;
                  width: 100%;
                }
                input[type="range"]:focus {
                  outline: none;
                }
                input[type="range"]::-webkit-slider-runnable-track {
                  width: 100%;
                  background: #ff5d007a;
                  border-radius: 25px;
                  border: none;
                }
                input[type="range"]::-webkit-slider-thumb {
                  -webkit-appearance: none;
                  border: 1px solid #000000;
                  height: 20px;
                  width: 15px;
                  border-radius: 50%;
                  background: red;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .sub_content_wrapper_4 {
          margin: 15px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #0f0404;
            }
          }
          .difficulty_level_collection_wrapper {
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .single_difficulty_level_wrapper {
              flex: 0 0 23%;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                width: 100%;
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 10px 20px;
                border-radius: 10px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                text-align: center;
              }
              input[type="radio"]:checked + label {
                background-color: #ff5d00;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
        .sub_content_wrapper_5 {
          margin: 15px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #0f0404;
            }
          }
          .questions_type_collection_wrapper {
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .single_question_type_wrapper {
              flex: 0 0 18%;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                width: 100%;
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 10px 20px;
                border-radius: 25px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                text-align: center;
              }
              input[type="radio"]:checked + label {
                background-color: #ff5d00;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
        .sub_content_wrapper_6 {
          margin: 15px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #0f0404;
            }
          }
          .subject_collection_wrapper {
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            .single_subject_wrapper {
              margin: 5px;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                width: 100%;
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 10px 20px;
                border-radius: 25px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                text-align: center;
              }
              input[type="radio"]:checked + label {
                background-color: #ff5d00;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
        .sub_content_wrapper_7 {
          margin: 15px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #0f0404;
            }
          }
          .btn_container {
            display: flex;
            justify-content: flex-end;
            .single_btn_wrapper {
              margin: 5px;
              display: flex;
              align-items: center;
              border: solid 1px #f0eef8;
              background-color: #fafafd;
              padding: 5px 10px;
              border-radius: 10px;
              input[type="checkbox"] {
                width: 20px;
                height: 20px;
                cursor: pointer;
              }
              label {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                text-align: center;
                margin: 0 5px;
                cursor: pointer;
              }
            }
          }
          .topic_collection_wrapper {
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            .single_topic_wrapper {
              margin: 5px;
              display: flex;
              align-items: center;
              border: solid 1px #f0eef8;
              background-color: #fafafd;
              padding: 5px 10px;
              border-radius: 10px;
              input[type="checkbox"] {
                width: 20px;
                height: 20px;
                cursor: pointer;
              }
              label {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                text-align: center;
                margin: 0 5px;
                cursor: pointer;
              }
            }
          }
        }
        .sub_content_wrapper_8 {
          margin: 20px 0;
          .btn_wrapper {
            text-align: center;
            button {
              border: none;
              outline: none;
              background-color: #6d48ef;
              padding: 10px 15px;
              border-radius: 25px;
              font-size: 18px;
              font-weight: 600;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userCreateCustomQuestionBankContentComponent_wrapper {
    padding-top: 60px;
    .userCreateCustomQuestionBankContentComponent_inner_wrapper {
      padding: 10px;
      .sec_1_wrapper {
        margin: 10px 0;
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        margin: 10px 0;
        .sec_2_inner_wrapper {
          padding: 10px;
          .sub_content_wrapper_1 {
            margin: 10px 0;
            .text_content_wrapper {
              .text_content {
              }
            }
            .input_wrapper {
              input[type="text"] {
              }
            }
          }
          .sub_content_wrapper_2 {
            margin: 10px 0;
            .text_content_wrapper {
              .text_content {
              }
            }
            .format_collection_wrapper {
              .single_format_wrapper {
                flex: none;
                input[type="radio"] {
                }
                label {
                }
                input[type="radio"]:checked + label {
                }
              }
            }
          }
          .sub_content_wrapper_3 {
            margin: 10px 0;
            .text_content_wrapper {
              .text_content {
              }
            }
            .questions_count_collection_wrapper {
              .single_count_wrapper {
                flex: none;
                input[type="radio"] {
                }
                label {
                }
                input[type="radio"]:checked + label {
                }
              }
            }
          }
          .sub_content_wrapper_4 {
            margin: 10px 0;
            .text_content_wrapper {
              .text_content {
              }
            }
            .difficulty_level_collection_wrapper {
              flex-wrap: wrap;
              justify-content: flex-start;
              .single_difficulty_level_wrapper {
                margin: 5px;
                flex: none;
                input[type="radio"] {
                }
                label {
                }
                input[type="radio"]:checked + label {
                }
              }
            }
          }
          .sub_content_wrapper_5 {
            margin: 10px 0;
            .text_content_wrapper {
              .text_content {
              }
            }
            .questions_type_collection_wrapper {
              flex-wrap: wrap;
              justify-content: flex-start;
              .single_question_type_wrapper {
                margin: 5px;
                flex: none;
                input[type="radio"] {
                }
                label {
                }
                input[type="radio"]:checked + label {
                }
              }
            }
          }
          .sub_content_wrapper_6 {
            margin: 10px 0;
            .text_content_wrapper {
              .text_content {
              }
            }
            .subject_collection_wrapper {
              .single_subject_wrapper {
                input[type="radio"] {
                }
                label {
                }
                input[type="radio"]:checked + label {
                }
              }
            }
          }
          .sub_content_wrapper_7 {
            margin: 10px 0;
            .text_content_wrapper {
              .text_content {
              }
            }
            .btn_container {
              .single_btn_wrapper {
                input[type="checkbox"] {
                }
                label {
                }
              }
            }
            .topic_collection_wrapper {
              .single_topic_wrapper {
                input[type="checkbox"] {
                }
                label {
                }
              }
            }
          }
          .sub_content_wrapper_8 {
            margin: 10px 0;
            .btn_wrapper {
              button {
              }
            }
          }
        }
      }
    }
  }
}
