////////No need to subtract scrollbar width in ipad,and mobile screens
.header_component_wrapper {
  width: 100%;
  position: fixed;
  z-index: 999;
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;
  .headerTopMenuWrapper {
    background-color: #ebebeb;
    .upper_layer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .headerTopMenuLeft {
    }
    .headerTopMenuLeft {
    }
  }
  .header_inner_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    margin: 0px auto;
    .header_left_wrapper {
      // flex: 0 0 58%;
    }
    .header_right_wrapper {
      // flex: 0 0 42%;
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .header_component_wrapper {
    // width: calc(100vw - 190px);
    .header_inner_wrapper {
      padding: 10px 10px;
      .header_left_wrapper {
        flex: 0 0 75%;
      }
      .header_right_wrapper {
        flex: 0 0 50%;
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .header_component_wrapper {
    // width: calc(100vw - 160px);
    .header_inner_wrapper {
      padding: 15px 5px;

      .header_left_wrapper {
        flex: 0 0 72%;
      }
      .header_right_wrapper {
        flex: 0 0 28%;
      }
    }
  }
}
