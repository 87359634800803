.successful_wrapper {
  .modal-content {
    width: 28.75rem;
    .modal-header {
      border: none;
    }

    .modal-body {
      padding: 1.5rem 1.88rem 2.5rem 1.88rem;
    }

    .successful_container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 5.625rem;
        height: 5.625rem;
        margin-bottom: 1rem;
      }

      h4 {
        color: #000;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // margin: 1rem 0 0.25 0;
      }

      h5 {
        color: #585858;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 19.25rem;
        margin-top: 0.25rem;
        margin-bottom: 2rem;
      }

      .ok_btn {
        border-radius: 0.25rem;
        border: 0.6px solid #b91311;
        background: #b50503;
        width: 50%;
        text-align: center;
        cursor: pointer;
        button {
          color: #fff;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 0.75rem;
        }
      }
    }
  }
}
