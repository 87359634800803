.mock_practice_test_ppr_test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .section_content_wrapper {
            display: flex;
            align-items: center;
            justify-content: center !important;
            margin: 20px 0px;
  
            .btn_wrapper {
              button {
                border: none;
                outline: none;
                width: 150px;
                height: 40px;
                border-radius: 25px;
                background-color: #6d48ef;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                color: #ffffff;
              }
            }
          }
        }
      }
  
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .section_content_wrapper_backdrop{
              display: none;
            }
            .right_btn_wrapper{
              display: none;
            }
  
            .left_side_content_wrapper {
              width: 32%;
              background-color: #ffffff;
              padding: 20px;
              position: sticky;
              top: 0px;
              height: 100vh;
  
              .sub_section_1_wrapper {
                flex: 0 0 100%;
                .closebtn{
                  display: none;
                }
  
                .text_content_wrapper {
                  margin-bottom: 20px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
  
                  .text_content {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 600;
                    color: #0f0404;
                  }
  
                  button {
                    font-weight: bold;
                    text-decoration: underline;
                  }
                }
  
                .questions_serial_number_collection {
                  overflow-y: scroll;
                  height: calc(100vh - 128px);
  
                  .single_question_number_wrapper {
                    margin: 10px 5px;
  
                    .arrow {
                      width: 8px;
                    }
  
                    .unanswered_lbl {
                      font-size: 14px;
                      font-weight: 500;
                      color: #000;
                      padding: 15px;
                      border-radius: 8px;
                      background-color: #fff;
                      border: 1px solid #e2e2e2;
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      background: #f5f4f8;
                      justify-content: space-between;
                      width: 100%;
                      min-height: 74px;
  
                      &.answered_lbl {
                        border: 1px solid #e2e2e2;
                        background-color: #48ef4c;
                      }
  
                      &.current_lbl {
                        border: 1px solid #ff933a;
                        cursor: pointer;
                        background: inherit;
                      }
  
                      .questionBox {
                        display: flex;
                        align-items: baseline;
                        margin-right: 5px;
                        overflow-x: hidden;
                        max-height: 70px;
                        overflow-y: hidden;
  
                        .QBox {
                          overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          display: inline-block;
                          vertical-align: middle;
                          margin-left: 5px;
  
                          .wrs_chemistry {
                            display: block;
                            margin: 20px auto;
                            font-family: Arial, sans-serif;
                            font-size: 14px;
                            // height: 100px;
                            overflow-x: auto;
  
                            mtable {
                              width: 100%;
                              border-collapse: collapse;
                              border: 1px solid #ccc;
  
                              mtr {
                                &:nth-child(even) {
                                  background-color: #f5f5f5;
                                }
  
                                mtd {
                                  padding: 5px;
                                  border: 1px solid #ccc;
                                  vertical-align: middle;
                                  text-align: center;
  
                                  mtext {
                                    display: inline-block;
                                  }
  
                                  mtable {
                                    margin-top: 5px;
                                    width: 100%;
  
                                    mtr {
                                      mtd {
                                        padding: 5px;
                                        border: 1px solid #ccc;
                                        vertical-align: middle;
                                        text-align: center;
                                        display: inline-block;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
  
                          .wrs_chemistry::-webkit-scrollbar {
                            width: 4px;
                          }
  
                          .wrs_chemistry::-webkit-scrollbar-track {
                            background-color: #f1f1f1;
                          }
  
                          .wrs_chemistry::-webkit-scrollbar-thumb {
                            background-color: #888;
                            border-radius: 2px;
                          }
  
                          .wrs_chemistry math,
                          .wrs_chemistry mo,
                          .wrs_chemistry mi,
                          .wrs_chemistry mn,
                          .wrs_chemistry msub,
                          .wrs_chemistry mtext,
                          .wrs_chemistry mfenced,
                          .wrs_chemistry msup,
                          .wrs_chemistry mrow {
                            display: inline;
                            height: 20px;
                          }
  
                          .tableContainer {
                            max-height: 200px;
                            max-height: 300px; /* Adjust the max-height as per your requirements */
                            overflow-y: auto;
                            overflow-x: auto;
  
                            ::-webkit-scrollbar {
                              width: 4px !important;
                              height: 2px !important; /* Adjust the width of the scrollbar */
                            }
  
                            ::-webkit-scrollbar-track {
                              background-color: #f1f1f1; /* Set the background color of the scrollbar track */
                            }
  
                            ::-webkit-scrollbar-thumb {
                              background-color: #888; /* Set the color of the scrollbar thumb */
                              border-radius: 4px; /* Add border radius to the scrollbar thumb */
                            }
  
                            ::-webkit-scrollbar-thumb:hover {
                              background-color: #555; /* Set the color of the scrollbar thumb on hover */
                            }
                          }
  
                          .tableContainer table {
                            width: 50%;
                            table-layout: fixed;
                          }
  
                          .tableContainer table td {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          }
  
                          .tableContainer::-webkit-scrollbar {
                            width: 4px !important;
                            height: 4px !important; /* Adjust the width of the scrollbar */
                          }
  
                          img {
                            width: 100%;
                            max-height: 100px;
                            object-fit: cover;
                          }
                          &.twoLines {
                            p {
                              display: -webkit-box;
                              -webkit-box-orient: vertical;
                              -webkit-line-clamp: 1;
                              overflow: hidden;
                              margin-left: 5px;
                            }
                          }
                        }
  
                        p {
                          margin-left: 2px;
                          margin-bottom: 0px;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                        }
  
                        p > p {
                          /* Add  desired styles for nested <p> elements here */
                          display: inline;
                          margin: 0;
                        }
                      }
                    }
                  }
                }
              }
  
              .sub_section_2_wrapper {
                flex: 0 0 100%;
                margin-bottom: 20px;
  
                .question_marking_wrapper {
                  display: flex;
                  justify-content: flex-start;
                  flex-wrap: wrap;
  
                  .single_marking {
                    margin-right: 15px;
  
                    &:last-child {
                      margin-right: 0px;
                    }
  
                    .box_pic {
                      width: 14px;
                      height: 14px;
                      border-radius: 3px;
                      margin-right: 5px;
                    }
  
                    .box_pick_background_1 {
                      background-color: #48ef4c;
                      border: 1px solid #ebebeb;
                    }
  
                    .box_pick_background_2 {
                      background-color: #f99746;
                    }
  
                    .box_pick_background_3 {
                      background-color: #e2e2e2;
                      border: 1px solid #e2e2e2;
                    }
  
                    .box_pick_background_4 {
                      background: #ffc4c4;
                      border: 1px solid #ffc4c4;
                    }
  
                    .box_pick_background_5 {
                      background: #cddc39;
                      border: 1px solid #cddc39;
                    }
  
                    .question_marking_text {
                      font-size: 12px;
                      font-weight: 500;
                      color: #000;
                      vertical-align: middle;
                      margin-top: -4px;
                    }
                  }
                }
              }
            }
  
            .right_side_content_wrapper {
              width: 68%;
              border-radius: 8px;
              padding: 10px;
              background-color: #f5f4f8;
  
              .sub_section_wrapper {
                max-width: 750px;
                margin: 0 auto;
                scroll-behavior: smooth;
  
                .sub_sub_section_wrapper_1 {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
  
                  .right_side_content_wrapper {
                    flex: 0 0 100%;
                    display: flex;
                    justify-content: flex-end;
  
                    .review_image_wrapper {
                      margin: 0 10px;
  
                      img {
                        cursor: pointer;
                      }
                    }
  
                    .bookmark_image_wrapper {
                      margin: 0 10px;
  
                      img {
                        cursor: pointer;
                      }
                    }
                  }
                }
  
                .testProgressIndicator {
                  background: #fff;
                  padding: 5px 10px;
                  border-radius: 8px;
                  margin-bottom: 20px;
  
                  h4 {
                    font-size: 14px;
                    color: #000;
                    margin-bottom: 16px;
                  }
  
                  .ProgressBar_Line {
                    position: relative;
                  }
  
                  .ProgressBar_Line span {
                    position: absolute;
                    top: -16px;
                    font-size: 14px;
                  }
  
                  .progress {
                    height: 8px;
                    margin-bottom: 5px;
  
                    .progress-bar {
                      background: #ff933a;
                      border-radius: 9px;
                    }
                  }
  
                  .questionPercentage {
                    display: flex;
                    justify-content: space-between;
  
                    span {
                      color: #707070;
                      font-size: 14px;
                    }
                  }
                }
  
                .sectionWiseTestWrapper {
                  .subjectWiseTest {
                    background: #fff;
                    display: flex;
                    padding: 7px 10px;
                    border-radius: 36px;
                    justify-content: space-between;
                    margin-bottom: 15px;
  
                    .single_select_subject {
                      input[type="radio"] {
                        opacity: 0;
                        position: fixed;
                        width: 0;
  
                        &:checked + label {
                          background-color: #ff933a;
                          border: none;
                          color: #ffffff;
                          border-radius: 19px;
                        }
                      }
  
                      label {
                        padding: 8px 20px;
                        font-size: 15px;
                        font-weight: 500;
                        letter-spacing: 0.2px;
                        color: #6b6876;
                        cursor: pointer;
                        min-width: 150px;
                        text-align: center;
                      }
                    }
                  }
  
                  .sectionWiseTest {
                    display: flex;
                    width: max-content;
                    margin: 0 auto;
                    margin-bottom: 15px;
                  }
                }
  
                .sub_sub_section_wrapper_2 {
                  h2 {
                    text-align: center;
                    font-size: 25px;
                    font-weight: bold;
                    margin-bottom: 20px;
                  }
  
                  .text_content_wrapper {
                    .typeLanguageWrapper {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 5px;
  
                      .stopwatch_timer {
                        font-family: 'Poppins' !important;
                        color: #6d48ef;
                        font-size: 15px;
                        justify-content: center;
                      }
  
                      span {
                        color: #707070;
                        font-size: 12px;
                      }
  
                      .markReview {
                        display: flex;
  
                        .bookmark_image_wrapper {
                          margin: 0px 10px;
  
                          span {
                            margin-right: 5px;
                          }
  
                          img {
                            cursor: pointer;
                          }
                        }
  
                        button {
                        }
                      }
                    }
  
                    .questionBox {
                      display: flex;
                      align-items: baseline;
                      margin-bottom: 20px;
  
                      .wrs_chemistry math,
                      .wrs_chemistry mo,
                      .wrs_chemistry mi,
                      .wrs_chemistry mn,
                      .wrs_chemistry msub,
                      .wrs_chemistry mtext,
                      .wrs_chemistry mfenced,
                      .wrs_chemistry msup,
                      .wrs_chemistry mrow {
                        display: inline;
                      }
  
                      .mtable-style {
                        display: block;
                        margin: 20px auto;
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        // height: 100px;
                        // overflow-x: auto;
  
                        mtable {
                          width: 80%;
                          border-collapse: collapse;
                          border: 1px solid #ccc;
  
                          mtr {
                            &:nth-child(even) {
                              background-color: #f5f5f5;
                            }
  
                            mtd {
                              padding: 5px;
                              border: 1px solid #ccc;
                              vertical-align: middle;
                              text-align: center;
  
                              mtext {
                                display: inline-block;
                              }
  
                              mtable {
                                margin-top: 5px;
                                width: 100%;
  
                                mtr {
                                  mtd {
                                    padding: 5px;
                                    border: 1px solid #ccc;
                                    vertical-align: middle;
                                    text-align: center;
                                    display: inline-block;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
  
                      .image-style {
                        width: 100%;
                        height: auto;
                        object-fit: contain;
                      }
  
                      .table-style {
                        width: 100%;
                        border-collapse: collapse;
  
                        td,
                        th {
                          border: 1px solid black;
                          padding: 8px;
                        }
                      }
  
                      p {
                        line-height: 1.5;
                        font-family: Poppins;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
  
                        .math-variant-normal {
                          font-style: italic;
  
                          sup {
                            vertical-align: super;
                            font-size: smaller;
                          }
  
                          sub {
                            vertical-align: sub;
                            font-size: smaller;
                          }
  
                          mo {
                            margin: 0 2px;
                          }
  
                          mrow {
                            display: inline;
                          }
  
                          mfenced {
                            display: inline;
                          }
  
                          mfrac {
                            display: inline;
                          }
  
                          mi {
                            font-style: italic;
                          }
  
                          mn {
                            font-style: normal;
                          }
  
                          mo[stretchy="true"] {
                            width: 1.2em;
                            text-align: center;
                          }
  
                          mo[stretchy="true"]:before {
                            content: "";
                            display: inline-block;
                            height: 0.85em;
                            vertical-align: middle;
                          }
  
                          mo[stretchy="true"][fence="true"] {
                            height: auto;
                            vertical-align: middle;
                          }
  
                          mo[stretchy="true"][fence="true"]:before {
                            content: "";
                            display: inline-block;
                            height: 0.4em;
                            vertical-align: middle;
                          }
  
                          mo[largeop="true"] {
                            vertical-align: middle;
                          }
  
                          mo[largeop="true"]:before {
                            content: "";
                            display: inline-block;
                            height: 0.5em;
                            vertical-align: middle;
                          }
                        }
                      }
  
                      span {
                        margin-right: 5px !important;
                      }
  
                      .text_content {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 500;
                        color: #000;
  
                        p {
                          margin-bottom: 0px;
                        }
                      }
  
                      .hide {
                        display: none;
                      }
  
                      .show {
                        display: block;
                      }
                    }
                  }
                }
  
                .sub_sub_section_wrapper_3 {
                  //single-select start
                  .single_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 30px;
  
                    .single_select {
                      width: calc(50% - 8px);
                      margin: 10px 16px 10px 0px;
  
                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }
  
                      .classCorrect {
                        border: 1px solid #5ddd9b !important;
                        background: #e1f1ec !important;
                      }
  
                      .classWrong {
                        border: 1px solid #f93030 !important;
                        background: #f6e0e4 !important;
                      }
  
                      input[type="checkbox"] {
                        opacity: 1;
                        position: fixed;
                        width: 0;
                      }
  
                      .single_option_wrapper {
                        min-height: 50px;
                        background-color: #fff;
  
                        border-radius: 8px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;
  
                        .option_initial {
                          margin-right: 10px;
  
                          .text_content_2 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #acacac;
                          }
                        }
  
                        .option_final {
                          .text_content_3 {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 1.5;
                            // color: #000;
                            color: #333;
                          }
  
                          p {
                            margin-bottom: 0px;
                          }
                        }
                      }
  
                      input[type="checkbox"]:checked + .single_option_wrapper {
                        border: 2px solid #6d48ef;
  
                        .option_initial {
                          .text_content_2 {
                            font-weight: 600;
                          }
                        }
  
                        .option_final {
                          .text_content_3 {
                            font-weight: 600;
                          }
                        }
                      }
                    }
                  }
                  // single-select end
  
                  .hide {
                    display: none !important;
                  }
  
                  .show {
                    display: flex !important;
                  }
  
                  .show2 {
                    display: block !important;
                  }
  
                  //multi-select start
                  .multi_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
  
                    .single_select {
                      width: calc(50% - 8px);
                      margin: 10px 16px 10px 0px;
  
                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }
  
                      input[type="checkbox"] {
                        opacity: 1;
                        position: fixed;
                        width: 0;
                      }
  
                      .single_option_wrapper {
                        min-height: 50px;
                        border: solid 1px #f0eef8;
                        background-color: #fafafd;
  
                        border-radius: 15px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;
  
                        .option_initial {
                          margin-right: 10px;
  
                          .text_content_2 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }
  
                        .option_final {
                          .text_content_3 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }
                      }
  
                      input[type="checkbox"]:checked + .single_option_wrapper {
                        border: 2px solid #6d48ef;
  
                        .option_initial {
                          .text_content_2 {
                            font-weight: 600;
                          }
                        }
  
                        .option_final {
                          .text_content_3 {
                            font-weight: 600;
                          }
                        }
                      }
                    }
                  }
                  // multi-select end
  
                  //integer start
                  .input_wrapper {
                    .input_inner_wrapper {
                      margin-bottom: 30px;
  
                      input {
                        border: none;
                        outline: none;
                        width: 100%;
                        border: solid 1px #f0eef8;
                        background-color: #fff;
                        height: 50px;
                        border-radius: 8px;
                        padding: 0 5px;
                        font-size: 16px;
                        font-weight: 500;
                      }
  
                      input[type="number"]:focus {
                        border: 2px solid #6d48ef;
                      }
  
                      input[type="number"]::-webkit-outer-spin-button,
                      input[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                    }
                  }
                }
  
                .sub_sub_section_wrapper_4 {
                  margin-bottom: 15px;
  
                  .checkbox_wrapper {
                    input[type="checkbox"] {
                      margin-right: 10px;
                    }
  
                    label {
                      font-size: 13px;
                      vertical-align: text-top;
                      color: #09041a;
                    }
                  }
                }
  
                .sub_sub_section_wrapper_5 {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
  
                  .left_side_wrapper {
                    width: calc(50% - 8px);
                    margin-right: 16px;
  
                    .btn_wrapper {
                      text-align: left;
  
                      button {
                        border: none;
                        outline: none;
                        border-radius: 8px;
                        background-color: #ffffff;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        color: #6d48ef;
                        padding: 10px;
                        width: 100%;
                        border: 1px solid #e2e2e2;
                        // text-decoration: underline;
                      }
                    }
                  }
  
                  .right_side_wrapper {
                    width: calc(50% - 8px);
  
                    .btn_wrapper {
                      text-align: right;
  
                      button {
                        border: none;
                        outline: none;
                        border-radius: 8px;
                        background-color: #ffffff;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        color: #fff;
                        padding: 10px;
                        width: 100%;
                        background: #6d48ef;
                        // text-decoration: underline;
                      }
                    }
                  }
                }
  
                // video Frame start
                .videoWrapper {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin-bottom: 50px;
  
                  .view_videoSolution_btn {
                    background: #ff933a;
                    width: 30%;
                    text-align: center;
                    padding: 10px;
                    border-radius: 10px;
                    margin-bottom: 30px;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #f5f4f8;
                  }
  
                  #iframe {
                    height: 420px;
                    width: 100%;
                  }
  
                  // .shakaPlayer {
                  //   height: 420px;
                  //   width: 100%;
                  //   background-color: #000000;
                  // }
                }
  
                // video Frame  End
  
                // solution section start
                .solution-text p {
                  margin-bottom: 10px;
                }
  
                .solution-text .equation {
                  font-style: italic;
                  font-family: "Courier New", monospace;
                  font-size: 16px;
                  // background-color: #f5f5f5;
                  padding: 10px;
                  display: inline-block;
                  vertical-align: middle;
                  border-bottom: 1px solid black;
                  padding: 2px;
                }
  
                /* Target MathJax elements */
                .solution-text .mjx-mi {
                  font-style: italic;
                }
  
                .solution-text .mjx-mo {
                  color: black;
                }
  
                .solution-text .mjx-mn {
                  font-weight: bold;
                  // color: red;
                  color: black;
                }
                /// solution scss end
  
                table {
                  border-collapse: collapse;
                  width: 500px;
                }
  
                table,
                th,
                td {
                  border: 1px solid #ccc;
                }
  
                th,
                td {
                  text-align: center;
                  padding: 10px;
                }
  
                .title {
                  font-weight: bold;
                  margin-bottom: 10px;
                }
  
                .math-expression {
                  font-style: italic;
                }
  
                .option {
                  margin-bottom: 10px;
                }
  
                .correct-answer {
                  color: green;
                }
  
                .incorrect-answer {
                  color: red;
                }
  
                .sub_sub_section_wrapper_6 {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
  
                  .text_content_wrapper {
                    .text_content {
                      margin: 0;
                      font-size: 18px;
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  //>>>>>>>>>>>>>>>>>>> on SubitModal start >>>>>>>>>>>>>>>>>> //
  
  .onSubmitModal {
    .modal-header {
      padding: 10px;
      border: 0px;
    }
  
    .modal-body {
      margin-top: 0px !important;
      .submit_Popup {
        .exit_img {
          width: 100%;
  
          img {
            width: 35%;
            height: 130px;
            margin-bottom: 20px;
            margin-top: -25px;
          }
        }
  
        h5 {
          font-size: 18px;
          font-weight: 600;
        }
  
        p {
          margin: 0 auto;
          max-width: 310px;
          margin-bottom: 20px;
        }
  
        .submitCancelBtn {
          button {
            border: 1px solid #6d48ef;
            border-radius: 6px;
            height: 40px;
            width: 150px;
            color: #fff;
            background: #6d48ef;
          }
  
          .cancel {
            color: #6d48ef;
            background: none;
            margin-right: 10px;
          }
        }
      }
    }
  }
  
  // >>>>>>>>>>>>> on SubmitModal End  >>>>>>>>>>>>>>>>>>>> //
  
  // >>>>>>>>>>>>>>>>>>>> onErrorModal Start >>>>>>>>>>>>>>>>> //
  .errorModal {
    margin-top: 70px;
  
    .modal-content {
      border-radius: 16px;
    }
    text-align: center;
  
    .modal-header {
      margin-top: 20px;
      font-weight: 600;
      font-family: "Poppins";
      font-style: normal;
  
      border-bottom: 0 none;
      padding: 0;
  
      .btn-close {
        width: 0;
        height: 0;
        margin-right: 20px;
      }
  
      h3 {
        color: #000000;
        font-weight: 600;
        margin-left: 150px;
        font-size: 20px;
      }
    }
  
    .modal-body {
      padding: 0;
  
      .error_Popup {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
  
        height: 85%;
        width: 85%;
        margin: auto;
      }
  
      .error_Popup .text_content {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
  
        letter-spacing: 0.02em;
        color: #606060;
      }
  
      .errorList {
        text-align: start;
        padding: 0;
        margin: 0;
      }
  
      .errorList .errorListItem {
        list-style: none;
        text-align: start;
        margin-bottom: 10px;
  
        font-size: 16px;
  
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        color: #000000;
      }
  
      .errorList .errorListItem input {
        font-size: 25px;
      }
  
      .errorList .errorListItem input[type="radio"] {
        height: 22px;
        width: 22px;
        vertical-align: middle;
        margin-right: 8px;
      }
  
      textarea::-webkit-input-placeholder {
        padding: 0;
        font-size: 14px;
  
        color: #b8b8b8;
      }
  
      button {
        padding: 10px 40px;
        font-weight: 500;
        background: #6d48ef;
        border-radius: 4px;
        color: #fff;
      }
  
      textarea {
        width: 100%;
        height: 85px;
        padding: 10px;
  
        border-radius: 4px;
        resize: none;
      }
    }
  }
  
  // >>>>>>>>>>>>>>>>>>> onErrorModal end  >>>>>>>>>>>>>>>>>>> //
  
  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 1199.98px) {
    .mock_practice_test_ppr_test_questions_view_wrapper {
      .test_questions_view_inner_wrapper {
        .sec_2_wrapper {
          .sec_2_inner_wrapper {
            .section_content_wrapper {
              .left_side_content_wrapper {
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 991.98px) {
    .mock_practice_test_ppr_test_questions_view_wrapper {
      .test_questions_view_inner_wrapper {
        .sec_2_wrapper {
          .sec_2_inner_wrapper {
            .section_content_wrapper {
              flex-direction: column;
  
              .left_side_content_wrapper {
                width: 100%;
                position: relative;
  
                .sub_section_1_wrapper {
                  .questions_serial_number_collection {
                    // height: 17vh;
                  }
                }
              }
  
              .right_side_content_wrapper {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 575.98px) {
    .mock_practice_test_ppr_test_questions_view_wrapper {
      .test_questions_view_inner_wrapper {
        .sec_2_wrapper {
          .sec_2_inner_wrapper {
            .section_content_wrapper {
              flex-direction: column;
              .section_content_wrapper_backdrop{
                height: 100vh;
                width: 100vw;
                z-index: 1995;
                background-color: #000;
                opacity: 0.5;
                position: absolute;
                display: block;
              }
              @keyframes fadeIn {  
                from {  
                    opacity:0;  
                }  
             
                to {  
                    opacity:1;  
                }  
             }
             .left_side_content_wrapperToggle{
              display: block !important;
                animation: fadeIn 200ms ease-in; 
            }
  
              .left_side_content_wrapper {
                width: 70%;
                position: absolute;
                display: none;
                z-index: 1999;
  
                .sub_section_1_wrapper {
                  position: relative;
                  .closebtn{
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    right: 0;
                  }
                  .text_content_wrapper {
                    button{
                      display: none;
                    }
                  }
                  .questions_serial_number_collection {
                    // height: 12vh;
                  }
                }
              }
  
              .right_side_content_wrapper {
                width: 100%;
                padding: 0;
                .right_btn_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 10px;
                  position: sticky;
                  top: 0;
                  z-index: 1000;
                  background: inherit;
                  padding: 10px;
                  .togglebtn {
                    border: none;
                    outline: none;
                    border-radius: 8px;
                    background-color: #ffffff;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                    color: #fff;
                    padding: 10px;
                    background: #6d48ef;
              
                    &.submit {
                      background: coral;
                    }
                  }
                }
  
                .sub_section_wrapper {
                  padding: 10px ;
                  .testProgressIndicator {
                    margin-bottom: 10px;
                  }
                  .sectionWiseTestWrapper {
                    .subjectWiseTest {
                      padding: 5px 6px;
                      margin-bottom: 10px;
  
                      .single_select_subject {
                        label {
                          padding: 6px 12px;
                          font-size: 14px;
                          min-width: max-content;
                        }
                      }
                    }
                  }
  
                  .sub_sub_section_wrapper_2 {
                    h2 {
                      font-size: 22px;
                      margin-bottom: 15px;
                    }
  
                    .text_content_wrapper {
                      .typeLanguageWrapper {
                        flex-direction: column;
  
                        span {
                          margin-bottom: 10px;
                        }
  
                        .markReview {
                          width: 100%;
                          justify-content: space-between;
                          margin-bottom: 5px;
                        }
                      }
  
                      .questionBox {
                        p {
                          img {
                            width: 100% !important;
                          }
                        }
                      }
                    }
                  }
  
                  .sub_sub_section_wrapper_3 {
                    .single_select_option_collection_wrapper {
                      .single_select {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                      }
                    }
                  }
  
                  .sub_sub_section_wrapper_5 {
                    .right_side_wrapper {
                      .btn_wrapper {
                        button {
                          font-size: 15px;
                          padding: 8px;
                        }
                      }
                    }
                  }
  
                  .sub_sub_section_wrapper_3 {
                    .single_select_option_collection_wrapper {
                      .single_select {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                      }
                    }
  
                    .multi_select_option_collection_wrapper {
                      .single_select {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  .classCorrect {
    border: 1px solid #5ddd9b;
    background: #e1f1ec !important;
  }
  
  .classWrong {
    border: 1px solid #f93030;
    background: #f6e0e4 !important;
  }
  
  .opacity {
    opacity: 0.5;
  }
  
  
  