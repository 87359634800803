.ots_payment_heading_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height */

  color: #000000;
  text-align: center;
}

hr.heading_line {
  border: 0.5px solid #cecece;
  margin-bottom: 42px;
}
.ots_payment_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .pkg_overview_advantages_wrapper {
    width: 390px;
    margin-left: 100px;

    h6 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .pkg_price_details {
    width: 390px;
    margin-right: 100px;
    border: 1px solid #d9d9d9;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(117, 117, 117, 0.1);
    border-radius: 4px;

    padding: 25px;

    h6 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      color: #000000;
      margin-bottom: 14px;
    }

    hr.line {
      border: 1px solid #d9d9d9;
    }

    .course_details {
      display: flex;
      justify-content: space-between;

      margin-bottom: 10px;

      .ots_course {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        color: #6d6d6d;
      }

      .course_duration,
      .course_amount,
      .course_discount,
      .course_final_price {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        color: #000000;
      }
    }

    .terms_condition {
      //   background-color: red;
      // display: inline-block;
      display: flex;
      margin-bottom: 65px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;

      letter-spacing: 0.02em;
      margin-left: 8px;
      margin-top: 2px;

      input {
        background: #ffffff;
        border: 0.5px solid #000000;
        border-radius: 2px;
        height: 18px;
        width: 18px;
      }

      span {
        margin-left: 8px;
        margin-top: 2px;
      }

      .terms_condition_text {
        display: inline-block;

        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;

        letter-spacing: 0.02em;
        margin-left: 4px;
        margin-top: 2px;
        color: #4181ff;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .pay_button {
      background: linear-gradient(
        90.65deg,
        #6d48ef 0.21%,
        rgba(58, 19, 196, 0.93) 97.55%
      );
      box-shadow: 0px 2px 8px rgba(109, 72, 239, 0.16);
      border-radius: 24px;
      text-align: center;
      padding: 12px 0px;
      opacity: 50%;

      button {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
      }
    }

    .pay_button_active {
      background: linear-gradient(
        90.65deg,
        #6d48ef 0.21%,
        rgba(58, 19, 196, 0.93) 97.55%
      );
      box-shadow: 0px 2px 8px rgba(109, 72, 239, 0.16);
      border-radius: 24px;
      text-align: center;
      padding: 12px 0px;

      cursor: pointer;
      button {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
      }
    }
  }
}
.coupon_modal {
  .mdl_hdr {
    border-bottom: 0px;
    padding-bottom: 0px;
  }
  .mbd {
    padding: 30px 50px !important;

    .coupon_wrapper {
      .coupon_wraper_inr {
        .package_amount {
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            color: #c00714;
            font-size: 35px;
            text-align: center;
            margin-bottom: 20px;
            margin-left: 14px;
          }

          p {
            text-align: center;
            letter-spacing: 0.32px;
            color: #000000;
            opacity: 0.7;
            padding: 0px 10%;
          }
        }
        .amt {
          color:#000000;
          font-size: 35px;
          text-align: center;
          // margin-bottom: 20px;
          // margin-left: 14px;
        }
      }
      .coupon_input {
        position: relative;
        margin-bottom: 15px;

        input {
          height: 37px;
          background: #f5f4f8;
          border: none;
          width: 100%;
          padding: 5px 10px;
          border-radius: 6px;
          font-size: 18px;

          &:focus {
            outline: none;
            border: none;
          }
        }
        button {
          position: absolute;
          right: 0px;
          background: linear-gradient(
            90.65deg,
            #6d48ef 0.21%,
            rgba(58, 19, 196, 0.93) 97.55%
          );
          border-radius: 6px;
          color: #fff;
          height: 100%;
          padding: 5px 12px;
        }
      }
      .updated_amount {
        text-align: center;
        letter-spacing: 0.32px;
        color: #000000;
        margin-bottom: 15px;

        span {
          letter-spacing: 0.4px;
          color: #c00714;
          margin-left: 7px;
          display: inline-block;
          font-weight: 600;
        }
      }
      .pay_now {
        button {
          background: #059249;
          letter-spacing: 0.36px;
          color: #ffffff;
          font-weight: 600;
          text-align: center;
          border-radius: 6px;
          padding: 8px 10px;
          width: 100%;
        }
        a {
          background: #059249;
          letter-spacing: 0.36px;
          color: #ffffff;
          font-weight: 600;
          text-align: center;
          border-radius: 6px;
          padding: 8px 10px;
          width: 100%;
          display: inline-block;
          text-decoration: none;
        }
      }
    }
  }
}

.modal {
  .my-modal {
    width: 50% !important;
    max-width: 50% !important;
    margin: 130px auto !important;

    .modal-header {
      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.01em;
        color: #303030;
        width: 100%;
        text-align: center;
      }
    }

    .terms_condition_wrapper {
      max-height: calc(80vh - 200px);
      overflow-y: scroll;
      overflow-x: hidden;
      background: #f9f9f9;
      border: 0.5px solid #a0a0a0;
      border-radius: 4px;
      padding: 20px;

      .terms_condition_inner_wrapper {
        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 22px;
          letter-spacing: 0.01em;
          color: #898989;
          width: 650px;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .modal .my-modal {
    width: 100% !important;
    max-width: 100% !important;
  }
}
