.testResultReportView_wrapper {
  .testResultReportView_inner_wrapper {
    .sec_1_wrapper {
      margin: 10px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 10px 0;
      border-radius: 8px;
      background-color: #ffffff;
      .sec_2_inner_wrapper {
        padding: 20px;
        .sub_section_1_wrapper {
          margin: 20px 0;
          .graph_container {
          }
          .cut_off_container {
            margin: 10px 0;
            .cut_off_content {
              margin: 0;
              font-size: 16px;
              font-weight: 600;
              color: red;
              text-align: center;
            }
          }
        }
        .sub_section_2_wrapper {
          margin: 20px 0;
          .options_wrapper {
            .custom_nav_pills {
              justify-content: space-between;
              padding: 10px;
              border-radius: 25px;
              background-color: #f2f2f3;
              .custom_nav_item {
                flex: 0 0 30%;
                .custom_nav_link {
                  width: 100%;
                  border-radius: 25px;
                  border: none;
                  outline: none;
                  font-size: 16px;
                  font-weight: 500;
                  color: #6b6876;
                }
                .custom_nav_link.active {
                  color: #ffffff;
                }
              }
            }
            .tab_wrapper {
              .custom_tab_content {
                //////marks tab////
                .custom_tab_pane {
                  .marks_tab_wrapper {
                    .outer_content_wrapper {
                    }
                  }
                }
                //////accuracy tab////
                .custom_tab_pane {
                  .accuracy_tab_wrapper {
                    .outer_content_wrapper {
                    }
                  }
                }
                //////time tab////
                .custom_tab_pane {
                  .time_tab_wrapper {
                    .outer_content_wrapper {
                    }
                  }
                }
              }
            }
          }
        }
        .sub_section_3_wrapper {
          margin: 20px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
        .sub_section_4_wrapper {
          margin: 20px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
        .sub_section_5_wrapper {
          margin: 20px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
        .sub_section_6_wrapper {
          margin: 20px 0;
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
        .sub_section_7_wrapper {
          margin: 20px 0;
          .view_solution_btn_wrapper {
            margin: 10px 0;
            text-align: center;
            button {
              border: none;
              outline: none;
              width: auto;
              height: 45px;
              border-radius: 25px;
              border: 2px solid #6d48ef;
              background-color: #6d48ef;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
}
