
.common_promo_banner {
    border-radius: 4px;
    background: #750000;
    width: 100%;
    margin-bottom: 30px;

    div:first-child {
      position: relative;
      padding-left: 24px;
      padding: 13px 0 13px 24px;

      .pos-img {
        position: absolute;
        left: 36.5px;
      }
    }

    div:nth-child(2) {
      padding-left: 16px;

      p {
        margin: 0;
      }

      p:first-child {
        color: #FFF;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      p:last-child {
        color: #FFF8F8;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
          font-weight: 600;
        }
      }
    }

    div:last-child {
      margin-left: auto;

      button {
        color: #FFF;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        display: flex;
        width: 207px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 24px;
        background: #F90;
        margin-right: 36px;
      }
    }
  }
