.userExerciseTestContentComponent_wrapper {
  .userExerciseTestContentComponent_inner_wrapper {

    //////tests tab////
    .tests_tab_wrapper {
      background-color: #ffffff;
      border-radius: 10px;

      .outer_content_wrapper {
        .content_wrapper {
          padding: 20px 0;

          .left_side_content_wrapper {
            text-align: -webkit-center;
            margin-bottom: 25px;
            text-align: center;

            .tests_type_collection_wrapper {
              display: inline-block;
              background-color: #F5F4F8;
              border-radius: 25px;
              overflow: hidden;

              .single_select_test_type {
                width: auto;
                min-width: 160px;
                display: inline-block;

                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }

                label {
                  background-color: #fafafd;
                  padding: .5rem 1rem;
                  font-size: 15px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                  color: #6b6876;
                  cursor: pointer;
                  width: 100%;
                  text-align: center;
                }

                input[type="radio"]:checked+label {
                  background-color: #FF933A;
                  border: none;
                  color: #ffffff;
                }
              }
            }
          }

          .right_side_content_wrapper {
            flex: 0 0 65%;
            padding: 0 10px;

            ////////////////////all tests/////////////////
            .all_tests_wrapper {
              .all_tests_inner_wrapper {
                .all_tests_collection_wrapper {
                  display: flex;
                  flex-wrap: wrap;

                  .single_test_wrapper {
                    padding: 20px;
                    border: 1px solid #EEEEEE;
                    border-radius: 8px;
                    background: #F5F4F8;
                    margin: 0px 16px 16px 0px;
                    cursor: pointer;
                    width: calc(50% - 8px);

                    &:nth-child(2n+2) {
                      margin-right: 0px;
                    }

                    .sub_section_wrapper_1 {
                      margin: 5px 0;

                      label {
                        border-radius: 15px;
                        background-color: #3aa02b;
                        padding: 5px 10px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #ffffff;
                      }
                    }

                    .sub_section_wrapper_2 {
                      margin: 5px 0;

                      .text_content_wrapper {
                        .text_content {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #0f0404;
                        }
                      }
                    }

                    .sub_section_wrapper_3 {
                      margin: 5px 0;

                      .sub_content_wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .left_content_wrapper {
                          label {
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;
                            color: #6b6876;
                          }
                        }

                        .right_content_wrapper {
                          .lbl_1 {
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;
                            color: #a02b2d;
                            cursor: pointer;
                          }

                          .lbl_2 {
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;
                            color: #6d48ef;
                            cursor: pointer;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .userExerciseTestContentComponent_wrapper {
    .userExerciseTestContentComponent_inner_wrapper {
      .tests_tab_wrapper {
        .outer_content_wrapper {
          .content_wrapper {
            .right_side_content_wrapper {
              .all_tests_wrapper {
                .all_tests_inner_wrapper {
                  .all_tests_collection_wrapper {
                    .single_test_wrapper {
                      padding: 10px;

                      .sub_section_wrapper_2 {
                        .text_content_wrapper {
                          .text_content {
                            font-size: 15px;
                          }
                        }
                      }

                      .sub_section_wrapper_3 {
                        .sub_content_wrapper {
                          .left_content_wrapper {
                            label {
                              font-size: 12px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)

@media (max-width: 575.98px) {
  .userExerciseTestContentComponent_wrapper {
    padding-top: 0px;

    .userExerciseTestContentComponent_inner_wrapper {
      padding: 0 10px 10px;

      .tests_tab_wrapper {
        .outer_content_wrapper {
          .content_wrapper {
            padding: 0;
            .left_side_content_wrapper {
              .tests_type_collection_wrapper {
                .single_select_test_type {
                  min-width: auto;
                  width: 50%;
                  &:last-child{
                    width: 100%;
                  }
                }
              }
            }

            .right_side_content_wrapper {
              padding: 0px;

              .all_tests_wrapper {
                .all_tests_inner_wrapper {
                  .all_tests_collection_wrapper {
                    .single_test_wrapper {
                      width: 100%;
                      margin-right: 0px;

                      .sub_section_wrapper_3 {
                        .sub_content_wrapper {
                          flex-direction: column;
                          align-items: flex-start;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}