.userStoreContentComponent_wrapper {
  padding-top: 61px;
  background: #f5f4f8;

  .userStoreContentComponent_inner_wrapper {
    .store_firstSec {
      background: transparent linear-gradient(27deg, #ff8826 0%, #ffc697 100%)
        0% 0% no-repeat padding-box;
      padding: 40px 0px;
      margin: 0px 9px;
      .store_firstSec_inr {
        color: #fff;
        display: flex;
        align-items: center;
        .store_textLft {
          width: 53%;
          margin-left: 180px;
          margin-top: 50px;
          h2 {
            margin-bottom: 20px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 45px;
            line-height: 48px;
            color: #ffffff;
            span {
              font-weight: 600;
            }
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
          }
        }
        .store_textRgt {
          text-align: center;
          .store_img {
            img {
              width: 90%;
              margin-left: -100px;
            }
          }
        }
      }
    }

    .tab_container {
      .container {
        margin-bottom: 100px;
        position: relative;
        .tabBox {
          .tabcontent {
            padding: 10px;
            .tab {
              background: #f7f7f7;
              display: flex;
              justify-content: space-between;
              margin-bottom: 13px;
              // background: red;
              ul {
                border: none;
                margin-left: 450px;
                width: max-content;
                // border-radius: 48px;
                overflow: hidden;
                border: none;
                margin-top: 3px;
                li {
                  border: none;
                  button {
                    border: none;
                    color: #a3a3a3;
                    font-size: 15px;
                    border-radius: 0px;
                    min-width: 220px;
                    padding: 10px 15px;
                  }

                  .nav-link.active {
                    color: #fff;
                    background: #6d48ef;
                    // border-radius: 48px;
                    border-radius: 10px;
                  }
                }
              }
              .wishLst_addCart {
                width: 18%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-right: 20px;
                color: #121212;
                img {
                  margin-right: 5px;
                }
                .wishList {
                  cursor: pointer;
                }
                .addCart {
                  cursor: pointer;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  .badge {
                    background: orange;
                    // color: black;
                    color: #f5f4f8;
                    font-weight: bold;
                    border-radius: 5px;
                    position: relative;
                    top: -11px;
                    right: 12px;
                  }
                  .cart {
                    margin-left: -5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sec_2_wrapper {
      margin-top: 40px;
      .wallet_wrapper{
        margin-top: 1.5rem;
        background: #EFEBFF;
        border-radius: 8px;
        padding: 1.5rem 3.5rem;
        .coinheading{
          font-family: 'Open Sans';
          font-weight: 600;
          font-size: 2rem;
          color: #000000;
        }
        .coinText{
          font-size: 2.3rem;
          font-family: 'Open Sans';
          font-weight: 600;
          color: #2D2D2D;
        }
        .coinText1{
          font-family: 'Open Sans';
          font-weight: 400;
          color: #262626;
        }
        .walletLink{
          font-family: 'Poppins';
          font-weight: 500;
          font-size: 1.1rem;
          color: #E1700D;
        }
      }

      .new_wallet_wrapper {
        background: #EFEBFF;
        // height: 150px;
        align-items: center;
        margin: 50px 0 10px;

        .coinsBannerText {
          margin-left: 55px;

          p {
            color: #000;
            font-family: 'Poppins';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.4px;
            margin: 0;
          }

          img {
            margin: 0 10px 10px 0;
          }

          span {
            color: #2D2D2D;
            font-family: 'Poppins';
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .wallet_image {
          margin: 20px 0 20px 40px;
        }

        .coins_history {
          margin: 0 55px 0;
          color: #E1700D;
          font-family: 'Poppins';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }

    .course_packge {
      background: #ffffff;
    }

  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userStoreContentComponent_wrapper {
    padding-top: 40px;
    .userStoreContentComponent_inner_wrapper {
      // padding: 10px;
      .sec_1_wrapper {
        .text_content_wrapper {
          .text_content {
          }
        }
      }
      .sec_2_wrapper {
        margin-top: 0;
          .wallet_wrapper{
            padding: 1rem;
            .coinheading{
              font-size: 1.5rem;
            }
          }
      }
      .tab_container{
        .container {
          .tabBox {
            .tabcontent {
              padding: 0;
              .tab{
                margin-top: 20px ;
                margin-bottom: 20px ;
                justify-content: center;
                ul{
                  margin-left: 0;
                  li{
                    button{
                      min-width: -webkit-fill-available;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
