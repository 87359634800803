.ReferandEarn_container {
  width: 100%;

  .refer_and_earn_inner_container {
    .backBtnContent {
      display: flex;
      align-items: center;
      padding: 10px;
      .backBtn {
        margin-right: 20px;
        img {
          width: 30px;
        }
      }

      .referAndEarnText {
        color: #000;
        font-family: Poppins;
        font-size: 1.3125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.01313rem;
      }
    }

    .referandEarn_Content {
      display: flex;
      justify-content: space-between;

      .contentLeft {
        // width: 65%;

        section {
          margin-bottom: 15px;
        }
      }

      .right_side_content {
        width: 33%;
        position: sticky;

        // .right_top {
        //   display: flex;
        //   justify-content: space-between;
        //   padding: 0.875rem 1rem 0.875rem 1rem;
        //   // align-items: flex-start;
        //   align-items: center;
        //   gap: 3.4375rem;
        //   border-radius: 0.5rem;
        //   background: linear-gradient(
        //     97deg,
        //     #ffdecc -0.94%,
        //     rgba(245, 204, 255, 0.15) 101.87%
        //   );
        //   margin-bottom: 1rem;
        //   .first {
        //     width: 1.5rem;
        //     height: 1.5rem;
        //   }

        //   .middle {
        //     color: #000;
        //     font-family: Poppins;
        //     font-size: 0.875rem;
        //     font-style: normal;
        //     font-weight: 400;
        //     line-height: normal;
        //   }

        //   .last {
        //     color: #121212;
        //     text-align: right;
        //     font-family: Poppins;
        //     font-size: 1.125rem;
        //     font-style: normal;
        //     font-weight: 600;
        //     line-height: normal;
        //     letter-spacing: 0.00563rem;
        //   }
        // }

        .contentRight {
          .copyCodeContainer {
            background: #ffffff;
            padding: 0.875rem 1rem 0.875rem 1rem;

            .sectionTitle {
              color: #121212;
              font-family: Poppins;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.00875rem;
              margin-bottom: 0.69rem;
            }
          }

          .total_earned {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            padding: 0.875rem 1rem 0.875rem 1rem;
            align-items: center;
            border-radius: 0.5rem;
            background: rgba(38, 50, 56, 0.1);

            margin-bottom: 1rem;
            .first {
              width: 1.5rem;
              height: 1.5rem;
            }

            .middle {
              color: #000;
              font-family: Poppins;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .last {
              color: #121212;
              text-align: right;
              font-family: Poppins;
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.00563rem;
            }
          }

          .termsAndconditions {
            margin-top: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }

            .text {
              letter-spacing: 0em;
              color: #4e4e4e;
              font-family: Poppins;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              span {
                cursor: pointer;
                margin-left: 5px;
                color: #1d60c5;
                font-family: Poppins;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
              }
            }
          }
        }
      }
    }
  }
}
