.test_result_content_component_wrapper {
  padding-top: 100px;

  .test_result_content_component_inner_wrapper {
    padding: 20px;
  }
}

@media (max-width: 575.98px) {
  .test_result_content_component_wrapper {
    padding-top: 50px;

    .test_result_content_component_inner_wrapper {
      padding: 20px 0px;
    }
  }
}