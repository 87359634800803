

.detailsModal {
  font-family: "Poppins";
  font-style: normal;
  margin-top: 50px;

  .modal-header {
    border: none;
  }

  h3 {
    margin-left: 12px;
    color: #303030;
    text-align: left;
    font: 600 20px "Poppins", sans-serif;
  }

  .modal-body {
    .details_Popup {
      form {
        .form-group {
          margin-top: -10px;
          margin-bottom: 40px;

          label {
            color: #444444;
            text-align: left;
            font: 400 18px "Poppins", sans-serif;
          }

          span {
            font: 400 18px "Poppins", sans-serif;
            color: #940001;
          }

          input {
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #c6c6c6;
            padding: 12px 16px 12px 16px;
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            justify-content: flex-start;
            width: 436px;

            &::placeholder {
              color: #a1a1a1;
              text-align: left;
              font: 400 16px "Poppins", sans-serif;
            }
          }
        }

        .submit_btn {
          background: linear-gradient(
            90.65deg,
            rgba(109, 72, 239, 1) 0%,
            rgba(58, 19, 195, 0.93) 100%
          );
          border-radius: 24px;
          padding: 8px 37px 8px 37px;
          display: flex;
          flex-direction: row;
          gap: 94px;
          align-items: center;
          justify-content: center;
          width: 436px;
          height: 46px;
          box-shadow: 0px 2px 8px 0px rgba(109, 72, 239, 0.16);

          &:disabled {
            background-color: #512fbb;
            opacity: 30%;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}