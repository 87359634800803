.update_course_modal{
  .modal-content{
    border-radius: 16px;
  .modal-header{
    position: relative;
    justify-content: center;
    border: 0px;
    padding-top: 25px;
    .btn-close{
      position: absolute;
      right: 20px;
    top: 15px;
    }
    .modal-title{
      color: #000000;
      font-size: 22px;
      font-weight: 600;
    }
  }
  .modal-body{
    padding: 0px 3rem !important;
.modal_body_wrapper {
  .sec_1_wrapper {
    padding-bottom: 15px;
    .text_content_wrapper {
      margin: 10px 0;
      .text_content {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #000;
        margin-bottom: 10px;
      }
    }
    .target_collection_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .single_target_wrapper {
        margin: 5px;
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        label {
          background-color: #F3F3F3;
          padding: 8px 20px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #000;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        input[type="radio"]:checked + label {
          background: transparent linear-gradient(332deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  .sec_2_wrapper {
    padding-bottom: 15px;
    .text_content_wrapper {
      margin: 10px 0;
      .text_content {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #000;
        margin-bottom: 10px;
      }
    }
    .class_collection_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .single_class_wrapper {
        margin: 5px;
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        label {
          background-color: #F3F3F3;
          padding: 8px 20px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #000;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        input[type="radio"]:checked + label {
          background: transparent linear-gradient(332deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  .sec_3_wrapper {
    padding-bottom: 15px;
    .text_content_wrapper {
      margin: 10px 0;
      .text_content {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #000;
        margin-bottom: 10px;
      }
    }
    .course_collection_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .single_course_wrapper {
        margin: 5px;
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        label {
          background-color: #F3F3F3;
          padding: 8px 20px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #000;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        input[type="radio"]:checked + label {
          background: transparent linear-gradient(332deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  .sec_4_wrapper {
    .text_content_wrapper {
      margin: 10px 0;
      .text_content {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        color: #a02b2d;
      }
    }
    .batch_collection_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .single_batch_wrapper {
        margin: 5px;
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        label {
          background-color: #F3F3F3;
          padding: 8px 20px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #000;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        input[type="radio"]:checked + label {
          background: transparent linear-gradient(332deg, #FF8826 0%, #FFC697 100%) 0% 0% no-repeat padding-box;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  .sec_5_wrapper {
    margin: 10px 0;
    .text_content_wrapper {
      .text_content {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        color: #ff0000;
      }
    }
  }
}
}
.modal-footer{
  border: 0px;
.modal_footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    border: none;
    outline: none;
    width: 300px;
    height: 40px;
    border-radius: 8px;
    background: #6D48EF;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
}
}
}
}
