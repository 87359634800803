.cart_details {
  padding-top: 141px;
  background: #e5e5e5;
  padding-bottom: 50px;
  .container {
    background: #ffffff;
    margin-left: 125px;
    width: 83.5%;
    padding-bottom: 100px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 15px;
      width: 100%;
      //   box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
      border-bottom: 2px solid rgba(0, 0, 0, 0.08);
      .right {
        width: 18%;
        .wishLst_addCart {
          display: flex;
          justify-content: space-around;
          align-items: center;
          color: #121212;
          img {
            margin-right: 5px;
          }
          .wishList {
            display: flex;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;
            .badge {
              background: orange;
              color: #f5f4f8;
              font-weight: bold;
              border-radius: 5px;
              position: relative;
              top: -11px;
              right: 12px;
            }
          }
          .addCart {
            cursor: pointer;
            display: flex;
            align-items: center;
            .badge {
              background: orange;
              color: #f5f4f8;
              font-weight: bold;
              border-radius: 5px;
              position: relative;
              top: -11px;
              right: 12px;
            }
            .cart {
              margin-left: -5px;
            }
          }
        }
      }
    }
    .backBtn_content {
      display: flex;
      gap: 1rem;
      padding: 20px 25px;
      .backBtn img {
        color: #6d48ef;
      }
      .backBtn_text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #6d48ef;
      }
    }

    .single_cartDetails_container {
      display: flex;
      justify-content: space-between;
      padding: 15px 0px 10px 0px;
      width: 95.5%;
      margin: auto;
      .left {
        width: 63%;
        .card {
          border: none;
          img {
            width: 100%;
            height: 400px;
            object-fit: stretch;
            // resize: cover;
          }
        }
      }
      .right {
        width: 32%;

        .priceDetails {
          border: 0.5px solid #9d9d9d;
          border-radius: 4px;
          .priceDetailsContent {
            // padding: 15px;
            h4 {
              padding: 10px 20px;
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 27px;
              color: #282828;
              // border-bottom: 1px solid black;
              border-bottom: 0.5px solid #d9d9d9;
            }

            .itemsBox {
              border-bottom: 0.5px solid #d9d9d9;
              .item {
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .item_left {
                  display: flex;
                  justify-content: space-between;
                  width: 60%;
                  padding: 0px;
                  margin: 0px;
                  img {
                    width: 25%;
                    height: 6vh;
                    object-fit: cover;
                  }
                  .item_content {
                    margin-left: 7px;
                    .subName {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 18px;
                      color: #2d2d2d;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 150px;
                    }

                    small {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      color: #787878;
                    }
                  }
                }

                .item_right {
                  text-align: center;
                  margin: 0px;
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 22px;
                  color: #000000;
                }
              }
            }

            .discountBox {
              padding: 10px 20px;
              border-bottom: 0.5px solid #d9d9d9;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .dicountName {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #6d6d6d;
              }
              .dicountPrice {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                // color: #000000;
                color: #666666;
              }
            }

            .totalPriceContent {
              display: flex;
              align-items: center;
              justify-content: space-between;
              background: #ece7ff;
              padding: 15px 20px;
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
              .totalPriceText {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: #121212;
              }
              .totalPrice {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: 0.02em;
                color: #121212;
              }
            }
          }
        }

        .btns {
          .addCartBtn {
            margin-top: 20px;
            text-align: center;
            background: #ff933a;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            border-radius: 4px;
            cursor: pointer;
            button {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              padding: 10px 0px;
              width: 100%;
            }
          }
          .buyNowBtn {
            margin-top: 20px;
            text-align: center;
            // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            border-radius: 4px;
            background: #ffffff;
            border: 1px solid #ff933a;
            cursor: pointer;
            button {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #ff933a;
              padding: 10px 0px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media(max-width:578px){
  .cart_details {
    padding-top: 55px;
    .container{
      margin: auto;
      width: 97%;
      .backBtn_content{
        padding: 20px 0;
      }
      .single_cartDetails_container{
        padding: 0;
        width: 100%;
        flex-direction: column;
        .left{
          width: 100%;
        }
        .right{
          width: 100%;
        }
      }
      .header{
        padding: 20px 0;
        .right{
          width: auto;
        }
      }
    }
  }
}
