.videoFeatureContentComponent_wrapper {
  padding-top: 101px;
  .videoFeatureContentComponent_inner_wrapper {
    .video_wrapper {
      .video_inner_wrapper {
        .sec_1_wrapper {
          margin: 20px 0;
          .sec_1_inner_wrapper {
            display: flex;
            justify-content: flex-start;
            .btn_wrapper {
              button {
                outline: none;
                border: none;
                background: transparent;
              }
            }
            .text_content_wrapper {
              margin: 0 10px;
              .text_content {
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                color: #0f0404;
              }
            }
          }
        }
        ////////Frame////////////
        .video_frame_wrapper {
          text-align: center;
          iframe {
            width: 100%;
            height: 600px;
          }
        }
        /////////Video Details////////////
        .video_details_wrapper {
          margin: 10px 0;
          .video_details_content_wrapper {
            .text_content_wrapper {
              .text_content {
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                color: #000000;
              }
            }
            .content_wrapper_1 {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .left_side_content_wrapper {
                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: #00000054;
                  }
                }
              }
              .right_side_content_wrapper {
                display: flex;
                align-items: center;
                .label_content_wrapper {
                  margin: 0 5px;
                  label {
                    padding: 5px 15px;
                    border-radius: 15px;
                    background-color: #fef4ec;
                    span {
                      img {
                        width: 15px;
                        height: 15px;
                        margin-right: 5px;
                        vertical-align: unset;
                      }
                    }
                  }
                }
                // .bookmark_content_wrapper {
                //   margin: 0 5px;
                //   .image_wrapper {
                //     img {
                //       cursor: pointer;
                //     }
                //   }
                // }
              }
            }
            .content_wrapper_2 {
              margin: 10px 0;
              .text_content_wrapper {
                .text_content {
                  margin: 0;
                  font-size: 18px;
                  font-weight: 600;
                  color: #000000;
                }
              }
              .topic_collection {
                .sub_content_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  .left_side_content_wrapper {
                    .text_content_wrapper {
                      .text_content {
                        margin: 0;
                        .key_tag {
                          margin-right: 5px;
                          font-size: 16px;
                          font-weight: 600;
                          color: #000000;
                        }
                        .value_tag {
                          font-size: 16px;
                          font-weight: 400;
                        }
                      }
                    }
                  }
                  .right_side_content_wrapper {
                    .text_content_wrapper {
                      .text_content {
                        margin: 0;
                        label {
                          padding: 5px 15px;
                          border-radius: 15px;
                          background-color: #e6e6e6;
                          font-size: 16px;
                          font-weight: 600;
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        /////////Other related content//////////
        .other_related_content_wrapper {
          margin: 20px 0;
          .other_related_content_container {
            .options_collection_wrapper {
              .options_collection {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                .single_select_option {
                  flex: 0 0 45%;
                  input[type="radio"] {
                    opacity: 0;
                    position: fixed;
                    width: 0;
                  }
                  label {
                    width: 100%;
                    border: solid 1px #3f3e41;
                    background-color: #c7c7d4;
                    padding: 10px 20px;
                    border-radius: 25px;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    color: #6b6876;
                    cursor: pointer;
                    text-align: center;
                  }
                  input[type="radio"]:checked + label {
                    background-color: #FF933A;
                    border: none;
                    color: #ffffff;
                  }
                }
              }
            }
            .options_data_view_wrapper {
              margin: 15px 0;
              //////notes////////////
              .notesCollectionView_wrapper {
                .notesCollectionView_inner_wrapper {
                  .notes_collection_wrapper {
                    display: flex;
                    flex-direction: column;
                    .single_notes_wrapper {
                      width: 100%;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      border-radius: 15px;
                      border: solid 1px #f0eef8;
                      background-color: #c8c8ce;
                      padding: 10px;
                      margin: 10px 0;
                      cursor: pointer;
                      .serial_number_wrapper {
                        border-radius: 15px;
                        background-image: linear-gradient(
                          149deg,
                          #fff5e6 32%,
                          #ffefe6 76%
                        );
                        padding: 15px;
                        .image_wrapper {
                          img {
                          }
                        }
                      }
                      .notes_description_wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 0 10px;
                        .notes_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #0f0404;
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                              margin: 0;
                              font-size: 14px;
                              font-weight: 500;
                              color: #6b6876;
                            }
                          }
                        }
                        .caret_wraper {
                          .image_wrapper {
                            img {
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ///////Dpp/////////
              .dppCollectionView_wrapper {
                .dppCollectionView_inner_wrapper {
                  .dpp_collection_wrapper {
                    display: flex;
                    flex-direction: column;
                    .single_dpp_wrapper {
                      width: 100%;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      border-radius: 15px;
                      border: solid 1px #f0eef8;
                      background-color: #c8c8ce;
                      padding: 10px;
                      margin: 10px 0;
                      cursor: pointer;
                      .serial_number_wrapper {
                        border-radius: 15px;
                        background-color: #6d48ef;
                        padding: 15px;
                        .image_wrapper {
                          img {
                          }
                        }
                      }
                      .dpp_description_wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 0 10px;
                        .dpp_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #0f0404;
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                              margin: 0;
                              font-size: 14px;
                              font-weight: 500;
                              color: #6b6876;
                            }
                          }
                        }
                        .caret_wraper {
                          .image_wrapper {
                            img {
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ////////Related videos/////////////
        .related_videos_wrapper {
          margin: 10px 0;
          .related_video_content_wrapper {
            .text_content_wrapper {
              .text_content {
                margin: 0;
                font-size: 24px;
                font-weight: 600;
                color: #000000;
              }
            }
            .video_collection_wrapper {
              .video_collection {
                display: flex;
                flex-direction: column;
                .single_video_wrapper {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #d1cfd8;
                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;
                  .left_side_content_wrapper {
                    width: auto;
                    flex: auto;
                    .image_wrapper {
                      text-align: center;
                      img {
                        width: 100px;
                      }
                    }
                  }
                  .right_side_content_wrapper {
                    flex: auto;
                    margin: 0 10px;
                    width: 100%;
                    .text_content_wrapper_1 {
                      .text_content_1 {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 600;
                        color: #0f0404;
                      }
                    }
                    .text_content_wrapper_2 {
                      .text_content_2 {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: #6f6868;
                      }
                    }
                    .sub_content_wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      .left_side_content_wrapper {
                        // flex: 0 0 50%;
                        .label_content_wrapper {
                          label {
                            padding: 5px 10px;
                            border-radius: 15px;
                            background-color: #fef4ec;
                            span {
                              img {
                                width: 15px;
                                height: 15px;
                              }
                            }
                          }
                        }
                      }
                      .right_side_content_wrapper {
                        // flex: 0 0 50%;
                        text-align: right;
                        margin: 0;
                        padding: 0;
                        width: auto;
                        .subject_name {
                          padding: 5px;
                          border-radius: 5px;
                          background-image: linear-gradient(
                            120deg,
                            #ff9600 37%,
                            #ff5d00 72%
                          );
                          font-size: 12px;
                          font-weight: 600;
                          color: #ffffff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .videoFeatureContentComponent_wrapper {
    padding-top: 60px;
    .videoFeatureContentComponent_inner_wrapper {
      padding: 20px 5px;
      .video_wrapper {
        .video_inner_wrapper {
          .video_frame_wrapper {
            iframe {
              width: 100%;
              height: calc(100vh - 60px - 40px);
            }
          }
        }
      }
    }
  }
}
