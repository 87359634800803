.ots_test_result_content_component_wrapper {
  padding-top: 55px;

  .ots_test_result_content_component_inner_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .otsTestSummaryPageWrapper {
      width: 78%;

      background: red;
      margin-top: 40px;

      background-color: #fff;
      border-radius: 10px;

      padding: 20px;

      .ots_test_summary_header {
        background: #fffcf9;
        border-bottom: 0.6px solid #ffe2c5;
        margin-bottom: 30px;

        .backBtn {
          display: inline-block;
          cursor: pointer;
        }

        .ots_paper_categories {
          display: flex;
          max-width: max-content;
          margin: 0 auto;
          overflow: hidden;

          .ots_single_paper_select_category {
            margin: 0 80px;
            &:last-child {
              margin-right: 0px;
            }

            input[type="radio"] {
              opacity: 0;
              position: fixed;
              width: 0;
            }

            label {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 27px;
              margin-right: 25px;

              color: #5e5e5e;
              cursor: pointer;
            }

            input[type="radio"]:checked + label {
              border: none;
              border-bottom: 3px solid #ff7a0c;
              border-bottom-left-radius: 1px;
              border-bottom-right-radius: 1px;

              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 27px;

              color: #ff7a0c;
            }
          }
        }
      }

      .ots_test_name {
        color: #000;
        font-family: Poppins;
        font-size: 22.5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .ots_test_result_content_component_wrapper {
    padding-top: 50px;
      .ots_test_result_content_component_inner_wrapper {
        .otsTestSummaryPageWrapper {
          width: 97%;
          margin-top: 10px;
          padding: 10px;
            .ots_test_summary_header {
              margin-bottom: 15px;
              .ots_paper_categories {
                .ots_single_paper_select_category {
                  margin: 0;
                }
              }
            }
          .otsTestSummaryPageInrWrapper {
            .test_summaryBox {
              .rankMarksBox{
                padding: 0;
                .rankChartBox {
                  display: block;
                  .expctd_AIR_Chart{
                    width: 100%;
                    margin-bottom: 15px;
                  }
                  .AttemptAndAccuracyPercent{
                    width: 100%;
                    padding: 15px 10px;
                    margin-bottom: 15px;
                  }
              }
              .testTypeOption{
                display: block;
                .testResultOption{
                  width: 100%;
                }
              }
              }
            }
          }
        }
    }
  }
}
