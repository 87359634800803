.test_intructions_view_wrapper {
  padding-top: 50px;
  padding-bottom: 100px;
  .container {
    width: 75%;
    .test_intructions_view_inner_wrapper {
      background-color: #fff;
      border-radius: 10px;
      padding: 15px 15px 100px 15px;
      //istruction Screen
      .instructionScreenBox {
        .screenHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
        }

        .backBtn {
          cursor: pointer;
          padding: 5px 15px;
        }

        .Instruction_title {
          flex: 1; /* Take up remaining space */
          text-align: center;
          font-size: 25px;
          font-weight: 600;
        }

        .instruction_subtitle {
          text-align: center;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          margin-bottom: 10px;
        }

        .topBox {
          background: #f5f4f8;
          display: flex;
          // align-items: center;
          justify-content: space-between;
          padding: 50px;
          margin-bottom: 20px;
          border-radius: 8px;

          .left {
            width: 70%;
            .subject {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0em;
              color: #515151;
              // padding: 10px 0px;
              margin-bottom: 20px;
              span {
                color: #121212;
                font-weight: 500;
              }
            }

            .weakTopics {
              // background: red;
              p {
                // background: green;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                color: #515151;
              }
              ul {
                margin-top: -15px !important;
                padding: 0px;
                padding-left: 18px !important;
                margin: 0px;
                li {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                  letter-spacing: 0em;
                  color: #121212;
                }
              }
            }
          }
          .right {
            width: 25%;
            .QuestionsNum {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0em;
              color: #515151;
              margin-bottom: 20px;
            }

            .attemptsLeft {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0em;
              color: #515151;
            }
          }
        }

        .instruction_detailBox {
          max-width: 750px;
          margin: 0 auto;
          .examTime {
            border: 1px solid #f5f4f8;
            //   background: #ff933a0d;
            background: #f5f4f8;
            border-radius: 8px;

            text-align: center;
            border-radius: 10px;
            margin-bottom: 20px;

            .acps {
              display: flex;
              justify-content: space-between;
              margin: 5px 0px 5px 30px;

              // margin:5px;
              // margin-left: 20px;
              // margin-right: 20px;
              // padding:1.5em;
              // background: red;
              padding: 5px 0;
              // border: 1px solid blue;

              span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height */

                color: #121212;
                margin-left: 5px;

                //   white-space: nowrap;
                //   width: 50%;
                //   overflow: hidden;
                //   text-overflow: ellipsis;
              }

              .adaptiveCpsText {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height */

                color: #515151;
                margin-bottom: 1.1875em;
                margin-left: 30px;
              }

              .adaptiveCpsWeakTopics {
                //   overflow: hidden;
                //   text-overflow: ellipsis;
                //   display: -webkit-box;
                //   -webkit-line-clamp: 1; /* number of lines to show */
                //   line-clamp: 1;
                //   -webkit-box-orient: vertical;
              }

              // .adaptiveCpsText-right {
              //     // background: red;
              //   font-family: "Poppins";
              //   font-style: normal;
              //   font-weight: 400;
              //   font-size: 14px;
              //   line-height: 21px;
              //   /* identical to box height */

              //   color: #515151;
              //   margin-bottom: 1.1875em;
              // //   margin-right: 30px;
              //   text-align: start;
              //   margin-right: 40px;
              // }

              // .attemps_left {
              //   margin-right: 15px;
              //   span {
              //     color: #1e00d7;
              //   }
              // }
            }
          }
          .instructions {
            border: 1px solid #ffdec2;
            border-radius: 10px;
            background: #ff933a0d;
            margin-bottom: 15px;
            h3 {
              font-size: 20px;
              border-bottom: 1px solid #ffdec2;
              padding: 10px 15px;
            }
            .instPoint {
              padding: 10px 15px;
              img {
                width: 100%;
              }
              p {
                font-size: 14px;
                color: #000;
              }
              h6 {
              }
            }
          }
          .img {
            text-align: center;
            padding: 15px 0px;
          }
          .startTestBtn {
            button {
              background: #6d48ef;
              width: 100%;
              color: #fff;
              padding: 7px 10px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .test_intructions_view_wrapper {
    .test_intructions_view_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .sub_section_wrapper_1 {
            .text_content_wrapper_1 {
              .text_content_1 {
              }
            }
            .text_content_wrapper_2 {
              .text_content_2 {
              }
            }
          }
          .sub_section_wrapper_2 {
            .text_content_wrapper {
              .text_content {
                img {
                }
              }
            }
          }
          .sub_section_wrapper_3 {
            .btn_wrapper {
              button {
                width: 220px;
              }
            }
          }
        }
      }
    }
  }
}
