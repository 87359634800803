.package_details_component_wrapper {
  .package_details_component_inner_wrapper {
    .sec_1_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 20px;
      padding: 20px 0 0 10px;
      .btn_wrapper {
        button {
          border: none;
          outline: none;
          background-color: transparent;
        }
      }
      .text_content_wrapper {
        .text_content {
          margin: 0;
          padding-left: 5px;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }
      }
    }
    .sec_2_wrapper {
      margin: 10px 0;
      .subject_collection_wrapper {
        .subject_collection {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          .single_subject {
            margin: 10px;
            cursor: pointer;
            .image_wrapper {
              text-align: center;
              img {
                width: 100px;
              }
            }
            .text_content_wrapper {
              text-align: center;
              margin: 10px 0;
              .text_content {
                margin: 0;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                color: #6b6876;
              }
            }
          }
        }
      }
    }
    .sec_3_wrapper {
      margin: 10px 0;
      .content_wrapper {
        // background: url("../../../../../../utilities/images/homepage/homepage_1.png")
        //   no-repeat;
        // background-size: cover;
        background-color: #f99746;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px;
        padding: 10px;
        .left_side_content_wrapper {
          .text_content_wrapper_1 {
            .text_content_1 {
              margin: 0;
              font-size: 20px;
              font-weight: 600;
              color: #ffffff;
            }
          }
          .text_content_wrapper_2 {
            .text_content_2 {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
              color: #ffffff;
            }
          }
        }
        .right_side_content_wrapper {
          .image_wrapper {
            img {
              width: 50px;
            }
          }
        }
      }
    }
    .sec_4_wrapper {
      margin: 10px 0;
      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }
      }
      .faculty_collection_wrapper {
        margin: 10px 0;
        .faculty_collection {
          display: flex;
          flex-wrap: wrap;
          .single_faculty_wrapper {
            flex: 0 0 100%;
            margin: 10px 0;
            .content_wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              border-radius: 15px;
              padding: 10px;
              background-color: #ebeaf2;
              .left_side_content_wrapper {
                .image_wrapper {
                  border-radius: 50%;
                  width: 100px;
                  height: 100px;
                  img {
                    width: 100%;
                    border-radius: 50%;
                  }
                }
              }
              .right_side_content_wrapper {
                margin-left: 10px;
                .text_content_wrapper_1 {
                  .text_content_1 {
                    margin: 0;
                    font-size: 20px;
                    font-weight: 600;
                    color: #09041a;
                    .teacherDetail {
                      display: flex;
                      justify-content: space-between;

                      .col10 {
                      }

                      .col2 {
                        button {
                          width: max-content;
                          background-color: #6d48ef;
                          padding: 5px 25px;
                          font-size: 18px;
                          color: #fff;
                          border-radius: 6px;
                        }
                      }
                    }
                  }
                }
                .text_content_wrapper_2 {
                  .text_content_2 {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                    color: #6b6876;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sec_5_wrapper {
      margin: 10px 0;
      .content_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px;
        padding: 10px;
        .left_side_content_wrapper {
          .text_content_wrapper_1 {
            .text_content_1 {
              margin: 0;
              font-size: 20px;
              font-weight: 600;
              color: #000000;
            }
          }
          .text_content_wrapper_2 {
            .text_content_2 {
              margin: 0;
              font-size: 18px;
              color: #6b6876;
            }
          }
        }
        .right_side_content_wrapper {
          .btn_wrapper {
            button {
              border: none;
              outline: none;
              background-color: #6d48ef;
              padding: 10px;
              border-radius: 25px;
              font-size: 16px;
              font-weight: 600;
              color: #ffffff;
              width: 100px;
            }
          }
        }
      }
    }
  }
}
.coupon_modal2 {
  .coupon_wraper_inr {
    .facultyImg {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 2px 0px 7px -4px #000;
      margin: 0 auto 20px;

      img {
      }
    }

    h2 {
      font-size: 26px !important;
      color: #000 !important;
      margin-bottom: 15px;
    }

    span {
      text-align: center;
      display: block;
      margin-bottom: 15px;
      font-size: 16px;
    }

    p {
      padding: 0px !important;
      color: #000 !important;
      font-size: 15px;
      opacity: 1 !important;
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

.coupon_modal2 .mbd {
  padding: 10px 30px !important;
}

.pay_now_in_pkg_details {
  button {
    color: #ffffff;

    background-image: linear-gradient(to bottom, #7c54f6, #5026d3);
    padding: 10px 28px 10px 28px;
    border-radius: 4px;
    width: 100%;
  }
}
