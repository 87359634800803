.slider_container {
  margin-top: 20px;
  margin-bottom: 60px;
  .container {
    .dashboard_offerings_slider_inner_wrapper {
      .slick-list {
        // margin: 0 -7px !important;
      }

      .slick-slide > div {
        padding: 0 8px !important;
      }

      .slick-arrow {
        box-shadow: 0px 0px 30px #0000001a;
        width: 30px;
        height: 30px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAXCAYAAADQpsWBAAAABHNCSVQICAgIfAhkiAAAAOhJREFUOE+N1L8KwjAQBnC76HuJiGAHQcXV2SkdnFz7AJY+gKvg4KM4C7pJB58hafwOYumf5HJZQsn9SMh3aTISjizLDtba8RkjkRil1Al1uatdR1EPVNhtyqI+MMakZVk+gigE6IhexAEvioEBkoAOkoIGAVzwsXc5VP9bCmWYAFyxuHMFX4AFXSsXOqE7ClZUhOA+mGZFUTxZRIuAN0wbB9+YlxxscgKk3qIeox1Z2Am3DWFfwKlvx0FHSGCojZqj+nbkGjYIY0/DCyWPsAO11vMocjm24VaECOLHcqzreoIIcjFqt9UPHiuwN42MBW0AAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        background-position: center;
        background-color: #fff;
        border-radius: 50%;
        z-index: 1;
        padding: 5px;
        background-size: 9px;
        top: 46%;
        position: absolute;
      }

      .slick-prev:before,
      .slick-next:before {
        content: "";
      }

      .slick-prev {
        left: -14px;
      }

      .slick-next {
        right: -12px;
        transform: rotate(180deg) translateY(50%);
        top: 50%;
      }

      .new_offerings_header_text h4 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.4px;
        color: #000000;
      }
      .new_offerings_wrapper {
        // width: 746px;
        // width: calc(50% - 30px);
        // height: 392px;
        // left: 204px;
        min-height: 250px;

        background: #fdfdfd;
        border: 1px solid #dddddd;
        border-radius: 6px;

        .new_offerings_inner_wrapper {
          padding: 10px 20px 10px 20px;

          .new_offerings_coming_soon {

            float: right;
            font-size: 14px;
            padding: 5px 10px;
            color: #ffffff;
            background: #31d680;
            border-radius: 5px;
            margin-right: -10px;
          }

          .img_content {
            display: flex;
            margin-bottom: 20px;

            .new_offering_image img {
              //   height: 70px;
              //   width: 100px;
              height: 90px;
              width: 90px;
              margin-top: 30px;
              margin-left: 20px;
            }
            .new_offerings_content_wrapper {
              padding: 20px 20px 20px 40px;
              .new_offerings_title {
                // text-align: center;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 42px;

                letter-spacing: 0.01em;

                color: #121212;
              }
              .new_offerings_desc p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                // line-height: 27px;

                color: #5a5a5a;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
              }
            }
          }
          .new_offerings_button {
            text-align: center;
            margin: auto;

            background: #ff8f33;
            border-radius: 6px;
            width: 70%;
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 27px;
              /* identical to box height */

              color: #ffffff;
              padding: 2px 0px;
            }
          }
        }
      }

      .new_offerings_wrapper:hover {
        cursor: pointer;
        background: linear-gradient(
          180deg,
          #6d48ef 0%,
          rgba(161, 87, 255, 0.83) 100%
        );
        // box-shadow: -4px -4px 16px 8px rgba(68, 68, 68, 0.08),
        //   4px 4px 16px 8px rgba(68, 68, 68, 0.08);

        .new_offering_image img {
          //   height: 70px;
          //   width: 100px;
          //   color: #ffffff;
          //   border: 5px solid red;
          //   background-color: #ffffff;

          //   filter: none;
          //   -webkit-filter: grayscale(0);
          filter: brightness(0) invert(1);
        }

        .new_offerings_content_wrapper {
          .new_offerings_title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 42px;

            letter-spacing: 0.02em;

            color: #ffffff !important;
          }

          .new_offerings_desc p {
            color: #f1f0f0 !important;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            // line-height: 27px;

            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .slider_container {
    margin-bottom: 20px;
    .container {
      padding: 0px;
      .dashboard_offerings_slider_inner_wrapper {
        .new_offerings_header_text h4 {
        }
        .new_offerings_wrapper {
          // min-height: 250px;
  
          .new_offerings_inner_wrapper {
  
            .new_offerings_coming_soon {
            }
  
            .img_content {
              flex-direction: column;
              margin-bottom: 0;

  
              .new_offering_image {
                // text-align: center;
                img
                 {
                  margin-top: 10px 0;
                  margin-left: auto;
                  margin-right: auto;
                }
              }
              .new_offerings_content_wrapper {
                text-align: center;
                padding: 10px;
                .new_offerings_title {
                }
                .new_offerings_desc p {
                }
              }
            }
            .new_offerings_button {
              p {
              }
            }
          }
        }
  
        .new_offerings_wrapper:hover {
  
          .new_offering_image img {
          }
  
          .new_offerings_content_wrapper {
            .new_offerings_title {
            }
  
            .new_offerings_desc p {
            }
          }
        }
      }
    }
  }
}
