.userNotificationsContentComponent_wrapper {
  padding-top: 80px;

  .userNotificationsContentComponent_inner_wrapper {
    padding: 20px;

    .heading_wrapper {
      .heading_text_content {
        margin: 0;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.33px;
        color: #463c34;
      }
    }

    .notifications_collection_wrapper {
      .single_notification_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0;
        border-radius: 10px;
        background-color: #ffffff;
        padding: 10px;

        .icon_wrapper {
          img {
            width: 45px;
          }
        }

        .text_content_wrapper {
          margin-left: 10px;

          .text_content {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            color: #6f6868;
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userNotificationsContentComponent_wrapper {
    padding-top: 60px;

    .userNotificationsContentComponent_inner_wrapper {
      padding: 10px;

      .heading_wrapper {
        .heading_text_content {
          font-size: 24px;
        }
      }
    }
  }
}