@mixin flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin font-family {
  font-family: "Poppins";
  font-style: normal;
}

.predictCollegeSection {
  padding-bottom: 50px;
  .cards {
    @include flex-container;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
    .card {
      // width: 206px;
      width: 23.5%;
      //   height: 94px;
      border: 0.953191px solid #e8e8e8;
      box-shadow: 2.38298px 2.38298px 9.53191px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      text-align: center;
      padding: 12px 15px;
      .top {
        @include font-family;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #828282;
        margin-bottom: 4px;
      }
      .btm {
        display: flex;
        justify-content: center;
        align-items: center;
        .num {
          @include font-family;
          font-weight: 600;
          font-size: 25px;
          line-height: 48px;
          letter-spacing: 0.02em;
          margin-left: 15px;
        }

        img {
          width: 18px;
          height: 18px;
          margin-bottom: 4px;
        }

        .expectedRank {
          color: #00afb9;
        }

        .all_india_rank {
          color: #ec6f64;
        }
      }
    }
  }
  .clgList {
    @include flex-container;
    margin-top: 25px;
    margin-bottom: 20px;
    padding: 0px !important;
    .left {
      height: 46px;
      @include font-family;
      font-weight: 400;
      text-align: left;
      letter-spacing: 0em;
      .section-Heading {
        font-size: 20px;
        color: #121212;
      }
      .desc {
        font-size: 16px;
        color: #5a5a5a;
      }
    }
    .right {
      @include flex-container;
      gap: 2rem;

      .stateDropDownWrpper {
        @include flex-container;
        gap: 1rem;
        select {
          border: 1px solid #575757;
          width: 218px;
          padding: 7px 10px;
          option {
            margin-left: 30px;
          }
        }
      }

      .categoriesDropdownWrpper {
        @include flex-container;
        gap: 1rem;
        select {
          border: 1px solid #575757;
          width: 218px;
          padding: 7px 10px;
        }
      }
    }
  }

  .table_section {
    border: 1px solid #c2c2c2;
    border-radius: 6px;

    table {
      .table_heading {
        text-align: center;

        tr {
          background: #a98b72;

          .firstTd {
            border-top-left-radius: 6px;
          }

          th {
            padding: 10px 25px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #ffffff;

            &:last-child {
              border-top-right-radius: 6px;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px dashed #f2f2f2;
          background: none;

          .num {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 27px;
            color: #000000;
          }

          .td-text {
            width: 65%;
          }

          .firstTd {
            text-align: start !important;
          }

          td {
            text-align: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            color: #373737;
            padding: 10px 25px 5px 25px;
          }

          &:nth-child(even) {
            background-color: #f2f2f2;

            > div {
              border-radius: 6px;
            }
          }

          &:last-child {
            border-bottom: none;
            background: none;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }

        .no-data {
          text-align: center;
          padding: 100px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
        }
      }
    }
  }
}
