.exam_section_test_intructions_view_wrapper {
  padding-top: 50px;
  .test_intructions_view_inner_wrapper {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    //istruction Screen
    .instructionScreenBox {
      .backBtn {
        cursor: pointer;
        display: inline-block;
      }
      .instruction_title {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .instruction_detailBox {
        max-width: 750px;
        margin: 0 auto;
        .examTime {
          border: 1px solid #ffdec2;
          background: #ff933a0d;
          padding: 15px;
          text-align: center;
          border-radius: 10px;
          margin-bottom: 20px;
          .examTitle {
            color: #f1a769;
            margin-bottom: 8px;
          }

          .duration_totalmrks_totalQts {
            display: flex;
            justify-content: space-around;
            align-items: center;
          
            width: 70%;
            margin-left: 110px;
            
          }
        }
        .instructions {
          border: 1px solid #ffdec2;
          border-radius: 10px;
          background: #ff933a0d;
          margin-bottom: 15px;
          h3 {
            font-size: 20px;
            border-bottom: 1px solid #ffdec2;
            padding: 10px 15px;
          }
          .instPoint {
            padding: 10px 15px;
            img {
              width: 100%;
            }
            p {
              font-size: 14px;
              color: #000;
            }
            h6 {
            }
          }
        }
        .img {
          text-align: center;
          padding: 15px 0px;
        }
        .startTestBtn {
          button {
            background: #6d48ef;
            width: 100%;
            color: #fff;
            padding: 7px 10px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .exam_section_test_intructions_view_wrapper {
    padding-top: 0 ;
    .container{
      padding: 0;
    }
    .test_intructions_view_inner_wrapper {
      .instructionScreenBox {
        .instruction_title{
          font-size: 20px;
          margin-bottom: 10px;
          line-height: 1;
        }
        .instruction_detailBox{
          .examTime {
            padding: 5px;
            font-size: 14px;
            margin-bottom: 10px;
            .examTitle{
              margin-bottom: 4px;
            }
            .duration_totalmrks_totalQts{
              width: 100%;
              margin-left: auto ;
              margin-right: auto ;
              flex-direction: column;
            }
          }
          
          .instructions{
            .instPoint{
              padding:0 10px 10px;
              height: 50vh;
              overflow-y: auto;
            }
          }
          .img{
            padding: 0 0 15px;
          }
        } 
      }
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .sub_section_wrapper_1 {
            .text_content_wrapper_1 {
              .text_content_1 {
              }
            }
            .text_content_wrapper_2 {
              .text_content_2 {
              }
            }
          }
          .sub_section_wrapper_2 {
            .text_content_wrapper {
              .text_content {
                img {
                }
              }
            }
          }
          .sub_section_wrapper_3 {
            .btn_wrapper {
              button {
                width: 220px;
              }
            }
          }
        }
      }
    }
  }
}