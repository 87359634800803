.headerRight_wrapper {
  text-align: right;
  width: 100%;
  .headerRight_inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .logout_wrapper {
      width: 40px;
      height: 40px;
      text-align: center;
      display: inline-block;

      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ebebeb;
      margin-left: 20px;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.3s; /* Adding a transition for background-color */

      .image_wrapper {
        img {
          width: 20px;
          height: 20px;
        }
      }

      &:hover {
        background-color: #c5c5c5; /* Changing background color on hover */
      }
    }

    .userName_and_image_wrpr {
      .userName_and_image_inner_wrapper {
        .content_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .dropdown-menu::before {
            content: "";
            position: absolute;
            top: -18px;
            right: 23px;
            height: 40px;
            width: 33px;
            // width: 42px !important;
            // height: 9vh !important;
            background: #f9f8ff;
            // box-shadow: rgb(100, 100, 111, 0.2) 3px 3px 6px 0px outset;
            box-shadow: rgba(100, 100, 111, 0.2) -4px -5px 3px;
            transform: rotate(49deg);
          }

          // .dropdown.show:before {
          //   position: fixed;
          //   top: 0;
          //   bottom: 0;
          //   left: 0;
          //   right: 0;
          //   background: rgba(100, 100, 100, 0.5);
          //   content: "";
          //   z-index: 1;
          // }

          .image_container {
            .custom_dropdown_toggle {
              padding: 0px;
              margin-left: 20px;

              &::after {
                display: none;
              }
              .image_wrapper {
                width: 40px;
                height: 40px;
                border-radius: 50%;

                background-color: #ffffff;

                text-align: center;
                display: inline-block;
                .image_inner_wrapper {
                  display: inline-block;
                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                  }
                }
              }
            }

            .custom_dropdown_menu {
              margin-top: 35px !important;
              margin-right: -17px !important;
              border: none;
              // box-shadow: 0px 0px 60px #0000001a;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              border-radius: 10px;
              width: 500px;

              .profile_option_wrapper {
                width: 300px;
                .profile_option_inner_wrapper {
                  // padding: 15px;

                  .student {
                    margin-top: -8px;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                  }
                  .details_wrapper {
                    .wrapper_heading {
                      background: rgba(247, 244, 255, 0.65);
                      width: 500px;
                      border-top-right-radius: 10px;
                      border-top-left-radius: 10px;
                      h1 {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 36px;
                        color: #6d48ef;
                        padding: 10px 15px;
                      }
                    }
                    .row {
                      border-bottom: 0.4px solid #e3e3e3;
                      width: 500px;
                      margin-left: 0px;
                      padding: 5px 6px;

                      .text-left {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 33px;
                        color: #858585;
                      }

                      .text-right {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 33px;
                        color: #121212;
                      }
                    }
                    .last-child {
                      margin-bottom: -7px;
                      border-bottom: none;
                    }

                    .first-row {
                      margin-top: -10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
