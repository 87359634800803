.recordedLiveStreamVideoView_wrapper {
  .recordedLiveStreamVideoView_inner_wrapper {
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .video_frame_wrapper {
      text-align: center;
      iframe {
        width: 100%;
        height: 600px;
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .recordedLiveStreamVideoView_wrapper {
    .recordedLiveStreamVideoView_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .video_frame_wrapper {
        iframe {
          height: calc(100vh - 60px - 40px);
        }
      }
    }
  }
}
