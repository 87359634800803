
.modal {
    .my-modal {
      width: 50% !important;
      max-width: 50% !important;
      margin: 130px auto !important;
  
      .modal-header {
        h3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 0.01em;
          color: #303030;
          width: 100%;
          text-align: center;
        }
      }
  
      .instruction_language {
        float: right;
  
        .lanugage_dropdown {
          width: 150px;
          // background: red;
          display: inline-block;
          background: #ffffff;
          border: 1.5px solid #ababab;
          box-shadow: 1px 1px 8px rgba(18, 18, 18, 0.12);
          border-radius: 3px;
          select {
            margin-right: 20px;
            border: none;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */
  
            color: #000000;
            padding: 7px 18px;
            cursor: pointer;
            outline: 0px;
          }
        }
  
        span {
          margin-right: 20px;
          display: inline-block;
        }
      }
  
      .instruction_wrapper {
        margin-top: 70px;
        max-height: calc(60vh - 200px);
        overflow-y: scroll;
        overflow-x: hidden;
        background: #f9f9f9;
        border: 0.5px solid #a0a0a0;
        border-radius: 4px;
        padding: 20px;
  
        .instruction_inner_wrapper {
          .instruction_inner_header {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.01em;
            color: #000000;
  
            h3 {
              font-size: 16px;
              line-height: 24px;
            }
            h4 {
              font-size: 14px;
              line-height: 21px;
            }
          }
  
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            letter-spacing: 0.01em;
            color: #898989;
            width: 650px;
          }
        }
      }
  
      .modal-footer-btn {
        text-align: center;
        margin-bottom: 30px;
        margin-top: 10px;
  
        button {
          width: 200px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #3839ce;
          border: 1px solid #3839ce !important;
          padding: 10px 20px;
          background: transparent;
          border-radius: 25px;
        }
        :hover {
          background: #3839ce;
          color: #ffff;
        }
      }
    }
  }

    @media (max-width: 575.98px) {
      .modal .my-modal {
        width: 100% !important;
        max-width: 100% !important;
      }
    }