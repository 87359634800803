.liveStreamFeatureContentComponent_wrapper {
  padding-top: 80px;
  .liveStreamFeatureContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .video_wrapper {
      .video_inner_wrapper {
        iframe {
          width: 100%;
          height: 600px;
        }
      }
    }
    .live_chat_action_wrapper {
      .live_chat_action_inner_wrapper {
        display: flex;
        justify-content: flex-end;
        .content_icon_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          border: 2px solid #25d366;
          padding: 5px;
          cursor: pointer;
          .text_content_wrapper {
            margin: 0 5px;
            .text_content {
              margin: 0;
              font-size: 20px;
              font-weight: 600;
            }
          }
          .image_wrapper {
            margin: 0 5px;
            img {
              width: 50px;
            }
          }
        }
        // .bounce {
        //   position: relative;
        //   animation: bounce 0.5s infinite linear;
        // }
        // @keyframes bounce {
        //   0% {
        //     top: 0;
        //   }
        //   50% {
        //     top: -0.2em;
        //   }
        //   70% {
        //     top: -0.3em;
        //   }
        //   100% {
        //     top: 0;
        //   }
        // }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      .sec_2_inner_wrapper {
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .liveStreamFeatureContentComponent_wrapper {
    padding-top: 60px;
    .liveStreamFeatureContentComponent_inner_wrapper {
      padding: 20px 5px;
      .video_wrapper {
        .video_inner_wrapper {
          iframe {
            width: 100%;
            height: calc(100vh - 60px - 40px);
          }
        }
      }
    }
  }
}
