.heading-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  margin: 0px 0px 20px 20px;
  color: #060606;
}

.type_collection_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // width: max-content;
  background: #f5f4f8;
  border-radius: 4px 0px 0px 4px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 20px;

  .single_select_type {
    border: 1px solid #737373;
    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    label {
      padding: 6px 20px;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: #6b6876;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    input[type="radio"]:checked + label {
      background: #ff933a;
      color: #ffffff;
      border-color: #ff933a;
      overflow: hidden;
      border: 1px solid #ff933a;
    }
  }
}

@media (max-width: 1199.98px) {
  .type_collection_wrapper{
    
    .single_select_type{
      margin-bottom: 0.3rem;
    }
  }
}
