.QuestionWiseAnalysisContentComponentWrapper {
  margin-top: -30px;
  width: 100%;
  .subjects_tab_wrapper {
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;

    .outer_content_wrapper {
      .content_wrapper {
        padding: 20px 0;

        .left_side_content_wrapper {
          text-align: -webkit-center;
          margin-bottom: 25px;
          text-align: center;

          .subject_type_collection_wrapper {
            display: inline-block;
            background-color: #f5f4f8;

            border-radius: 25px;
            overflow: hidden;

            .single_select_subject_type {
              width: auto;
              min-width: 160px;
              display: inline-block;
              background: #f5f4f8 !important;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                // background-color: #fafafd;

                padding: 0.5rem 1rem;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }

              input[type="radio"]:checked + label {
                background-color: #ff933a;
                border: none;
                color: #ffffff;
              }
            }
          }
        }

        .right_side_content_wrapper {
          flex: 0 0 65%;
          padding: 0 20px;

          .cards {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 70%;
            margin: auto;
            margin-bottom: 20px;
            .card {
              width: 30%;
              text-align: center;
              padding: 10px 20px;
              .text {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #828282;
              }
              .num {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 48px;
                letter-spacing: 0.02em;
                color: #6d48ef;
              }
            }
          }

          .table_section {
            border-radius: 6px;
            margin-bottom: 50px;
            border: 1px solid #c2c2c2;

            table {
              font-family: Arial, Helvetica, sans-serif;
              border-collapse: collapse;
              margin: auto;
              .table_heading {
                background: #a98b72;
                th {
                  padding: 12px 0px 12px 35px;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0.01em;
                  color: #fff2e7;
                  border-bottom: none !important;
                }
                :first-child {
                  border-top-left-radius: 6px !important;
                }
                :last-child {
                  border-top-right-radius: 6px !important;
                }
              }

              td {
                padding: 5px 0px 5px 35px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #828282;
              }
              .num {
                padding: 5px 0px 5px 65px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #121212;
              }
            }
          }

          .questionDetails {
            border-radius: 6px;
          }
        }
      }
    }
  }
}
