.left_sideBar_wrapper {
  position: fixed;
  width: 220px;
  max-width: 220px;
  .left_sideBar_inner_wrapper {
    min-height: 100vh;
    background-color: #ffffff;
    .sec_1_wrppaer {
      padding: 20px 15px;
      .sec_1_inner_wrapper {
        .image_wrapper {
          text-align: center;
          a {
            img {
            }
          }
        }
      }
    }
    .sec_2_wrapper {
      padding: 15px 0;
      .sec_2_inner_wrapper {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          li {
            a {
              text-decoration: none;
              .wrapping_container {
                padding: 10px 20px;
                img {
                }
                .active_icon {
                  display: none;
                }
                span {
                  margin-left: 10px;
                  font-size: 18px;
                  font-weight: 500;
                  color: #6f6868;
                  vertical-align: middle;
                }
              }
            }
            .active {
              .wrapping_container {
                background-color: #f5f2fe;
                .active_icon {
                  display: inline-block;
                }
                .inactive_icon {
                  display: none;
                }
                span {
                  font-weight: 600;
                  color: #6d48ef;
                }
              }
            }
            &:not(:last-child) {
              border-bottom: 1px solid #0000000f;
            }
          }
        }
      }
    }
    .sec_3_wrapper {
      padding: 5px 0;
      border-top: 2px solid #0000000f;
      .sec_3_inner_wrapper {
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            margin: 5px 0;
            padding: 0 15px;
            a {
              text-decoration: none;
              .wrapping_container {
                .heading_tag {
                  font-size: 18px;
                  color: #a6a8af;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .left_sideBar_wrapper {
    width: 190px;
    max-width: 190px;
    .left_sideBar_inner_wrapper {
      .sec_1_wrppaer {
        padding: 20px 10px;
        .sec_1_inner_wrapper {
          .image_wrapper {
            a {
              img {
              }
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          ul {
            li {
              a {
                .wrapping_container {
                  padding: 5px 15px;
                  img {
                  }
                  .active_icon {
                  }
                  span {
                  }
                }
              }
              .active {
                .wrapping_container {
                  .active_icon {
                  }
                  .inactive_icon {
                  }
                  span {
                  }
                }
              }
              &:not(:last-child) {
              }
            }
          }
        }
      }
    }
  }
}
// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .left_sideBar_wrapper {
    width: 160px;
    max-width: 160px;
    .left_sideBar_inner_wrapper {
      .sec_1_wrppaer {
        .sec_1_inner_wrapper {
          .image_wrapper {
            a {
              img {
              }
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          ul {
            li {
              a {
                .wrapping_container {
                  padding: 25px 5px;
                  img {
                  }
                  .active_icon {
                  }
                  span {
                  }
                }
              }
              .active {
                .wrapping_container {
                  .active_icon {
                  }
                  .inactive_icon {
                  }
                  span {
                  }
                }
              }
              &:not(:last-child) {
              }
            }
          }
        }
      }
    }
  }
}
