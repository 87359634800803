.examSummaryComponentWrapper {
    padding-top: 40px;
    min-height: 100vh;
    background: #f5f4f8;
  
    .examSummaryComponentInnrWrapper {
      .container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 60px;
        .examSummaryContainer {
          // width: 750px;
          // height: 400px;
          width: 60%;
          flex-shrink: 0;
          border-radius: 12px;
          background: #fff;
  
          .examSummaryContent {
            .examSummaryBackBtn {
              text-align: left;
              padding: 22px;
            }
  
            .section_heading {
              color: #353535;
              text-align: center;
              font: 400 22px "Poppins", sans-serif;
              margin-top: -47px;
            }
  
            .cards {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              padding: 30px 110px;
              gap: 2rem;
              margin-top: 20px;
              .card {
                //   width: 144px;
                width: 154px;
                height: 100px;
                display: flex;
                justify-content: center;
                padding: 10px;
                .text {
                  color: #585858;
                  text-align: center;
                  font: 400 14px "Poppins", sans-serif;
                }
  
                .num {
                  color: #000000;
                  text-align: center;
                  font: 500 24px "Poppins", sans-serif;
                }
              }
            }
  
            .exam_no_analysis_found_wrapper {
              img {
                width: 150px;
                height: 150px;
                margin: 20px 0px 0px 300px;
              }
  
              p {
                text-align: center;
                margin-top: 20px;
              }
            }
          }
        }
      }
  
      .back-to-tests {
        text-align: center;
        font: 500 16px "Poppins", sans-serif;
        margin-top: 35px;
  
        button {
          border: 1px solid #6d48ef;
          padding: 10px 113px;
          color: #6d48ef;
          border-radius: 6px;
        }
      }
    }
  }

  @media(max-width:578px){
    .examSummaryComponentWrapper {
      .examSummaryComponentInnrWrapper {
        .container {
          padding-top: 20px;
          .examSummaryContainer{
            width: 97%;
            .examSummaryContent {
              .cards{
                padding: 15px 15px 25px;
                .card{
                  // width: auto;
                }
              }
            }
          }
        }
        .back-to-tests{
          margin-bottom: 35px;
        }
      }
    }
  }
  