$primaryColor: #815efb;
$secondaryColor: #6d48ef;
$imgWidth: 110px;
$imgHeight: 100px;

.adaptiveCpsCreatePaperWrppr {
  font-family: "Poppins", sans-serif;
  padding: 30px;
  text-align: center;
  background: #fff8f2;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .adaptiveCpsCreatePaperContent {
    text-align: center;
    width: 40%;
    margin: 0 auto;
    .heading {
      font-weight: 500;
      font-size: 22px;
      line-height: 33px;
      color: #121212;
    }

    .imgWrppr {
      text-align: center;

      img {
        width: $imgWidth;
        height: $imgHeight;
      }
    }

    .adaptiveCpsCreatePaperContentPara {
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;
      color: #555555;
      margin: 0px;

      margin-bottom: 20px !important;
      .span-1 {
        color: #252525;
        font-weight: bold;
      }

      .span-2 {
        color: #169b00;
        font-weight: bold;
      }
    }

    .create_new_button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 56px;
      background: linear-gradient(
        91.51deg,
        $primaryColor 0%,
        $secondaryColor 97.4%
      );
      box-shadow: 0px 2px 4px rgba(110, 73, 240, 0.16);
      border-radius: 4px;
      cursor: pointer;

      p {
        padding: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        margin-bottom: 0px;

        .add_btn {
          margin-right: 10px;
        }
      }
    }
  }
}
