.question_bank_Wrapper {
  padding-top: 101px;
  background-color: #f5f4f8;

  .question_bank_InrWrapper {
    padding: 30px 0px;

    .question_bank_contentWpr {
      background-color: #fff;
      border-radius: 10px;
    }
  }
}
