@keyframes fadeInDelayed {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-dialog {
  &.refund-modal {
    .modal-content {
      .modal-header {
        h4 {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0em;
        }

        .btn-close {
          color: #000000 !important;
        }
      }

      .modal-body {
        padding: 100px;
        background: #fafafa;

        .motion-assistant-msg {
          margin-bottom: 52px;

          .msg {
            margin: 0px 50px 15px 24px;
            padding: 8px 12px 8px 12px;
            width: fit-content;
            border-radius: 16px 16px 16px 0px;
            font-size: 14px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            color: #121212;
            box-shadow: 0px 2px 8px 0px #a3a3a314;
            background: #ffffff;

            animation: fadeInDelayed 0.5s ease 1s forwards;
          }
        }

        .refund-options {
          margin: 0px 0px 15px 24px;

          .single-refund-option {
            cursor: pointer;

            p {
              width: fit-content;
              border-radius: 16px;
              text-align: center;
              margin-bottom: 16px;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: 0.02em;
              border: 1px solid #ff933a;
              padding: 8px 24px 8px 24px;
              color: #ff933a;
              box-shadow: 0px 2px 8px 0px #a3a3a314;
              transition: background-color 0.3s ease, color 0.3s ease;

              &:hover {
                background-color: #ff933a;
                color: #ffffff;
              }
            }
          }
        }

        .some {
          display: flex;
          flex-direction: column;
          align-items: end;

          .user-selected-refund-option {
            padding: 8px 12px 8px 12px;
            border-radius: 16px 16px 0px 16px;
            width: 259px;
            font-size: 14px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0em;
            color: #ffffff;
            background-color: #ff933a;
            box-shadow: 0px 2px 8px 0px #a3a3a314;
            margin-bottom: 24px;
            text-align: center;
            overflow: hidden;
            word-break: break-all;
          }

          .motion-assistant-msg {
            margin-bottom: 52px;
            animation: fadeInDelayed 0.5s ease 1s forwards;

            .msg {
              margin: 0px 50px 15px 24px;
              padding: 8px 12px 8px 12px;
              width: fit-content;
              border-radius: 16px 16px 16px 0px;
              font-size: 14px;
              font-weight: 400;
              line-height: 23px;
              letter-spacing: 0em;
              color: #121212;
              box-shadow: 0px 2px 8px 0px #a3a3a314;
              background: #ffffff;

              animation: fadeInDelayed 0.5s ease 1s forwards;
            }
          }
        }

        .input-submit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          margin-bottom: -10px;

          textarea {
            width: 100%;
            flex: 1;
            padding: 10px;
            border: none;
            border-radius: 4px;
            resize: none;
            transition: background-color 0.3s ease-in-out;
            min-height: 30px;
            line-height: 1.4;
            margin-right: 10px;
            box-shadow: 0px 2px 8px 0px #a3a3a314;
            background: #ffffff;
            overflow: hidden;
            color: #121212;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.01em;

            &::placeholder {
              color: #999;
            }

            &:focus {
              background-color: #fff;
              outline: none;
            }
          }

          button {
            cursor: pointer;
            background: none;
            border: none;
            padding: 0;
            display: flex;
            align-items: center;
            transition: transform 0.3s ease-in-out;

            &:hover {
              transform: scale(1.1);
            }

            img {
              width: 38px;
              height: 38px;
            }
          }

          .disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }


        .refund-btns {
          .ask-refund-btn {
            border: 1px solid #ff933a;
            box-shadow: 0px 2px 8px 0px #a3a3a314;
            border-radius: 16px;
            margin: 0 auto;
            background-color: #ff933a;
            width: fit-content;
            text-align: center;
            margin-bottom: 20px;
            cursor: pointer;

            button {
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: 0.02em;
              padding: 8px 24px 8px 24px;
              color: #ffffff;
            }
          }

          .bck-to-prvs-menu-btn {
            cursor: pointer;
            border: 1px solid #7e7e7e;
            box-shadow: 0px 2px 8px 0px #a3a3a314;
            border-radius: 16px;
            margin: 0 auto;
            width: fit-content;
            text-align: center;

            button {
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0.02em;
              color: #7e7e7e;
              padding: 8px 24px 8px 24px;
            }
          }
        }
      }
    }
  }
}
