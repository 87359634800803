.otsSubjectWiseMarksWrapper {
    background-color: #F5F4F8;
    min-height: 100vh;
  
    .subjectWiseMarksInrWrapper {
      padding-top: 101px;
      padding-bottom: 20px;
  
      .sbjtWiseMarksInr {
        margin: 20px 50px 0px;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
  
        .backBtn {
          cursor: pointer;
  
          img {}
        }
  
        .difficultyLevel {
          h3 {
            text-align: center;
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 25px;
          }
  
          .difficultyLevelTab {
            .difficultyLevelTabBox {
              display: flex;
              background: #F5F4F8;
              max-width: max-content;
              margin: 0 auto;
              border-radius: 20px;
              overflow: hidden;
              margin-bottom: 40px;
  
              .single_select_subject {
                &:last-child {
                  margin-right: 0px;
                }
  
                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }
  
                label {
                  padding: 8px 20px;
                  font-size: 15px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                  color: #6b6876;
                  cursor: pointer;
                  min-width: 150px;
                  text-align: center;
                }
  
                input[type="radio"]:checked+label {
                  background-color: #FF933A;
                  border: none;
                  color: #ffffff;
                }
              }
            }
  
            .difficultyLevelcontent {
              display: flex;
              flex-wrap: wrap;
  
              .difficultyData {
                background: #F5F4F8;
                padding: 15px 20px;
                width: calc(50% - 15px);
                border-radius: 8px;
                margin-bottom: 30px;
                margin: auto;
  
                ul {
                  padding: 0px;
                  margin: 0px;
  
                  li {
                    list-style: none;
                    margin-bottom: 20px;
  
                    span {
                      width: 25%;
                      display: inline-block;
                      font-weight: 600;
                      font-size: 15px;
                    }
                  }
  
                  .titleData {
                    span {
                      font-weight: 500;
                      font-size: 14px;
                    }
                  }
                }
              }
  
              .scorePercentageBox {
                width: calc(50% - 15px);
                display: flex;
                align-items: center;
                border: 1px solid #eeeeee;
                border-radius: 8px;
                background: #f5f4f8;
                margin-bottom: 30px;
                margin-left: 30px;
  
                &:nth-child(2n+1) {
                  margin-left: 0px;
                }
  
                .scoreChart {
                  width: 150px;
                  margin: 40px 20px;
  
                  .CircularProgressbar .CircularProgressbar-path {
                    stroke: #FCB21D;
                  }
                }
  
                .mediumResult {
                  .CircularProgressbar .CircularProgressbar-path {
                    stroke: #6D48EF;
                  }
                }
  
                .hardResult {
                  .CircularProgressbar .CircularProgressbar-path {
                    stroke: #4DD7D8;
                  }
                }
  
                .scoreDetail {
                  p {
                    font-size: 14px;
                    margin-bottom: 10px;
                    font-weight: 600;
                    padding-right: 15px;
                  }
  
                  .percentageDiv {
                    font-size: 12px;
                    margin-bottom: 5px;
  
                    span {
                      background-color: #E2E2E2;
                      width: 8px;
                      height: 8px;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 8px;
                    }
                  }
  
                  .gotPrcntg {
                    span {
                      background-color: #FCB21D;
                    }
                  }
  
                  .medium {
                    span {
                      background-color: #6D48EF;
                    }
                  }
  
                  .hard {
                    span {
                      background-color: #4DD7D8;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 575.98px) {
    .otsSubjectWiseMarksWrapper {
        .subjectWiseMarksInrWrapper {
          padding-top: 46px;
          .sbjtWiseMarksInr {
            margin: 10px 0 0px;
          }
          .sbjtWiseMarksInr{
            .difficultyLevel {
              .difficultyLevelTab {
                .difficultyLevelTabBox{
                  max-width: none;
                  flex-wrap: wrap;
                  .single_select_subject{
                    width: 50%;
                    label{
                      min-width: -webkit-fill-available;
                    }
                  }
                }
                .difficultyLevelcontent{
                  flex-wrap: nowrap;
                  flex-direction: column;
                  .difficultyData{
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 20px;
                    .type_list {
                      .list{
                        max-width: none;
                        flex-wrap: wrap;
                        // border: 1px solid #6d48ef;
                        .single_select_subject{
                          width: 50%;
                          &:last-child{
                            width: 100%;
                          }
                          label{
                            min-width: -webkit-fill-available;
                          }
                        }
                      }
                    }
                    ul{
                      padding: 0px;
    margin: 0px;
    overflow-y: scroll;
    li{
      display: flex;
      gap: 1rem;
    }
                    }
                  }
                }
              }
            }
          } 
        }
      }
  }
  