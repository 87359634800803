.ftr{
  background: #ffffff;
  .container {
    margin-left: 175px;
    padding: 50px 20px;

    width: 80%;

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ul {
        padding: 0px;
        li {
          list-style: none;
          width: 280px;
          padding: 5px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          height: 40px;
          .letter {
            color: #b73333;
          }
          .text {
            color: #454545;
            display: inline;
          }
        }
      }
    }
  }
}
