.questionDetailPanel {
  width: 68%;
  border-radius: 8px;
  padding: 10px;
  background-color: #f5f4f8;
  .right_btn_wrapper{
    display: none;
  }

  .questionDetailPanel_wrapper {
    max-width: 750px;
    margin: 0 auto;
    scroll-behavior: smooth;

    .solutionBoxWrapper {
      padding-top: 20px;

      p {
        word-break: break-all;
      }
    }
  }
}
@media(max-width:578px) {
  .questionDetailPanel {
    width: 100%;
    padding: 0;
    .right_btn_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      position: sticky;
      top: 0;
      z-index: 1000;
      background: inherit;
      padding: 10px;
      .togglebtn {
        border: none;
        outline: none;
        border-radius: 8px;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        padding: 10px;
        background: #6d48ef;
  
        &.submit {
          background: coral;
        }
      }
    }
    .questionDetailPanel_wrapper{
      padding: 10px;
    }
}
}