////////No need to subtract scrollbar width in ipad,and mobile screens
.header_component_two_wrapper {
  width: 100%;
  position: fixed;
  z-index: 999;
  .header_component_two_inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    background-color: #ffffff;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .header_component_two_wrapper {
    .header_component_two_inner_wrapper {
      padding: 15px 10px;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .header_component_two_wrapper {
    .header_component_two_inner_wrapper {
      padding: 15px 5px;
    }
  }
}
