.exam_cracker_test_questions_view_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;
          

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .section_content_wrapper_backdrop{
            display: none;
          }

          // .right_side_content_wrapper {
          //   width: 68%;
          //   border-radius: 8px;
          //   padding: 10px;
          //   background-color: #f5f4f8;

          //   .sub_section_wrapper {
          //     max-width: 750px;
          //     margin: 0 auto;

          //     .sub_sub_section_wrapper_1 {
          //       display: flex;
          //       align-items: center;
          //       justify-content: space-between;

          //       .right_side_content_wrapper {
          //         flex: 0 0 100%;
          //         display: flex;
          //         justify-content: flex-end;

          //         .review_image_wrapper {
          //           margin: 0 10px;

          //           img {
          //             cursor: pointer;
          //           }
          //         }

          //         .bookmark_image_wrapper {
          //           margin: 0 10px;

          //           img {
          //             cursor: pointer;
          //           }
          //         }
          //       }
          //     }

          //     .testProgressIndicator {
          //       background: #fff;
          //       padding: 5px 10px;
          //       border-radius: 8px;
          //       margin-bottom: 20px;

          //       h4 {
          //         font-size: 14px;
          //         color: #000;
          //         margin-bottom: 16px;
          //       }

          //       .ProgressBar_Line {
          //         position: relative;
          //       }

          //       .ProgressBar_Line span {
          //         position: absolute;
          //         top: -16px;
          //         font-size: 14px;
          //       }

          //       .progress {
          //         height: 8px;
          //         margin-bottom: 5px;

          //         .progress-bar {
          //           background: #ff933a;
          //           border-radius: 9px;
          //         }
          //       }

          //       .questionPercentage {
          //         display: flex;
          //         justify-content: space-between;

          //         span {
          //           color: #707070;
          //           font-size: 14px;
          //         }
          //       }
          //     }

          //     .sectionWiseTestWrapper {
          //       display: none;

          //       .subjectWiseTest {
          //         background: #fff;
          //         display: flex;
          //         padding: 7px 10px;
          //         border-radius: 36px;
          //         justify-content: space-between;
          //         margin-bottom: 15px;

          //         .single_select_subject {
          //           input[type="radio"] {
          //             opacity: 0;
          //             position: fixed;
          //             width: 0;

          //             &:checked + label {
          //               background-color: #ff933a;
          //               border: none;
          //               color: #ffffff;
          //               border-radius: 19px;
          //             }
          //           }

          //           label {
          //             padding: 8px 20px;
          //             font-size: 15px;
          //             font-weight: 500;
          //             letter-spacing: 0.2px;
          //             color: #6b6876;
          //             cursor: pointer;
          //             min-width: 150px;
          //             text-align: center;
          //           }
          //         }
          //       }

          //       .sectionWiseTest {
          //         display: flex;
          //         width: max-content;
          //         margin: 0 auto;
          //         margin-bottom: 15px;
          //       }
          //     }

          //     .sub_sub_section_wrapper_2 {
          //       h2 {
          //         text-align: center;
          //         font-size: 25px;
          //         font-weight: bold;
          //         margin-bottom: 20px;
          //       }

          //       .text_content_wrapper {
          //         .typeLanguageWrapper {
          //           display: flex;
          //           justify-content: space-between;
          //           align-items: center;
          //           margin-bottom: 5px;

          //           span {
          //             color: #707070;
          //             font-size: 12px;
          //           }

          //           .markReview {
          //             display: flex;

          //             .bookmark_image_wrapper {
          //               margin: 0px 10px;

          //               span {
          //                 margin-right: 5px;
          //               }

          //               img {
          //                 cursor: pointer;
          //               }
          //             }

          //             button {
          //             }
          //           }
          //         }

          //         .questionBox {
          //           display: flex;
          //           align-items: baseline;
          //           margin-bottom: 20px;

          //           span {
          //             margin-right: 5px !important;
          //           }

          //           .text_content {
          //             margin: 0;
          //             font-size: 16px;
          //             font-weight: 500;
          //             color: #000;

          //             p {
          //               margin-bottom: 0px;
          //             }
          //           }

          //           .hide {
          //             display: none;
          //           }

          //           .show {
          //             display: block;
          //           }
          //         }
          //       }
          //     }

          //     .sub_sub_section_wrapper_3 {
          //       //single-select
          //       .single_select_option_collection_wrapper {
          //         display: flex;
          //         justify-content: space-between;
          //         align-items: center;
          //         flex-wrap: wrap;
          //         margin-bottom: 30px;

          //         .single_select {
          //           width: calc(50% - 8px);
          //           margin: 10px 16px 10px 0px;

          //           &:nth-child(2n + 2) {
          //             margin-right: 0px;
          //           }

          //           .classCorrect {
          //             border: 1px solid #5ddd9b !important;
          //             background: #e1f1ec !important;
          //           }

          //           .classWrong {
          //             border: 1px solid #f93030 !important;
          //             background: #f6e0e4 !important;
          //           }

          //           input[type="checkbox"] {
          //             opacity: 1;
          //             position: fixed;
          //             width: 0;
          //           }

          //           .single_option_wrapper {
          //             min-height: 50px;
          //             background-color: #fff;
          //             border-radius: 8px;
          //             display: flex;
          //             justify-content: flex-start;
          //             align-items: center;
          //             padding: 10px;
          //             cursor: pointer;

          //             .option_initial {
          //               margin-right: 10px;

          //               .text_content_2 {
          //                 margin: 0;
          //                 font-size: 16px;
          //                 font-weight: 500;
          //                 color: #acacac;
          //               }
          //             }

          //             .option_final {
          //               .text_content_3 {
          //                 margin: 0;
          //                 font-size: 16px;
          //                 font-weight: 500;
          //                 color: #000;

          //                 p {
          //                   margin-bottom: 0px;
          //                 }
          //               }
          //             }
          //           }

          //           input[type="checkbox"]:checked + .single_option_wrapper {
          //             border: 2px solid #6d48ef;

          //             .option_initial {
          //               .text_content_2 {
          //                 font-weight: 600;
          //               }
          //             }

          //             .option_final {
          //               .text_content_3 {
          //                 font-weight: 600;
          //               }
          //             }
          //           }
          //         }
          //       }

          //       .hide {
          //         display: none !important;
          //       }

          //       .show {
          //         display: flex !important;
          //       }

          //       .show2 {
          //         display: block !important;
          //       }

          //       //multi-select
          //       .multi_select_option_collection_wrapper {
          //         display: flex;
          //         justify-content: space-between;
          //         align-items: center;
          //         flex-wrap: wrap;

          //         .single_select {
          //           width: calc(50% - 8px);
          //           margin: 10px 16px 10px 0px;

          //           &:nth-child(2n + 2) {
          //             margin-right: 0px;
          //           }

          //           input[type="checkbox"] {
          //             opacity: 1;
          //             position: fixed;
          //             width: 0;
          //           }

          //           .single_option_wrapper {
          //             min-height: 50px;
          //             border: solid 1px #f0eef8;
          //             background-color: #fafafd;
          //             border-radius: 15px;
          //             display: flex;
          //             justify-content: flex-start;
          //             align-items: center;
          //             padding: 10px;
          //             cursor: pointer;

          //             .option_initial {
          //               margin-right: 10px;

          //               .text_content_2 {
          //                 margin: 0;
          //                 font-size: 16px;
          //                 font-weight: 500;
          //                 color: #6b6876;
          //               }
          //             }

          //             .option_final {
          //               .text_content_3 {
          //                 margin: 0;
          //                 font-size: 16px;
          //                 font-weight: 500;
          //                 color: #6b6876;
          //               }
          //             }
          //           }

          //           input[type="checkbox"]:checked + .single_option_wrapper {
          //             border: 2px solid #6d48ef;

          //             .option_initial {
          //               .text_content_2 {
          //                 font-weight: 600;
          //               }
          //             }

          //             .option_final {
          //               .text_content_3 {
          //                 font-weight: 600;
          //               }
          //             }
          //           }
          //         }
          //       }

          //       //integer
          //       .input_wrapper {
          //         .input_inner_wrapper {
          //           margin-bottom: 30px;

          //           input {
          //             border: none;
          //             outline: none;
          //             width: 100%;
          //             border: solid 1px #f0eef8;
          //             background-color: #fff;
          //             height: 50px;
          //             border-radius: 8px;
          //             padding: 0 5px;
          //             font-size: 16px;
          //             font-weight: 500;
          //           }

          //           input[type="number"]:focus {
          //             border: 2px solid #6d48ef;
          //           }

          //           input[type="number"]::-webkit-outer-spin-button,
          //           input[type="number"]::-webkit-inner-spin-button {
          //             -webkit-appearance: none;
          //             margin: 0;
          //           }
          //         }
          //       }
          //     }

          //     .sub_sub_section_wrapper_4 {
          //       margin-bottom: 15px;

          //       .checkbox_wrapper {
          //         input[type="checkbox"] {
          //           margin-right: 10px;
          //         }

          //         label {
          //           font-size: 13px;
          //           vertical-align: text-top;
          //           color: #09041a;
          //         }
          //       }
          //     }

          //     .sub_sub_section_wrapper_5 {
          //       display: flex;
          //       align-items: center;
          //       justify-content: space-between;

          //       .left_side_wrapper {
          //         width: calc(50% - 8px);
          //         margin-right: 16px;

          //         .btn_wrapper {
          //           text-align: left;

          //           button {
          //             border: none;
          //             outline: none;
          //             border-radius: 8px;
          //             background-color: #ffffff;
          //             font-size: 16px;
          //             font-weight: 600;
          //             text-align: center;
          //             color: #6d48ef;
          //             padding: 10px;
          //             width: 100%;
          //             border: 1px solid #e2e2e2;
          //             // text-decoration: underline;
          //           }
          //         }
          //       }

          //       .right_side_wrapper {
          //         width: calc(50% - 8px);

          //         .btn_wrapper {
          //           text-align: right;

          //           button {
          //             border: none;
          //             outline: none;
          //             border-radius: 8px;
          //             background-color: #ffffff;
          //             font-size: 16px;
          //             font-weight: 600;
          //             text-align: center;
          //             color: #fff;
          //             padding: 10px;
          //             width: 100%;
          //             background: #6d48ef;
          //             // text-decoration: underline;
          //           }
          //         }
          //       }
          //     }

          //     .solutionBoxWrapper {
          //       padding-top: 20px;

          //       p {
          //         word-break: break-all;
          //       }
          //     }

          //     .videoWrapper {
          //       display: flex;
          //       flex-direction: column;
          //       align-items: center;
          //       .view_videoSolution_btn {
          //         background: #ff933a;
          //         width: 30%;
          //         text-align: center;
          //         padding: 10px;
          //         border-radius: 10px;
          //         margin-bottom: 30px;
          //         font-family: "Poppins";
          //         font-style: normal;
          //         font-weight: 500;
          //         font-size: 14px;
          //         line-height: 21px;
          //         color: #f5f4f8;
          //       }
          //       #iframe {
          //         height: 420px;
          //         width: 100%;
          //       }
          //     }

          //     .QuestionSolution {
          //       padding-top: 20px;

          //       p {
          //         margin-bottom: 5px;
          //       }

          //       h5 {
          //       }

          //       div {
          //         p {
          //           margin-bottom: 2px;
          //         }
          //       }
          //     }

          //     .sub_sub_section_wrapper_6 {
          //       display: flex;
          //       justify-content: space-between;
          //       align-items: center;

          //       .text_content_wrapper {
          //         .text_content {
          //           margin: 0;
          //           font-size: 18px;
          //           font-weight: 600;
          //         }
          //       }
          //     }
          //   }
          // }
        }
      }
    }
  }
}

.onSubmitModal {
  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    margin-top: 0px !important;
    .submit_Popup {
      .exit_img {
        width: 100%;

        img {
          width: 35%;
          height: 130px;
          margin-bottom: 20px;
          margin-top: -25px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #6d48ef;
          border-radius: 6px;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #6d48ef;
        }
        :hover {
        }
        :hover {
          background: #6d28ef;
        }

        .cancel {
          color: #6d48ef;
          background: none;
          margin-right: 10px;
        }
      }
    }
  }
}

///////////////////////////   REPORT ERROR MODAL ///////////////////////////

.errorModal {
  margin-top: 70px;
  .modal-content {
    border-radius: 16px;
  }
  text-align: center;

  .modal-header {
    margin-top: 20px;
    font-weight: 600;
    font-family: "Poppins";
    font-style: normal;
    border-bottom: 0 none;
    padding: 0;

    .btn-close {
      width: 0;
      height: 0;
      margin-right: 20px;
    }

    h3 {
      color: #000000;
      font-weight: 600;
      margin-left: 150px;
      font-size: 20px;
    }
  }

  .modal-body {
    padding: 0;

    .error_Popup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 85%;
      width: 85%;
      margin: auto;
    }

    .error_Popup .text_content {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #606060;
    }

    .errorList {
      text-align: start;
      padding: 0;
      margin: 0;
    }

    .errorList .errorListItem {
      list-style: none;
      text-align: start;
      margin-bottom: 10px;
      font-size: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: #000000;
    }

    .errorList .errorListItem input {
      font-size: 25px;
    }

    .errorList .errorListItem input[type="radio"] {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 8px;
    }

    textarea::-webkit-input-placeholder {
      padding: 0;
      font-size: 14px;
      color: #b8b8b8;
    }

    button {
      padding: 10px 40px;
      font-weight: 500;
      background: #6d48ef;
      border-radius: 4px;
      color: #fff;
    }

    textarea {
      width: 100%;
      height: 85px;
      padding: 10px;
      border-radius: 4px;
      resize: none;
    }
  }
}
.classCorrect {
  border: 1px solid #5ddd9b;
  background: #e1f1ec !important;
}

.classWrong {
  border: 1px solid #f93030;
  background: #f6e0e4 !important;
}

.opacity {
  opacity: 0.5;
}


@media(max-width:578px) {
  .exam_cracker_test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {

      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {

            .section_content_wrapper_backdrop {
              height: 100vh;
              width: 100vw;
              z-index: 1995;
              background-color: #000;
              opacity: 0.5;
              position: absolute;
              display: block;
              top: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
}