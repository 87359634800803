.bookMark_test_questions_view_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .left_side_content_wrapper {
            width: 32%;
            background-color: #ffffff;
            padding: 20px;
            position: sticky;
            top: 0px;

            .sub_section_1_wrapper {
              flex: 0 0 100%;

              .text_content_wrapper {
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 100px;
                .text_content {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #0f0404;
                  margin-left: 6px;
                }

                button {
                  font-weight: bold;
                  text-decoration: underline;
                }
              }

              .questions_serial_number_collection {
                overflow-y: auto;
                height: calc(100vh - 128px);

                .single_question_number_wrapper {
                  margin: 10px 5px;

                  .arrow {
                    width: 8px;
                  }

                  .unanswered_lbl {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    padding: 15px;
                    border-radius: 8px;
                    background-color: #fff;
                    border: 1px solid #e2e2e2;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    background: #f5f4f8;
                    justify-content: space-between;
                    width: 100%;
                    // max-height: 74px;
                    // min-height: 74px;

                    .questionBox {
                      display: flex;
                      align-items: baseline;
                      margin-right: 5px;
                      overflow: hidden;

                      .QBox {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        padding-right: 10px;

                        p {
                          font-size: 14px;
                          margin-left: 2px;
                          margin-bottom: 10px;
                          // overflow-y: hidden;
                          // height: 40px; /* Adjust the height as needed */
                          // max-height: 40px;
                          // min-height: 40px;
                          height: 74px; /* Adjust the height as needed */
                          max-height: 43px;
                          min-height: 43px;
                          word-wrap: break-word;
                          white-space: normal;

                          math {
                            font-family: "Times New Roman", Times, serif;
                            font-size: 15px;
                            // line-height: 1.5;

                            //   font-family: "Poppins";
                            //   font-weight: normal;
                            //   font-size: 13px;
                          }
                        }
                      }
                    }
                  }

                  .answered_lbl {
                    border: 1px solid #e2e2e2;
                    background-color: #48ef4c;
                  }

                  .review_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    padding: 10px;
                    border-radius: 8px;
                    background: #fed5d5;
                    border: 1px solid #ffc4c4;
                    cursor: pointer;
                  }

                  .skip_lbl {
                    border: 1px solid #ffc4c4;
                    background-color: #fed5d5;
                  }

                  .current_lbl {
                    border: 1px solid #ff933a;
                    cursor: pointer;
                    background: inherit;
                  }

                  .isGussedAnswer {
                    border: 1px solid #6d48ef;
                    background-color: #6d48ef !important;
                  }
                }
              }
            }
          }

          .right_side_content_wrapper {
            width: 68%;
            border-radius: 8px;
            padding: 10px;
            background-color: #f5f4f8;
            margin-top: 50px;

            .sub_section_wrapper {
              max-width: 750px;
              margin: 0 auto;
              .testProgressIndicator {
                background: #fff;
                padding: 5px 10px;
                border-radius: 8px;
                margin-bottom: 30px;
                margin-top: 50px;
                h4 {
                  font-size: 14px;
                  color: #000;
                  margin-bottom: 16px;
                }

                .ProgressBar_Line {
                  position: relative;
                }

                .ProgressBar_Line span {
                  position: absolute;
                  top: -16px;
                  font-size: 14px;
                }

                .progress {
                  height: 8px;
                  margin-bottom: 5px;

                  .progress-bar {
                    background: #ff933a;
                    border-radius: 9px;
                  }
                }

                .questionPercentage {
                  display: flex;
                  justify-content: space-between;

                  span {
                    color: #707070;
                    font-size: 14px;
                  }
                }
              }

              .text_content_wrapper {
                .text_content_wrapper_heading {
                  text-align: center;
                  margin-bottom: 20px;
                }
                .typeLanguageWrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 5px;

                  span {
                    color: #707070;
                    font-size: 12px;
                  }

                  .markReview {
                    display: flex;

                    .bookmark_image_wrapper {
                      margin: 0px 10px;

                      span {
                        margin-right: 5px;
                      }

                      img {
                        cursor: pointer;
                      }
                    }
                  }
                }

                .questionBox {
                  display: flex;
                  align-items: baseline;

                  span {
                    margin-right: 5px !important;
                  }

                  .text_content {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: #000;

                    p {
                      margin-bottom: 0px;
                    }
                  }

                  .hide {
                    display: none;
                  }

                  .show {
                    display: block;
                  }
                }
              }

              .sub_sub_section_wrapper_3 {
                //single-select
                .single_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;
                  margin-bottom: 30px;
                  margin-top: -30px;
                  .single_select {
                    width: calc(50% - 8px);
                    margin: 10px 16px 10px 0px;

                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }

                    .classCorrect {
                      border: 1px solid #5ddd9b !important;
                      background: #e1f1ec !important;
                    }

                    .classWrong {
                      border: 1px solid #f93030 !important;
                      background: #f6e0e4 !important;
                    }

                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }

                    .single_option_wrapper {
                      min-height: 50px;
                      background-color: #fff;
                      border-radius: 8px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;

                      .option_initial {
                        margin-right: 10px;

                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #acacac;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #000;

                          p {
                            margin-bottom: 0px;
                          }
                        }
                      }
                    }

                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;

                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }

                .hide {
                  display: none !important;
                }

                .show {
                  display: flex !important;
                }

                .show2 {
                  display: block !important;
                }

                //multi-select
                .multi_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;

                  .single_select {
                    width: calc(50% - 8px);
                    margin: 10px 16px 10px 0px;

                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }

                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }

                    .single_option_wrapper {
                      min-height: 50px;
                      border: solid 1px #f0eef8;
                      background-color: #fafafd;
                      border-radius: 15px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;

                      .option_initial {
                        margin-right: 10px;

                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }
                    }

                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;

                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }

                //integer
                .input_wrapper {
                  .input_inner_wrapper {
                    margin-bottom: 30px;

                    input {
                      border: none;
                      outline: none;
                      width: 100%;
                      border: solid 1px #f0eef8;
                      background-color: #fff;
                      height: 50px;
                      border-radius: 8px;
                      padding: 0 5px;
                      font-size: 16px;
                      font-weight: 500;
                    }

                    input[type="number"]:focus {
                      border: 2px solid #6d48ef;
                    }

                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_5 {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left_side_wrapper {
                  width: calc(50% - 8px);
                  margin-right: 16px;

                  .btn_wrapper {
                    text-align: left;

                    button {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #6d48ef;
                      padding: 10px;
                      width: 100%;
                      border: 1px solid #e2e2e2;
                      // text-decoration: underline;
                    }
                  }
                }

                .right_side_wrapper {
                  width: calc(50% - 8px);

                  .btn_wrapper {
                    text-align: right;

                    button {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #fff;
                      padding: 10px;
                      width: 100%;
                      background: #6d48ef;
                      // text-decoration: underline;
                    }
                  }
                }
              }

              .solutionBoxWrapper {
                padding-top: 20px;

                p {
                  word-break: break-all;
                }
              }

              .videoWrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                .view_videoSolution_btn {
                  background: #ff933a;
                  width: 30%;
                  text-align: center;
                  padding: 10px;
                  border-radius: 10px;
                  margin-bottom: 30px;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: #f5f4f8;
                }
                #iframe {
                  height: 420px;
                  width: 100%;
                }
              }

              .QuestionSolution {
                padding-top: 20px;

                p {
                  margin-bottom: 5px;
                }

                div {
                  p {
                    margin-bottom: 2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
