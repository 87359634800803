.wishList_addCart {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 20px;
  right: 80px;
  .wishList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;
    cursor: pointer;
    .badge {
      background: orange;
      color: #f5f4f8;
      font-weight: bold;
      border-radius: 5px;
      position: relative;
      top: -11px;
      right: 12px;
    }
    .text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #121212;
    }
  }
  .addCart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .badge {
      background: orange;
      color: #f5f4f8;
      font-weight: bold;
      border-radius: 5px;
      position: relative;
      top: -11px;
      right: 12px;
    }
    .text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #121212;
    }
  }
}

.handBooks {
  background: #ffffff;
  margin-top: 10px;
  padding: 20px;
  section {
    margin: 0px 20px;
  }

  .courses_dropDown {
    width: 100%;
    margin-top: 20px;
    border: 0.6px solid #626262;
    box-shadow: 0px 4px 8px rgba(179, 179, 179, 0.12);
    border-radius: 8px;
    padding: 10px 10px;
    cursor: pointer;
  }

  .select_product {
    margin-top: 20px;
    .section_header {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      color: #000000;
      margin-bottom: 10px;
    }
    .select_product_container {
      .row {
        margin: 0px;
        gap: 1rem;

        .col {
          margin-top: 0px;
          padding: 0px;
          width: 23.97%;
          cursor: pointer;
          position: relative;
          .card {
            box-shadow: 0px 4px 8px rgba(135, 135, 135, 0.12);
            .card_img {
              height: 280px;
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
            }
            .bottom_text {
              padding: 18px;
              .card-text {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #000;
              }
              .price {
                font-family: "Poppins";
                font-style: normal;
                font-size: 20px;
                line-height: 27px;
                color: #000;

                .disc_price {
                  font-weight: 600;
                  color: #000;
                  margin-right: 20px;
                }
                strike {
                  font-weight: 500;
                  color: #000;
                }
              }
            }
          }

          .img-overlay {
            .wishlist_icon {
              position: absolute;
              top: 3px;
              right: 3px;
              margin: 0px;
              // width: 60px;
              width: 48px;
              height: 48px;
              opacity: 90;
            }
            .wishlist_icon_filled {
              position: absolute;
              top: 3px;
              right: 3px;
              margin: 0px;
              // width: 50px;
              width: 41px;
              height: 41px;
              opacity: 90;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 578px) {
  .wishList_addCart {
    justify-content: center;
    position: unset;
  }
  .handBooks {
    padding: 0;
    section {
      margin: 0;
    }
    .select_product {
      .select_product_container {
        .row {
          flex-direction: column;
          .col {
            width: 100%;
          }
        }
      }
    }
  }
}
