.privacyPolicyContentComponent_wrapper {
  padding-top: 40px;

  .privacyPolicyContentComponent_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .heading_wrapper {
          .heading_text_content {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0.33px;
            color: #000;
          }
        }
      }
    }

    .sec_2_wrapper {
      p {
        font-size: 14px;
        letter-spacing: 0.42px;
        color: #707070;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .privacyPolicyContentComponent_wrapper {
    padding-top: 70px;
  }
}