.ten_hours_free_container {
  border-radius: 0.5rem;
  border: 0.6px solid rgba(109, 72, 239, 0.28);
  background: #f7f4ff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  width: 1300px;
  margin: 0 auto;

  .firstt {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 1.875rem;
      height: 1.875rem;
      margin-right: 0.85rem;
    }

    .ten_hours_text {
      color: #282828;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      .tenhrs {
        color: #121212;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .free-text {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.0075rem;
        margin-left: 0.44rem;
        padding: 0.13rem 0.37rem;
        border-radius: 0.25rem 0rem;
        background: linear-gradient(
          100deg,
          #db3a38 3.35%,
          #ce3f3d 50.73%,
          #b91311 102.23%
        );
      }
    }
  }

  .secondd {
    img {
      width: 2rem;
      height: 2rem;
    }

    .ten_hours_btn {
      button {
        color: #6d48ef;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.01rem;
      }
    }
  }
}
