
.video-player-container {



    .video-details-watch-count{

      display: flex;
      justify-content: space-between;
      margin-bottom: 0.6rem;
      .video-details{
        display: flex;
        align-items: center;

        .subject-name{

          color: #383838;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-right: 0.25rem;
        }

        .morethan-symbol{
          margin-right: 0.25rem;

          img{
            width: 1rem;
            height: 1rem;
          } 

        }

        .topic-name{

          color: #383838;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

        }
      }

      .watch-count{
        border-radius: 0.25rem;
        border: 0.6px solid #F2F2F2;
        background: #F6F6F7;


        .img-and-count{
          padding:0.5rem 0.75rem;
          color: #121212;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.00375rem;

          img{
            margin-right: 0.5rem;
            width: 1rem;
            height: 1rem;
          }


        }

      }


    }


    .more-details{
margin-top: 1rem;

      .live{ 
        display: flex;
        align-items: center;
        margin-bottom: 0.25rem;

      .live-circle{

        border-radius: 50%;
        width: 0.75rem;
height: 0.75rem;
background: #FF2424;
margin-right: 0.25rem;
      }

      .live-text{

        color: #121212;

font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
      }

    }


    .topic-and-leave-class{

      display: flex;
      justify-content: space-between;
      align-items:center;
      margin-bottom: 1rem;

      .topic{
        color: #121212;
font-family: Poppins;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;


      }

      .leave-class-btn{
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 0.25rem;
background: #B50303;
padding:0.38rem 0.75rem;
cursor: pointer;

      }
    }

    .tutor-details{

      display: flex;
      align-items: center;

      .tutor-profile-pic{

        margin-right: 1rem;

        display: flex;
        align-items: center;

        img{

          width: 2.00894rem;
height: 2.5rem;
margin-right: 0.5rem;

        }

        .tutor-name{
          color: #585858;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
  
        }

      }

     

      .tutor-rating{
        display: flex;
        align-items: center;


        img{
          width: 1.25rem;
         height: 1.25rem;
margin-right: 0.25rem;


        }

        .rating{

          color: #000;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
margin-top: 2.5px;
        }
      }




    }


    }




 

  
  
   
  }

  .is-live{

 width: 920px;
    
  }

  .is-recorded-video{


   

    // width: 1150px;
    width: 1270px;
    height: 650px;


  }