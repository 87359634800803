.scheduleClass_wrapper {
  .scheduleClass_inner_wrapper {
    display: flex;
    justify-content: space-between;

    .scheduleClass_left {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
      padding: 20px;
      width: calc(50% - 8px);

      h4 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.4px;
        color: #000000;
      }

      .sec_2_wrapper {
        margin: 10px 0;

        .date_time_collection {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow-x: auto;

          .single_select_date {
            margin: 5px;

            input[type="radio"] {
              opacity: 1;
              position: fixed;
              width: 0;
            }

            label {
              width: 45px;
              border-radius: 6px;
              background-color: #F5F4F8;
              cursor: pointer;
              padding: 5px;

              .text_content_wrapper_1 {
                .text_content_1 {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 500;
                  text-align: center;
                  color: #000000;
                }
              }

              .text_content_wrapper_2 {
                .text_content_2 {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 500;
                  text-align: center;
                  color: #000000;
                }
              }
            }

            input[type="radio"]:checked+label {
              border: none;
              background-color: #FF933A;

              .text_content_wrapper_1 {
                .text_content_1 {
                  color: #ffffff;
                }
              }

              .text_content_wrapper_2 {
                .text_content_2 {
                  color: #ffffff;
                }
              }
            }
          }
        }

        .date_time_collection::-webkit-scrollbar {
          height: 0px;
        }

        // .date_time_collection::-webkit-scrollbar-track {
        //   box-shadow: inset 0 0 5px grey;
        //   border-radius: 10px;
        // }
        // .date_time_collection::-webkit-scrollbar-thumb {
        //   background-color: #6d48ef45;
        //   border-radius: 10px;
        // }
        // .date_time_collection::-webkit-scrollbar-thumb:hover {
        //   background-color: #6d48ef;
        // }
      }

      .sec_3_wrapper {
        max-height: 295px;
        overflow-y: auto;

        .classes_collection {
          display: flex;
          flex-direction: column;

          .single_class_wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 8px;
            background-color: #faf9fe;
            padding: 10px;
            margin: 0px 0 20px;
            cursor: pointer;

            .left_side_container_wrapper {
              width: auto;

              .image_wrapper {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                margin-left: 10px;
                margin-right: 5px;

                img {
                  width: 100%;
                  min-width: 60px;
                  min-height: 60px;
                }
              }
            }

            .right_side_container_wrapper {
              width: auto;
              margin: 0 10px;
              width: 100%;
              position: relative;

              .text_content_wrapper_1 {
                .text_content_1 {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #000;
                }
              }

              .text_content_wrapper_2 {
                .text_content_2 {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 500;
                  color: #707070;
                }

                .batchNm {
                  color: #000;
                  font-size: 16px;
                }
              }

              .sub_content_wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .left_side_container_wrapper {
                  flex: 0 0 87%;

                  .text_content_wrapper {
                    display: flex;
                    align-items: center;

                    .text_content {
                      margin: 0;
                      font-size: 12px;
                      font-weight: 500;
                      color: #6D48EF;

                      img {
                        margin-right: 5px;
                        vertical-align: sub;
                      }

                      span {
                        margin-right: 5px;
                        color: #000;
                      }
                    }

                    .Ended {
                      background-color: #ff5d00;
                      background-image: linear-gradient(180deg, #ff9600, #ff5d00);
                      padding: 3px 12px;
                      font-size: 13px;
                      color: #fff;
                      border-radius: 5px;
                      margin-left: 12px;
                    }

                    .Upcoming {
                      background-color: #ffa700;
                      background-image: linear-gradient(180deg, #ffa700, #ffd300);
                      padding: 3px 12px;
                      font-size: 13px;
                      color: #fff;
                      border-radius: 5px;
                      margin-left: 12px;
                    }

                    .Live {
                      background-color: #006eff;
                      background-image: linear-gradient(180deg, #006eff, #00a7ff);
                      padding: 3px 12px;
                      font-size: 13px;
                      color: #fff;
                      border-radius: 5px;
                      margin-left: 12px;
                    }
                  }
                }

                // .right_side_container_wrapper {
                //   flex: 0 0 35%;
                //   text-align: right;
                //   .subject_name {
                //     padding: 5px;
                //     border-radius: 5px;
                //     background-image: linear-gradient(
                //       120deg,
                //       #ff9600 37%,
                //       #ff5d00 72%
                //     );
                //     font-size: 12px;
                //     font-weight: 600;
                //     color: #ffffff;
                //   }
                // }
              }

              .arrow_dv {
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
              }
            }
          }

          .no_data_found_wrapper {

            //margin: 5px 0;
            .text_content_wrapper {
              p {
                margin: 0;
                font-size: 18px;
                font-weight: 600;
              }
            }

            img {
              width: 200px;
            }
          }
        }
      }
    }

    .scheduleClass_right {
      width: calc(50% - 8px);

      .my_progress {
        background-color: #fff;
        padding: 20px;
        margin-bottom: 10px;

        h4 {
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 0.4px;
          margin-bottom: 15px;
        }

        .progress_box_wrapper {
          background-color: #F5F4F8;
          padding: 15px 15px 15px 15px;

          .progress_box {
            display: flex;
            align-items: center;

            .progress-circle {
              width: 30%;
              padding: 12px;
              position: relative;

              .CircularProgressbarr{

              }


              .CircularProgressbar {
                background: #fff;
                border-radius: 50%;



              }

              .CircularProgressbar .CircularProgressbar-trail {
                stroke: #f5f4f8;

              }

              .CircularProgressbar .CircularProgressbar-path {
                stroke: #31D680;

              }

              .CircularProgressbar .CircularProgressbar-text {
                fill: #000;
                font-weight: 600;
                dominant-baseline: auto;

              }

              .score {
                color: #707070;
                font-size: 11px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 50%);
                width: 100%;
                text-align: center;
              }
            }

            .prgs_tst_detail {
              position: relative;
              width: 70%;
              margin-left: 35px;

              .tst_dtl_count {
                padding: 3px 0px;

                .tst_count {
                  color: #000;
                  font-weight: 600;
                  min-width: 30px;
                  display: inline-block;
                  margin-right: 10px;
                  text-align: center;
                }

                .tst_name {
                  color: #707070;
                  font-size: 14px;
                }
              }

              .arrow_dv {
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
              }
            }
          }
        }
      }

      .pyq_cyps_wrapper {
        background-color: #fff;
        padding: 20px;

        h4 {
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 0.4px;
          margin-bottom: 15px;
        }

        .pyq_cpsBox {
          .pyqCpsBox {
            background-color: #F5F4F8;
            border: 1px solid #EEEEEE;
            border-radius: 8px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            color: #000;
            text-decoration: none;

            span {
              img {
                width: 75%;
              }
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .scheduleClass_wrapper {
    .scheduleClass_inner_wrapper {
      padding: 10px;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .scheduleClass_wrapper {
    .scheduleClass_inner_wrapper {
      .scheduleClass_left {
        .sec_3_wrapper {
          .classes_collection {
            .single_class_wrapper {
              .right_side_container_wrapper {
                font-size: 14px;

                .text_content_wrapper_1 {
                  .text_content_1 {
                    line-height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .scheduleClass_wrapper {
    .scheduleClass_inner_wrapper {
      padding: 0px;
      flex-direction: column;

      .scheduleClass_left {
        width: 100%;
        padding: 5px;
        margin-bottom: 15px;

        h4 {
          font-size: 18px;
        }

        .sec_2_wrapper {
          .date_time_collection {
            .single_select_date {
              label {
                border-radius: 5px;
                padding: 3px;

                .text_content_wrapper_1 {
                  .text_content_1 {
                    font-size: 12px;
                  }
                }

                .text_content_wrapper_2 {
                  .text_content_2 {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }

      .scheduleClass_right {
        width: 100%;

        .my_progress {
          padding: 10px;

          h4 {
            font-size: 18px;
          }

          .progress_box_wrapper {
            .progress_box {
              flex-direction: column;
              align-items: center;
              .progress-circle {
                width: 50%;
                padding: 0px;
                .CircularProgressbar{
                  width: auto;
                }

                .score {
                  font-size: 9px;
                }
              }

              .prgs_tst_detail {
                width: 100%;
                margin-left: 0;
                .tst_dtl_count {
                  .tst_count {}

                  .tst_name {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }

        .pyq_cyps_wrapper {
          padding: 10px;
        }
      }
    }
  }
}