.userBookmarksContentComponent_wrapper {
  padding-top: 101px;
  min-height: 96vh;

  .userBookmarksContentComponent_inner_wrapper {
    padding: 20px;

    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .heading_wrapper {
          .heading_text_content {
            margin: 0;
            font-size: 28px;
            font-weight: 600;
            letter-spacing: 0.33px;
            color: #463c34;
          }
        }
      }
    }

    .sec_2_wrapper {
      margin: 20px 0;

      .sec_2_inner_wrapper {
        .sub_content_wrapper_1 {
          margin-bottom: 20px;

          .bookmark_option_collection_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .single_select_bookmark_option {
              flex: 0 0 18%;

              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                background-color: #f5f4f8;
                padding: 8px 20px;
                border-radius: 8px;
                font-size: 16px;
                color: #707070;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }

              input[type="radio"]:checked + label {
                background-color: #6d48ef;
                border: none;
                color: #ffffff;
              }
            }
          }
        }

        .sub_content_wrapper_2 {
          margin-top: 10px;

          /////dpp questions///////////////////
          .dpp_collection_view_wrapper {
            .dpp_collection_view_innner_wrapper {
              background-color: #ffffff;
              border-radius: 10px;

              .dpp_question_collection_wrapper {
                display: flex;
                flex-direction: column;
                // height: 400px;
                overflow-y: auto;
                padding: 10px;

                .single_question_wrapper {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #faf9fe;
                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;

                  .left_side_content_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex: 0 0 90%;

                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-image: linear-gradient(
                        149deg,
                        #fff5e6 32%,
                        #ffefe6 76%
                      );
                      padding: 15px;

                      .text_content_wrapper {
                        .text_content {
                          margin: 0;
                          font-size: 20px;
                          font-weight: 600;
                          text-align: center;
                          color: #b35557;
                        }
                      }
                    }

                    .details_wrapper {
                      margin: 0 10px;

                      .text_content_wrapper {
                        p {
                          margin: 0;
                          font-size: 14px;
                          font-weight: 500;
                          color: #6f6868;
                        }
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    flex: 0 0 10%;
                    display: flex;
                    justify-content: center;
                  }
                }

                .not_bookmarks {
                  .null_bkmark_inr {
                    text-align: center;
                    padding-top: 20px;

                    .img_wrp {
                    }

                    p {
                    }

                    a {
                      background-color: #6d48ef;
                      padding: 7px 15px;
                      font-size: 15px;
                      color: #fff;
                      border-radius: 8px;
                      text-decoration: none;
                      display: inline-block;
                    }
                  }
                }
              }

              .dpp_question_collection_wrapper::-webkit-scrollbar {
                width: 7px;
              }

              .dpp_question_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .dpp_question_collection_wrapper::-webkit-scrollbar-thumb {
                background: #6d48ef;
                border-radius: 10px;
              }

              /* Handle on hover */
              .dpp_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #6d48ef;
              }
            }
          }

          /////exercise questions///////////////////

          .exercise_collection_view_wrapper {
            .exercise_collection_view_innner_wrapper {
              background-color: #ffffff;
              border-radius: 10px;

              .exercise_question_collection_wrapper {
                display: flex;
                // flex-wrap: wrap;
                flex-direction: column;
                // height: 400px;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 10px;

                .single_question_wrapper {
                  // width: 32.5%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #faf9fe;
                  padding: 10px;
                  margin: 10px 0;
                  margin-right: 13px;
                  cursor: pointer;

                  &:nth-child(3n + 3) {
                    margin-right: 0px;
                  }

                  .left_side_content_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex: 0 0 90%;

                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-image: linear-gradient(
                        149deg,
                        #fff5e6 32%,
                        #ffefe6 76%
                      );
                      padding: 15px;

                      .text_content_wrapper {
                        .text_content {
                          margin: 0;
                          font-size: 20px;
                          font-weight: 600;
                          text-align: center;
                          color: #b35557;
                        }
                      }
                    }

                    .details_wrapper {
                      margin: 0 10px;

                      .text_content_wrapper {
                        p {
                          margin: 0;
                          font-size: 14px;
                          font-weight: 500;
                          color: #6f6868;
                        }
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    flex: 0 0 10%;
                    display: flex;
                    justify-content: center;
                  }
                }

                .not_bookmarks {
                  width: 100%;

                  .null_bkmark_inr {
                    text-align: center;
                    padding-top: 20px;

                    .img_wrp {
                    }

                    p {
                    }

                    a {
                      background-color: #6d48ef;
                      padding: 7px 15px;
                      font-size: 15px;
                      color: #fff;
                      border-radius: 8px;
                      display: inline-block;
                      text-decoration: none;
                    }
                  }
                }
              }

              .exercise_question_collection_wrapper::-webkit-scrollbar {
                width: 7px;
              }

              .exercise_question_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .exercise_question_collection_wrapper::-webkit-scrollbar-thumb {
                background: #6d48ef;
                border-radius: 10px;
              }

              /* Handle on hover */
              .exercise_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #6d48ef;
              }
            }
          }

          /////exercise questions///////////////////
          .test_collection_view_wrapper {
            .test_collection_view_innner_wrapper {
              background-color: #ffffff;
              border-radius: 10px;

              .test_question_collection_wrapper {
                display: flex;
                flex-direction: column;
                // height: 400px;
                overflow-y: auto;
                padding: 10px;

                .single_question_wrapper {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #faf9fe;
                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;

                  .left_side_content_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex: 0 0 90%;

                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-image: linear-gradient(
                        149deg,
                        #fff5e6 32%,
                        #ffefe6 76%
                      );
                      padding: 15px;

                      .text_content_wrapper {
                        .text_content {
                          margin: 0;
                          font-size: 20px;
                          font-weight: 600;
                          text-align: center;
                          color: #b35557;
                        }
                      }
                    }

                    .details_wrapper {
                      margin: 0 10px;

                      .text_content_wrapper {
                        p {
                          margin: 0;
                          font-size: 14px;
                          font-weight: 500;
                          color: #6f6868;
                        }
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    flex: 0 0 10%;
                    display: flex;
                    justify-content: center;
                  }
                }

                .not_bookmarks {
                  .null_bkmark_inr {
                    text-align: center;
                    padding-top: 20px;

                    .img_wrp {
                    }

                    p {
                    }

                    button {
                      background-color: #6d48ef;
                      padding: 7px 15px;
                      font-size: 15px;
                      color: #fff;
                      border-radius: 8px;
                    }
                  }
                }
              }

              .test_question_collection_wrapper::-webkit-scrollbar {
                width: 7px;
              }

              .test_question_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .test_question_collection_wrapper::-webkit-scrollbar-thumb {
                background: #6d48ef;
                border-radius: 10px;
              }

              /* Handle on hover */
              .test_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #6d48ef;
              }
            }
          }

          ///////////videos//////////////
        

          .video_collection_view_wrapper {
            .video_collection_view_innner_wrapper {
              border-radius: 10px;

              .videos_collection_wrapper {
                display: flex;
                flex-wrap: wrap;

                .not_bookmarks {
                  width: 100%;
                }

                .single_video_wrapper {
                  width: 32.5%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #faf9fe;
                  padding: 7px;
                  margin: 10px 0;
                  margin-right: 13px;
                  cursor: pointer;

                  &:nth-child(3n + 3) {
                    margin-right: 0px;
                  }

                  .left_side_content_wrapper {
                    width: 100%;
                    flex: 0 0 100px;

                    .image_wrapper {
                      text-align: center;
                      padding: 3px 0px;

                      img {
                        width: 100px;
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    flex: auto;
                    margin-left: 10px;
                    width: 100%;

                    button {
                      display: inline-block;
                      background-color: #ff933a;
                      color: #fff;
                      font-size: 13px;
                      padding: 2px 10px;
                      border-radius: 4px;
                    }

                    .right_side_content_wrapper_top {
                      display: flex;
                      justify-content: space-between;
                      margin-right: 13px;

                      .text_content_wrapper_1 {
                        .text_content_1 {
                          font-size: 14px;
                          font-weight: 500;
                          color: #0f0404;
                          line-height: 18px;
                          margin-bottom: 4px;
                        }
                      }
                    }

                    .text_content_wrapper_2 {
                      display: flex;
                      justify-content: space-between;
                      margin-right: 10px;
                      .text_content_2 {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: #6f6868;
                      }
                    }

                    .sub_content_wrapper {
                      text-align: right;
                      padding-right: 10px;
                      width: 100%;

                      .label_content_wrapper {
                        label {
                          span {
                            vertical-align: middle;

                            img {
                              width: 13px;
                              height: 13px;
                            }
                          }

                          .rating {
                            vertical-align: bottom;
                            font-size: 14px;
                            margin-left: 3px;
                          }
                        }
                      }

                      .right_side_content_wrapper {
                        text-align: right;
                        margin: 0;
                        padding-top: 20px;
                        width: auto;

                        .subject_name {
                          padding: 5px;
                          border-radius: 5px;
                          background-image: linear-gradient(
                            120deg,
                            #ff9600 37%,
                            #ff5d00 72%
                          );
                          font-size: 12px;
                          font-weight: 600;
                          color: #ffffff;
                        }
                      }
                    }

                    .resume_btn {
                      text-align: right;
                      padding-top: 15px;

                      span {
                        background: #668de1;
                        display: inline-block;
                        font-size: 10px;
                        color: #fff;
                        padding: 3px 8px;
                        border-radius: 4px;
                      }
                    }
                  }
                }

                .not_bookmarks {
                  .null_bkmark_inr {
                    text-align: center;
                    padding-top: 20px;

                    .img_wrp {
                    }

                    p {
                    }

                    button {
                      background-color: #6d48ef;
                      padding: 7px 15px;
                      font-size: 15px;
                      color: #fff;
                      border-radius: 8px;
                    }
                  }
                }
              }

              .videos_collection_wrapper::-webkit-scrollbar {
                width: 7px;
              }

              .videos_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .videos_collection_wrapper::-webkit-scrollbar-thumb {
                background: #6d48ef;
                border-radius: 10px;
              }

              /* Handle on hover */
              .videos_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #6d48ef;
              }

              .navigation_btn_wrapper {
                margin: 10px 0;

                .navigation_btn_inner_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .btn_wrapper {
                    button {
                      border: none;
                      outline: none;
                      width: auto;
                      height: 45px;
                      border-radius: 5px;
                      border: 2px solid #6d48ef;
                      background-color: #6d48ef;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #ffffff;
                      padding: 0 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userBookmarksContentComponent_wrapper {
    padding-top: 50px;

    .userBookmarksContentComponent_inner_wrapper {
      padding: 0px;

      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .sub_content_wrapper_1 {
            .bookmark_option_collection_wrapper {
              .single_select_bookmark_option {
                flex: 0 0 50%;
                padding: 5px;
              }
            }
          }
        }
      }
    }
  }
}
